import styled from 'styled-components/macro'
import { NotesRounded } from '@mui/icons-material'
import { InputBase } from '@mui/material'

export const SwipeableBar = styled.span`
  margin: 0;
  padding: 0;
  width: 40px;
  height: 3.75px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: table;
`

export const DrawerTitle = styled.h2`
  color: #000000;
  font-size: 18px;
  margin: 12px 0 16px 0;
  padding: 0;
`

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 20px;
`

export const OptionNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`

export const OptionIcon = styled.img`
  margin-right: 6px;
  width: 15px;
`

export const OptionName = styled.h3`
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
`

export const MenuIcon = styled(NotesRounded)`
    color: ${props => props.theme.palette.icons.primary.light};
`

export const Searcher = styled(InputBase)`
    width: 100%;
    color: ${props => props.theme.palette.text.primary.main};
`

export const RecentPlacesTitle = styled.h2`
  color: #222831;
  font-size: 16px;
  margin: 0;
  margin-bottom: 14px;
`
