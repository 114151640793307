import { db } from '../firebase/firebase-config'
import { collection, getDocs } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

export const getUserLines = async () => {
  const auth = getAuth()
  const user = auth?.currentUser
  const lines = []

  if (!user) {
    throw new Error('Error to getting lines, user not exists')
  }

  try {
    const querySnapshot = await getDocs(collection(db, `users/${user?.uid}/lines`))

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      lines.push({
        ...doc.data(),
        id: doc.id
      })
    })

    return lines
  } catch (e) {
    console.error('Error getting lines')
  }
}
