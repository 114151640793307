import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const getStopById = async (params) => {
  const auth = getAuth()
  const user = auth?.currentUser
  const token = await user?.getIdToken()

  const reqBody = {
    city_id: params?.city_id,
    stop_id: params?.stop_id
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return (
    fetch(`${REACT_APP_DATABASE_HOST}/stops/getById`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error getting current stop')
        }
      })
      .then((response) => response?.result)
      .catch((err) => console.error('Error', err))
  )
}

getStopById.propTypes = {
  token: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
}
