import { useEffect, useState } from 'react'
import { Box, Button, IconButton, Typography, useMediaQuery, FormControl, InputLabel, TextField, Select, FormHelperText, InputAdornment, Tooltip, styled, tooltipClasses } from '@mui/material'
import { Layout } from '../../uicomponents/layouts/Layout'
import { CustomMap } from '../../uicomponents/Map'
import { theme } from '../../theme/theme'
import { useBlocker, useNavigate } from 'react-router-dom'
import { ArrowBackRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useDispatch, useSelector } from 'react-redux'
import { getTicketValidationUserStats } from '../../db/getTicketValidationUserStats'
import { REACT_APP_SHOW_LOGIN, REACT_APP_TICKETS_REWARDS_PROGRAM } from '../../constants/config'
import UserProfilePreview from '../Desktop/UserProfilePreview'
import { getCityConfig } from '../../db/getCityConfig'
import { setCurrentCityConfig } from '../../actions/ui'
import { setUserTicketRewardStats } from '../../actions/user'
import { FAQSection } from '../RewardTickets'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { validateTicket } from '../../db/validateTicket'
import { LoadingButton } from '@mui/lab'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import JSConfetti from 'js-confetti'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'
import { getDownloadURL, ref, getStorage, uploadBytes } from 'firebase/storage'
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { ConfirmDialog } from '../../uicomponents/ConfirmDialog'
import { logEvent } from '../../firebase/firebase-config'

/**
 * Renders the RewardTicketsValidate component.
 * This component is responsible for validating reward tickets.
 *
 * @returns {JSX.Element} The rendered RewardTicketsValidate component.
 */
export default function RewardTicketsValidate () {
  const jsConfetti = new JSConfetti()

  const prices = [
    '1.00',
    '1.50',
    '2.00',
    '2.50',
    '3.00',
    '3.50',
    '4.00',
    '4.50',
    '5.00',
    '5.50',
    '6.00',
    '6.50',
    '7.00',
    '7.50',
    '8.00',
    '8.50',
    '9.00',
    '9.50',
    '10.00'
  ]

  const isMobile = useMediaQuery('(max-width:480px)')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector((state) => state.user.auth)
  const cityConfig = useSelector((state) => state.ui.cityConfig)
  const userTicketRewardStats = useSelector((state) => state.user.userTicketRewardStats)

  const searchParams = new URLSearchParams(window.location.search)

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState('')
  const [price, setPrice] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')
  const [image, setImage] = useState(null)
  const [faqOpen, setFaqOpen] = useState(false)
  const [formErrors, setFormErrors] = useState({
    company: {
      error: false,
      message: ''
    },
    price: {
      error: false,
      message: ''
    },
    ticketNumber: {
      error: false,
      message: ''
    },
    image: {
      error: false,
      message: ''
    }
  })
  const [resultComponentOpen, setResultComponentOpen] = useState(false)
  const [validationResponse, setValidationResponse] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  useEffect(() => {
    if (!REACT_APP_TICKETS_REWARDS_PROGRAM) return navigate('/')
    if (auth && auth?.isAnonymous && !searchParams.get('custom_token')) return navigate('/login')

    const cityId = searchParams.get('city_id')

    if (!cityConfig && cityId && auth) {
      getCityConfig(cityId)
        .then((response) => {
          dispatch(setCurrentCityConfig(response))
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [auth, cityConfig])

  useEffect(() => {
    setLoading(true)
    const cityId = searchParams.get('city_id')

    if ((cityConfig || cityId) && auth && !auth?.isAnonymous && !userTicketRewardStats) {
      getTicketValidationUserStats(cityConfig?.city_id || cityId)
        .then((response) => {
          dispatch(setUserTicketRewardStats(response))
          setLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [cityConfig, auth])

  const handleBack = () => {
    if (!document.startViewTransition) {
      navigate(-1)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => navigate(-1))
  }

  const handleFAQOpen = () => {
    // log event
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null
    }

    logEvent('ticket_rewards_faq', eventParams)
    if (!document.startViewTransition) {
      setFaqOpen(!faqOpen)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => setFaqOpen(!faqOpen))
  }

  /**
   * Validates the form inputs and performs the necessary actions if the form is valid.
   * @returns {Promise<void>} A promise that resolves when the form validation is complete.
   */
  const validateForm = async () => {
    const errors = {
      company: {
        error: false,
        message: ''
      },
      price: {
        error: false,
        message: ''
      },
      ticketNumber: {
        error: false,
        message: ''
      },
      image: {
        error: false,
        message: ''
      }
    }

    if (!company) {
      errors.company.error = true
      errors.company.message = t('tickets_rewards_program.select_company_error')
    }

    if (!price) {
      errors.price.error = true
      errors.price.message = t('tickets_rewards_program.select_price_error')
    }

    if (!image) {
      errors.image.error = true
      errors.image.message = t('tickets_rewards_program.select_image_error')
    }

    const regex = company && new RegExp(userTicketRewardStats.find((item) => item.id === company).ticket_valid_regex)

    if (!ticketNumber || !regex.test(ticketNumber)) {
      errors.ticketNumber.error = true
      errors.ticketNumber.message = t('tickets_rewards_program.select_ticket_number_error')
    }

    setFormErrors(errors)

    if (!errors.company.error && !errors.price.error && !errors.ticketNumber.error && !errors.image.error) {
      setLoading(true)

      const storage = getStorage()
      const storageRef = ref(storage, `transportation/cities/${cityConfig.city_id}/validated_ticket_pictures/${auth.uid}_${ticketNumber}_${Date.now()}`)

      const metadata = {
        contentType: image.type
      }

      try {
        await uploadBytes(storageRef, image, metadata)
      } catch (err) {
        console.error(err)
        setLoading(false)
        return
      }

      const downloadURL = await getDownloadURL(storageRef)

      // send data to backend
      const params = {
        cityId: cityConfig.city_id,
        ticketId: ticketNumber,
        agencyId: userTicketRewardStats.find((item) => item.id === company).agency_id,
        ticketFare: price,
        ticketPictureUrl: downloadURL
      }

      const eventParams = {
        lat: userPosition?.lat ? userPosition.lat : null,
        lng: userPosition?.lng ? userPosition.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: auth.uid,
        user_birthday_timestamp: userData?.birthday?.long_value || null,
        user_gender: userData?.gender || null, // String
        ticket_agency_id: company,
        ticket_price: price,
        ticket_id: ticketNumber,
        agency_id: userTicketRewardStats.find((item) => item.id === company).agency_id
      }

      try {
        const response = await validateTicket(params)
        setValidationResponse(response)
        dispatch(setUserTicketRewardStats(response.ticketAgenciesAndUserStats))
        // log event

        logEvent('ticket_validation_result', { ...eventParams, success: true })
        jsConfetti.addConfetti()
      } catch (err) {
        // log event
        logEvent('ticket_validation_result', { ...eventParams, success: false })
        const errorObj = {
          error: true,
          message: err.message
        }
        setValidationResponse(errorObj)
      } finally {
        setLoading(false)
        setResultComponentOpen(true)
        // reset form
        setCompany('')
        setPrice('')
        setTicketNumber('')
        setImage(null)
        setFormErrors({
          company: {
            error: false,
            message: ''
          },
          price: {
            error: false,
            message: ''
          },
          ticketNumber: {
            error: false,
            message: ''
          },
          image: {
            error: false,
            message: ''
          }
        })
      }
    }
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      padding: '0px',
      borderRadius: '16px'
    }
  }))

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !resultComponentOpen && (company || price || ticketNumber || image) &&
      currentLocation.pathname !== nextLocation.pathname
  )

  const buttons = [
    {
      label: t('tickets_rewards_program.unsave_changes_confirmation_leave_button'),
      onClick: () => blocker.proceed(),
      sx: {
        borderRadius: '16px'
      }
    },
    {
      label: t('tickets_rewards_program.unsave_changes_confirmation_cancel_button'),
      onClick: () => blocker.reset(),
      sx: {
        borderRadius: '16px',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  ]

  return (
    <Layout>
      <Box
        sx={{
          width: '450px',
          height: 'calc(100% - 32px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          zIndex: '430',
          backgroundColor: '#ffffff',
          borderRadius: '16px',
          boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px',
          position: 'absolute',
          top: '16px',
          left: '16px',
          padding: '16px',
          '@media (max-width:480px)': {
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            padding: 0
          }
        }}
      >
        <Box
          sx={{
            display: `${!searchParams.get('custom_token') ? 'flex' : 'none'}`,
            width: '100%',
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 8px',
            '@media (max-width:480px)': {
              backgroundColor: theme.palette.primary.main,
              padding: '10px 5px',
              alignItems: 'center'
            }
          }}
        >
          <Box
            sx={{
              display: `${!searchParams.get('custom_token') ? 'flex' : 'none'}`,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row'
            }}
          >
            <IconButton
              onClick={handleBack}
            >
              <ArrowBackRounded
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />
            </IconButton>
            <Typography
              sx={{
                color: '#222831',
                fontSize: '16px',
                margin: '0 0 0 8px',
                '@media (max-width:480px)': {
                  color: '#fff',
                  margin: 0
                }
              }}
              variant='h1'
            >
              {!faqOpen && t('tickets_rewards_program.reward_tickets_page_title')}
              {faqOpen && t('tickets_rewards_program.faq_title')}
            </Typography>
          </Box>
          <IconButton
            id='faq_button'
            onClick={handleFAQOpen}
          >
            {!faqOpen &&
              <HelpRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}
            {faqOpen &&
              <ClearRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'auto',
            height: 'inherit'
          }}
        >
          {!faqOpen && !resultComponentOpen &&
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 10px',
                gap: '20px',
                overflowY: 'auto'
              }}
            >
              <Typography
                variant='h1'
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  fontWeight: '800',
                  margin: '0'
                }}
              >
                {t('tickets_rewards_program.reward_tickets_validate_page_hero_title')}
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  validateForm()
                }}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px'
                }}
              >
                <FormControl
                  error={formErrors.company.error}
                >
                  <InputLabel
                    shrink
                    id='company'
                  >
                    {t('tickets_rewards_program.company_label')}
                  </InputLabel>
                  <Select
                    disabled={loading}
                    notched
                    sx={{
                      borderRadius: '16px'
                    }}
                    native
                    variant='outlined'
                    labelId='company'
                    id='company'
                    name='company'
                    label={t('tickets_rewards_program.company_label')}
                    value={company}
                    onChange={(e) => {
                      setCompany(Number(e.target.value))
                      setFormErrors({
                        ...formErrors,
                        company: {
                          error: false,
                          message: ''
                        }
                      })
                    }}
                    error={formErrors.company.error}
                  >
                    <option value=''>{t('tickets_rewards_program.select_company')}</option>
                    {userTicketRewardStats.map((item) => (
                      <option key={item.id} value={item.id}>{item.agency_name}</option>
                    ))}
                  </Select>
                  {formErrors.company.error &&
                    <FormHelperText>
                      {formErrors.company.message}
                    </FormHelperText>}
                </FormControl>
                {company &&
                  <FormControl
                    error={formErrors.price.error}
                  >
                    <InputLabel
                      shrink
                      id='price'
                    >
                      {t('tickets_rewards_program.price_label')}
                    </InputLabel>
                    <Select
                      disabled={loading}
                      notched
                      displayEmpty
                      sx={{
                        borderRadius: '16px'
                      }}
                      native
                      variant='outlined'
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value)
                        setFormErrors({
                          ...formErrors,
                          price: {
                            error: false,
                            message: ''
                          }
                        })
                      }}
                      labelId='price'
                      id='price'
                      name='price'
                      label={t('tickets_rewards_program.price_label')}
                      error={formErrors.price.error}
                    >
                      <option value=''>{t('tickets_rewards_program.select_price')}</option>
                      {prices.map((price) => (
                        <option key={price} value={price}>{price}</option>
                      ))}
                    </Select>
                    {formErrors.price.error &&
                      <FormHelperText>
                        {formErrors.price.message}
                      </FormHelperText>}
                  </FormControl>}
                {company &&
                  <TextField
                    disabled={loading}
                    type={userTicketRewardStats.find((item) => item.id === company).ticket_valid_id_example.match(/[a-z]/i) ? 'text' : 'number'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      style: { borderRadius: '16px' },
                      endAdornment:
  <InputAdornment position='end'>
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <CustomTooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={tooltipOpen}
          placement='bottom-end'
          title={
            <>
              {company &&
                <img
                  src={userTicketRewardStats.find((item) => item.id === company).ticket_sample_picture_url}
                  width='100%'
                  height='auto'
                  alt='Ticket'
                  style={{
                    borderRadius: '16px'
                  }}
                />}
            </>
      }
        >
          <IconButton
            onClick={() => setTooltipOpen(!tooltipOpen)}
            edge='end'
          >
            <InfoRoundedIcon />
          </IconButton>
        </CustomTooltip>
      </div>
    </ClickAwayListener>
  </InputAdornment>
                    }}
                    id='ticketNumber'
                    name='ticketNumber'
                    label={t('tickets_rewards_program.ticket_number_label')}
                    variant='outlined'
                    // value={ticketNumber}
                    onChange={(e) => {
                      setTicketNumber(e.target.value)
                      setFormErrors({
                        ...formErrors,
                        ticketNumber: {
                          error: false,
                          message: ''
                        }
                      })
                    }}
                    fullWidth
                    placeholder={`${t('tickets_rewards_program.example')}: ${userTicketRewardStats.find((item) => item.id === company).ticket_valid_id_example}`}
                    error={formErrors.ticketNumber.error}
                    helperText={formErrors.ticketNumber.message}
                  />}
              </form>
              {company &&
                <ImageUploader
                  setFormErrors={setFormErrors}
                  formErrors={formErrors}
                  setImage={setImage}
                  loading={loading}
                />}
            </Box>}
          {faqOpen && <FAQSection open={faqOpen} t={t} />}

          {resultComponentOpen && validationResponse && !faqOpen &&
            <ValidationResponseComponent
              response={validationResponse}
              setResultComponentOpen={setResultComponentOpen}
              company={company}
              price={price}
              ticketNumber={ticketNumber}
            />}
        </Box>
        {!faqOpen && !resultComponentOpen &&
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '16px',
              backgroundColor: '#EFEFEF',
              width: '100%',
              borderRadius: '0 0 16px 16px'
            }}
          >
            <LoadingButton
              disabled={loading || !company || !price || !ticketNumber || !image}
              loading={loading}
              onClick={() => validateForm()}
              startIcon={<CheckCircleRoundedIcon />}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                padding: '12px',
                borderRadius: '14px',
                fontSize: '14px',
                fontWeight: '800',
                cursor: 'pointer',
                width: '100%',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main
                }
              }}
            >
              {t('tickets_rewards_program.validate_code')}
            </LoadingButton>
          </Box>}
      </Box>
      {!isMobile && (
        <CustomMap
          showDraggableDestinationMarkers={false}
        />
      )}
      {!isMobile && REACT_APP_SHOW_LOGIN &&
        <UserProfilePreview />}

      <ConfirmDialog
        icon={<InfoRoundedIcon
          sx={{
            color: '#ff5353'
          }}
              />}
        open={blocker.state === 'blocked'}
        title={t('tickets_rewards_program.unsave_changes_confirmation_title')}
        content={t('tickets_rewards_program.unsave_changes_confirmation_content')}
        buttons={buttons}
        onClose={() => blocker.reset()}
      />
    </Layout>
  )
}

/**
 * Renders a validation response component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.response - The validation response object.
 * @param {boolean} props.setResultComponentOpen - The function to set the result component open state.
 * @returns {JSX.Element} The validation response component.
 */
const ValidationResponseComponent = ({ response, setResultComponentOpen, company, price, ticketNumber }) => {
  const { t } = useTranslation()
  const { error, message, rewardPoints } = response
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)

  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state.user.auth)
  const cityConfig = useSelector(state => state.ui.cityConfig)

  const handleClose = () => {
    if (!error) {
      const eventParams = {
        lat: userPosition?.lat ? userPosition.lat : null,
        lng: userPosition?.lng ? userPosition.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: auth.uid,
        user_birthday_timestamp: userData?.birthday?.long_value || null,
        user_gender: userData?.gender || null, // String
        ticket_agency_id: company,
        ticket_price: price,
        ticket_id: ticketNumber,
        agency_id: company
      }

      logEvent('ticket_rewards_return', eventParams)
    }

    if (!document.startViewTransition) {
      return error ? setResultComponentOpen(false) : navigate(`/rewards/tickets${searchParams.get('custom_token') ? `?custom_token=${searchParams.get('custom_token')}` : ''}`)
    }

    // With a View Transition:
    document.startViewTransition(() => error ? setResultComponentOpen(false) : navigate(`/rewards/tickets${searchParams.get('custom_token') ? `?custom_token=${searchParams.get('custom_token')}` : ''}`))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px'
        }}
      >
        {error
          ? <CancelRoundedIcon
              sx={{
                color: 'red',
                fontSize: '100px'
              }}
            />
          : <CheckCircleRoundedIcon
              sx={{
                color: 'green',
                fontSize: '100px'
              }}
            />}
        <Typography
          variant='h1'
          sx={{
            color: error ? 'red' : 'green',
            fontSize: '20px',
            fontWeight: '800'
          }}
        >
          {message}
        </Typography>
        {!error &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#96EDB580',
              color: 'green',
              padding: '10px 20px',
              borderRadius: '50px',
              fontSize: '24px',
              fontWeight: '800'
            }}
          >
            {t('tickets_rewards_program.validation_component.points_earned', { points: rewardPoints })}
          </Box>}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '16px',
          backgroundColor: '#EFEFEF',
          width: '100%',
          borderRadius: '0 0 16px 16px'
        }}
      >

        <Button
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            padding: '12px',
            borderRadius: '14px',
            fontSize: '14px',
            fontWeight: '800',
            cursor: 'pointer',
            width: '100%',
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            }
          }}
          onClick={handleClose}
        >
          {!error && t('tickets_rewards_program.validation_component.success_button')}
          {error && t('tickets_rewards_program.validation_component.error_button')}
        </Button>
      </Box>
    </Box>
  )
}

/**
 * ImageUploader component for uploading and previewing images.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setFormErrors - Function to set form errors.
 * @param {Object} props.formErrors - Object containing form errors.
 * @param {Function} props.setImage - Function to set the image.
 * @param {boolean} props.loading - Flag indicating if the image is being loaded.
 * @returns {JSX.Element} - The ImageUploader component.
 */
const ImageUploader = ({ setFormErrors, formErrors, setImage, loading }) => {
  const { t } = useTranslation()
  const [imagePreview, setImagePreview] = useState(null)
  const [showingImage, setShowingImage] = useState(false)

  useEffect(() => {
    const dropzone = new Dropzone('#dropzone', {
      uploadMultiple: false,
      acceptedFiles: 'image/*',
      maxFiles: 1,
      previewsContainer: false
    })

    dropzone.on('addedfile', (file) => {
      setShowingImage(true)
      setFormErrors({
        ...formErrors,
        image: {
          error: false,
          message: ''
        }
      })
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        setShowingImage(false)
        setImagePreview(reader.result)
        setImage(file)
      }
    })

    return () => {
      dropzone.destroy()
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview)
      }
    }
  }, [imagePreview])

  return (
    <div>
      <form
        id='dropzone'
        style={{
          borderStyle: 'dashed',
          borderWidth: '2px',
          borderColor: `${formErrors.image.error ? 'red' : '#D1D5DB'}`,
          borderRadius: '16px',
          aspectRatio: '16 / 9',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative',
          margin: '0',
          padding: '16px'
        }}
        action='#'
      >
        {!imagePreview && !showingImage && (
          <>
            <CenterFocusStrongRoundedIcon
              sx={{
                fontSize: '50px',
                pointerEvents: 'none'
              }}
            />
            <strong
              style={{
                fontSize: '16px',
                marginTop: '1rem',
                color: '#1F2937',
                textAlign: 'center',
                pointerEvents: 'none'
              }}
            >
              {t('tickets_rewards_program.image_upload_component')}
            </strong>
          </>
        )}
        {imagePreview && (
          <img
            src={imagePreview}
            alt='Preview'
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '16px',
              opacity: 0.5
            }}
          />
        )}
        {/* Add a button to remove the image */}
        {imagePreview && !loading && (
          <IconButton
            onClick={() => {
              setImagePreview(null)
              setImage(null)
              setFormErrors({
                ...formErrors,
                image: {
                  error: false,
                  message: ''
                }
              })
            }}
            sx={{
              position: 'absolute',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              top: '50%',
              right: '50%',
              transform: 'translate(50%, -50%)',
              padding: '20px',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        )}
        {(loading || showingImage) &&
          <CircularProgress
            sx={{
              position: 'absolute',
              padding: 0,
              margin: 0
            }}
          />}
      </form>
      {formErrors.image.error &&
        <span
          style={{
            color: 'red',
            fontSize: '12px',
            margin: '0',
            marginLeft: '14px'
          }}
        >
          {formErrors.image.message}
        </span>}
    </div>
  )
}
