import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST, REACT_APP_VARIANT_ID } from '../constants/config'

export const validateTicket = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(params)
  }

  try {
    const response = await fetch(`${REACT_APP_DATABASE_HOST}/rewards/tickets/validateTicket`, reqConfig)
    if (response.ok) {
      return response.json()
    } else if (response.status === 400) {
      const errorData = await response.json()
      throw new Error(errorData.error)
    } else {
      throw new Error('Error validating ticket')
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}
