import styled from 'styled-components/macro'
import { Chip, Fab, IconButton } from '@mui/material'
import { FlagRounded, ShareRounded, ThumbUpAltRounded } from '@mui/icons-material'

export const ReportContainer = styled.div`  
    padding: ${props => props.mode ? '16px' : '16px 32px'};
    background-color: white;
    margin-top: ${props => props?.mode ? '0' : '34px'};
    display: flex;
    align-items: flex-start;
    border-radius: 16px;   
`

export const ReportInformation = styled.div`
    width: 100%;
`

export const Container = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Information = styled.div` 
    margin-left: 55px;
`

export const Name = styled.p`
    margin: 0;   
    color: #828282;
    font-weight: 600; 
`

export const Time = styled.span`
    margin: 0;   
    color: #828282;
    font-size: 14px;
`

export const Type = styled(Chip)` 
    margin: 0 0 10px 0;
    background-color: #ffeded !important;
    color: #b30d19 !important;
    font-weight: 600; 
`

export const ReportButton = styled(IconButton)` 
`

export const ReportIcon = styled(FlagRounded)` 
`

export const LinesAffected = styled.div` 
    margin: 0 0 5px 0;
    max-height: 74px;
    overflow: hidden auto; 
     
    ::-webkit-scrollbar { 
        width: 6px;
        height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {   
        border-radius: 7px;
        background-color: #E0E8F7;  
    }
    
    /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #BCBEC0; 
        border-radius: 7px;
        height: 30px;
    }
`

export const Affected = styled(Chip)`
    background-color: ${props => props?.color} !important;
    color: white !important; 
    margin: 0 5px 5px 0;
`

export const VowsIcon = styled(ThumbUpAltRounded)` 
    margin-right: 10px;   
    color: #959595;
`

export const Vows = styled.p`
    margin: 0;   
    color: #959595;
`

export const ShareButton = styled(Chip)`  
`

export const ShareIcon = styled(ShareRounded)` 
    color: white;
`

export const Text = styled.div` 
    margin: ${props => props.mode ? '0' : '0 0 10px 0'};
    max-width: ${props => props.mode ? 'auto' : '215px'}; 
    overflow: hidden auto; 
    max-height: 99px;
    color: #828282;
    
    ::-webkit-scrollbar { 
        width: 6px;
        height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {   
        border-radius: 7px;
        background-color: #E0E8F7;  
    }
    
    /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #BCBEC0; 
        border-radius: 7px;
        height: 30px;
    }
    
`
export const UserInformation = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const VowsContainer = styled.div` 
    display: none;
    align-items: center;
    justify-content: center;
`

export const Buttons = styled.div`  
    display: flex;
    align-items: center;   
    justify-content: right;
    position: relative;
    margin-top: 16px;
`

export const Picture = styled.img`  
    width: 100px;
    height: 100px;
    border-radius: 8px;
    top: -110px;
    position: absolute;
`

export const AddButton = styled(Fab)`   
`

export const ReportImage = styled.div`   
    background-image: url(${props => props?.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    width: 100%;
    border-radius: 8px;
`
