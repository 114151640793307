import React, { useEffect, useState } from 'react'
import {
  BackIcon,
  ButtonFilterIcon,
  CloseIcon,
  Container,
  Distance,
  FiltersContainer,
  FindPlaceButton,
  FindPlaceContainer,
  Icon,
  IconContainer,
  NavigationButton,
  NavigationIcon,
  PlaceCity,
  PlaceInformation,
  PlaceItem,
  PlaceName,
  RecentPlacesContainer,
  SearchContainer,
  Searcher,
  SearcherContainer,
  SearchIcon,
  SearchIconContainer,
  SearchResults,
  WaterMark
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  enabledAlertsAndNewsComponent,
  enableTransportFiltersComponent,
  setButtonNavbarSelected,
  setFromPlacesSelected,
  setLinesByAgency,
  setOpenTripPreview,
  setSearch,
  setShowLines,
  setShowNavbar,
  setShowSearcher,
  setToPlacesSelected
} from '../../../actions/ui'
import { useNavigate, useLocation } from 'react-router-dom'
import { getPlacesByCity } from '../../../db/getPlacesByCity'
import { getLocalRecentPlaces } from '../../../db/getLocalRecentPlaces'
import { motion } from 'framer-motion'
import { getDistanceFromLatLonInKm, getPlacesIcon } from '../../../utils'
import { getPlacesAutoSuggest } from '../../../db/getPlacesAutoSuggest'
import _ from 'lodash'
import { setZoom } from '../../../actions/map'
import { SearchNavbar } from '../../../uicomponents/desktop/search/SearchNavbar'
import SavedPlaces from '../../SavedPlaces'
import { RecentPlacesTitle } from '../../Search/styles'
import { setLocalRecentPlaces } from '../../../db/setLocalRecentPlaces'
import { useLines } from '../../../hooks/useLines'
import { FiltersSkeleton } from '../../../uicomponents/desktop/lines/FiltersSkeleton'
import { useTranslation } from 'react-i18next'
import { setPointsOfInterest } from '../../../db/setPointsOfInterest'
import { theme } from '../../../theme/theme'
import { Button, IconButton, Skeleton } from '@mui/material'
import { AllInclusive, HistoryRounded } from '@mui/icons-material'
import { setTransportationType } from '../../../actions/user'
import maplibregl from 'maplibre-gl'

export const Search = ({
  showTransportFilters = false
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { t } = useTranslation()

  const {
    handleSearch
  } = useLines()

  const { autocomplete } = useSelector(state => state?.ui?.components)
  const { term } = useSelector(state => state?.ui?.search)
  const { orientation } = useSelector(state => state?.ui)
  const { bounds } = useSelector(state => state?.map)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const savedPlaces = useSelector(state => state?.user?.savedPlaces)
  const lines = useSelector(state => state?.ui.lines)
  const transportIdSelected = useSelector(state => state?.user.lines?.transportationType)

  const [places, setPlaces] = useState()
  const [recentPlaces, setRecentPlaces] = useState()
  const [showRecentPlaces, setShowRecentPlaces] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const transportationTypes = cityConfig?.transport_types?.filter(item => item?.third_party_search === false)
  const lineSearchResult = useSelector(state => state?.user?.lines?.lineSearchResult)

  useEffect(() => {
    if (cityConfig) {
      getLocalRecentPlaces()
        .then((response) => {
          if (response) {
            // Recent places by current city
            const recent = response?.filter((recent) => recent?.city_id === cityConfig?.city_id)
            setRecentPlaces(recent)
          }
        })
    }
  }, [places, cityConfig])

  useEffect(() => {
    if (autocomplete?.enabled) {
      if (term.length < 3) {
        recentPlaces?.length > 0 && setShowRecentPlaces(true)
      } else {
        setShowRecentPlaces(false)
      }
    } else if (pathname !== '/lines') {
      if (term.length < 3) {
        if (recentPlaces?.length > 0) {
          setShowRecentPlaces(true)
        } else {
          setShowRecentPlaces(false)
        }
      } else {
        setShowRecentPlaces(false)
        dispatch(setShowSearcher(true))
        dispatch(setOpenTripPreview(false))
      }
    }
  }, [term, recentPlaces])

  useEffect(() => {
    if (autocomplete?.enabled) {
      // verify that the term is greater than or equal to three characters to consume the API
      if (term?.length >= 3 && cityConfig && bounds) {
        setSearchLoading(true)
        getPlacesByCity(cityConfig, term, bounds)
          .then((response) => {
            const results = response?.map(result => {
              if (userPosition?.lat) {
                return {
                  ...result,
                  distanceToUser: getDistanceFromLatLonInKm(userPosition?.lat, userPosition?.lng, result.geometry.coordinates[1], result.geometry.coordinates[0]),
                  categoryId: recentPlaces?.some(place => place.id === result.properties.osm_id) ? 'recent_place' : result.properties.osm_value,
                  name: result.properties ? result.properties.name ? result.properties.name : `${result.properties.street} ${result.properties.housenumber}` : result.title,
                  id: result.properties.osm_id,
                  providerName: 'UALABEE'
                }
              } else {
                return {
                  ...result,
                  categoryId: recentPlaces?.some(place => place.id === result.properties.osm_id) ? 'recent_place' : result.properties.osm_value,
                  name: result.properties ? result.properties.name ? result.properties.name : `${result.properties.street} ${result.properties.housenumber}` : result.title,
                  id: result.properties.osm_id,
                  providerName: 'UALABEE'
                }
              }
            })

            setPlaces(results)
            setSearchLoading(false)
          })
      } else {
        getLocalRecentPlaces()
          .then((response) => {
            if (response) {
              // Recent places by current city
              const recent = response?.filter((recent) => recent?.city_id === cityConfig?.city_id)
              setRecentPlaces(recent)
            }
          })
      }
    }
  }, [term, cityConfig])

  const handleBackClick = () => {
    if (showRecentPlaces) {
      dispatch(setOpenTripPreview(true))
      dispatch(setShowLines(false))
      dispatch(setShowSearcher(false))
      dispatch(setShowNavbar(false))
      dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
    } else {
      dispatch(setButtonNavbarSelected(''))
      dispatch(enableTransportFiltersComponent(true))
      navigate('/')
      setShowRecentPlaces(false)
      dispatch(setSearch('', new Date()))
    }
  }

  const handleClickFilterItem = (id) => {
    let currentLines = id === 0 ? lines : lines.filter(line => line.transport_type_id === id)

    if (lineSearchResult || lineSearchResult?.length >= 0) {
      currentLines = currentLines?.filter(line =>
        (line?.route_short_name || line?.route_long_name)?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            lineSearchResult
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      )
    }

    dispatch(setLinesByAgency(id, currentLines))
    dispatch(setTransportationType(id))
  }

  const handleChangeSearch = (e) => {
    if (pathname === '/lines') {
      handleSearch(e)
    }

    dispatch(setSearch(e.target.value, new Date()))
  }

  const handlePlaceClick = async (result) => {
    const recentPlaces = await getLocalRecentPlaces() || []

    dispatch(setZoom(14))

    const currentLocalPlace = {
      ...result,
      name: result.properties
        ? result.properties.name
          ? result.properties.name
          : `${result.properties.street} ${result.properties.housenumber}`
        : result.title,
      latLng: {
        lat: result.geometry ? result.geometry.coordinates[1] : result.position[0],
        lng: result.geometry ? result.geometry.coordinates[0] : result.position[1]
      },
      id: result?.properties?.osm_id || result?.id,
      city_id: cityConfig?.city_id,
      categoryId: result?.properties?.osm_value || result?.category
    }

    const pointOfInterest = {
      city_id: cityConfig?.city_id,
      point_of_interest: {
        name: currentLocalPlace.name,
        provider_id: currentLocalPlace.id.toString(),
        provider_name: currentLocalPlace.providerName || 'UALABEE',
        category: currentLocalPlace.categoryId,
        latitude: currentLocalPlace.latLng.lat,
        longitude: currentLocalPlace.latLng.lng
      }
    }

    await setPointsOfInterest(pointOfInterest)

    // save recent place in local storage if it is not already saved
    if (!recentPlaces.some(place => place.id === currentLocalPlace.id)) {
      setLocalRecentPlaces([...recentPlaces, currentLocalPlace])
    }

    const currentPlace = {
      name: result.properties
        ? result.properties.name
          ? result.properties.name
          : `${result.properties.street} ${result.properties.housenumber}`
        : result.title,
      latLng: {
        lat: result.geometry ? result.geometry.coordinates[1] : result.position[0],
        lng: result.geometry ? result.geometry.coordinates[0] : result.position[1]
      }
    }

    if (orientation === 'to') {
      dispatch(setToPlacesSelected(currentPlace))
    } else {
      dispatch(setFromPlacesSelected(currentPlace))
    }

    dispatch(setOpenTripPreview(true))
    dispatch(setShowLines(false))
    dispatch(setShowSearcher(false))
    dispatch(setShowNavbar(false))
    dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
    dispatch(setShowSearcher(false))
  }

  const handleCloseAutocomplete = () => {
    dispatch(setSearch('', new Date()))
  }

  const handleClickSearcher = () => {
    if (pathname !== '/lines') {
      navigate('/search')
    }
  }

  const handleClickFindPlace = async () => {
    const response = await getPlacesAutoSuggest(cityConfig?.map.map_center_lat, cityConfig?.map.map_center_lng, term, cityConfig?.name, bounds)

    const results = response?.results?.map(result => {
      if (bounds.contains(new maplibregl.LngLat(result?.position[1], result?.position[0]))) {
        return {
          distanceToUser: userPosition && getDistanceFromLatLonInKm(userPosition.lat, userPosition.lng, result.position[0], result.position[1]),
          categoryId: result.resultType,
          providerName: 'HERE',
          ...result
        }
      }
    }).filter(result => result)

    const orderArr = _.orderBy(results, ['distanceToUser'], ['asc'])
    setPlaces(userPosition ? orderArr : results)
  }

  return (
    <>
      <SearchContainer
        sx={{
          borderRadius: '16px',
          position: autocomplete?.enabled ? 'absolute' : 'relative',
          zIndex: 1,
          boxShadow: 'rgb(34 40 49 / 15%) 0px 0px 24px'
        }}
      >
        <Container isTransportFilters={showTransportFilters}>
          <SearchNavbar />
          <SearcherContainer>
            <SearchIconContainer>
              {autocomplete?.enabled
                ? (
                  <IconButton onClick={handleBackClick}>
                    <BackIcon />
                  </IconButton>
                  )
                : (<SearchIcon />
                  )}
            </SearchIconContainer>
            <Searcher
              onChange={handleChangeSearch}
              placeholder={`${pathname === '/lines'
                ? t('lines.autocomplete.alternative_placeholder')
                : (orientation === 'to'
                  ? t('hint_location_B')
                  : t('hint_location_A'))}`}
              value={showTransportFilters ? lineSearchResult : term || ''}
              onClick={handleClickSearcher}
            />

            {pathname !== '/lines' && !autocomplete?.enabled &&
              <NavigationButton>
                <NavigationIcon />
              </NavigationButton>}

            {autocomplete?.enabled &&
              <IconButton style={{ padding: 10, marginRight: 5 }} onClick={handleCloseAutocomplete}>
                <CloseIcon />
              </IconButton>}
          </SearcherContainer>

          {showTransportFilters &&
            <>
              {transportationTypes?.length >= 1
                ? (
                  <FiltersContainer>
                    <Button
                      sx={{
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontWeight: 400,
                        padding: '6px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        margin: '0 5px 0 0',
                        borderRadius: 0,
                        color: transportIdSelected === 0 ? theme.palette.primary.main : theme.palette.icons.secondary.main,
                        borderBottom: transportIdSelected === 0 ? `2px solid ${theme.palette.primary.main}` : '2px solid #ff000000',
                        fontSize: '14px'
                      }}
                      onClick={() => handleClickFilterItem(0)}
                      startIcon={<AllInclusive />}
                    >
                      {t('lines.transport_types.all')}
                    </Button>

                    {transportationTypes?.map((type, index) => {
                      return (
                        <Button
                          sx={{
                            textDecoration: 'none',
                            textTransform: 'none',
                            fontWeight: 400,
                            padding: '6px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            margin: '0 5px 0 0',
                            borderRadius: 0,
                            color: type.transport_type_id === transportIdSelected ? theme.palette.primary.main : theme.palette.icons.secondary.main,
                            borderBottom: type.transport_type_id === transportIdSelected ? `2px solid ${theme.palette.primary.main}` : '2px solid #ff000000',
                            fontSize: '14px'
                          }}
                          key={index}
                          onClick={() => handleClickFilterItem(type.transport_type_id)}
                          startIcon={
                            <ButtonFilterIcon
                              src={type?.flat_icon}
                              isSelected={type.transport_type_id === transportIdSelected}
                            />
                          }
                        >
                          {type.name}
                        </Button>
                      )
                    })}
                  </FiltersContainer>)
                : (
                  <FiltersContainer>
                    <FiltersSkeleton />
                  </FiltersContainer>
                  )}
            </>}
        </Container>
      </SearchContainer>
      {autocomplete?.enabled &&
        <motion.div
          animate={{
            x: -1,
            y: 20,
            scale: 1,
            rotate: 0
          }}
        >
          {!showRecentPlaces && term.length >= 3 &&
            <SearchResults
              sx={{
                width: '100%',
                marginBottom: '12px',
                borderRadius: '16px',
                boxShadow: '0px 0px 24px #22283126',
                position: 'absolute',
                top: '104px',
                paddingTop: '40px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                zIndex: 0,
                backgroundColor: '#fff'
              }}
            >
              {searchLoading
                ? <PlaceItem>
                  <IconContainer>
                    <Skeleton style={{ borderRadius: 8 }} variant='rect' width={28} height={28} />
                    <Skeleton style={{ marginTop: 5 }} variant='rect' width={24.32} height={14.44} />
                  </IconContainer>
                  <PlaceInformation>
                    <Skeleton style={{ marginTop: 5 }} variant='rect' width={182} height={21.11} />
                    <Skeleton style={{ marginTop: 5 }} variant='rect' width={182} height={16.67} />
                  </PlaceInformation>
                </PlaceItem>
                : <div style={{ marginTop: 17, marginBottom: 17 }}>
                  {places?.map((result) => {
                    const { icon, color } = getPlacesIcon(result?.categoryId)

                    return (
                      <PlaceItem
                        key={result?.properties?.osm_id}
                        onClick={() => handlePlaceClick(result)}
                      >
                        <IconContainer>
                          <Icon style={{ backgroundColor: color }}>
                            {icon}
                          </Icon>
                          {result?.distanceToUser &&
                            <Distance>{Math.round(result.distanceToUser)}km</Distance>}
                        </IconContainer>
                        <PlaceInformation>
                          <PlaceName>
                            {result?.properties?.name ? result.properties.name : result?.title ? result.title : `${result.properties.street} ${result?.properties?.housenumber}`}
                          </PlaceName>
                          <PlaceCity>{result?.properties ? result?.properties?.city ? result?.properties?.city : result?.properties?.state : result?.vicinity?.replace(/\d/g, '').replace('<br/>', ' ')}</PlaceCity>
                        </PlaceInformation>
                      </PlaceItem>
                    )
                  })}
                  <FindPlaceContainer>
                    <WaterMark>© OpenStreetMap contributors</WaterMark>
                    <FindPlaceButton
                      onClick={handleClickFindPlace}
                      sx={{
                        color: 'white',
                        backgroundColor: '#ffb633',
                        '&:hover': {
                          backgroundColor: '#ffb633'
                        },
                        '&:focus': {
                          backgroundColor: '#ffb633'
                        }
                      }}
                      label={t('search.super_search')}
                      size='small'
                    />
                  </FindPlaceContainer>
                </div>}
            </SearchResults>}
          <SearchResults
            sx={{
              width: '100%',
              marginBottom: '12px',
              borderRadius: '16px',
              boxShadow: '0px 0px 24px #22283126',
              position: 'absolute',
              top: '104px',
              paddingTop: '40px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              zIndex: 0,
              backgroundColor: '#ffffff'
            }}
          >
            {savedPlaces && term.length <= 2 &&
              <SavedPlaces border />}
            {showRecentPlaces &&
              <RecentPlacesContainer style={{
                maxHeight: 250,
                overflow: 'scroll',
                overflowX: 'hidden',
                backgroundColor: '#ffffff',
                borderRadius: '16px'
              }}
              >
                <RecentPlacesTitle>{t('search.recent')}</RecentPlacesTitle>
                {recentPlaces?.map((result, index) => {
                  return (
                    <PlaceItem
                      key={index}
                      onClick={() => handlePlaceClick(result)}
                    >
                      <IconContainer>
                        <IconContainer>
                          <Icon style={{ backgroundColor: '#d7f3f63a' }}>
                            <HistoryRounded sx={{ color: '#00a7bc' }} />
                          </Icon>
                          {result?.distanceToUser &&
                            <Distance>{Math.round(result?.distanceToUser)}km</Distance>}
                        </IconContainer>
                      </IconContainer>
                      <PlaceInformation>
                        <PlaceName>
                          {result?.properties?.name ? result.properties.name : result?.title ? result.title : `${result.properties.street} ${result?.properties?.housenumber}`}
                        </PlaceName>
                        <PlaceCity>{result?.properties ? result?.properties?.city ? result?.properties?.city : result?.properties?.state : result?.vicinity?.replace(/\d/g, '').replace('<br/>', ' ')}</PlaceCity>
                      </PlaceInformation>
                    </PlaceItem>
                  )
                })}
              </RecentPlacesContainer>}
          </SearchResults>
        </motion.div>}
    </>
  )
}
