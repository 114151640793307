import styled from 'styled-components/macro'
import { theme } from '../../../../theme/theme'

export const Container = styled.div` 
    background-color: #fff;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 3px solid ${theme.palette.primary.main}; 
`

export const Content = styled.div`  
    width: calc(100% - 54px);
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Logo = styled.img` 
    width: 40px;
    border-radius: 8px;
    margin: 0 10px 0 0; 
`

export const Title = styled.h2` 
    font-size: 14px; 
    color: #909397;
`
