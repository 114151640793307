import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

export const loadStopAlertTypes = async () => {
  const types = []

  try {
    const querySnapshot = await getDocs(collection(db, '/global/alert_types/stop'))

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      types.push({
        id: doc.id,
        ...doc.data()
      })
    })
  } catch (e) {
    console.error('Error getting stop alert types' + e)
  }

  return types
}
