import { Button, Skeleton, Typography, Box, Chip } from '@mui/material'
import { Subtitle, SubtitleContainer } from '../SearchResults/styles'
import { theme } from '../../theme/theme'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTaxiRideDetails } from '../../db/getTaxiRideDetails'
import { useTranslation } from 'react-i18next'

export default function MotoTaxiResult({ show }) {
  const { t } = useTranslation()
  const tripResponse = useSelector(state => state?.ui?.tripResponse)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [isIconLoaded, setIsIconLoaded] = useState(false)
  const [transport, setTransport] = useState()
  const [tipDetails, setTripDetails] = useState()
  const [warningMessage, setWarningMessage] = useState()

  useEffect(() => {
    if (!tripResponse || !cityConfig) return

    if (tripResponse && cityConfig && show) {
      const motoTaxiTrip = tripResponse?.itinerariesMotorcycle?.find(itinerary => itinerary.mode === 'MOTOTAXI')

      setTransport(cityConfig?.transport_types?.find(transport => transport.transport_type_id === motoTaxiTrip.legs[0].transportTypeId))

      const { legs } = motoTaxiTrip

      const params = {
        city_id: cityConfig?.city_id,
        pickup_lat: legs[0].from.lat,
        pickup_lng: legs[0].from.lon,
        dropoff_lat: legs[0].to.lat,
        dropoff_lng: legs[0].to.lon,
        distance_in_meters: legs[0].distance,
        duration_in_seconds: motoTaxiTrip?.duration
      }

      getTaxiRideDetails(params).then((response) => {
        setTripDetails(response)
        if (response.pickup_zone_id !== response.dropoff_zone_id) {
          setWarningMessage(t('trip_share.moto_taxi.pick_up_drop_off_diferent_zones_warning'))
        }
        if (response.dropoff_zone_id === -1) {
          setWarningMessage(t('trip_share.moto_taxi.no_drop_off_zone_covered_warning'))
        }
      })
        .catch((error) => {
          console.error(error)
          setWarningMessage(t('trip_share.moto_taxi.no_pick_up_zone_covered_warning'))
        })
    }
  }, [tripResponse, cityConfig])
  // handleTripClick mock
  const handleTripClick = () => {}

  const mobile = true

  return show
    ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box'
        }}
      >
        <SubtitleContainer>
          <Subtitle>
            {t('trip_share.moto_taxi.title')}
          </Subtitle>
        </SubtitleContainer>
        <Button
          onClick={() => handleTripClick()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '16px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#F6F8F9'
            },
            '&:focus': {
              border: `${!mobile ? `1px solid ${theme.palette.primary.main}` : 'none'}`
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            <Box
              sx={{
                width: '49px',
                minWidth: '49px',
                height: '49px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: transport?.color || '#b0b0b0',
                borderRadius: '8px',
                margin: '0 16px 0 0',
                position: 'relative'
              }}
            >
              <img
                style={{
                  borderRadius: '8px',
                  position: 'absolute',
                  width: '100%'
                }}
                src={transport?.flat_icon}
                alt={`${transport?.name} icono`}
                onLoad={() => {
                  setIsIconLoaded(true)
                }}
                onError={() => {

                }}
                loading='lazy'
              />
              {!isIconLoaded &&
                <Skeleton
                  sx={{
                    backgroundColor: '#505050',
                    borderRadius: '8px'
                  }}
                  width='50px'
                  height='50px'
                  variant='rectangular'
                />}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '8px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexDirection: 'row'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '6px'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: 'normal',
                      textAlign: 'initial',
                      color: '#000',
                      fontWeight: 600
                    }}
                  >
                    {transport?.name}
                  </Typography>
                  {tipDetails?.cost &&
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '6px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: '#000000'
                        }}
                      >
                        {cityConfig?.country?.coin_symbol}{tipDetails?.cost}

                      </Typography>
                      <Chip
                        label={t('trip_share.moto_taxi.suggested_price')}
                        size='small'
                        sx={{
                          backgroundColor: '#29b6f629',
                          color: '#29b6f6',
                          fontSize: '12px',
                          lineHeight: 'normal',
                          textAlign: 'center',
                          padding: '4px 8px',
                          marginLeft: '8px',
                          fontWeight: 800,
                          borderRadius: '8px',
                          border: '1px solid #29b6f6'
                        }}
                      />
                    </Box>}
                </Box>
                <Chip
                  label={t('trip_share.moto_taxi.call_to_action_button_text')}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: '#ffffff',
                    fontSize: '12px',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    padding: '4px 8px',
                    marginLeft: '8px',
                    fontWeight: 600
                  }}
                />
              </Box>
              {warningMessage &&
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: '#fcf5e3',
                    padding: '8px',
                    borderRadius: '12px',
                    border: '1px solid #e6c663',
                    color: '#e6c663',
                    fontStyle: 'bold'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: 'normal',
                      textAlign: 'initial',
                      fontWeight: 600
                    }}
                  >
                    {warningMessage}
                  </Typography>
                </Box>}
            </Box>
          </Box>
        </Button>
      </div>
      )
    : null
}
