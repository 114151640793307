import React, { useEffect, useState } from 'react'
import {
  AlertsContainer,
  Container,
  Item,
  ItemImg,
  ItemInfo,
  ItemsContainer,
  ItemText,
  ItemTitle,
  Title
} from './styles'
import { ShowMoreButton } from '../../../uicomponents/desktop/alerts/ShowMoreButton'
import { useAlerts } from '../../../hooks/useAlerts'
import { useSelector, useDispatch } from 'react-redux'
import { AlertsSkeleton } from '../../../uicomponents/desktop/alerts/Skeleton'
import { Skeleton, Slide } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { enabledAlertsAndNewsComponent } from '../../../actions/ui'
import NoResultMessage from '../../../uicomponents/NoResultMessage'

export const Alerts = ({ map }) => {
  const { t, i18n } = useTranslation()

  const {
    handleAlertClick
  } = useAlerts()

  const dispatch = useDispatch()

  const alertTypes = JSON.parse(localStorage.getItem('alertTypes') ? localStorage.getItem('alertTypes') : '[]')

  const [showMoreNews, setShowMoreNews] = useState(false)
  const [showMoreAlerts, setShowMoreAlerts] = useState(false)
  const alertsAndNews = useSelector(state => state?.ui?.alerts)

  const news = alertsAndNews?.filter((alert) => !alert.isAlert)
  const alerts = alertsAndNews?.filter((alert) => alert.isAlert)

  const handleClickExpandAlerts = () => {
    setShowMoreAlerts(!showMoreAlerts)
  }

  const handleClickExpandNews = () => {
    setShowMoreNews(!showMoreNews)
  }

  useEffect(() => {
    return () => {
      dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
    }
  }, [])

  return (
    <Slide direction='up' in mountOnEnter>
      <AlertsContainer
        sx={{
          marginTop: '12px',
          width: '450px',
          boxShadow: '0px 0px 24px #22283126',
          borderRadius: '16px',
          padding: '32px 0 20px 0',
          position: 'absolute',
          zIndex: 450,
          top: '304px',
          left: '16px'
        }}
      >
        <Container>
          {!alertsAndNews
            ? (
              <>
                <Skeleton
                  style={{
                    margin: '0px 14px 20px 14px'
                  }} variant='rectangular' width={70} height={20}
                />
                {[1, 2]?.map((i) => {
                  return <AlertsSkeleton key={i} />
                })}
              </>
              )
            : (
              <>
                {news && news?.length > 0 &&
                  <>
                    <Title>{t('news.news.title')}</Title>
                    <ItemsContainer>
                      {news?.map((item, index) => {
                        if (index >= 0 && (index <= 3 || showMoreNews)) {
                          return (
                            <Item
                              id='news'
                              key={item.id}
                              sx={{
                                width: 'calc(100% - 15px)',
                                padding: 0,
                                textTransform: 'none',
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                margin: '0 15px 0 10px',
                                '& .MuiButton-label': {
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }
                              }}
                              onClick={() => handleAlertClick(item, map)}
                            >
                              <div style={{
                                margin: '0 14px',
                                width: '25%',
                                height: 108,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              >
                                <ItemImg img={item?.pictureUrl} />
                              </div>
                              <ItemInfo>
                                <ItemTitle>{item?.title}</ItemTitle>
                                <ItemText>{item?.content}</ItemText>
                              </ItemInfo>
                            </Item>
                          )
                        }
                      })}
                      {news?.length > 3 &&
                        <ShowMoreButton
                          handleClick={handleClickExpandNews}
                          href='#news'
                          label={!showMoreNews ? t('news.news.load_more_news') : t('news.news.show_less')}
                        />}
                    </ItemsContainer>
                  </>}
                {alerts?.length >= 1 &&
                  <>
                    <Title>{t('news.alerts.title')}</Title>
                    <ItemsContainer>
                      {alerts?.map((item, index) => {
                        if (index >= 0 && index <= 3 || showMoreAlerts) {
                          return (
                            <Item
                              key={item.id + index}
                              sx={{
                                width: 'calc(100% - 15px)',
                                padding: 0,
                                textTransform: 'none',
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                margin: '0 15px 0 10px',
                                '& .MuiButton-label': {
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }
                              }}
                              onClick={() => handleAlertClick(item, map)}
                            >
                              <div style={{
                                margin: '0 14px',
                                width: '25%',
                                height: 108,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              >
                                <ItemImg img={item?.pictureUrl} />
                              </div>
                              <ItemInfo>
                                <ItemTitle>
                                  {i18n?.resolvedLanguage?.includes('es')
                                    ? alertTypes?.find(type => type.id === item.reportType)?.name_es
                                    : alertTypes?.find(type => type.id === item.reportType)?.name}
                                </ItemTitle>
                                <ItemText>{item?.title}</ItemText>
                              </ItemInfo>
                            </Item>
                          )
                        }
                      })}
                      {alerts?.length >= 3 &&
                        <ShowMoreButton
                          handleClick={handleClickExpandAlerts}
                          href='#alerts'
                          label={!showMoreAlerts ? t('news.alerts.load_more_alerts') : t('news.alerts.show_less')}
                        />}
                    </ItemsContainer>
                  </>}
              </>
              )}
          {alertsAndNews?.length === 0 &&
            <NoResultMessage message={t('news.no_results')} />}
        </Container>
      </AlertsContainer>
    </Slide>
  )
}
