import React from 'react'
import { ButtonContainer, FiltersContainer, Header, HeaderTitle } from './styles'
import { useFilters } from '../../hooks/useFilters'
import { RouteTypes } from '../../uicomponents/filters/RouteTypes'
import { TransportTypes } from '../../uicomponents/filters/TransportTypes'
import { Walk } from '../../uicomponents/filters/Walk'
import { RememberFilters } from '../../uicomponents/filters/RememberFilters'
import { SearchFiltersButton } from '../../uicomponents/filters/SearchFiltersButton'
import { useTranslation } from 'react-i18next'
import { Drawer, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'

export const FilterDrawer = ({ openFilter, setOpenFilter, arriveBy }) => {
  const { t } = useTranslation()

  const {
    handleClose
  } = useFilters()

  return (
    <Drawer
      open={openFilter}
      anchor='bottom'
      PaperProps={{
        style: {
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }
      }}
    >
      <FiltersContainer>
        <Header>
          <HeaderTitle>{t('search_preview.filters.title')}</HeaderTitle>
          <IconButton
            onClick={() => handleClose(setOpenFilter)}
            sx={{
              padding: '16px'
            }}
          >
            <CloseRounded
              sx={{
                color: theme.palette.icons.secondary.main
              }}
            />
          </IconButton>
        </Header>
        <Walk />
        <RouteTypes />
        <TransportTypes />
        <RememberFilters />
        <ButtonContainer>
          <SearchFiltersButton arriveBy={arriveBy} />
        </ButtonContainer>
      </FiltersContainer>
    </Drawer>
  )
}
