import styled from 'styled-components/macro'
import { Chip } from '@mui/material'

export const ArrivalTimeContainer = styled.div`
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  height: 56px;
  min-height: 56px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props?.theme?.palette?.icons?.primary?.main};
  -webkit-mask: url(${props => props?.src}) center/contain;
  mask: url(${props => props?.src}) center/contain;
  margin-right: 16px;
`

export const Label = styled.p`
  margin: 0;
  width: 190px;
  color: ${props => props?.theme?.palette?.text?.primary?.main};
  font-size: inherit;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const ShowMore = styled(Chip)`
  width: 80px;
  color: ${props => props?.theme?.palette?.text?.primary?.main};
  border: 1px solid #E0E8F7;
`
