import styled from 'styled-components/macro'
import { IconButton } from '@mui/material'
import { AddAPhotoRounded, Person, ThumbUpRounded } from '@mui/icons-material'

export const ImageContainer = styled.div`
    padding: ${props => props.mode ? '16px' : '16px 24px'};
    border-radius: 16px; 
    margin: 0;
    background-color: white; 
    box-sizing: border-box;
    width: 100%;
`

export const StopImage = styled.div`  
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 120px;
    border-radius: 8px; 
`

export const AddButton = styled(IconButton)`
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between; 
`
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column; 
`

export const AddIcon = styled(AddAPhotoRounded)`
`

export const AddText = styled.p`
    margin: 0;
    font-size: 14px;
    color: white;
`

export const ImageData = styled.div` 
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`

export const LikesContainer = styled.div`  
    display: flex;
    align-items: center;                                                      
`

export const Likes = styled.p`  
    color: white;
    margin: 0;
    font-size: 14px;
`

export const LikesIcon = styled(ThumbUpRounded)`   
    color: white;
    margin-right: 10px;
`

export const Name = styled.p`  
    color: white;
    margin: 0;
    font-size: 14px;
`

export const UserIcon = styled(Person)`   
    color: white;
    margin-right: 10px;
`

export const User = styled.div`   
     display: flex;
     align-items: center;
`

export const Information = styled.div`   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Data = styled.div`   
    padding: 16px; 
`

export const DefaultImageContainer = styled.div` 
     position: relative;
     height: 100%;
`

export const StopDefaultImage = styled.div` 
    position: relative; 
`

export const StopDefaultContent = styled.div` 
    position: absolute;
    top: 0;
    width: 100%;
    
`
