import styled from 'styled-components/macro'
import { Card, IconButton } from '@mui/material'
import { ArrowBack, CheckCircleRounded, ErrorRounded, FavoriteRounded, MoreVert } from '@mui/icons-material'

export const StopContainer = styled(Card)`
  height: ${props => props?.mode ? '100%' : 'calc(100vh - 32px)'};
`

export const Header = styled.div`
  box-shadow: 0 0 24px #22283126;
`

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 12px 0 12px;
  position: relative;
`

export const BackButton = styled(IconButton)`
`

export const BackIcon = styled(ArrowBack)`
`

export const FavButton = styled(IconButton)`
`

export const FavIcon = styled(FavoriteRounded)`
`

export const MoreButton = styled(IconButton)`
`

export const MoreIcon = styled(MoreVert)`
`

export const CheckIcon = styled(CheckCircleRounded)`
`

export const WarningIcon = styled(ErrorRounded)`
`

export const StateLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StateText = styled.p`
  margin: 0 6px 0 0;
  color: red;
`

export const Content = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding: 0 0 10px 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 48px);
    overflow: hidden auto;
  }
`

export const Panel = styled.div`
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ShareButtonsContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 480px;
  z-index: 402;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
`
