import styled from 'styled-components/macro'

export const Header = styled.div` 
    padding: 8px 20px 8px 20px; 
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    border-bottom: 1px solid #E8EDEE; 
`

export const Title = styled.p`  
    margin: 0;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
`

export const OccupancyContainer = styled.div` 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    margin-bottom: 20px;
`

export const OccupancyIconContainer = styled.div` 
    background-color: ${props => props.theme.palette.icons.primary.main}; 
    width: 64px;
    height: 64px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50px;
    margin-bottom: 10px;
`

export const OccupancyIcon = styled.div`
    width: 50px;
    height: 50px;
    display: inline-block;  
    background-color: white;
    -webkit-mask:url(${props => props?.src}) center/contain;
    mask:url(${props => props?.src}) center/contain; 
`

export const OccupancyLabel = styled.p` 
    margin: 0;
`

export const ButtonContainer = styled.div` 
    display: flex; 
    align-items: center; 
    justify-content: flex-end; 
`

export const ContentContainer = styled.p`  
    padding: 20px;
    margin: 0;
`

export const LineBreak = styled.br`   
`

export const Content = styled.p`  
    margin: 0 20px 20px 20px;
    text-align: center;
`
