import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import DirectionsBikeRoundedIcon from '@mui/icons-material/DirectionsBikeRounded'
import Chip from '@mui/material/Chip'
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded'
import Button from '@mui/material/Button'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const BikeStationData = ({ station }) => {
  const { t } = useTranslation()

  const [link, setLink] = useState('')
  const [isAd, setIsAd] = useState(false)

  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    if (cityConfig) {
      const {
        bike_package_name: googlePackageName = '',
        bike_package_name_apple: applePackageName = ''
      } = cityConfig?.config

      if (googlePackageName && applePackageName) {
        setIsAd(true)

        const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1
        const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

        if (isAndroid) {
          setLink(`https://play.google.com/store/apps/details?id=${googlePackageName}`)
        } else if (isIos) {
          setLink(`https://apps.apple.com/app/${applePackageName}`)
        }
      }
    }
  }, [cityConfig])

  const getAvailabilityColor = (value) => {
    if (value === 0) {
      return '#707070'
    } else if (value > 0 && value <= 16) {
      return '#fece60'
    } else if (value > 16) {
      return '#20afa7'
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 0
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          margin: '10px 0px'
        }}
      >
        <Grid
          item xs={9}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 1)'
          }}
        >
          {station?.extra?.address}
        </Grid>
        <Grid
          item xs={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            textAlign: 'right',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 1)',
            whiteSpace: 'nowrap'
          }}
        >
          {t('trip_share.bike_station')}
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '5px'
        }}
      />
      <Grid
        container
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '20px'
          }}
        >

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '5px',
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >
            <DirectionsBikeRoundedIcon />
            <Typography>
              {t('trip_share.bikes')}
            </Typography>

          </Box>
          <Chip
            sx={{
              backgroundColor: getAvailabilityColor(station?.free_bikes),
              color: '#ffffff',
              fontWeight: 'bold'
            }}
            label={station?.free_bikes}
          />

        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '10px'
          }}
        >

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '5px',
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >
            <LocalParkingRoundedIcon />
            <Typography>
              {t('trip_share.places')}
            </Typography>
          </Box>
          <Chip
            sx={{
              backgroundColor: getAvailabilityColor(station?.empty_slots),
              color: '#ffffff',
              fontWeight: 'bold'
            }}
            label={station?.empty_slots}
          />

        </Grid>

        {useMediaQuery('(max-width:600px)') && isAd && link && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: '20px'
            }}
          >
            <Button
              onClick={() => window.open(link, '_blank')}
              variant='contained'
              color='primary'
              startIcon={<OpenInNewRoundedIcon />}
              sx={{
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '30px',
                padding: '10px 0px',
                backgroundColor: '#29b6f6',
                '&:hover': {
                  backgroundColor: '#29b6f6'
                }
              }}
            >
              {t('trip_share.open')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default BikeStationData
