import styled from 'styled-components/macro'
import { Button, Chip } from '@mui/material'
import { CheckCircle, ErrorRounded, Timer } from '@mui/icons-material'

export const ArrivalContainer = styled.div` 
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #E8EDEE;
    width: 100%; 
`

export const Container = styled(Button)`  
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 75%; 
`

export const IconContainer = styled.div` 
    background-color: ${props => props?.color};
    width: 25px;
    min-width: 25px;
    height: 25px; 
    border-radius: 8px; 
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-right: 20px;
    position: relative;
    margin-left: 24px;  
`

export const Icon = styled.div`  
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px; 
    display: inline-block;  
    background-color: white;
    -webkit-mask:url(${props => props?.src}) center/contain;
    mask:url(${props => props?.src}) center/contain; 
`

export const Information = styled.div`    
    display: flex;
    align-items: flex-start; 
    flex-direction: column;
    width: calc(100% - 20px); 
    overflow: hidden;
`
export const Name = styled.h4`   
    margin: 0;
    text-align: initial;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    color: ${props => props.theme.palette.text.primary.main};
`

export const Description = styled.h5`   
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #909397; 
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: initial;
    max-width: 100%;
    text-overflow: ellipsis;
`

export const CrowdedAverage = styled(Button)`    
`

export const CrowdedLabel = styled.p`
    margin: 0;    
    font-size: 12px;   
`

export const CrowdedIcon = styled.img`   
    width: 20px;
`

export const ScheduleContainer = styled(Button)`    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    height: 70px;
    width: 25%;
`

export const ScheduleLabel = styled.p`    
    font-size: 10px;   
    color: #909397; 
    margin: 0;
`

export const Schedule = styled.p`    
    font-size: ${props => props?.fontSize ? props.fontSize : '24px'};   
    color: #222831; 
    margin: 0;
    font-weight: 600;
`

export const Schedules = styled(Chip)`   
`

export const SchedulesIcon = styled(Timer)`   
    color: #A6A9AC;
    width: 10px;
    height: 10px;
`

export const RealtimeIcon = styled.img`    
    position: absolute;
    top: 0;
    left: -13px;
`
export const Realtime = styled.div`    
    display: flex;
    align-items: flex-end;
    justify-content: center; 
    position: relative;
`

export const TransportIcon = styled(CheckCircle)`   
    color: #5DCD8E;   
    width: 13px;
    height: 13px;
    max-width: 13px; 
    font-size: 20px !important;
`

export const CircleIconContainer = styled.div`   
    width: 15px;
    height: 15px;
    border-radius: 18px;
    background-color: #ffffff;
    position: absolute;
    right: -5px;
    top: -9px;
`

export const StaticCircleIcon = styled.div`   
    width: 13px;
    height: 13px;
    border-radius: 18px;
    background-color: #ffffff; 
    position: relative;
`

export const TransportAffected = styled(ErrorRounded)`   
    color: #F05454; 
    width: 13px;
    height: 13px;
    max-width: 13px; 
    font-size: 20px !important;
`

export const Programmed = styled.div`   
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
`
