import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { logEvent } from '../../firebase/firebase-config'

const PageView = () => {
  const location = useLocation()
  const auth = useSelector(state => state?.user?.auth)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const logPageView = (location) => {
    const params = {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname,
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      user_gender: userData?.gender ? userData.gender : null,
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null,
      user_id: auth.uid
    }
    logEvent('web_page_view', params)
  }

  useEffect(() => {
    if (auth) {
      logPageView(location)
    }
  }, [location, auth])

  return null
}

export default PageView
