import React from 'react'
import { Chip, Dialog, Rating } from '@mui/material'
import {
  Animation,
  App,
  AppInfo,
  Background,
  Content,
  Download,
  DownloadButtons,
  Downloads,
  Feedback,
  Header,
  Icon,
  Info,
  Leafs,
  LeafsContainer,
  Phone,
  PhoneContianer,
  Rocket,
  RocketContianer,
  Title
} from './styles'
import logo from '../../../../img/ualabeelogo.png'
import appLogo from '../../../../img/logo.png'
import { StarRounded } from '@mui/icons-material'
import leaftsAnimation from '../../../../img/smart_banner_full_page_leafts.svg'
import backgroundAnimation from '../../../../img/smart_banner_full_page_background.svg'
import phoneAnimation from '../../../../img/smart_banner_full_page_phone.png'
import rocketAnimation from '../../../../img/smart_banner_full_page_roket.png'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenBottomSmartBanner, setOpenSmartBanner, setOpenTopSmartBanner } from '../../../../actions/ui'
import { getValue } from 'firebase/remote-config'
import { logEvent, remoteConfig } from '../../../../firebase/firebase-config'
import { getLocalShowPrompt } from '../../../../db/localStorage/getLocalShowPrompt'
import { useTranslation } from 'react-i18next'
import { getAuth } from 'firebase/auth'
import { REACT_APP_APP_NAME } from '../../../../constants/config'

/** <SmartBannerFullScreen/>
 * @description This full screen dialog called "Smart Banner" is only displayed on the mobile web
 * when the site isn't white label and isn't the web served on the Ios app.
 *
 * Component states:
 *      Initial state:
 *              - open: It's a redux state. Its initial value is sets in the uiReducer file (src/reducers/uiReducer)
 *                  and its value is obtained with the useSelector method of the "react-redux" library.
 *       Set state:
 *              - setOpenSmartBanner(boolean): It's a redux action created in ui actions file (src/actions/ui).
 *                                  Uses the redux action setOpenSmartBanner() to close or open the dialog, we send false as a parameter to the function and true to open it instead.
 * @returns {JSX.Element}
 * @constructor
 */

export const SmartBannerFullScreen = () => {
  const { t } = useTranslation()

  const auth = getAuth()
  const user = auth.currentUser

  // Obtain redux store's dispatch function
  const dispatch = useDispatch()

  // Obtain  smart banner full screen open  redux's state value
  const open = useSelector(state => state?.ui.components.openSmartBanner)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const handleClose = async () => {
    logEvent('captive_portal_continue_in_web_cta', {
      lat: userPosition?.lat ? userPosition?.lat : null, // Double or null
      lng: userPosition?.lng ? userPosition?.lng : null, // Double or null
      user_gender: userData?.gender ? userData?.gender : null, // String or null
      user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long or null
      os: 'web', // String
      user_id: user.uid // String
    })

    dispatch(setOpenSmartBanner(false))

    try {
      const { _value: openTopBanner } = getValue(remoteConfig, 'show_smart_banner_top')
      dispatch(setOpenTopSmartBanner(JSON.parse(openTopBanner)))

      const count = getLocalShowPrompt()

      if (count >= 3) {
        dispatch(setOpenBottomSmartBanner(false))
        // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
        const deferredPrompt = window.deferredPrompt

        if (!deferredPrompt) return

        deferredPrompt.prompt()

        // Find out whether the user confirmed the installation or not
        const { outcome } = await deferredPrompt?.userChoice
        // The deferredPrompt can only be used once.
        window.deferredPrompt = null
        // Act on the user's choice
        if (outcome === 'accepted') {
          logEvent('install_pwa_from_prompt_cta', {
            lat: userPosition?.lat ? userPosition.lat : null, // Double
            lng: userPosition?.lng ? userPosition.lng : null, // Double
            user_gender: userData ? userData?.gender : null, // String
            user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null, // Long
            user_id: user?.uid, // String
            city_id: cityConfig?.city_id?.toString() // String
          })

          console.error('User accepted the install prompt.')
        } else if (outcome === 'dismissed') {
          logEvent('dismiss_install_pwa_from_prompt', {
            lat: userPosition?.lat ? userPosition.lat : null, // Double
            lng: userPosition?.lng ? userPosition.lng : null, // Double
            user_gender: userData ? userData?.gender : null, // String
            user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null, // Long
            user_id: user?.uid, // String
            city_id: cityConfig?.city_id?.toString() // String
          })
          console.error('User dismissed the install prompt')
        }
      } else {
        const { _value: openBottomBanner } = getValue(remoteConfig, 'show_smart_banner_bottom')
        dispatch(setOpenBottomSmartBanner(JSON.parse(openBottomBanner)))
      }
    } catch (e) {
      dispatch(setOpenBottomSmartBanner(false))
      console.error('Error getting user choice from PWA deferred prompt', e)
    }
  }

  const handleOpenApp = async () => {
    try {
      const { _value: ctaUrl } = getValue(remoteConfig, 'captive_portal_cta_url')

      logEvent('captive_portal_download_app_cta', {
        lat: userPosition?.lat ? userPosition?.lat : null, // Double or null
        lng: userPosition?.lng ? userPosition?.lng : null, // Double or null
        user_gender: userData?.gender ? userData?.gender : null, // String or null
        user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long or null
        os: 'web', // String
        user_id: user.uid, // String
        url: ctaUrl
      })

      /* const {_value: link} = getValue(remoteConfig, "captive_portal_cta_url");

            const parmas = {
                link,
                title: '¡Descarga la app!',
                description: 'Elige entre las rutas y horarios de transporte público, alternativas de micromovilidad y servicios de taxis o autos geolocalizados',
                picture: downloadApp
            } */

      /* const {shortLink} = await getDynamicLinks(parmas); */
      window.open(ctaUrl, '_self')
    } catch (e) {
      console.error('Error getting link', e)
    }

    dispatch(setOpenSmartBanner(false))
  }

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      fullScreen
    >
      <Content>
        <Header>
          <Icon
            alt='Ualabee logo'
            src={logo}
            width='100px'
          />
          <Title
            margin='16px 0 0 0'
            fontSize='18px'
            textAlign='center'
            fontWeight='300'
            color='#909397'
          >
            {t('smart_banners.full_page.title')}
          </Title>
          <Title
            margin='16px 0 0 0'
            fontSize='18px'
            textAlign='center'
            fontWeight='600'
            color='#909397'
          >
            {t('smart_banners.full_page.subtitle')}
          </Title>
        </Header>
        <Animation>

          <Background
            alt='background image animation'
            src={backgroundAnimation}
          />

          <LeafsContainer>
            <Leafs
              alt='leafts image animation'
              src={leaftsAnimation}
            />
          </LeafsContainer>

          <PhoneContianer>
            <Phone
              alt='phone image animation'
              src={phoneAnimation}
            />
          </PhoneContianer>

          <RocketContianer>
            <Rocket
              alt='rocket image animation'
              src={rocketAnimation}
            />
          </RocketContianer>

        </Animation>
        <AppInfo>
          <App>
            <Icon
              src={appLogo}
              alt='ualabee application logo'
              width='48px'
              borderRadius='8px'
            />
            <Info>
              <Title
                margin='0 0 5px 3px'
                fontWeight='600'
                fontSize='18px'
              >
                {REACT_APP_APP_NAME}
              </Title>
              <Feedback>
                <Rating
                  name='text-feedback'
                  value={4.5}
                  readOnly
                  precision={0.5}
                  size='small'
                  emptyIcon={
                    <StarRounded
                      style={{ opacity: 0.55 }}
                      fontSize='inherit'
                    />
                  }
                />
                <Downloads>{t('smart_banners.full_page.app_dowloads', { prop1: '+500k' })}</Downloads>
              </Feedback>
            </Info>
          </App>
          <DownloadButtons>
            <Download>
              <Chip
                sx={{
                  backgroundColor: '#3e9e98',
                  color: '#fff',
                  fontSize: 14,
                  width: '100%',
                  '&:hover': {
                    backgroundColor: '#3e9e98'
                  }
                }}
                clickable
                label={t('smart_banners.full_page.download_app')}
                disabled={!user}
                onClick={handleOpenApp}
              />
            </Download>
            <Chip
              sx={{
                backgroundColor: '#fff',
                color: user ? '#909397' : '#fff',
                fontSize: 14,
                minWidth: '50%',
                margin: '8px 0 0 0',
                '&:hover': {
                  backgroundColor: '#fff'
                }
              }}
              clickable
              label={t('smart_banners.full_page.continue_on_mobile_site')}
              onClick={handleClose}
              disabled={!user}
            />
          </DownloadButtons>
        </AppInfo>
      </Content>
    </Dialog>
  )
}
