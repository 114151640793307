import { types } from '../types/types'
import { setLocalCurrentZoom } from '../db/setLocalCurrentZoom'
import { setLocalCurrentCenter } from '../db/setLocalCurrentCenter'
import {
  enabledAlertsAndNewsComponent,
  setCurrentCityConfig,
  setCurrentStopPictures,
  setOpenAlertInformation,
  setOpenStop,
  setStopArrivals,
  setStopSecurity
} from './ui'
import { getUserReports } from '../db/getUserReports'
import { getLocalFiltersConfig } from '../db/getLocalMapFilters'
import { getRechargePointsByLocation } from '../db/getRechargePointsByLocation'
import { loadSurveyTypes } from '../helpers/loadSurveyTypes'
import { loadStopVows } from '../helpers/loadStopVows'
import { loadUserVows } from '../helpers/loadUserVows'
import { getStopPictures } from '../db/getStopPictures'
import _ from 'lodash'
import moment from 'moment'
import { getBikeNetworksByCity } from '../db/getBikeNetwotksByCity'
import { getNearby } from '../db/getNearby'
import { getCityConfig } from '../db/getCityConfig'
import { getStopArrivals } from '../db/getStopArrivals'
import { getUrlRealTimeByCity } from '../utils'
import { getTripUpdates } from '../db/getTripUpdates'
import i18n from '../i18n'
import { getArrivalsRealTime } from '../db/getArrivalsRealTime'
import { getAuth } from 'firebase/auth'
import { REACT_APP_SHOW_MAP_USER_REPORTS, REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS } from '../constants/config'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from '../firebase/firebase-config'

export const setZoom = (zoom) => ({
  type: types.zoom,
  payload: zoom
})

export const setCenter = (center) => ({
  type: types.center,
  payload: center
})

export const setBounds = (bounds) => ({
  type: types.bounds,
  payload: bounds
})

export const setStops = (stop) => ({
  type: types.stops,
  payload: stop
})

export const setRefillPoints = (object) => ({
  type: types.refillPoints,
  payload: object
})

export const setUserReports = (object) => ({
  type: types.userReports,
  payload: object
})

export const setBikeStations = (bikeStations) => ({
  type: types.bikeStations,
  payload: bikeStations
})

/** setBikeStationSelected()
 * Sets bike station selected from map
 * @param {Boolean} bikeStationSelected
 * @returns {{payload: boolean, type: string}}
 */
export const setBikeStationSelected = (bikeStationSelected) => ({
  type: types.bikeStationSelected,
  payload: bikeStationSelected
})

export const handleMove = (event, bounds, map, showStopsNearby) => {
  return (dispatch) => {
    if (!map) return console.error('map does not exist yet')

    const zoom = map.getZoom()
    const center = map.getCenter()

    dispatch(setZoom(zoom))
    setLocalCurrentZoom(zoom)

    dispatch(setCenter([center.lat, center.lng]))
    setLocalCurrentCenter([center.lat, center.lng])

    if (showStopsNearby) {
      dispatch(getMapStops())
      if (REACT_APP_SHOW_MAP_USER_REPORTS) {
        dispatch(getMapUserReports(zoom, center))
      }
    }

    dispatch(verifyBikeStations())
    dispatch(getMapRechargePoints())
  }
}

export const handleStopClick = (stop, userPosition, logEvent, uid, navigate) => {
  // TODO: set userPosition, logEvent and ui in Redux

  return async (dispatch, getState) => {
    const { ui: { cityConfig }, user: userData } = getState()

    dispatch(setStopArrivals(null))

    const eventParams = {
      os: 'web', // String
      stop_lat: stop?.stop_lat,
      stop_lng: stop?.stop_lon,
      stop_color: stop?.transport_type?.color,
      stop_id: stop?.stop_id,
      is_affected: stop?.affected,
      lat: userPosition?.lat || null, // Double
      lng: userPosition?.lng || null, // Double
      city_id: cityConfig?.city_id.toString(), // String
      user_id: uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    }

    // Send events to analytics
    logEvent('stop_marker_click', eventParams)

    const stopParams = {
      city_id: cityConfig?.city_id,
      stop_id: stop?.stop_id
    }

    navigate(`/stops/${stopParams?.city_id}/${stopParams?.stop_id}`)
  }
}

export const loadCurrentStop = () => {
  return (dispatch, getState) => {
    const { ui: { cityConfig } } = getState()

    const pathname = window?.location?.pathname

    const urlParams = {
      city_id: Number(pathname?.split('/')[2]),
      stop_id: decodeURI(pathname?.split('/')[3])
    }

    if (cityConfig && urlParams.city_id === cityConfig?.city_id) {
      dispatch(loadCurrentStopPictures(urlParams, cityConfig))
      dispatch(loadCurrentStopVows({ urlParams, cityConfig }))
      dispatch(loadCurrentStopArrivals(urlParams, cityConfig))
    } else {
      getCityConfig(urlParams?.city_id)
        .then((currentCityConfig) => {
          dispatch(loadCurrentStopPictures(urlParams, currentCityConfig))
          dispatch(loadCurrentStopVows({ urlParams, cityConfig: currentCityConfig }))
          dispatch(loadCurrentStopArrivals(urlParams, currentCityConfig))
          dispatch(setCurrentCityConfig(currentCityConfig))
        })
        .catch(e => console.error(e))
    }
  }
}

const loadCurrentStopPictures = (urlParams) => {
  return async (dispatch) => {
    const stopParams = {
      city_id: parseInt(urlParams?.city_id),
      stop_id: urlParams?.stop_id
    }

    const pictures = await getStopPictures(stopParams)
    dispatch(setCurrentStopPictures(pictures?.length > 0 ? pictures : undefined))
  }
}

export const loadCurrentStopVows = ({ urlParams, cityConfig, stopIdParam }) => {
  return async (dispatch, getState) => {
    const { user: { userData } } = getState()
    const stopId = stopIdParam || urlParams?.stop_id

    const survey = await loadSurveyTypes()
    const vows = await loadStopVows(stopId)

    const stopVows = vows?.find((stop) => stop?.stop_code.toString() === stopId.toString() && Number(stop?.city_id) === Number(cityConfig?.city_id))

    const vowsParams = {
      doc_id: stopVows?.id
    }

    const reason = stopVows ? await loadUserVows(vowsParams) : {}

    const reasons = survey?.map((type) => {
      if (type?.id === 'assault') {
        type.vows = stopVows?.assault_count || 0
      } else if (type?.id === 'lighting') {
        type.vows = stopVows?.lighting_count || 0
      } else if (type.id === 'lonely') {
        type.vows = stopVows?.lonely_count || 0
      }
      return type
    })

    const options = [
      {
        label: i18n.t('stop.yes'),
        vows: stopVows?.secure_count || 0,
        color: '#5DCD8E',
        value: true,
        selected: false
      },
      {
        label: i18n.t('stop.no'),
        vows: stopVows?.insecure_count || 0,
        color: '#FF4B55',
        value: false,
        selected: false
      }
    ]

    const userReasons = reasons?.map((item) => {
      item.selected = item?.id === reason?.reason_id
      return item
    })

    const userOptions = options?.map((item) => {
      item.selected = item?.value === reason?.value
      return item
    })

    dispatch(setStopSecurity({
      reasons: userData ? userReasons : reasons,
      stop_vows: stopVows,
      enabled: false,
      options: userData ? userOptions : options
    }))

    dispatch(setOpenStop(true))
  }
}

export const loadCurrentStopArrivals = (urlParams, cityConfig) => {
  return async (dispatch, getState) => {
    const { ui: { stopSelected } } = getState()

    const response = await getStopArrivals(urlParams)
    const arrivalsArr = response?.map(arrival => ({
      ...arrival,
      shape_id: arrival.trip.shape_id,
      isLoading: true,
      realtime: null
    }))
    const arrivals = getArrivalsGrouped(arrivalsArr)
    dispatch(setStopArrivals(arrivals))

    if (cityConfig.config?.realtime_hub_enabled) {
      dispatch(getArrivalsOfRealtimeHub(cityConfig, stopSelected, arrivals))
    } else if (cityConfig.config?.realtime_arrivals_enabled) {
      dispatch(getArrivalsOfThirdParties(cityConfig, stopSelected, arrivals, urlParams))
    } else {
      const currentArrivals = arrivals.map(arrivalGroup => {
        arrivalGroup.arrival.isLoading = false
        return arrivalGroup
      })
      dispatch(setStopArrivals(currentArrivals))
    }
  }
}

export const getArrivalsOfRealtimeHub = (cityConfig, stopSelected, arrivals) => {
  return async (dispatch) => {
    try {
      const response = await getTripUpdates(cityConfig.city_id, stopSelected.stop_id)

      const arrivalsWithRealtime = arrivals.map((arrival) => {
        const arrivalTimeObj = response?.result?.filter((time) => time.routeId === arrival.arrival.trip.route_id && parseInt(time.directionId) === parseInt(arrival.arrival.trip.direction_id))[0]

        return {
          arrival: {
            ...arrival.arrival,
            realtime: arrivalTimeObj?.realtime && arrivalTimeObj?.realtimeArrival > 0 ? Math.floor(arrivalTimeObj?.realtimeArrival / 60000) : null,
            arrival_time: moment(arrivalTimeObj?.scheduledArrival).format('HH:mm:ss'),
            isLoading: false
          },
          arrivals: arrival.arrivals,
          id: arrival.id,
          route_name: arrival.route_name
        }
      })

      await fetchAndActivate(remoteConfig)
      const value = getValue(remoteConfig, 'order_arrivals_by_rt')
      if (value?._value === 'true') {
        // sort by realtime true first
        const sortedArrivals = arrivalsWithRealtime.sort((a, b) => {
          const realTimeA = a?.arrival?.realtime
          const realTimeB = b?.arrival?.realtime

          if (realTimeA !== realTimeB) {
            return realTimeB - realTimeA // true is prioritized over false
          }

          return a?.arrival_time - b?.arrival_time
        })

        dispatch(setStopArrivals(sortedArrivals))
      } else {
        dispatch(setStopArrivals(arrivalsWithRealtime))
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export const getArrivalsOfThirdParties = (cityConfig, stopSelected, arrivals, urlParams) => {
  return async (dispatch) => {
    const cityId = urlParams?.city_id || cityConfig?.city_id

    if (!stopSelected?.stop_id || arrivals?.length === 0 || !arrivals[0]?.arrival?.trip?.route_id) return

    const transportTypeId = cityConfig.transport_types.find(transport => transport.route_type === arrivals[0].arrival.trip.route?.route_type)?.transport_type_id

    const [busUrl, subwayUrl] = getUrlRealTimeByCity(cityId, {
      stop_code: stopSelected.stop_id,
      route_id: arrivals[0]?.arrival?.trip?.route_id
    })

    const params = {
      city_id: cityId,
      api_buenos_aires: cityId === 21,
      url: transportTypeId === 1 ? busUrl : subwayUrl
    }

    let arrivalsWithRealtime = arrivals?.map((group) => {
      group.arrival.isLoading = false
      return group
    })

    if (cityId === 22) { // Santiago de Chile
      if (transportTypeId !== 1) {
        dispatch(setStopArrivals(arrivalsWithRealtime))
        return
      }

      const auth = getAuth()
      const user = auth.currentUser

      try {
        const token = await user.getIdToken()

        arrivals.forEach(group => {
          const cityId = parseInt(window.location.pathname.split('/')[2])
          const [busUrl] = getUrlRealTimeByCity(cityId, {
            stop_code: stopSelected.stop_id,
            route_id: group.arrival?.trip?.route_id
          })

          const controller = new AbortController()
          const signal = controller.signal

          setTimeout(() => {
            controller.abort()
          }, 5000)

          fetch(busUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`
            },
            signal
          })
            .then((response) => {
              if (response.ok) {
                return response.json()
              } else {
                throw new Error('Error getting realtime')
              }
            })
            .then((response) => {
              arrivalsWithRealtime = arrivals.map(arrivalGroup => {
                if (arrivalGroup.arrival.trip.route_id === group.arrival.trip.route_id) {
                  const result = response?.result?.entities?.item?.find(item => item?.horaprediccionbus1)

                  if (result) {
                    arrivalGroup.arrival.realtime = result?.horaprediccionbus1?.replace(' min.', "'")
                  }
                  arrivalGroup.arrival.isLoading = false
                }
                return arrivalGroup
              })
              dispatch(setStopArrivals(arrivalsWithRealtime))
            })
            .catch((e) => {
              dispatch(setStopArrivals(arrivalsWithRealtime))
              console.error(e)
            })
          return group
        })
      } catch (e) {
        dispatch(setStopArrivals(arrivalsWithRealtime))
        console.error(e)
      }
    } else {
      switch (cityConfig?.city_id) {
        case 21: // Buenos Aires, Argentina
          if (transportTypeId !== 1 && transportTypeId !== 3) {
            return dispatch(setStopArrivals(arrivalsWithRealtime))
          } else {
            try {
              const realtime = await getArrivalsRealTime(params)

              if (transportTypeId === 1) { // Bus
                if (realtime?.code !== 200) {
                  dispatch(setStopArrivals(arrivalsWithRealtime))
                  return
                }
                arrivalsWithRealtime = arrivals?.map((group) => {
                  const arrivalRealtimes = realtime?.data?.entry?.arrivalsAndDepartures?.filter(item => {
                    const routeName = item?.routeShortName || item?.routeLongName
                    return item?.predicted && routeName === group?.route_name
                  })

                  if (arrivalRealtimes.length === 0) {
                    return {
                      ...group,
                      arrival: {
                        ...group?.arrival,
                        realtime: false,
                        isLoading: false
                      }
                    }
                  }

                  const order = _.orderBy(arrivalRealtimes, ['predictedArrivalTime'], ['asc'])

                  const closestRealtime = order?.map(item => {
                    return getMinutesFromNow(item?.predictedArrivalTime)
                  })

                  const orderArr = closestRealtime?.filter(item => item !== false)

                  return {
                    ...group,
                    arrival: {
                      ...group?.arrival,
                      realtime: orderArr?.length > 0 ? orderArr[0] : false,
                      isLoading: false
                    }
                  }
                })
              } else if (transportTypeId === 3) { // Subway
                arrivalsWithRealtime = arrivals?.map((group) => {
                  let minutes

                  realtime?.Entity?.map((entity) => {
                    const stopId = group?.arrival?.stop_id?.includes(':') ? group?.arrival?.stop_id?.split(':')[1] : group?.arrival?.stop_id

                    const time = entity?.Linea?.Estaciones?.find(station => station.stop_id === stopId)?.arrival?.time
                    if (minutes === undefined || minutes === false) {
                      minutes = getMinutesFromNow(time * 1000)
                    } else {
                      return minutes
                    }
                  })

                  return {
                    ...group,
                    arrival: {
                      ...group?.arrival,
                      realtime: minutes,
                      isLoading: false
                    }
                  }
                })
              }

              dispatch(setStopArrivals(arrivalsWithRealtime))
            } catch (e) {
              dispatch(setStopArrivals(arrivalsWithRealtime))
              console.error(e)
            }
          }
          break // Buenos Aires, Argentina
        case 30: // Montevideo, Uruguay
          // Validations:
          // - If response (realtime) includes "mensaje" or "No existe" words or if transport type id is different to 1 (isn't a bus transport type), the execution of the function is stopped

          try {
            const realtime = await getArrivalsRealTime(params)

            if (JSON.stringify(realtime).includes('mensaje') || JSON.stringify(realtime).includes('No existe') || transportTypeId !== 1) {
              dispatch(setStopArrivals(arrivalsWithRealtime))
              return
            }

            arrivalsWithRealtime = arrivals?.map((group) => {
              const realTimes = realtime?.filter((time) => {
                const shape = group?.arrival?.shape_id?.includes('-') ? group?.arrival?.shape_id?.split('-')[1] : group?.arrival?.shape_id
                return time?.real && time?.variante?.toString() === shape?.toString()
              })

              group.arrival.realtime = getMinutesFromNow(_.orderBy(realTimes, ['times'], ['asc'])[0]?.time)
              group.arrival.isLoading = false
              return group
            })
            dispatch(setStopArrivals(arrivalsWithRealtime))
          } catch (e) {
            dispatch(setStopArrivals(arrivalsWithRealtime))
            console.error(e)
          }
          break // Montevideo, Uruguay
        case 29: // Mendoza, Argentina
          // Validations:
          // - If code prop of realtime response is different to 200, the execution of the function is stopped

          try {
            const realtime = await getArrivalsRealTime(params)

            if (realtime?.code !== 200) {
              dispatch(setStopArrivals(arrivalsWithRealtime))
              return
            }
            arrivalsWithRealtime = arrivals?.map((group) => {
              const realTimes = realtime?.data?.entry?.arrivalsAndDepartures?.filter((time) => time?.predicted && time?.routeId?.toString() === group.arrival.trip?.route_id?.toString())
              group.arrival.realtime = getMinutesFromNow(_.orderBy(realTimes, ['scheduledArrivalTime'], ['asc'])[0]?.scheduledArrivalTime)
              group.arrival.isLoading = false
              return group
            })
            dispatch(setStopArrivals(arrivalsWithRealtime))
          } catch (e) {
            dispatch(setStopArrivals(arrivalsWithRealtime))
            console.error(e)
          }
          break // Mendoza, Argentina
        default:
          dispatch(setStopArrivals(arrivalsWithRealtime))
      }
    }
  }
}

const getArrivalsGrouped = (arrivals) => {
  const arrivalsGroupedByShapeId = _.groupBy(arrivals, 'shape_id')

  return Object?.keys(arrivalsGroupedByShapeId)?.map((key) => {
    const orderArr = _.orderBy(arrivalsGroupedByShapeId[key], ['arrival_time'], ['asc'])
    const arrival = orderArr?.filter((arrival) => arrival?.realtime)[0]

    return {
      route_name: arrivalsGroupedByShapeId[key][0]?.trip?.route?.route_short_name || arrivalsGroupedByShapeId[key][0]?.trip?.route?.route_long_name,
      arrivals: arrivalsGroupedByShapeId[key],
      arrival: arrival || orderArr[0],
      id: arrivalsGroupedByShapeId[key][0]?.trip_id
    }
  })
}

const getMinutesFromNow = (realtime) => {
  const now = moment()
  const arrival = moment(realtime)

  const diff = arrival.diff(now, 'minutes')

  if (diff > 0) {
    return diff
  } else {
    return false
  }
}

export const handleReportClick = (alert, language, alertTypes, cityConfig, userPosition, uid, logEvent, map, userReportDrawerRef, navigate) => {
  return (dispatch, getState) => {
    const { ui: { components } } = getState()

    dispatch(enabledAlertsAndNewsComponent({
      enabled: components?.alertsAndNews?.enabled,
      current: alert
    }))

    dispatch(setOpenAlertInformation(true))

    navigate(`/user_reports/${cityConfig?.city_id}/${alert.id}`)

    const eventParams = {
      user_id: uid,
      city_id: cityConfig?.city_id?.toString(), // String
      os: 'web', // String
      lat: userPosition?.lat || null, // Double
      lng: userPosition?.lng || null, // Double
      user_birthday_timestamp: null, // Long
      user_gender: null, // String
      alert_id: alert.id
    }

    logEvent('alert_marker_clicked', eventParams)

    map?.flyTo({
      center: [alert.lng, alert.lat],
      zoom: 16,
      speed: 0.5,
      padding: {
        bottom: userReportDrawerRef?.current?.offsetHeight
      }
    })

    // set data in Redux
    dispatch(setZoom(16))
    dispatch(setCenter([alert?.lat, alert?.lng]))

    // set data in LocalStorage
    setLocalCurrentZoom(16)
    setLocalCurrentCenter([alert?.lng, alert?.lat])
  }
}

export const setCurrentBounds = (currentBounds) => ({
  type: types.currentBounds,
  payload: currentBounds
})

export const verifyBikeStations = () => {
  return (dispatch, getState) => {
    const { ui: { cityConfig }, map: { markers } } = getState()

    if (cityConfig?.config?.bike_networks && !markers?.bikeStations?.stations || markers?.bikeStations?.stations?.length === 0) {
      getBikeNetworksByCity(cityConfig?.config?.bike_networks).then((response) => {
        dispatch(setBikeStations({
          enabled: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS,
          stations: response?.network?.stations
        }))
      })
    }
  }
}

export const getMapStops = () => {
  return (dispatch, getState) => {
    const { map: { center, zoom, markers }, ui: { cityConfig } } = getState()

    if (!cityConfig) return

    const currentLocalFilters = getLocalFiltersConfig()

    const currentMapFilters = currentLocalFilters?.find((filters) => filters?.city_id === cityConfig?.city_id)

    const params = {
      city_id: cityConfig?.city_id,
      lat: Number(center[0]),
      lng: Number(center[1]),
      zoom,
      client_platform: 'web'
    }

    if (center && zoom >= 15) {
      getNearby(params)
        .then((response) => {
          const currentStops = response
            ?.filter(stop => stop)
            ?.filter(stop => stop)?.map((stop) => {
              stop.transport_type_id = stop?.transport_type?.transport_type_id
              return stop
            })

          const stopsGroupedByTransportType = _.groupBy(currentStops, 'transport_type_id')

          const stopsGrouped = Object?.keys(stopsGroupedByTransportType)?.map((key) => {
            const currentFilter = currentMapFilters?.filters?.filter((filter) => {
              return filter?.id === stopsGroupedByTransportType[key][0]?.transport_type_id
            })[0]

            return {
              transport_type_id: stopsGroupedByTransportType[key][0]?.transport_type_id,
              stops: stopsGroupedByTransportType[key],
              enabled: currentFilter ? currentFilter?.show : true
            }
          })

          dispatch(setStops(stopsGrouped))
        })
        .catch(e => console.error(e))
    } else {
      const currentStops = markers?.stops?.map((group) => {
        group.enabled = false
        return group
      })

      dispatch(setStops(currentStops))
    }
  }
}

export const getMapRechargePoints = () => {
  return (dispatch, getState) => {
    const { map: { center }, ui: { cityConfig } } = getState()

    const localFilters = getLocalFiltersConfig()
    const currentFilters = localFilters?.find((filters) => filters?.city_id === cityConfig?.city_id)

    const showRefillPoints = currentFilters?.filters?.find(filter => filter?.id === 0)

    if (showRefillPoints?.show) {
      getRechargePointsByLocation(cityConfig, center)
        .then(refillPoints => dispatch(setRefillPoints({ enabled: showRefillPoints?.show, points: refillPoints })))
        .catch(e => console.error(e))
    } else {
      dispatch(setRefillPoints({ enabled: false, points: undefined }))
    }
  }
}

export const getMapUserReports = (zoom, center) => {
  return (dispatch, getState) => {
    const { map: { markers }, ui: { cityConfig } } = getState()

    const params = {
      city_id: cityConfig?.city_id,
      lat: center?.lat,
      lng: center?.lng,
      zoom
    }

    const userReports = markers?.userReports

    getUserReports(params)
      .then((response) => {
        const result = response?.filter((item) => {
          const alreadyExists = !!userReports?.reports?.filter((report) => {
            return report?.id === item?.id
          })[0]

          return !alreadyExists
        })

        if (result?.length > 0) {
          if (userReports?.reports?.length > 0) {
            dispatch(setUserReports({
              enabled: userReports?.enabled || true,
              reports: [...result, ...userReports?.reports]
            }))
          } else {
            dispatch(setUserReports({ enabled: userReports?.enabled, reports: result }))
          }
        }
      })
      .catch(e => console.error(e))
  }
}

export const setViewState = (viewState) => ({
  type: types.viewState,
  payload: viewState
})

export const setSubwayAndTrainNetworks = (networks) => {
  return {
    type: types.subwayAndTrainNetworks,
    payload: networks
  }
}
