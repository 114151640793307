import React, { useEffect, useState } from 'react'
import { NavigationIcon, NavigationLabel } from './styles'
import { useSelector } from 'react-redux'
import { getLocalUserUid } from '../../../db/getLocalUserUid'
import { logEvent } from '../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../theme/theme'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import { REACT_APP_HOST } from '../../../constants/config'
import { Fab, Zoom, useMediaQuery } from '@mui/material'
import { detectIos } from '../../../utils'

export const NavigationButton = ({ tripSelected, transportType, travelModeFunction, travelMode }) => {
  const uid = getLocalUserUid()
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)
  const isIosApp = useSelector((state) => state?.ui?.isIosApp)
  const timestamp = useSelector(state => state?.ui?.timestamp)

  const [link, setLink] = useState(null)
  const [isAd, setIsAd] = useState(false)
  const [eventObj, setEventObj] = useState(null)

  useEffect(() => {
    if (!transportType) return

    const { package_name_google: googlePackageName, package_name_apple: applePackageName } = transportType
    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    if (googlePackageName && applePackageName) {
      setIsAd(true)
      setLink(isAndroid ? `https://play.google.com/store/apps/details?id=${googlePackageName}` : `https://apps.apple.com/app/${applePackageName}`)

      const event = {
        name: `open_app_${transportType.name.toLowerCase()}`,
        params: {
          lat: userPosition?.lat || null,
          lng: userPosition?.lng || null,
          user_gender: userData?.gender || null,
          user_birthday_timestamp: userData?.birthday?.long_value || null,
          user_id: uid,
          city_id: cityConfig?.city_id.toString(),
          app_installed: false,
          app_name: isAndroid ? googlePackageName : isIos ? applePackageName : null
        }
      }

      setEventObj(event)
    } else {
      setIsAd(false)
      setLink(tripSelected?.link_to)

      const event = {
        name: 'web_to_app_conversion',
        params: {
          from: 'navigation_button',
          lat: userPosition?.lat || null,
          lng: userPosition?.lng || null,
          user_gender: userData?.gender || null,
          user_birthday_timestamp: userData?.birthday?.long_value || null,
          user_id: uid,
          city_id: cityConfig?.city_id.toString(),
          link_to: tripSelected?.link_to || `${REACT_APP_HOST}/${window?.location?.href?.split('/')[3]}`
        }
      }

      setEventObj(event)
    }
  }, [transportType])

  const handleClick = () => {
    logEvent(eventObj.name, eventObj.params)

    if (tripSelected?.mode === 'MOTORCYCLE' || transportType?.transport_type_id === 21) {
      openMap()
    } else if (isAd) {
      window.open(link, '_blank')
    } else if (!transportType.third_party_search) {
      travelModeFunction()
    }
  }

  /** openMap()
   * Sends "open_map_app" event and opens a map with data from current itinerary selected
   */

  const openMap = () => {
    if (!from?.latLng || !to?.latLng) return

    const startLat = from?.latLng.lat
    const startLng = from?.latLng.lng
    const endLat = to?.latLng.lat
    const endLng = to?.latLng.lng

    const isIos = detectIos()
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

    let url
    let appName = 'google_maps'

    if (isIosApp || isIos || isMac) {
      appName = 'apple_maps'
      url = `https://maps.apple.com/?saddr=${startLat},${startLng}&daddr=${endLat},${endLng}&dirflg=d`
    } else {
      url = `https://www.google.com/maps/dir/?api=1&destination=${endLat},${endLng}&travelmode=driving&dir_action=navigate`
    }

    logEvent(`open_app_${appName}`, {
      app_installed: null,
      lat: userPosition?.lat ? userPosition?.lat : null, // Float
      lng: userPosition?.lng ? userPosition?.lng : null, // Float
      os: 'web', // String
      user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long
      user_id: uid, // String
      user_gender: userData?.gender || null, // String
      city_id: cityConfig?.city_id?.toString(), // String
      search_uuid: uid + '_' + `${timestamp || new Date().getTime()}` // String
    })

    window.open(url, mobile ? '_self' : '_blank')
  }

  return (
    <Zoom
      in={!travelMode && tripSelected && transportType && (!tripSelected.is_alternative && !isAd)}
      unmountOnExit
      style={{
        transitionDelay: `${!travelMode ? 200 : 0}ms`
      }}
      onClick={handleClick}
    >
      <Fab
        variant='extended'
        sx={{
          backgroundColor: isAd ? transportType.color : theme.palette.primary.main,
          zIndex: 800,
          right: 16,
          color: 'white',
          textTransform: 'none',
          borderRadius: 50,
          padding: '14px 14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: isAd ? transportType.color : theme.palette.primary.main
          }
        }}
      >
        {isAd && tripSelected?.mode !== 'MOTORCYCLE'
          ? <LaunchRoundedIcon fontSize='small' />
          : <NavigationIcon fontSize='small' />}
        <NavigationLabel>{isAd && tripSelected?.mode !== 'MOTORCYCLE' ? t('trip_share.open') : t('results.start')}</NavigationLabel>
      </Fab>
    </Zoom>
  )
}
