import styled from 'styled-components/macro'
import { IconButton, Button, Card } from '@mui/material'
import { ArrowBackRounded, ArrowDropDownRounded } from '@mui/icons-material'
import { REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR, REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR } from '../../../constants/config'

export const LineContainer = styled.div` 
    position: absolute;  
    top: 16px;
    left: 16px;
    z-index: 400;  
    width: 450px;   
    background-color: #fff; 
    min-width: 450px;
    height: calc(100vh - 32px);
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    flex-direction: column; 
`

export const LineNavbar = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
`
export const LineButton = styled(Button)`
`

export const BackButton = styled(IconButton)`
    margin-right: 12px;
`

export const BackIcon = styled(ArrowBackRounded)`
    color: ${props => props.theme.palette.icons.secondary.main};
`

export const LineDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`
export const TextButton = styled.p`
    margin: 0;
    width: inherit; 
    height: inherit;
    word-break: break-all; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const LineHours = styled(Card)` 
    width: 450px; 
    border-radius: 16px;
    margin-top: 16px; 
`

export const TimelineContainer = styled.div` 
    padding: 18px 6px 6px 6px;
    height: 100%;
    box-sizing: border-box;
`

export const Container = styled.div` 
    padding: 12px 23px 28px;
    overflow: hidden auto;
    height: 100%; 
`

export const TimeLineButtonText = styled.p`  
    margin: 0; 
    max-width: 200px; 
    overflow: hidden;
    height: 24px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: #222831;
`

export const TimeLineButton = styled(Button)`  
    margin: 0;  
`

export const MoreHoursButton = styled(Button)`  
`

export const TimeLineContentContainer = styled.div`  
    width: 100%;  
    &:hover ${MoreHoursButton} { 
        color: #909397 !important; 
    },  
`

export const UpcomingArrivals = styled.div`  
    position: absolute;
    bottom: 6px;
    left: 8px;
    color: #ff2424; 
    display: flex; 
    align-items: center;
    color: white;
    display: none;
`

export const MoreHoursIcon = styled(ArrowDropDownRounded)`  
    color: '#909397'
`

export const LineItem = styled.div` 
    display: flex;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid #E8EDEE;
    justify-content: space-between;
`

export const LineGroup = styled(Card)`   
`

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
`

export const Name = styled.h1` 
    font-size: 18px;
    color: ${REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR};
    font-weight: 400;
    white-space: nowrap;
    margin: 0;
`

export const Direction = styled.h2` 
    font-size: 14px;
    color: ${REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR};
    font-weight: 400;
    margin: 0;
    
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const Icons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
`
