import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import { handleClickDesktopShare, handleClickSendToPhone } from '../../actions/ui'
import { useDispatch } from 'react-redux'
import SendToMobileRoundedIcon from '@mui/icons-material/SendToMobileRounded'
import { logEvent } from '../../firebase/firebase-config'
import { Fab, Typography } from '@mui/material'
import { theme } from '../../theme/theme'
import { REACT_APP_SHOW_SHARE_BUTTONS } from '../../constants/config'

const ShareLargeButton = ({ shareText, shareType, params, eventName, eventParams, notificationBody }) => {
  const dispatch = useDispatch()

  const [, setLoading] = useState(false)

  const getButtonIcon = () => {
    switch (shareType) {
      case 'share': {
        return <ShareIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
      }
      case 'send-to-mobile': {
        return <SendToMobileRoundedIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
      }
    }
  }

  const handleClick = () => {
    logEvent(eventName, eventParams)

    switch (shareType) {
      case 'share': {
        dispatch(handleClickDesktopShare(params, setLoading))
        break
      }
      case 'send-to-mobile': {
        dispatch(handleClickSendToPhone(params, setLoading, notificationBody))
        break
      }
    }
  }

  return (
    REACT_APP_SHOW_SHARE_BUTTONS &&
      <Fab
        onClick={() => handleClick()}
        variant='extended'
        size='medium'
        sx={{
          boxShadow: '0 3px 6px #2228311E',
          marginLeft: '16px',
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: theme.palette.primary.light
          }
        }}
      >
        {getButtonIcon()}
        <Typography
          variant='h6'
          sx={{
            fontSize: '14px',
            whiteSpace: 'nowrap',
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.54)'
          }}
        >
          {shareText}
        </Typography>
      </Fab>
  )
}

export default ShareLargeButton
