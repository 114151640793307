import PropTypes from 'prop-types'
import { db } from '../firebase/firebase-config'
import { collection, getDocs, query, where } from 'firebase/firestore'

export const loadStopVows = async (stopId) => {
  const stops = []

  if (stopId) {
    const q = query(collection(db, 'global/transportation/stops'), where('stop_code', '==', stopId))

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      stops.push({
        id: doc.id,
        ...doc.data()
      })
    })
  } else {
    console.error('Error load stop vows. Stop id is required')
  }

  return stops
}

loadStopVows.propTypes = {
  stopId: PropTypes.string.isRequired
}
