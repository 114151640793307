import { types } from '../types/types'

export const mapReducer = (state = {
  markers: {
    stops: [],
    refillPoints: [],
    bikeStations: [],
    userReports: []
  },
  reportTypes: null,
  bikeStationSelected: null,
  viewState: {
    latitude: -31.420167,
    longitude: -64.18887,
    zoom: 13
  },
  subwayAndTrainNetworks: {
    enabled: false,
    trips: []
  }
}, action) => {
  switch (action.type) {
    case types.zoom:
      return {
        ...state,
        zoom: action.payload
      }
    case types.center:
      return {
        ...state,
        center: action.payload
      }
    case types.bounds:
      return {
        ...state,
        bounds: action.payload
      }
    case types.userReports:
      return {
        ...state,
        markers: {
          ...state.markers,
          userReports: action.payload
        }
      }
    case types.stops:
      return {
        ...state,
        markers: {
          ...state.markers,
          stops: action.payload
        }
      }
    case types.refillPoints:
      return {
        ...state,
        markers: {
          ...state.markers,
          refillPoints: action.payload
        }
      }
    case types.bikeStations:
      return {
        ...state,
        markers: {
          ...state.markers,
          bikeStations: action.payload
        }
      }
    case types.reportTypes:
      return {
        ...state,
        reportTypes: action.payload
      }
    case types.currentBounds:
      return {
        ...state,
        currentBounds: action.payload
      }
    case types.bikeStationSelected:
      return {
        ...state,
        bikeStationSelected: action.payload
      }
    case types.viewState:
      return {
        ...state,
        viewState: action.payload
      }
    case types.subwayAndTrainNetworks:
      return {
        ...state,
        subwayAndTrainNetworks: action.payload
      }
    default:
      return state
  }
}
