import { createTheme } from '@mui/material/styles'
import {
  REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_PRIMARY_LIGHT_COLOR,
  REACT_APP_PALLET_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR,
  REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR,
  REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR,
  REACT_APP_FONT_FAMILY
} from '../constants/config'

/**
 Theme configuration file:
    - The theme is created with the values obtained from the environment variables.
      These are:
        - REACT_APP_PALLET_PRIMARY_MAIN_COLOR
        - REACT_APP_PALLET_PRIMARY_LIGHT_COLOR
        - REACT_APP_PALLET_SECONDARY_MAIN_COLOR
        - REACT_APP_PALLET_SECONDARY_LIGHT_COLOR
        - REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
        - REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR
        - REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR
        - REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
        - REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
        - REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR
        - REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR
        - REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR
        - REACT_APP_PALLET_BUTTONS_PRIMARY_MAIN_COLOR
        - REACT_APP_PALLET_BUTTONS_SECONDARY_MAIN_COLOR
 **/

export const theme = createTheme({
  palette: {
    primary: {
      main: REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
      light: REACT_APP_PALLET_PRIMARY_LIGHT_COLOR
    },
    secondary: {
      main: REACT_APP_PALLET_SECONDARY_MAIN_COLOR
    },
    icons: {
      primary: {
        main: REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR,
        light: REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
      },
      secondary: {
        main: REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
      }
    },
    outline: {
      primary: {
        main: REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR
      },
      secondary: {
        main: REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR,
        light: REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR
      }
    },
    error: {
      main: '#ff4b55'
    }
  },
  text: {
    primary: {
      main: REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
    },
    secondary: {
      main: REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
      dark: REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: '${REACT_APP_FONT_FAMILY}';
        src: local('${REACT_APP_FONT_FAMILY}'), url('/fonts/${REACT_APP_FONT_FAMILY}.otf') format('truetype');
      }
      body {
        font-family: '${REACT_APP_FONT_FAMILY}', sans-serif;
      }
      `
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: 'primary.main' },
          style: {
            color: REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR
          }
        },
        {
          props: { variant: 'primary.light' },
          style: {
            color: REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
          }
        },
        {
          props: { variant: 'secondary.main' },
          style: {
            color: REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
          }
        }
      ]
    },
    MuiDrawer: {
      styleOverrides: {
        zIndex: 403,
        paper: {
          backgroundColor: '#fff',
          borderRadius: '14px 14px 0 0',
          boxSizing: 'border-box',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0'
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '18px'
          }
        }
      ],
      styleOverrides: {
        color: REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR
      }
    },
    MuiButtonBase: {
      label: {
        textTransform: 'none'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  },
  typography: {
    fontFamily: [
      REACT_APP_FONT_FAMILY,
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  }
})
