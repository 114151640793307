import styled from 'styled-components/macro'
import { theme } from '../../../theme/theme'
import { Button, IconButton } from '@mui/material'
import {
  AccountCircleRounded,
  BusinessRounded,
  CloseRounded,
  LocationCityRounded,
  PeopleRounded
} from '@mui/icons-material'

export const MenuContainer = styled.div` 
`

export const Container = styled.div` 
    height: 100%;
`

export const ItemsContainer = styled.div`  
    height: calc(100% - 93px);
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`

export const LogoContainer = styled.div` 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; 
`

export const Navbar = styled.div`  
    display: flex;
    padding-left: 23.5px;
    padding-right: 10px;
    padding-top: 28px;
    padding-bottom: 22.5px;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #E8EDEE;
    position: sticky;
    top: 0;
    flex-direction: column;
    background-color: white;
    z-index: 1;
`

export const Logo = styled.img`
    width: 128px;
    height: 100%;
`

export const CloseButton = styled(IconButton)`
`

export const CloseIcon = styled(CloseRounded)`
    color: ${theme.palette.icons.secondary.main};
`

export const MenuItems = styled.div` 
    padding: 16px;
    border-bottom: 1px solid #E8EDEE;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

export const MenuItem = styled(Button)` 
`

export const LoginItem = styled(Button)`
    display: flex;
    align-items: center;
    text-transform: none;
    font-weight: 400; 
    margin-bottom: 15px;
        &:last-child {
        margin-bottom: 0;
    };
    height: 36px;
`

export const CompanyIcon = styled(BusinessRounded)`
    color: #A6A9AC;
`

export const LogOutIcon = styled.img``

export const TextItem = styled.p`
    margin: 0;
    color: ${theme.palette.text.primary.main};
    margin-left: 16px;
    font-size: 16px;
`

export const BlogIcon = styled(PeopleRounded)`
    color: #A6A9AC;
`

export const CityIcon = styled(LocationCityRounded)`
    color: #A6A9AC;
`

export const UserIcon = styled(AccountCircleRounded)`
    color: #A6A9AC;
`

export const MediaContainer = styled.div`
    padding: 32px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const GooglePlayButton = styled.a` 
`

export const GooglePlayAdIcon = styled.img`
    width: 172px;
    height: 51px;
`

export const SocialMediaContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 28px;
    margin-bottom: 28px;
    gap: 10px;
`

export const SocialMediaButton = styled(IconButton)`
`

export const CopyRightText = styled.p`
    margin: 0;
    font-size: 12px;
    color: ${theme.palette.text.secondary.main};
`

export const LegalContainer = styled.div`
    margin-bottom: 23px;
`

export const LegalLink = styled.a`
    font-size: 12px;
    color: ${theme.palette.primary.main};
    margin: 0%;
    text-decoration: none;
`
