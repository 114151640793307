import React, { useEffect, useState } from 'react'
import { Loading } from '../../uicomponents/mobile/Loading'
import { useDispatch, useSelector } from 'react-redux'
import NavBar from '../NavBar'
import Search from '../Search'
import SavedPlaces from '../SavedPlaces'
import { SmartBannerFullScreen, SmartTopBanner } from '../../uicomponents/ui'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import {
  enabledAlertsAndNewsComponent,
  handleOpenAlert,
  resetPlacesSelected,
  setCurrentNavbarPosition,
  setOpenAlertInformation,
  setOpenSendCommentariesDialog,
  setOpenStop,
  setSearch,
  setShowSearcher
} from '../../actions/ui'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../uicomponents/layouts/Layout'
import { Home as Desktop } from '../../components/Desktop/Home'
import { Box, Button, DialogActions, DialogContent, TextField, Typography, useMediaQuery } from '@mui/material'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import { CustomDialog } from '../../uicomponents/Dialog'
import { useTranslation } from 'react-i18next'
import { CustomMap } from '../../uicomponents/Map'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { db, logEvent } from '../../firebase/firebase-config'
import { REACT_APP_IS_IFRAME } from '../../constants/config'

export default function Home () {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const mobile = useMediaQuery('(max-width:480px)')

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const openTopSmartBanner = useSelector(state => state?.ui?.components.openTopSmartBanner)
  const openCaptivePortal = useSelector(state => state?.ui.components.openSmartBanner)
  const stop = useSelector(state => state?.ui?.components?.stop)
  const alertsAndNews = useSelector(state => state?.ui?.alerts)
  const open = useSelector(state => state?.ui?.components?.openSendCommentariesDialog)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const user = useSelector(state => state?.user?.auth)

  const [value, setValue] = useState('')

  useEffect(() => {
    dispatch(setCurrentNavbarPosition(0))

    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      event.preventDefault()
      // Save the event because you'll need to trigger it later.
      window.deferredPrompt = event
    })

    if (cityConfig) {
      dispatch(resetPlacesSelected())
    }
  }, [])

  useEffect(() => {
    dispatch(setSearch('', new Date()))
    if (!mobile) {
      if (pathname?.includes('news')) {
        if (stop?.enabled) {
          dispatch(setOpenStop(false))
          dispatch(setShowSearcher(true))
        }

        dispatch(enabledAlertsAndNewsComponent({ enabled: true }))

        if (pathname?.split('/')[2] && alertsAndNews?.length) {
          const current = alertsAndNews?.find((alert) => alert?.id === pathname?.split('/')[2])

          if (current) {
            dispatch(enabledAlertsAndNewsComponent({
              enabled: true,
              current: { ...current, isAlert: false }
            }))

            dispatch(setOpenAlertInformation(true))
          }
        } else if (pathname === '/news' && !alertsAndNews?.enabled) {
          dispatch(enabledAlertsAndNewsComponent({ enabled: true }))
          dispatch(setOpenAlertInformation(false))
        }
      }
    }
  }, [pathname])

  const handleChange = (e) => {
    setValue(e?.target?.value)
  }

  const handleClose = () => {
    dispatch(setOpenSendCommentariesDialog(false))
    setValue('')
  }

  const handleSend = async () => {
    const collectionRef = collection(db, '/feedback')

    const docData = {
      feedback: value, // string
      user_id: user.uid, // string
      os: 'Web', // string
      os_version: '', // string
      os_version_name: '', // string
      app_version: '', // string
      status: 'pending', // string
      score: userData?.score || null, // number or null
      type: 'app_feedback', // string
      current_city_id: cityConfig.city_id, // number
      current_city: cityConfig.name, // string
      timestamp: new Date() // map
      /* timestamp: Timestamp?.now().toJSON() // map */
    }

    try {
      const docRef = await addDoc(collectionRef, docData)

      dispatch(handleOpenAlert({
        open: true,
        severity: 'success',
        duration: 3000,
        title: t('home.menu.send_feedback_thanks_title'),
        message: t('home.menu.send_feedback_thanks_content')
      }))

      logEvent('web_review', {
        user_id: user.uid,
        lat: userPosition ? userPosition?.lat : null,
        lng: userPosition ? userPosition?.lng : null,
        user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null,
        user_gender: userData?.gender ? userData?.gender : null, // String
        city_id: cityConfig?.city_id?.toString(),
        score: userData?.score || null // number or null
      })

      handleClose()

      updateDoc(doc(db, '/feedback', docRef.id), {
        id: docRef.id
      })
        .catch((e) => {
          console.error('Error updating id document', e)
        })
    } catch (e) {
      console.error('Error sending a commentary', e)

      dispatch(handleOpenAlert({
        open: true,
        severity: 'error',
        duration: 3000,
        title: t('home.menu.send_feedback_error_title'),
        message: t('home.menu.send_feedback_error_content')
      }))
    }
  }

  return (
    <Layout>

      {user
        ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'auto auto'
            }}
          >
            {mobile
              ? (
                <>
                  {!cityConfig && <Loading />}

                  {openCaptivePortal && pathname === '/'
                    ? <SmartBannerFullScreen />
                    : (
                      <>
                        <Box
                          sx={{
                            height: 'calc(100% - 49px)'
                          }}
                        >
                          <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 401
                          }}
                          >
                            {openTopSmartBanner && <SmartTopBanner />}
                            <Search />
                            <SavedPlaces />
                          </div>
                          <CustomMap
                            showAdMarkers
                            showDraggableDestinationMarkers={false}
                          />
                        </Box>

                      </>
                      )}
                </>
                )
              : (
                <Desktop />
                )}
            {mobile && !REACT_APP_IS_IFRAME && <NavBar />}
            <SendToPhoneModal />
            <ShareModal />
            <CustomDialog
              open={open}
              handleClose={handleClose}
              title={t('home.menu.items.send_feedback_title')}
              id='send-commentaries'
            >
              <DialogContent>
                {!userData &&
                  <Typography>{t('home.menu.items.send_feedback_description')}</Typography>}
                <TextField
                  sx={{
                    width: '100%',
                    '& label.Mui-focused': {
                      color: '#E8E8E8'
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#E8E8E8'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#E8E8E8'
                      },
                      '&:hover fieldset': {
                        borderColor: 'primary.main'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#E8E8E8'
                      }
                    }
                  }}
                  id='filled-multiline-static'
                  label=''
                  multiline
                  onChange={handleChange}
                  value={value}
                  minRows={4}
                  autoFocus
                  variant='outlined'
                />
              </DialogContent>
              <DialogActions sx={{ padding: '16px' }}>
                <Button
                  sx={{
                    backgroundColor: 'primary.main',
                    color: '#fff',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: 'primary.main'
                    },
                    '&.Mui-disabled': {
                      backgroundColor: 'secondary.light',
                      color: 'secondary.dark'
                    }
                  }}
                  disabled={!value?.length}
                  onClick={handleSend}
                >
                  {t('send')}
                </Button>
              </DialogActions>
            </CustomDialog>
          </Box>
          )
        : (
          <SkeletonApp />
          )}

    </Layout>
  )
}
