import styled from 'styled-components/macro'
import { CheckCircleRounded } from '@mui/icons-material'

export const Content = styled.div` 
    width: 100%;
    display: flex; 
    flex-direction: column; 
    padding: 0 0 10px 0;
    background-color: #FAFAFA;
    
    height: ${props => props?.mode ? 'calc(100vh - 110px)' : 'calc(100vh - 32px - 129px)'};
    overflow: hidden auto; 
`

export const CheckIcon = styled(CheckCircleRounded)` 
    color: #5DCD8E;
`

export const ReportsContainer = styled.div`
`
