import styled from 'styled-components/macro'
import { ArrowBack } from '@mui/icons-material'

export const StopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden hidden;
`

export const BackIcon = styled(ArrowBack)` 
    color: white;
`

export const Container = styled.div` 
    width: 100%; 
`

export const Icon = styled.img`
    width: 20px;
    height: 20px;   
`
