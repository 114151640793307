import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_API_FEED } from '../constants/config'

export const getVehiclePositionsByRoute = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  if (isNaN(parseInt(params.city_id))) return

  const reqBody = {
    route_id: params?.route_id,
    direction_id: params?.direction_id
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return (
    fetch(`${REACT_APP_API_FEED}/${params?.city_id}/realtimeFeed/vehiclePositionsByRoute`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error getting vehicle positions by route')
        }
      })
      .then((response) => {
        // order by currentStopSequence
        response.result.sort((a, b) => a.vehicle.currentStopSequence - b.vehicle.currentStopSequence).reverse()
        return response
      })
  )
}

getVehiclePositionsByRoute.propTypes = {
  city_id: PropTypes.string,
  route_id: PropTypes.string,
  direction_id: PropTypes.string
}

// response example:
// {result:[
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309622",
//         "startTime": "10:01:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.094352,
//         "longitude": -77.0488,
//         "bearing": 0
//       },
//       "currentStopSequence": 849,
//       "timestamp": 1725032330000,
//       "stopId": "Pershing",
//       "vehicle": {
//         "id": "vehicle_654_654_AMY775",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309621",
//         "startTime": "09:52:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.078029,
//         "longitude": -77.084015,
//         "bearing": 0
//       },
//       "currentStopSequence": 1077,
//       "timestamp": 1725032330000,
//       "stopId": "S00024299",
//       "vehicle": {
//         "id": "vehicle_655_655_AMV761",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309619",
//         "startTime": "09:34:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.06536,
//         "longitude": -77.118706,
//         "bearing": 0
//       },
//       "currentStopSequence": 1258,
//       "timestamp": 1725032300000,
//       "stopId": "S00039007",
//       "vehicle": {
//         "id": "vehicle_752_752_AWI904",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309626",
//         "startTime": "10:37:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.066419,
//         "longitude": -76.943886,
//         "bearing": 0
//       },
//       "currentStopSequence": 277,
//       "timestamp": 1725032300000,
//       "stopId": "S00033206",
//       "vehicle": {
//         "id": "vehicle_758_758_AWI905",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309623",
//         "startTime": "10:10:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.092263,
//         "longitude": -77.03416,
//         "bearing": 0
//       },
//       "currentStopSequence": 774,
//       "timestamp": 1725032330000,
//       "stopId": "S00033210",
//       "vehicle": {
//         "id": "vehicle_779_779_AWY808",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309617",
//         "startTime": "09:16:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.080916,
//         "longitude": -76.96703,
//         "bearing": 0
//       },
//       "currentStopSequence": 996,
//       "timestamp": 1725032270000,
//       "stopId": "S00039164",
//       "vehicle": {
//         "id": "vehicle_807_807_AXE821",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309616",
//         "startTime": "09:07:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.07749,
//         "longitude": -77.09267,
//         "bearing": 0
//       },
//       "currentStopSequence": 1105,
//       "timestamp": 1725032330000,
//       "stopId": "S00025157",
//       "vehicle": {
//         "id": "vehicle_816_816_AXF888",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309620",
//         "startTime": "09:43:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.073412,
//         "longitude": -77.10148,
//         "bearing": 0
//       },
//       "currentStopSequence": 1157,
//       "timestamp": 1725032330000,
//       "stopId": "S00033211",
//       "vehicle": {
//         "id": "vehicle_826_826_AXF945",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309624",
//         "startTime": "10:19:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.087355,
//         "longitude": -77.00312,
//         "bearing": 0
//       },
//       "currentStopSequence": 630,
//       "timestamp": 1725032270000,
//       "stopId": "Guardia Civil",
//       "vehicle": {
//         "id": "vehicle_830_830_AXG764",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309627",
//         "startTime": "10:46:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.053503,
//         "longitude": -76.937965,
//         "bearing": 0
//       },
//       "currentStopSequence": 173,
//       "timestamp": 1725032330000,
//       "stopId": "S00033193",
//       "vehicle": {
//         "id": "vehicle_883_883_BBB746",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   },
//   {
//     "vehicle": {
//       "trip": {
//         "tripId": "309625",
//         "startTime": "10:28:00",
//         "startDate": "2024-08-30",
//         "routeId": "11_201",
//         "directionId": 1
//       },
//       "position": {
//         "latitude": -12.083756,
//         "longitude": -76.97316,
//         "bearing": 0
//       },
//       "currentStopSequence": 478,
//       "timestamp": 1725032330000,
//       "stopId": "S00033207",
//       "vehicle": {
//         "id": "vehicle_891_891_ARD881",
//         "label": "Ceres - Óvalo La Perla"
//       }
//     }
//   }
// ]}
