import _ from 'lodash'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import { REACT_APP_VARIANT_ID } from '../constants/config'

export const getNews = async (cityId) => {
  const news = []

  const newsRef = collection(db, 'alerts')

  const q1 = query(newsRef,
    where('city_id', '==', cityId?.toString()),
    where('enabled', '==', true),
    where('date_to', '>=', new Date()),
    where('variant_id', '==', REACT_APP_VARIANT_ID)
  )

  const q2 = query(newsRef,
    where('city_id', '==', cityId.toString()),
    where('enabled', '==', true),
    where('date_from', '<=', new Date()),
    where('date_to', '==', null),
    where('variant_id', '==', REACT_APP_VARIANT_ID)
  )

  const querySnapshot1 = await getDocs(q1)

  querySnapshot1.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    news.push({
      id: doc.id,
      isAlert: false,
      ...doc.data()
    })
  })

  const querySnapshot2 = await getDocs(q2)

  querySnapshot2.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    news.push({
      id: doc.id,
      isAlert: false,
      ...doc.data()
    })
  })

  return _.orderBy(news, ['date_from'], ['desc'])
}
