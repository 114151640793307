import styled from 'styled-components/macro'

export const UserActivityContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
background-color: #ffffff;
box-sizing: border-box;
border-radius: 10px 10px 0 0;
`

export const ActivityItemContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
box-sizing: border-box;
margin-bottom: 8px;
padding-left: 8px;
`
export const ActivityItemDate = styled.span`
margin: 0;
font-size: 10px;
color: #909397;
margin-right: 8px;
text-transform: capitalize;
width: 45px;
`
export const ActivityItemContent = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
border-radius: 14px;
border: 1px solid #E0E8F7;
padding: 16px;
box-sizing: border-box;
max-width: 450px;
`
export const ActivityItemIcon = styled.div`
    display: inline-block; 
    margin-right: 16px; 
    width: 18px;
    height: 18px; 
    background-size: cover;
     background-color: #A6A9AC;
    background-position: center center; 
    background-repeat: no-repeat; 
    -webkit-mask:url(${props => props?.url}) center/contain;
    mask:url(${props => props?.url}) center/contain;
`
export const ActivityItemText = styled.p`
margin: 0;
font-size: 14px;
color: #222831;
`

export const MonthTitleContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
box-sizing: border-box;
justify-content: flex-start;
`

export const MonthTitle = styled.h4`
margin: 0;
font-size: 16px;
color: #222831;
text-transform: capitalize;
margin-bottom: 32px;
margin-top: 36px;
:first-child {
margin-top: 0;
}
`
