import React, { useEffect, useState } from 'react'
import {
  ActivityItemContainer,
  ActivityItemContent,
  ActivityItemDate,
  ActivityItemIcon,
  ActivityItemText,
  MonthTitle,
  UserActivityContainer
} from './styles'
import moment from 'moment'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'

/** <UserActivity/>
 * UserActivity (contribution history) component
 * @returns {JSX.Element}
 * @constructor
 */

const UserActivity = () => {
  const contributionHistory = useSelector(state => state?.user.userContributionHistory)

  const [contributionHistoryFiltered, setContributionHistoryFiltered] = useState()

  useEffect(() => {
    const arrWhitMonth = contributionHistory?.contributions?.map(contribution => {
      return {
        ...contribution,
        month_year: moment(contribution.timestamp).format('MMMM YYYY').toLocaleLowerCase()
      }
    })

    const groupedByMonth = _.groupBy(arrWhitMonth, 'month_year')

    if (groupedByMonth) {
      const contributions = Object.entries(groupedByMonth)?.map(item => {
        return {
          month: item[0],
          contributions: item[1]
        }
      })

      setContributionHistoryFiltered(contributions)
    }
  }, [contributionHistory])

  return (
    <UserActivityContainer>
      {contributionHistoryFiltered
        ? contributionHistoryFiltered?.map((item) => {
          const { month, contributions } = item
          return (
            <div key={month}>
              <MonthTitle>
                {month}
              </MonthTitle>
              {contributions?.map(contribution => {
                return (
                  <ActivityItemContainer key={contribution.timestamp}>
                    <ActivityItemDate>{moment(contribution.timestamp).format('ddd DD')}</ActivityItemDate>
                    <ActivityItemContent>
                      <ActivityItemIcon url={contribution?.action_type_icon} />
                      <ActivityItemText>
                        {contribution?.action_type_name}
                      </ActivityItemText>
                    </ActivityItemContent>
                  </ActivityItemContainer>
                )
              })}
            </div>
          )
        })
        : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((placeholder, index) => {
            return (
              <Skeleton
                key={index} variant='text' width='100%' height={60} animation='wave' style={{
                  marginBottom: '8px'
                }}
              />
            )
          }
          )}
    </UserActivityContainer>
  )
}

export default UserActivity
