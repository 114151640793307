import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const setPointsOfInterest = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(params)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/pointsOfInterest`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).catch((err) => {
      console.error(err)
    })
}
