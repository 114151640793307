import styled from 'styled-components/macro'
import { Button, Dialog, IconButton } from '@mui/material'

export const InvitationMessageContainer = styled(Dialog)`
`

export const MessageContainer = styled.div`
  border-radius: 14px;
  margin-top: 0;
  @media (max-width: 480px) {
    margin-top: 32px;
  }
`

export const MessageHeader = styled.div`
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props?.theme?.palette?.primary?.main};
  padding: 0 4px 0 24px;
`

export const HeaderContent = styled.p`
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
  color: white;
  width: 392px;
  @media (max-width: 480px) {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: inherit;
    width: inherit;
  }
`

export const CloseButton = styled(IconButton)`
`

export const MessageContent = styled.p`
  margin: 28px 0;
  text-align: center;
  padding: 0 16px;
  @media (max-width: 480px) {
    margin: 25px 0 40px 0;
  }
`

export const DownloadAppButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
`

export const DownloadAppButton = styled(Button)`
`

export const GooglePlayImage = styled.img`
  border-radius: 8px;
  pointer-events: none;
  width: 120px;
  height: 36px;
`

export const DrawerBar = styled.div`
  height: 4px;
  width: 24px;
  background-color: rgb(188, 190, 192);
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: calc(50vw - 12px);
`

export const DrawerContainer = styled.div`
  padding: 0 18px 18px 18px;
`
