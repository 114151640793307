import { getAuth } from 'firebase/auth'

/** sendEmailVerification()
 * send custom email with link for verificate user account
 * @returns {Promise<Response>}
 */

export const sendEmailVerification = async () => {
  const auth = getAuth()
  const user = auth.currentUser

  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    }
  }

  return (
    fetch(`${process.env.REACT_APP_DATABASE_HOST}/users/sendEmailVerification`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error sending email verification')
        }
      })
      .then((response) => response)
  )
}
