import styled from 'styled-components/macro'

export const FiltersContainer = styled.div`
    height: 90vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 513;
`

export const HeaderTitle = styled.h1`
    margin: 0;
    padding: 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.palette.text.secondary.main};
`

export const WalkIconImageContainer = styled.div` 
`

export const SliderLabel = styled.div`
    position: absolute;
    bottom: -30px;
    background: white;
    width: 80px;
    height: 24px;
    border-radius: 8px;
    color: #909397;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 0 1px 2px #4173cd28;
    z-index: 512;
`

export const OptionText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.palette.text.primary.main};
`

export const ButtonContainer = styled.div`
    max-width: 100%;
    padding: 34px 16px 28px 16px;
    position: sticky;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 5; 
`
