export const setLocalCurrentZoom = (data) => {
  localStorage.setItem(
    'currentZoom',
    JSON.stringify(data)
  )
}

export const getLocalCurrentZoom = () => {
  if (!localStorage.getItem('currentZoom')) return null
  const zoom = JSON.parse(localStorage.getItem('currentZoom'))
  return zoom || null
}
