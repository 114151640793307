import { getAuth } from 'firebase/auth'
import { isSupported } from 'firebase/messaging'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const subscribeToTopic = async (topic, registrationTokens) => {
  const auth = getAuth()

  const user = auth.currentUser
  const token = await user.getIdToken()

  isSupported()
    .then((canInit) => {
      if (canInit) {
        return new Promise(function(resolve, reject) {
          const reqBody = {
            topic,
            registration_tokens: registrationTokens
          }

          const reqConfig = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
              'variant-id': process.env.REACT_APP_VARIANT_ID
            },
            body: JSON.stringify(reqBody)
          }

          fetch(`${REACT_APP_DATABASE_HOST}/users/subscribeToTopic`, reqConfig)
            .then((response) => {
              if (response.ok) {
                return response.json()
              }
            })
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              console.error('subscribe failed')
              reject(error)
            })
        })
      } else {
        console.info('Firebase messaging not supported')
      }
    })
    .catch((e) => {
      console.info('Firebase messaging not supported', e)
    })
} // subscribe to topics
