import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenOccupancyLevel } from '../../../../actions/ui'
import {
  ButtonContainer,
  Content,
  ContentContainer,
  Header,
  LineBreak,
  OccupancyContainer,
  OccupancyIcon,
  OccupancyIconContainer,
  OccupancyLabel,
  Title
} from './styles'
import { Container } from '../Information/styles'
import { Button, Dialog, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { theme } from '../../../../theme/theme'

export const OccupancyLevel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const occupancyLevel = useSelector(state => state?.ui?.components?.occupancyLevel)

  const handleClose = () => {
    dispatch(setOpenOccupancyLevel({ enabled: !occupancyLevel?.enabled }))
  }

  return (
    <Dialog
      open={occupancyLevel?.enabled}
      onClose={handleClose}
      PaperProps={{
        borderRadius: '16px'
      }}
    >
      <Container>
        <Header>
          <Title>{t('stop.estimated_occupancy')}</Title>
          <IconButton onClick={handleClose} edge='end'>
            <CloseRounded />
          </IconButton>
        </Header>
        <ContentContainer>
          <OccupancyContainer>
            <OccupancyIconContainer>
              <OccupancyIcon src={occupancyLevel?.icon} />
            </OccupancyIconContainer>
            <OccupancyLabel>{occupancyLevel?.label}</OccupancyLabel>
          </OccupancyContainer>
          <Content>
            {t('stop.occupancy_dialog_desc_3')}
            <LineBreak />
            <LineBreak />
            {t('stop.occupancy_dialog_desc_2')}
          </Content>
          <ButtonContainer>
            <Button
              sx={{
                '& .MuiButton-label': {
                  color: theme.palette.primary.main,
                  fontWeight: 400
                },
                fontSize: '16px'
              }}
              onClick={handleClose}
            >
              {t('stop.understood')}
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </Dialog>
  )
}
