import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ArrivalContainer,
  CircleIconContainer,
  Container,
  CrowdedAverage,
  CrowdedIcon,
  CrowdedLabel,
  Description,
  Icon,
  IconContainer,
  Information,
  Name,
  Programmed,
  Realtime,
  RealtimeIcon,
  Schedule,
  ScheduleContainer,
  ScheduleLabel,
  TransportAffected,
  TransportIcon
} from './styles'
import occupancyVeryFew from '../../../../img/occupancyVeryFew.svg'
import occupancyFew from '../../../../img/occupancyFew.svg'
import occupancyMany from '../../../../img/occupancyMany.svg'
import occupancyFull from '../../../../img/occupancyFull.svg'
import RealTimeIcon from '../../../../img/real-time-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setArrivalSelected, setOpenOccupancyLevel } from '../../../../actions/ui'
import { useLines } from '../../../../hooks/useLines'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TimerOutlined } from '@mui/icons-material'
import { Box, Chip, CircularProgress } from '@mui/material'
import { theme } from '../../../../theme/theme'
import { logEvent } from '../../../../firebase/firebase-config'
import { getAuth } from 'firebase/auth'

export const Arrival = ({ arrival }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const auth = getAuth()
  const user = auth.currentUser

  const {
    handleClickArrival
  } = useLines()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const ocuppancyLevel = useSelector(state => state?.ui?.components?.occupancyLevel)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const stopSelected = useSelector(state => state?.ui?.stopSelected)

  const [crowdedAverage, setCrowdedAverage] = useState()
  const [affected, setAffected] = useState()

  useEffect(() => {
    setAffected(arrival?.trip?.affected)
    setCrowdedAverage(arrival?.trip?.crowded_average > 0 ? getCrowdedAverage(arrival?.trip?.crowded_average) : false)
  }, [])

  const getCrowdedAverage = (value) => {
    switch (value) {
      case 0: {
        return { label: t('trip_share.occupancy_item_title_1'), icon: occupancyVeryFew }
      }
      case 1: {
        return { label: t('trip_share.occupancy_item_title_2'), icon: occupancyFew }
      }
      case 2: {
        return { label: t('trip_share.occupancy_item_title_3'), icon: occupancyMany }
      }
      case 3: {
        return { label: t('trip_share.occupancy_item_title_4'), icon: occupancyFull }
      }
      default: {
        return {}
      }
    }
  }

  const handleClickCrowdedAverage = (crowded) => {
    dispatch(setOpenOccupancyLevel({ enabled: !ocuppancyLevel?.enabled, ...crowded }))
  }

  const handleClickSchedules = (arrival) => {
    dispatch(setArrivalSelected(arrival))

    const params = {
      user_id: user?.uid,
      lat: userPosition?.lat || null,
      lng: userPosition?.lng || null,
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      city_id: cityConfig?.city_id?.toString() || null, // Int
      line_id: arrival?.trip?.route_id || null, // String
      line_color: arrival?.trip?.route?.route_color || null, // String
      line_name: arrival?.trip?.trip_headsign || null, // String
      line_pathway: '',
      stop_lat: stopSelected?.stop_lat || null, // Double
      stop_lng: stopSelected?.stop_lon || null, // Double
      stop_color: cityConfig?.transport_types?.find(transport => transport.route_type === arrival.trip.route?.route_type)?.color, // String
      stop_id: stopSelected?.stop_id || null, // String
      arrival_freq: 'stopArrival.frecuencia', // String
      arrival_time: arrival?.arrival_time || null, // String
      trip_id: arrival?.trip_id || null, // String
      trip_name: arrival?.trip?.trip_headsign || null // String
    }

    if (!cityConfig?.config?.realtime_hub_enabled || !arrival?.realtime) {
      logEvent('stop_details_arrival_time_click', params)
      navigate(`/stop_times/${urlParams.city_id}/${arrival.shape_id}/${urlParams.stop_id}`)
    } else {
      handleClickArrival(arrival)
    }
  }

  return (
    <ArrivalContainer>
      <Container
        sx={{
          textTransform: 'none',
          padding: 0,
          '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          },
          borderRadius: 0,
          width: '100%',
          lineHeight: 'normal'
        }}
        onClick={() => handleClickArrival(arrival)}
      >
        <IconContainer color={arrival?.trip?.route?.transport_type?.color}>
          <Icon src={arrival?.trip?.route?.transport_type?.flat_icon} />
          {affected
            ? (
              <CircleIconContainer>
                <TransportAffected />
              </CircleIconContainer>
              )
            : (<CircleIconContainer>
              <TransportIcon />
            </CircleIconContainer>
              )}
        </IconContainer>
        <Information>
          <Name>{arrival?.trip?.route?.route_short_name || arrival?.trip?.route?.route_long_name}</Name>
          <Description>{arrival?.trip?.trip_headsign}</Description>
        </Information>
      </Container>
      {crowdedAverage &&
        <CrowdedAverage
          sx={{
            textTransform: 'none',
            height: 70,
            borderRadius: 0,
            '& .MuiButton-label': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            },
            width: '25%',
            minWidth: '25%',
            padding: 0,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
          onClick={() => handleClickCrowdedAverage(crowdedAverage)}
        >
          <CrowdedIcon alt='' src={crowdedAverage.icon} />
          <CrowdedLabel>{crowdedAverage.label}</CrowdedLabel>
        </CrowdedAverage>}

      <ScheduleContainer
        sx={{
          textTransform: 'none',
          '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          },
          padding: 0,
          width: '25%',
          minWidth: '25%',
          borderRadius: 0,
          lineHeight: 'normal'
        }}
        onClick={() => handleClickSchedules(arrival)}
      >

        {arrival?.isLoading
          ? (
            <Box sx={{
              padding: '3px'
            }}
            >
              <CircularProgress size='26px' />
            </Box>
            )
          : (
            <>
              {arrival?.realtime
                ? (
                  <Realtime>
                    <RealtimeIcon alt='realtime icon' src={RealTimeIcon} />
                    <Schedule
                      fontSize={cityConfig?.city_id === 22 ? '12px' : '24px'}
                    >
                      {arrival.realtime}
                    </Schedule>
                    {cityConfig?.city_id !== 22 &&
                      <p style={{
                        margin: '0 0 0 2px',
                        fontSize: 14,
                        color: '#909397',
                        marginLeft: 5
                      }}
                      >min</p>}
                  </Realtime>
                  )
                : (
                  <Programmed>
                    <ScheduleLabel>{t('stop.scheduled')}</ScheduleLabel>
                    <Schedule
                      fontSize='22px'
                    >
                      {arrival?.arrival_time ? `${arrival?.arrival_time?.slice(0, 5)}` : '--.--'}
                    </Schedule>
                    <Chip
                      icon={
                        <TimerOutlined
                          sx={{
                            width: '10px',
                            height: '10px'
                          }}
                        />
                      }
                      sx={{
                        height: 18,
                        fontWeight: 'inherit',
                        marginTop: 0,
                        maxWidth: '100%',
                        '& .MuiChip-label': {
                          fontSize: 10,
                          padding: '0 8px'
                        },
                        '& .MuiChip-icon': {
                          color: theme.palette.icons.secondary.main
                        }
                      }}
                      label={t('stop.see_times')}
                      variant='outlined'
                    />
                  </Programmed>
                  )}
            </>
            )}

      </ScheduleContainer>
    </ArrivalContainer>
  )
}

Arrival.propTypes = {
  arrival: PropTypes.object.isRequired
}
