import React from 'react'
import { Box, Button } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { sendEmailVerification } from '../../db/sendEmailVerification'
import { useDispatch } from 'react-redux'
import { handleOpenAlert } from '../../actions/ui'
import { useTranslation } from 'react-i18next'

const ResendVerificationEmail = () => {
  const auth = getAuth()
  const user = auth.currentUser
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSendVerificationEmail = () => {
    sendEmailVerification()
      .then(() => {
        dispatch(handleOpenAlert({
          open: true,
          severity: 'success',
          duration: 3000,
          title: `Email sent to ${user.email}`
        }))
      })
      .catch(error => {
        console.error({ error })
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: error.message
        }))
      })
  }

  return (
    user?.email && !user?.emailVerified
      ? <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: '24px',
            backgroundColor: '#F5F7FA',
            borderRadius: '14px',
            padding: '8px',
            textAlign: 'center'
          }}
        >
        {t('login.email_not_verified')}
        <Button
          onClick={handleSendVerificationEmail}
        >{t('login.resend_email')}</Button>
      </Box>
      : null
  )
}

export default ResendVerificationEmail
