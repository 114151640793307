import styled, { keyframes } from 'styled-components/macro'
import { theme } from '../../../../theme/theme'

const expandIt = keyframes`
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  } 
  5% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 3deg);
  }
  10% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  15% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, -3deg);
  } 
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
`

const moveIn = keyframes`
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  } 
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
    100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
`

const slideIn = keyframes`
  0% {
    left: 0;
    top: 260px;
  } 
  100% {
    left: 220px;
    top: 60px;
  }
`

export const Content = styled.div` 
    height: 100vh; 
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; 
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 32px 16px;
    width: 100%;
    height: 25%;
`

export const Titles = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; 
    height: 100%;
`

export const Icon = styled.img`
    width: ${props => props?.width};
    border-radius: ${props => props?.borderRadius || '0'};
`

export const Title = styled.h1`
    margin: ${props => props?.margin ? props.margin : 'inherit'};
    font-size: ${props => props?.fontSize || 'inherit'};
    text-align: ${props => props?.textAlign || 'inherit'};
    font-weight: ${props => props?.fontWeight || 'inherit'};
    color: ${props => props?.color || 'inherit'};
`

export const Subtitle = styled.h2`
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #909397;
`

export const AppInfo = styled.div`
    border-top: 3px solid ${theme.palette.primary.main};
    width: 100%;
    position: absolute;
    bottom: 0; 
    height: 30%;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
`

export const App = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 24px 0;
`

export const Info = styled.div` 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 0 8px;
`

export const Feedback = styled.div` 
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const Downloads = styled.p`  
    margin: 0 0 0 8px;
    font-size: 14px;
    color: #909397;
`

export const DownloadButtons = styled.div`  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`

export const Download = styled.div`   
    width: 50%;
    animation: 1s ${expandIt} ease-out;
    animation-iteration-count: 6; 
`

export const Animation = styled.div`  
    width: 100%;
    height: 40%; 
    position: absolute;
    bottom: 30%;
    overflow: hidden;
`

export const LeafsContainer = styled.div`   
    position: absolute;
    left: 10px;
    top: 27px;
    z-index: 0;
    animation: 3s ${moveIn} ease-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
`

export const Leafs = styled.img`   
    width: 200px;
`

export const Background = styled.img`   
    max-width: 100vw;
    overflow: hidden;
    position: absolute;
    top:  0;
    right: 0; 
`

export const PhoneContianer = styled.div`   
    max-width: 100vw;
    overflow: hidden;
    position: absolute;
    left:  calc(50% - 100px);
    right: 0; 
    max-height: 400px;
    overflow: hidden;
    top: -15px;
`

export const Phone = styled.img`   
    width: 200px; 
`

export const RocketContianer = styled.div`   
    position: absolute;
    left: 220px;
    top: 60px;
    max-height: 382px;
    overflow: hidden; 
    animation: 5s ${slideIn} ease-out;
    animation-iteration-count: 1;
    animation-direction: normal;
`

export const Rocket = styled.img`   
    width: 160px; 
`
