import { db } from '../firebase/firebase-config'
import { doc, getDoc } from 'firebase/firestore'

export const loadAlertTypesUpdatedAt = async () => {
  const docRef = doc(db, '/global/promo')

  try {
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data()?.alert_types_updated_at
    } else {
      console.error('Error getting alert types updated at data. No data found')
      return null
    }
  } catch (e) {
    console.error('Error getting alert types updated at data. ' + e)
  }
}
