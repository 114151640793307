import styled from 'styled-components/macro'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { InputBase } from '@mui/material'

export const ModalContainer = styled(Box)`
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: #ffffff;
    border-radius: 14px;
    box-sizing: border-box;
`

export const ModalHeaderContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 14px 24px;
border-bottom: 0.5px solid #E8EDEE;
box-sizing: border-box;
`

export const ModalHeaderTitle = styled.h2`
font-size: 16px;
color: #222831;
margin: 0;
`

export const ShareOptionsContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: flex-start;
justify-content: center;
padding: 20px 24px;
box-sizing: border-box;
`

export const ShareOptionTitle = styled.h3`
font-size: 14px;
color: #222831;
margin: 4px 0;
`

export const LinkInput = styled(InputBase)`
   width: 70%;
   background-color: #ffffff;
   border-radius: 8px;
   padding: 8px 12px;
   font-size: 14px;
   box-sizing:border-box;
   border: 1px solid #E8E8E8;
   color: #8C8C8C;
`

export const LinkOptionContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
box-sizing: border-box;
margin-bottom: 16px;
`

export const LinkCopyButton = styled(Button)`
text-transform: none !important;
color: ${props => props.theme.palette.primary.main} !important;
font-size: 12px !important;
`

export const SocialIcon = styled.img`
width: 24px;
margin-right: 16px;
`

export const SocialText = styled.span`
font-size: 14px;
color: #525253;
margin: 0;
`

export const CopiedContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`

export const CopiedText = styled.span`
font-size: 14px;
color: #49CB5C;
margin: 0;
`
