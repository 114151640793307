import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenArrivalInformation, setShowETA } from '../../../../actions/ui'
import {
  Ancillary,
  CloseButton,
  CloseIcon,
  Container,
  ContainerContent,
  Content,
  Header,
  Realtime,
  RealtimeContainer,
  RealtimeIcon,
  Schedule,
  Title
} from './styles'
import RealTimeIcon from '../../../../img/real-time-icon.svg'
import { InfoTitle } from '../../../ResultItem/styles'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { Dialog, useMediaQuery } from '@mui/material'

export const Information = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const open = useSelector(state => state?.ui?.components?.stopInformation)
  const mobile = useMediaQuery('(max-width:480px)')

  const handleClose = () => {
    dispatch(setOpenArrivalInformation(false))
  }

  const handleAccept = () => {
    dispatch(setShowETA(false))
    localStorage.setItem('show_arrival_time_information', JSON.stringify(false))
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '16px'
        }
      }}
    >
      <Container>
        {mobile
          ? (
            <Header>
              <Title>{t('stop.arrival_times_text')}</Title>
            </Header>
            )
          : (
            <Header>
              <Title>{t('stop.arrival_times_text')}</Title>
              <CloseButton edge='end' onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </Header>
            )}

        <ContainerContent>
          <RealtimeContainer>
            <Realtime>
              <RealtimeIcon src={RealTimeIcon} alt='realtime icon' />
              <Schedule>22</Schedule>
            </Realtime>
            <Ancillary>min</Ancillary>
          </RealtimeContainer>
          <Content>
            {t('stop.arrival_time_info_green')}
          </Content>
          <RealtimeContainer>
            <Realtime>
              <InfoTitle>{t('stop.scheduled')}</InfoTitle>
              <Schedule>22:00</Schedule>
            </Realtime>
          </RealtimeContainer>
          <Content>
            {t('stop.arrival_time_info_yellow')}
          </Content>
          <Button
            onClick={handleAccept}
            sx={{
              '& .MuiButton-root': {
                fontSize: 16
              }
            }}
          >
            {t('stop.understood')}
          </Button>
        </ContainerContent>

      </Container>
    </Dialog>
  )
}
