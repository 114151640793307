import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

/** getAllLines
 * Get city lines
 * @param {String} cityId
 * @returns {Promise<Response | void>}
 */

export const getAllLines = async (cityId) => {
  if (!cityId) return

  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    city_id: cityId
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/routes/getAll`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting city lines')
      }
    })
    .then((response) => response?.result)
    .catch((err) => console.error(err))
}
