import styled from 'styled-components/macro'

export const Span = styled.span`
  margin: 0;
  padding: 0;
  width: 24px;
  height: 4px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: table;
`
