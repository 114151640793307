import styled from 'styled-components/macro'
import { theme } from '../../theme/theme'

export const TimeLineItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-radius: 16px;
  background-color: ${props => props.active ? `${theme.palette.primary.main}9c` : '#ffffff'};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.active ? '#ffffff' : '#000000'};
`
