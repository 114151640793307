import React from 'react'
import appLogo from '../../../../img/logo.png'
import { Chip } from '@mui/material'
import { Container, Content, Logo, Title } from './styles'
import { getValue } from 'firebase/remote-config'
import { logEvent, remoteConfig } from '../../../../firebase/firebase-config'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { REACT_APP_HARDCODED_LOGO } from '../../../../constants/config'
import { theme } from '../../../../theme/theme'

/** <SmartTopBanner/>
 * @description The component is displayed at the bottom of the home screen on mobile when the openTopSmartBanner redux value is true. This means it is not a white label version, not an iframe, not embedded in the ios version and the value of the "show_smart_banner_top" parameter is true.
 *
 * Initial state:
 *     - openTopSmartBanner: It can be: true or false.
 *                           If it is not a white label version, it is not an iframe, it is not embedded in the ios version and the value of the parameter "show_smart_banner_top" is true, otherwise the value is false
 *
 * Methods:
 *     - handleOpenApp(): The link is fetched from the remote config and passed as a parameter to the getDynamicLinks
 *                        function, the dynamic link is fetched and opened to redirect the user to the app or open the store for download.
 *                        Sends "smart_banner_top_cta" event to Analytics.
 * @returns {JSX.Element}
 * @constructor
 */

export const SmartTopBanner = () => {
  const { t } = useTranslation()

  const user = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  /** handleOpenApp()
   * @description The link is fetched from the remote config and passed as a parameter to the getDynamicLinks
   * function, the dynamic link is fetched and opened to redirect the user to the app or open the store for download.
   * Sends "smart_banner_top_cta" event to Analytics.
   * @returns {Promise<void>}
   */

  const handleOpenApp = async () => {
    try {
      //  Gets the value of the parameter "smart_banner_top_cta_url" from the remote config
      /* const {_value: link} = getValue(remoteConfig, "smart_banner_top_cta_url"); */
      const { _value: ctaUrl } = getValue(remoteConfig, 'smart_banner_top_cta_url')

      // Sents "smart_banner_top_cta" event.
      logEvent('smart_banner_top_cta', {
        lat: userPosition?.lat ? userPosition?.lat : null, // Double or null
        lng: userPosition?.lng ? userPosition?.lng : null, // Double or null
        user_gender: userData?.gender ? userData?.gender : null, // String or null
        user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long or null
        os: 'web', // String
        user_id: user.uid, // String
        city_id: cityConfig?.city_id?.toString(),
        url: ctaUrl // String
      })

      /* const parmas = {
                link,
                title: '¡Descarga la app!',
                description: 'Elige entre las rutas y horarios de transporte público, alternativas de micromovilidad y servicios de taxis o autos geolocalizados',
                picture: downloadApp
            } */

      // Gets dynamic link
      /* const {shortLink} = await getDynamicLinks(parmas); */

      // Open dynamic link
      window.open(ctaUrl, '_self')
    } catch (e) {
      console.error('Error getting link', e)
    }
  }

  return (
    <Container>

      <Logo
        alt='Ualabee app logo'
        src={REACT_APP_HARDCODED_LOGO || appLogo}
      />
      <Content>
        <Title>{t('smart_banners.top.title')}</Title>
        <Chip
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            fontSize: 14,
            padding: '0 2px'
          }}
          label={t('smart_banners.top.open')}
          onClick={handleOpenApp}
          clickable
        />
      </Content>

    </Container>
  )
}
