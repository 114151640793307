import styled from 'styled-components/macro'
import { Button as ButtonMaterial, Card } from '@mui/material'

export const NavbarContainer = styled(Card)` 
`

export const Container = styled.div`
    padding: 8px;
    display: flex;   
`

export const ButtonIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: ${props => `${props.theme?.palette?.primary?.main}40`};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: 'column'; 
`

export const ButtonContainer = styled(ButtonMaterial)`          
    border-radius: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex: 1 ;
    padding: 10px 0;
    font-weight: 400;
    font-size: 16px;
    text-transform: none !important;
    &:hover { 
        background-color: #FAFAFA !important;           
         ${ButtonIcon} {
            transition: border-radius 0.5s 0s ease !important;
            border-radius: 8px !important; 
            background-color: "#fff" !important; 
            path {
                fill: "#fff" !important;            
            }
        }
    }
`

export const Label = styled.p`
    margin: 0;
    margin-top: 6px;
    line-height: normal;
    height: 38px;
    color: ${props => props.theme.palette.text.secondary.main};
`

export const Button = styled.div` 
    border-radius: 12px;
    border: none; 
    font-weight: 400px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
`
