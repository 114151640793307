import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleClickWebToAppButton, setStopPictures } from '../../../../actions/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import {
  CloseButton,
  CloseIcon,
  HeaderText,
  Image,
  Name,
  Next,
  NextIcon,
  Prev,
  PrevIcon,
  ReportButton,
  ReportIcon,
  SwiperButtons,
  ThumbIcon,
  Thumbs,
  UserInformation
} from './styles'
import { useLocation } from 'react-router-dom'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import { logEvent } from '../../../../firebase/firebase-config'
import { Dialog } from '@mui/material'
import { REACT_APP_HOST } from '../../../../constants/config'

SwiperCore.use([Navigation])

export const MorePictures = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const uid = getLocalUserUid()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const pictures = useSelector(state => state?.ui?.components?.openMoreStopPictures)
  const index = useSelector(state => state?.ui?.components?.openMoreStopPictures?.index)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const handleClose = () => {
    dispatch(setStopPictures({ ...pictures, enabled: false }))
  }

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <Dialog
      open={pictures?.enabled}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '100vw',
          height: '100vh',
          margin: 0,
          maxWidth: '70vw',
          maxHeight: '70vh',
          borderRadius: '16px'
        }
      }}
    >
      <Swiper
        style={{
          width: '100%',
          position: 'relative'
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        spaceBetween={0}
        slidesPerView={1}
      >

        {index
          ? (
            <SwiperSlide style={{ position: 'relative' }} key={pictures?.pictures[index]?.id}>
              <HeaderText>{pictures?.pictures[index]?.user_display_name}</HeaderText>
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
              <Image src={pictures?.pictures[index]?.stop_picture_uri} />
              <UserInformation>
                <Name>{pictures?.pictures[index]?.user_nickname}</Name>
                <ThumbIcon />
                <Thumbs>{pictures?.pictures[index]?.likes_count || 0}</Thumbs>
              </UserInformation>
            </SwiperSlide>
            )
          : (
            <>
              {pictures?.pictures?.map((picture) => {
                return (
                  <SwiperSlide style={{ position: 'relative' }} key={picture?.id}>
                    <HeaderText>{picture?.user_display_name}</HeaderText>
                    <CloseButton onClick={handleClose}>
                      <CloseIcon />
                    </CloseButton>
                    <Image src={picture?.stop_picture_uri} />
                    <UserInformation>
                      <Name>{picture?.user_nickname}</Name>
                      <ThumbIcon />
                      <Thumbs>{picture?.likes_count || 0}</Thumbs>
                    </UserInformation>
                  </SwiperSlide>
                )
              })}
            </>
            )}

        <SwiperButtons>
          <Prev
            sx={{
              padding: '8px',
              backgroundColor: '#222831a8',
              '&:hover': {
                backgroundColor: '#222831a8'
              },
              '&:focus': {
                backgroundColor: '#222831'
              },
              '&:active': {
                backgroundColor: '#222831'
              }
            }}
            ref={navigationPrevRef}
          >
            <PrevIcon fontSize='small' />
          </Prev>
          <Next
            sx={{
              padding: '8px',
              backgroundColor: '#222831a8',
              '&:hover': {
                backgroundColor: '#222831a8'
              },
              '&:focus': {
                backgroundColor: '#222831'
              },
              '&:active': {
                backgroundColor: '#222831'
              }
            }}
            ref={navigationNextRef}
          >
            <NextIcon fontSize='small' />
          </Next>
          <ReportButton onClick={() => dispatch(handleClickWebToAppButton(true, {
            from: 'stop_picture_report',
            lat: userPosition ? userPosition?.lat : null,
            lng: userPosition ? userPosition?.lng : null,
            user_id: uid,
            os: 'web',
            city_id: urlParams?.city_id.toString(),
            link_to: `${REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
          }, logEvent))}
          >
            <ReportIcon />
          </ReportButton>
        </SwiperButtons>
      </Swiper>
    </Dialog>
  )
}
