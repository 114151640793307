export const getBikeNetworksByCity = (BikeNetworks) => {
  return fetch(`https://api.citybik.es/v2/networks/${BikeNetworks}`)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting bike stations')
      }
    })
    .then((response) => response)
    .catch(error => console.error(error))
}
