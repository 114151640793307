import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST, REACT_APP_VARIANT_ID } from '../constants/config'

export const getAllRedeemedRewards = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': REACT_APP_VARIANT_ID
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/rewards/tickets/getAllRedeemedRewards?cityId=${cityId}`, reqConfig)
}
