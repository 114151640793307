import React, { useEffect } from 'react'
import {
  CityInfoContainer,
  Container,
  InfoContainer,
  InfoIcon,
  InfoItem,
  InfoSelectedCloseButton,
  InfoSelectedCloseIcon,
  InfoSelectedContainer,
  InfoSelectedTitle,
  InfoText,
  ItemSelectedContent,
  ItemSelectedNavbar
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { ActionButtonsContainer } from '../../CityInfo/styles'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import ShareSmallButton from '../../../uicomponents/ShareSmallButton'
import {
  enabledAlertsAndNewsComponent,
  handleCityInfoClick,
  setButtonNavbarSelected,
  setCityInfoSelected,
  setLoading,
  setShowUsefulInformation,
  verifyCityInfo
} from '../../../actions/ui'
import { CityInfoSkeleton } from '../../../uicomponents/desktop/cityInfo/Skeleton'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Paper, Slide, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { theme } from '../../../theme/theme'
import { REACT_APP_HOST, REACT_APP_APP_NAME } from '../../../constants/config'
import NoResultMessage from '../../../uicomponents/NoResultMessage'

export const CityInfo = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const infoList = useSelector(state => state?.ui?.components?.city_info)
  const cityInfoSelected = useSelector(state => state.ui.components?.city_info_selected)
  const alertsAndNews = useSelector(state => state?.ui?.components.alertsAndNews)

  useEffect(() => {
    dispatch(setLoading(pathname?.split('/')[3] && !cityInfoSelected))
  }, [cityInfoSelected])

  const handleClose = () => {
    dispatch(setCityInfoSelected(undefined))
    navigate('/city_info')
  }

  const handleShowCityInfo = () => {
    dispatch(setCityInfoSelected(undefined))

    if (alertsAndNews?.enabled) {
      dispatch(enabledAlertsAndNewsComponent({ enabled: !alertsAndNews?.enabled }))
    }

    dispatch(setButtonNavbarSelected('information'))
    dispatch(setShowUsefulInformation(true))

    if (pathname === '/city_info') {
      navigate('/')
    } else {
      navigate('/city_info')
      dispatch(verifyCityInfo())
    }
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        marginTop: '12px',
        borderRadius: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px #22283129'
      }}
    >
      <Slide direction='down' in mountOnEnter unmountOnExit>
        <Paper style={{ backgroundColor: '#ffe4c400', boxShadow: 'none', height: '100%' }} elevation={4}>
          <CityInfoContainer>
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px 16px',
                  borderBottom: '1px solid #E8EDEE'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: theme.text.secondary.main
                  }}
                  variant='h1'
                >
                  {t('home.navbar.items.alt_info_uppercase')}
                </Typography>
                <IconButton
                  edge='end'
                  onClick={handleShowCityInfo}
                >
                  <CloseRounded />
                </IconButton>
              </Box>

              <InfoContainer
                height='calc(100vh - 395px)'
              >
                {infoList?.length > 0 &&

                  <>
                    {infoList?.map((item) => {
                      return (
                        <InfoItem
                          key={item.id}
                          onClick={() => dispatch(handleCityInfoClick(item, navigate))}
                        >
                          <InfoText
                            isSelected={pathname?.split('/')[3] === item?.id}
                          >
                            {item.title}
                          </InfoText>
                          <InfoIcon
                            isSelected={pathname?.split('/')[3] === item?.id}
                            fontSize='small'
                          />
                        </InfoItem>
                      )
                    }
                    )}
                  </>}
                {!infoList && <CityInfoSkeleton />}
                {infoList?.length === 0 && <NoResultMessage message={t('city_info.no_results')} />}

              </InfoContainer>
            </Container>
          </CityInfoContainer>
        </Paper>
      </Slide>
      <Slide direction='up' in={cityInfoSelected} mountOnEnter unmountOnExit>
        <Paper style={{ backgroundColor: '#ffe4c400', boxShadow: 'none' }} elevation={15}>
          <CityInfoContainer
            style={{
              position: 'absolute',
              zIndex: 450,
              top: 176,
              left: 462,
              margin: 0,
              width: 688,
              height: 'calc(100% - 12px - 164px)',
              boxShadow: '0px 3px 6px #22283129'
            }}
          >
            <InfoSelectedContainer>
              <ItemSelectedNavbar>
                <InfoSelectedTitle>{cityInfoSelected?.title}</InfoSelectedTitle>
                <ActionButtonsContainer>
                  <ShareSmallButton
                    eventName='share_city_info'
                    eventParams={{
                      user_id: auth?.uid,
                      os: 'web',
                      user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                      user_gender: userData ? userData?.gender : null,
                      user_lat: userPosition ? userPosition.lat : null,
                      user_lng: userPosition ? userPosition.lng : null,
                      city_id: cityConfig?.city_id,
                      id: cityInfoSelected?.id,
                      title: cityInfoSelected?.title
                    }}
                    params={
                      {
                        link: `${REACT_APP_HOST}/city_info/${params?.city}/${cityInfoSelected?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_city_info`,
                        title: `Información de ${REACT_APP_APP_NAME} - ${cityInfoSelected?.title}`,
                        description: cityInfoSelected?.description
                      }
}
                    shareType='share'
                    shareText={t('share.title')}
                  />
                  <ShareSmallButton
                    eventName='send_to_phone'
                    eventParams={{
                      user_id: auth?.uid,
                      os: 'web',
                      user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                      user_gender: userData ? userData?.gender : null,
                      user_lat: userPosition ? userPosition.lat : null,
                      user_lng: userPosition ? userPosition.lng : null,
                      city_id: cityConfig?.city_id,
                      id: cityInfoSelected?.id,
                      title: cityInfoSelected?.title,
                      from: 'city_info'
                    }}
                    params={
                      {
                        link: `${REACT_APP_HOST}/city_info/${params?.city}/${cityInfoSelected?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_city_info`,
                        title: `Información de ${REACT_APP_APP_NAME} - ${cityInfoSelected?.title}`,
                        description: cityInfoSelected?.description
                      }
}
                    notificationBody={{
                      title: `Información de ${REACT_APP_APP_NAME} - ${cityInfoSelected?.title}`,
                      content: cityInfoSelected?.description
                    }}
                    shareType='send-to-mobile'
                    shareText={t('send_to_mobile.title')}
                  />

                  <InfoSelectedCloseButton onClick={handleClose}>
                    <InfoSelectedCloseIcon />
                  </InfoSelectedCloseButton>

                </ActionButtonsContainer>
              </ItemSelectedNavbar>
              <ItemSelectedContent dangerouslySetInnerHTML={{ __html: cityInfoSelected?.content }} />
            </InfoSelectedContainer>
          </CityInfoContainer>
        </Paper>
      </Slide>
    </Box>
  )
}
