import styled from 'styled-components/macro'
import { theme } from '../../theme/theme'

export const SearchResult = styled.div` 
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    border-bottom: ${props => {
  return props.border ? '1px solid #E8EDEE' : ''
}};
    padding: ${props => props?.mode ? '16px' : '16px 6px 16px 23px'};
    cursor: pointer;
    &:hover {
        background-color: #F6F8F9;
    }
    border: ${props => props?.selected ? '1px solid #1b7585' : 'none'};
    border-radius: 8px;
`

export const TripDetails = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const InfoGroup = styled.div`
display: flex;
flex-direction: row;
box-sizing: border-box;
`

export const InfoItem = styled.div`
display: flex;
flex-direction: column;
box-sizing: border-box;
align-items: center;
margin-right: 22px;
:last-child {
    margin-right: 0;
};
`

export const InfoTitle = styled.h3`
    margin: 0;
    color: ${theme.palette.text.secondary.main};
    font-size: ${props => props?.mode ? '10px' : '14px'}; 
    font-weight: ${props => props?.mode ? 'bold' : '400'};
    margin-bottom: 4px;
`

export const InfoData = styled.span`
    margin: 0;
    color: ${theme.palette.text.primary.main};
    font-size: 16px;   
    @media (max-width: 480px) {
      font-size: 12px;   
    }
`

export const Schedule = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
`

export const ScheduleDate = styled.span`
    margin: 0;
    color: ${theme.palette.text.primary.main};
    font-size: 24px;
    font-weight: ${props => props?.mode ? 'inherit' : '500'};
`

export const TripSteps = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border: 1px solid #E0E8F7;
    border-radius: 8px;
    align-items: center;
    width: fit-content;
    padding: 8px;
    margin-bottom: 8px;
    height: ${props => props?.mode ? 'auto' : '48px'};
    max-width: 80%;
    overflow-y: hidden;
    overflow-x: auto;
`

export const FromToIcon = styled.img`
width: 15px
`

export const WalkStep = styled.div`
display: flex;
flex-direction: row;
box-sizing: border-box;
align-items: flex-end;
`

export const WalkValue = styled.span`
margin: 0;
color: #909397;
font-size: 10px;
`

export const LineContainer = styled.div`
display: flex;
flex-direction: row;
box-sizing: border-box;
border: 1px solid ${props => `#${props.color}`};
border-left: 4px solid ${props => `#${props.color}`};
border-radius: 6px;
align-items: center;
padding: 5px 4px;
width: max-content;
`

export const HeadSign = styled.h4`
    margin: 0;
    color: ${theme.palette.text.secondary.main};
    font-size: ${props => props?.mode ? '12px' : '14px'};
    font-weight: ${props => props?.mode ? 'bold' : '400'}; 
    margin-bottom: 8px;
`

export const LineIcon = styled.img`
    width: 20px;
    margin-right: 4px;
`

export const LineName = styled.span`
    margin: 0;
    color: #222831;
    font-size: 12px;
    font-weight: regular;
`

export const WarningLabel = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
`

export const WarningText = styled.span`
    margin: 0;
    color: #222831;
    font-size: 10px;
    font-weight: regular;
`

export const OcupancyContainer = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const OcupancyIcon = styled.img`
    width: 20px;
    margin-right: 2px;
`

export const RealTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: flex-end;
    position: relative;
`

export const RealTimeIconImg = styled.img`
    position: absolute;
    top: ${props => props?.top};
    left: -10px;
`

export const Number = styled.h4`
    margin: 0;
    color: #222831;
    font-size: ${props => props.fontSize};
`

export const Span = styled.span`
    margin: 0;
    color: #909397;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 4px;
`
