import React, { useEffect, useState } from 'react'
import { AllLines, Container, LinesContainer, Title } from './styles'
import { useSelector } from 'react-redux'
import { LineGroup } from '../Line/styles'
import { Loading } from '../../../uicomponents/mobile/Loading'
import { LineItem } from '../../../uicomponents/LineItem'
import { SkeletonLine } from '../../../uicomponents/desktop/line/Line'
import { Skeleton } from '@mui/material'

import { useTranslation } from 'react-i18next'
import HelpMessage from '../../../uicomponents/HelpMessage'

export const Lines = () => {
  const { t } = useTranslation()

  const linesByAgency = useSelector(state => state?.ui?.currentLinesByAgency)
  const lines = useSelector(state => state?.ui?.currentLines)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const loading = useSelector(state => state?.ui?.loading)
  const { term = '' } = useSelector(state => state?.ui?.search)
  const transportationType = useSelector(state => state?.user?.lines?.transportationType)

  const [currentFavLines, setCurrentFavLines] = useState([])

  useEffect(() => {
    if (!lines || lines?.length === 0) return

    let favLines = transportationType === 0
      ? lines?.filter(line => line.fav)
      : lines.filter(line => line.fav && line?.transport_type?.transport_type_id === transportationType)

    if (term.length >= 1) {
      favLines = favLines?.filter(line =>
        (line?.route_short_name || line?.route_long_name)?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            term
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      )
    }
    setCurrentFavLines(favLines)
  }, [lines, transportationType, term])

  return (
    <Container>
      {lines?.length === 0 || loading && <Loading />}
      <LinesContainer
        id='line-list'
      >
        {currentFavLines?.length > 0 &&
          <LineGroup
            sx={{
              boxShadow: '0 0 24px #22283108',
              borderRadius: '16px',
              marginBottom: '16px',
              padding: '16px',
              '&:last-child': {
                marginBottom: 0
              }
            }}
          >

            {currentFavLines?.length > 0
              ? <Title>{t('lines.favorites.title')}</Title>
              : <Skeleton variant='text' width={60} height={21} />}

            <div>
              {lines?.length > 0
                ? (
                  <>
                    {currentFavLines?.map((line) => {
                      const icon = cityConfig?.transport_types?.filter((type) => {
                        return line?.transport_type.transport_type_id === type?.transport_type_id
                      })[0]?.flat_icon

                      if (line?.fav) {
                        return (
                          <LineItem
                            key={line.route_id}
                            line={line}
                            icon={icon}
                          />
                        )
                      }
                    })}
                  </>
                  )
                : (
                  <SkeletonLine />
                  )}
            </div>
          </LineGroup>}
        <AllLines>
          {linesByAgency?.length >= 1
            ? (
              <>
                {linesByAgency
                  ?.filter(group => group?.lines?.length >= 1)
                  ?.map((group) => {
                    return (
                      <LineGroup
                        sx={{
                          boxShadow: '0 0 24px #22283108',
                          borderRadius: '16px',
                          marginBottom: '16px',
                          padding: '16px',
                          '&:last-child': {
                            marginBottom: 0
                          }
                        }}
                        key={group?.name}
                      >
                        <Title>{group?.name}</Title>
                        <div>
                          {group?.lines?.map((line) => {
                            const icon = cityConfig?.transport_types?.filter((type) => {
                              return line?.transport_type.transport_type_id === type?.transport_type_id
                            })[0]?.flat_icon

                            return (
                              <LineItem
                                key={line.route_id}
                                line={line}
                                icon={icon}
                              />
                            )
                          })}
                        </div>
                      </LineGroup>
                    )
                  })}
              </>
              )
            : (
              <div style={{
                padding: 16
              }}
              >
                {currentFavLines?.length === 0 &&
                  <>
                    {term?.length >= 1
                      ? (
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        >
                          <HelpMessage
                            message={t('lines.helper_text')}
                            color='#cfcfcf'
                          />
                        </div>
                        )
                      : (
                        <>
                          <Skeleton variant='text' width={60} height={21} />
                          <SkeletonLine />
                        </>
                        )}
                  </>}
              </div>
              )}
        </AllLines>
      </LinesContainer>
    </Container>
  )
}
