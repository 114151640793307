import styled from 'styled-components/macro'
export const Container = styled.div`    
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 14px;
`

export const TextContainer = styled.div`    
    margin-Left: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 86px;
`
