import React from 'react'
import {
  AffectedIcon,
  Container,
  Info,
  InfoContainer,
  LineName,
  LineNameContainer,
  NotAffectedIcon,
  RouteName,
  StatusLineContainer
} from './styles'
import { useLines } from '../../hooks/useLines'
import { Icon, LineIcon } from '../../components/Desktop/Lines/styles'
import { theme } from '../../theme/theme'
import { Box, IconButton, Tooltip } from '@mui/material'
import { FavoriteBorder, FavoriteRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const LineItem = ({ line, icon }) => {
  const {
    handleClickLine,
    handleAddLineFav,
    handleRemoveLineFav
  } = useLines()
  const { t } = useTranslation()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  return (
    <Box
      sx={{ position: 'relative' }}
    >
      <Container
        onClick={() => handleClickLine(line)}
      >
        <InfoContainer>
          <Info>
            <LineIcon color={line?.route_color}>
              <Icon src={icon} />
            </LineIcon>

            <LineNameContainer>
              <LineName>{line?.route_short_name || line?.route_long_name}</LineName>
              <RouteName>{line?.route_desc}</RouteName>
            </LineNameContainer>
          </Info>
        </InfoContainer>

        {line?.affected_route
          ? <Tooltip title={t('lines.line_with_delays')} placement='bottom' arrow><AffectedIcon /></Tooltip>
          : <Tooltip title={t('lines.line_without_delays')} placement='bottom' arrow><NotAffectedIcon /></Tooltip>}

      </Container>

      <StatusLineContainer>

        {line?.fav
          ? (
            <IconButton
              onClick={() => handleRemoveLineFav(line)}
              disable={!cityConfig}
              sx={{
                textTransform: 'none',
                position: 'absolute',
                bottom: '4px',
                right: '4px',
                marginLeft: 0,
                padding: '8px',
                '@media (max-width:480px)': {
                  bottom: '8px',
                  right: '8px'
                }
              }}
            >
              <FavoriteRounded style={{ color: '#F05454' }} />
            </IconButton>
            )
          : (
            <Tooltip title={t('lines.add_to_favorites')} placement='bottom' arrow>

              <IconButton
                disable={!cityConfig}
                onClick={() => handleAddLineFav(line)}
                sx={{
                  textTransform: 'none',
                  position: 'absolute',
                  bottom: '4px',
                  right: '4px',
                  marginLeft: 0,
                  padding: '8px',
                  '@media (max-width:480px)': {
                    bottom: '8px',
                    right: '8px'
                  }
                }}
              >
                <FavoriteBorder style={{ color: theme.palette.icons.secondary.main }} />
              </IconButton>
            </Tooltip>
            )}
      </StatusLineContainer>
    </Box>

  )
}
