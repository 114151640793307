export const types = {
  lines: '[UI] set all lines',
  currentLines: '[UI] set current lines',
  currentLinesByAgency: '[UI] set current lines by agency',
  searchValue: '[UI] set search value',
  autocompleteResults: '[UI] save autocomplete results',
  placesSelectedFrom: '[UI] set from places values',
  placesSelectedTo: '[UI] set to places values',
  autocompleteOrientation: '[UI] set orientation search',
  selectDateTimeLabel: '[UI] set label of select',
  dateTimePickerValue: '[UI] set data time picker value',
  tripSelected: '[UI] set trip selected',
  tripDetails: '[UI] set trip details',
  tripSelectedVehicles: '[UI] set trip selected vehicles position',
  lineSelected: '[UI] set line selected',
  arrivalSelected: '[UI] set arrival selected',
  stopSelected: '[UI] set stop selected',
  timestamp: '[UI] set timestamp',
  openCovidCenter: '[UI] open covid center information',
  showUsefulInformation: '[UI] show useful information',
  buttonNavbarSelected: '[UI] button navbar selected',
  cityConfig: '[UI] city config',
  openCityModal: '[UI] open identified city modal',
  identifiedCity: '[UI] set identified city',
  alerts: '[UI] set alerts list',
  countries: '[UI] set countries list',
  cities: '[UI] set cities list',
  linesComponent: '[UI] hide/show lines component',
  transportFiltersComponent: '[UI] hide/show transport filters component',
  alertsAndNewsComponent: '[UI] hide/show alerts and news component',
  navbar: '[UI] hide/show navbar component',
  search: '[UI] hide/show search component',
  userReportDrawerRef: '[UI] set user report drawer ref',
  routes: '[UI] set routes',
  data: '[UI] set line data',
  tripPreview: '[UI] hide/show tripPreview component',
  tripResults: '[UI] hide/show tripResult component',
  autocomplete: '[UI] hide/show search autocomplete component',
  filters: '[UI] hide/show filters component',
  mapFilters: '[UI] hide/show map filters component',
  mode: '[UI] mobile app mode',
  tripResponse: '[UI] trip results response',
  noLoginDialog: '[UI] noLogin dialog',
  invitationMessage: '[UI] show/hide invitation message dialog',
  arrivals: '[UI] current stop arrivals',
  stop: '[UI] hide/show stop component',
  security: '[UI] set stop security',
  showETA: '[UI] set show ETA',
  openMoreStopPictures: '[UI] open more stop pictures',
  openStopArrivalTimeInformation: '[UI] open stop arrival time information',
  openStopOptions: '[UI] open stop options',
  openOccupancyLevel: '[UI] open stop occupancy level',
  openAlertInformation: '[UI] hide/show current alert or new information',
  openShareModal: '[UI] hide/show share modal',
  openShareDrawer: '[UI] hide/show mobile share drawer',
  openSendToPhoneModal: '[UI] hide/show send to phone modal',
  stopPictures: '[UI] set stop pictures',
  openLoginPopup: '[UI] hide/show login popup',
  currentFilters: '[UI] set current map filters',
  geoLocationBlocked: '[UI] set geo location blocked',
  loading: '[UI] set loading application',
  openCountries: '[UI] hide/show countries component',
  cityIdentifiedDismissed: '[UI] set city identified dismissed',
  countrySelected: '[UI] set country selected',
  openCities: '[UI] hide/show cities component',
  menu: '[UI] hide/show menu component',
  openCountryAlert: '[UI] hide/show country alert',
  navbarPosition: '[UI] set navbar position',
  helmet: '[UI] set current helmet data',
  cityInfo: '[UI] set city info list',
  cityInfoSelected: '[UI] set city info selected',
  stopImage: '[UI] set current stop image selected',
  isIosApp: '[UI] set value if is ios app',
  showMenuNotification: '[UI] set show menu notification',
  openSendCommentariesDialog: '[UI] set open send commentaries dialog',
  alert: '[UI] set user alert',
  openSmartBanner: '[UI] set open smart banner',
  openTopSmartBanner: '[UI] set open top smart banner',
  openBottomSmartBanner: '[UI] set open bottom smart banner',
  ads: '[UI] set city ads',
  adSelected: '[UI] set ad selected',
  setAdList: '[ui set ads list]',
  openAdSelected: '[UI] set open ad selected',
  showRouteExtended: '[UI] set show route extended',
  autoCompleteStations: '[UI] set autocomplete stations',
  searchResultClickCount: '[UI] set search result click count',
  NPSSurveyShown: '[UI] set NPS survey shown',

  // user
  linesFav: '[USER] set lines fav',
  transportationType: '[USER] set transportation',
  lineSearchResult: '[USER] set line search result',
  userPosition: '[USER] set user position',
  auth: '[USER] set current user',
  userData: '[USER] set current user data',
  fcmToken: '[USER] set current user fcmtoken',
  savedPlaces: '[USER] set user places',
  searchFilters: '[UI] set user search filters',
  userContribution: '[USER] set user contribution history',
  userTicketRewardStats: '[USER] set user tickets rewards stats',

  // map
  zoom: '[MAP] set map zoom',
  center: '[MAP] set map center',
  bounds: '[MAP] set map bounds',
  userReports: '[MAP] set user reports',
  stops: '[MAP] set map stops',
  refillPoints: '[MAP] set map refill points',
  bikeStations: '[MAP] set bike stations',
  reportTypes: '[MAP] set user report types',
  currentBounds: '[MAP] set current map bounds',
  bikeStationSelected: '[MAP] set bike station selected',
  viewState: '[MAP] set map view state',
  subwayAndTrainNetworks: '[UI] set subway and train networks'
}
