import React from 'react'

import { Description, ModalContainer, Title } from './styles'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'

const DataProviderModal = ({ provider, description, open, setOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <ModalContainer>
        <Title>{t('routes.data_provider', { prop1: provider })}</Title>
        <Description>{description}</Description>
      </ModalContainer>
    </Modal>
  )
}

export default DataProviderModal
