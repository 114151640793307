import { REACT_APP_FIREBASE_API_KEY, REACT_APP_ANDROID_PACKAGE_NAME, REACT_APP_IOS_BUNDLE_ID, REACT_APP_DOMAIN_URI_PREFIX } from '../constants/config'
export const getDynamicLinks = (params) => {
  const { link, title = null, description = null, picture = null } = params

  const url = new URL(link)

  const reqBody = {
    dynamicLinkInfo: {
      domainUriPrefix: REACT_APP_DOMAIN_URI_PREFIX,
      link: url.href,
      androidInfo: {
        androidPackageName: REACT_APP_ANDROID_PACKAGE_NAME
      },
      iosInfo: {
        iosBundleId: REACT_APP_IOS_BUNDLE_ID
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: description,
        socialImageLink: picture
      }

    }
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${REACT_APP_FIREBASE_API_KEY}`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting dynamic link')
      }
    })
    .then((response) => response)
    .catch((err) => console.error(err))
}
