import React from 'react'
import { Skeleton } from '@mui/material'
import { Container, Details, Info } from './styles'

export const SkeletonLine = () => {
  const lines = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

  return (
    <>
      {lines?.map((item) => {
        return (
          <Container key={item}>
            <Details>
              <Skeleton
                style={{
                  borderRadius: 10,
                  marginRight: 16
                }} variant='rectangular' width={32} height={32}
              />
              <Info>
                <Skeleton variant='text' width={17} height={18} />
                <Skeleton variant='text' width={127} height={17} />
              </Info>
            </Details>
            <Details>
              <Skeleton
                style={{
                  marginRight: 16
                }} variant='circular' width={24} height={24}
              />
              <Skeleton variant='circular' width={24} height={24} />
            </Details>
          </Container>
        )
      })}
    </>

  )
}
