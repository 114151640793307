import styled from 'styled-components/macro'

export const SkeletonContainer = styled.div` 
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    width: 100%;
    height: 109px;
    min-height: 109px;
    background-color: #efefef;
    border-radius: 10px;
    margin-bottom: 12px;
`

export const CircleContainer = styled.div` 
    padding: 14px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const TextContainer = styled.div` 
    padding: 14px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
