import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 16px 0 0;
  width: 100%;
  @media (max-width: 480px) {
    margin: 6px 8px;
  }
`

export const Details = styled.div`
  display: flex;
  align-items: center;
`

export const Info = styled.div`
`
