import React from 'react'
import VerifiedReport from '../../img/ic_check_white_24px.svg'
import PendingReport from '../../img/ic_pending_white.svg'

import { CardContainer, CardTitle, Distance, IconContainer, ValidateIcon } from './styles'

const ImageCard = ({
  item,
  title,
  backgroundImg,
  onClickFunction,
  iconImage,
  validate,
  map
}) => {
  return (
    <CardContainer
      backgroundImg={backgroundImg}
      onClick={() => onClickFunction(item, map)}
    >
      <CardTitle>{title}</CardTitle>
      {iconImage &&
        <IconContainer iconImage={iconImage}>
          <ValidateIcon
            src={`${validate ? VerifiedReport : PendingReport}`}
            validate={validate}
          />
        </IconContainer>}

      {item?.distance &&
        <Distance>
          {item?.distance?.toLocaleString(navigator?.language, {
            maximumFractionDigits: 2
          })} Km
        </Distance>}
    </CardContainer>
  )
}

export default ImageCard
