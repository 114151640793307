import { db } from '../firebase/firebase-config'
import { collection, getDocs } from 'firebase/firestore'

export const loadAlertTypes = async () => {
  const types = []

  const alertTypesRef = collection(db, '/global/alert_types/data')

  const querySnapshot = await getDocs(alertTypesRef)

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    types.push({
      id: doc.id,
      ...doc.data()
    })
  })

  return types
}
