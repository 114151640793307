import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'

/** deleteUserLine()
 * @description Delete a user line from the database
 * @param {string} id
 * @returns {Promise<void>}
 */

export const deleteUserLine = async (id) => {
  const auth = getAuth()
  const user = auth.currentUser

  if (id) {
    deleteDoc(doc(db, `users/${user?.uid}/lines`, id))
      .then(() => console.info('Line was removed successfully!'))
      .catch(error => {
        alert('Se produjo un error al eliminar la linea')
        console.error('Error deleting line: ' + error)
      })
  } else {
    console.error('Error deleting line: No line id provided')
  }
}

deleteUserLine.propTypes = {
  id: PropTypes.string.isRequired
}
