import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { setSearchResultClickCount, setTripSelected, setTripSelectedVehicles } from '../actions/ui'
import polyUtil from 'polyline-encoded'
import { logEvent } from '../firebase/firebase-config'

export const useTripResults = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const uid = sessionStorage.getItem('uid')

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const timestamp = useSelector(state => state?.ui?.timestamp)
  const userData = useSelector(state => state?.user?.userData)
  const mode = useSelector(state => state?.ui?.mode)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)

  const handleTripClick = (trip) => {
    // update or save a counter in local storage
    dispatch(setSearchResultClickCount())
    dispatch(setTripSelectedVehicles([]))

    if (trip?.legs[0]?.mode === 'BICYCLE') {
      const eventParams = {
        lat: userPosition?.lat || null, // Double
        lng: userPosition?.lng || null, // Double
        os: 'web', // String
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_id: uid, // String
        user_gender: userData?.gender || null, // String
        origin_address: from?.name, // String
        origin_latitude: from?.latLng?.lat, // Double
        origin_longitude: from?.latLng?.lng, // Double
        destination_address: to?.name, // String
        destination_latitude: to?.latLng?.lat, // Double
        destination_longitude: to?.latLng?.lng, // Double
        quick_result_distance: trip?.legs[0].distance, // Double
        quick_result_duration: trip?.duration, // Long
        flat_result_distance: trip?.legs[0].distance, // Double
        flat_result_duration: trip?.duration, // Long
        friendly_result_distance: trip?.legs[0].distance, // Double
        friendly_result_duration: trip?.duration, // Long
        search_uuid: uid + timestamp, // String
        city_id: cityConfig?.city_id?.toString() // String
      }

      // Send events to analytics
      logEvent('show_user_bike_trip_details', eventParams)
    } else if (trip?.legs[0]?.mode === 'CAR') {
      const type = cityConfig?.transport_types?.filter(transport => transport.transport_type_id === parseInt(trip?.legs[0].transportTypeId))

      const price = trip?.fare?.cents && trip?.fare?.default_fraction_digits ? trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits : null

      const eventParams = {
        user_id: uid,
        city_id: cityConfig?.city_id?.toString(), // String
        os: 'web', // String
        price_normal: price, // Float
        price_night: price, // Float
        distance: Number((trip?.legs[0]?.distance / 1000)?.toFixed(2)), // Double
        duration: trip?.duration, // Double
        car_type: type[0]?.name, // String
        lat: userPosition?.lat || null, // Double
        lng: userPosition?.lng || null, // Double
        origin_latitude: from?.latLng?.lat, // Double
        origin_longitude: from?.latLng?.lng, // Double
        origin_address: from?.name, // String
        destination_address: to?.name, // String
        destination_latitude: to?.latLng?.lat, // Double
        destination_longitude: to?.latLng?.lng, // Double
        search_uuid: uid + timestamp, // String
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null // String
      }

      // Send events to analytics
      logEvent('show_car_trip_details', eventParams)
    } else {
      const firstNoWalkLeg = trip?.legs?.filter(leg => leg?.mode !== 'WALK')[0]
      const lastNoWalkLeg = trip?.legs?.filter(leg => leg?.mode !== 'WALK').slice(-1)[0]

      const firstLegWalk = trip?.legs?.filter(leg => leg?.mode === 'WALK')[0]
      const secondLegWalk = trip?.legs?.filter(leg => leg?.mode === 'WALK')[1]
      const thirdLegWalk = trip?.legs?.filter(leg => leg?.mode === 'WALK')[2]

      const currentStops = trip?.legs?.filter(trip => trip?.mode !== 'WALK').map(current => {
        return `${current.to.stopCode},${current.from.stopCode}`
      }).toString()

      const eventParams = {
        stop_address_a: firstNoWalkLeg?.from?.name, // String
        stop_address_a_lat: firstNoWalkLeg?.from?.lat, // Double
        stop_address_a_lng: firstNoWalkLeg?.from?.lon, // Double
        stop_address_b: firstNoWalkLeg?.to?.name, // String
        stop_address_b_lat: firstNoWalkLeg?.to?.lat, // Double
        stop_address_b_lng: firstNoWalkLeg?.to?.lon, // Double
        stop_address_c: lastNoWalkLeg ? lastNoWalkLeg?.from?.name : null, // String
        stop_address_c_lat: lastNoWalkLeg ? lastNoWalkLeg?.from?.lat : null, // Double
        stop_address_c_lng: lastNoWalkLeg ? lastNoWalkLeg?.from?.lon : null, // Double
        stop_address_d: lastNoWalkLeg ? lastNoWalkLeg?.to?.name : null, // String
        stop_address_d_lat: lastNoWalkLeg ? lastNoWalkLeg?.to?.lat : null, // Double
        stop_address_d_lng: lastNoWalkLeg ? lastNoWalkLeg?.to?.lon : null, // Double
        route_id_a: firstNoWalkLeg?.routeId, // String
        route_id_b: lastNoWalkLeg ? lastNoWalkLeg?.routeId : null, // String
        line_a: firstNoWalkLeg?.routeShortName, // String
        line_b: lastNoWalkLeg ? lastNoWalkLeg?.routeShortName : null, // String
        combination: trip?.transfers > 0, // Boolean
        user_id: uid, // String
        os: 'web', // String
        search_uuid: uid + timestamp, // String
        lat: userPosition ? userPosition?.lat : null, // Float
        lng: userPosition ? userPosition?.lng : null, // Float
        fare: trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits, // Float
        user_birthday_timestamp: userData ? userData?.birthday?.long_value : null, // Long
        stop_codes: currentStops, // String
        user_gender: userData ? userData?.gender : null // String
      }

      logEvent('check_route_path', eventParams)

      const startTime = moment(firstNoWalkLeg?.startTime)
      const nowTime = moment()

      const arrivalWaitTime = startTime?.diff(nowTime, 'ms')
      const realtimeArrivalWaitTime = trip?.arrivalRealtime ? moment.duration(trip?.arrivalRealtime, 'minutes').asMilliseconds() : null

      const tripEventParams = {
        user_id: uid, // String
        os: 'web', // String
        travel_duration_a: firstNoWalkLeg?.distance, // Long
        travel_duration_b: lastNoWalkLeg ? lastNoWalkLeg?.distance : 0, // Long
        walk_distance_1: firstLegWalk?.distance, // Long
        walk_duration_1: firstLegWalk?.duration, // Long
        walk_distance_2: secondLegWalk?.distance, // Long
        walk_duration_2: secondLegWalk?.duration, // Long
        search_uuid: uid + timestamp, // String
        transportation_type_a: firstNoWalkLeg?.transportTypeId,
        transportation_type_b: lastNoWalkLeg ? lastNoWalkLeg?.transportTypeId : 0,
        arrival_wait_time: arrivalWaitTime, // Long
        lat: userPosition ? userPosition?.lat : null, // Double
        lng: userPosition ? userPosition?.lng : null, // Double
        walk_distance_3: thirdLegWalk ? thirdLegWalk?.distance : null, // Long
        walk_duration_3: thirdLegWalk ? thirdLegWalk?.duration : null, // Long
        stop_codes: firstNoWalkLeg?.from?.stopCode + ',' + firstNoWalkLeg?.to?.stopCode + ',' + lastNoWalkLeg?.from?.stopCode + ',' + lastNoWalkLeg?.to?.stopCode,
        realtime_arrival_wait_time: realtimeArrivalWaitTime, // Long
        travel_distance_a: firstNoWalkLeg?.distance, // Long
        travel_distance_b: lastNoWalkLeg?.distance, // Long
        city_id: cityConfig?.city_id?.toString(), // String
        user_birthday_timestamp: userData ? userData?.birthday?.long_value : null, // Long
        user_gender: userData ? userData?.gender : null // String
      }

      logEvent('trip_started', tripEventParams)
    }

    trip?.legs?.map((leg) => {
      if (typeof leg.points === 'string') {
        leg.points = polyUtil.decode(leg.points)
      }
      return leg
    })

    const currentTransportTypeId = trip?.legs?.find(leg => leg.mode !== 'WALK')?.transportTypeId
    const color = cityConfig?.transport_types?.find(transport => parseInt(transport?.transport_type_id) === parseInt(currentTransportTypeId))?.color

    dispatch(setTripSelected({
      ...trip,
      color,
      id: trip.legs.some(leg => leg.mode !== 'WALK' && leg.mode !== 'CAR' && leg.mode !== 'BICYCLE') ? trip.legs.filter(leg => leg.mode !== 'WALK').map(leg => leg?.tripId)?.toString() : trip.id,
      link_to: `${process.env.REACT_APP_HOST}/${window.location.href.split('/')[3]}`,
      is_alternative: trip.legs.some(leg => leg.mode === 'CAR' || leg.mode === 'BICYCLE')
    }))

    mode && navigate('/results-routes-and-trajectory')
  }

  return {
    handleTripClick
  }
}
