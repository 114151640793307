import styled from 'styled-components/macro'

export const Container = styled.div` 
    padding: 16px;
`

export const Item = styled.div` 
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
