import { animateScroll as scroll } from 'react-scroll'
import { setLineSearchResult, setLinesFav } from '../actions/user'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLines, setLineRoutes, setLinesByAgency, setLineSelected, setLoading } from '../actions/ui'
import { getRoutes } from '../db/getRoutes'
import { useNavigate } from 'react-router-dom'
import polyUtil from 'polyline-encoded'
import { logEvent, setUserProperties } from '../firebase/firebase-config'
import { getAuth } from 'firebase/auth'
import { getLocalFavLines } from '../db/getLocalFavLines'
import { setLocalFavLines } from './setLocalFavLines'
import { Timestamp } from '@firebase/firestore'
import { getUserLines } from '../helpers/getUserLines'
import { addUserLine } from '../helpers/addUserLine'
import { subscribeToTopic } from '../db/subscribeToTopic'
import { deleteUserLine } from '../helpers/deleteUserLine'
import { unsubscribeToTopic } from '../db/unsubscribeToTopic'
import { REACT_APP_VARIANT_ID } from '../constants/config'

export const useLines = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auth = getAuth()
  const user = auth.currentUser

  const userPosition = useSelector(state => state?.user?.userPosition)
  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const fcmToken = useSelector(state => state?.user?.fcmToken)
  const userData = useSelector(state => state?.user?.userData)
  const lines = useSelector(state => state?.ui?.lines)
  const linesFav = useSelector(state => state?.user?.lines?.linesFav)

  const transportationType = useSelector(state => state?.user?.lines?.transportationType)

  const handleAddLineFav = (line) => {
    scroll.scrollToTop({
      containerId: 'line-list'
    })

    const localFavLines = getLocalFavLines()

    localFavLines.push({
      city_id: cityConfig?.city_id,
      color: line?.route_color,
      line_id: line?.route_id,
      line_name: line?.route_short_name,
      timestamp: Timestamp?.now().toJSON()
    })

    dispatch(setLinesFav(localFavLines))
    setLocalFavLines(localFavLines)

    const currentLines = lines?.map(item => {
      if (line?.line_id === item?.route_id || line.route_id === item?.route_id) {
        item.fav = true
      }
      return item
    })

    dispatch(setCurrentLines(currentLines))
    dispatch(setLinesByAgency(transportationType, currentLines))

    getUserLines()
      .then((userLinesFav) => {
        if (!user.isAnonymous && !userLinesFav?.some(current => current?.line_id === line?.route_id)) {
          const lineData = {
            city_id: JSON.stringify(cityConfig.city_id),
            color: line?.route_color,
            line_id: line?.route_id,
            line_name: line?.route_short_name || line?.route_long_name,
            timestamp: Timestamp?.now().toJSON()
          }

          addUserLine(lineData)
            .catch(e => console.error(e))
        }
      })
      .catch(e => console.error(e))

    setUserProperties({
      has_fav_lines: localFavLines?.length > 0 ? 'true' : 'false',
      variant_id: REACT_APP_VARIANT_ID.toString()
    })

    logEvent('fav_line_added', {
      os: 'web',
      line_id: line?.route_id,
      line_name: line?.route_short_name || line?.route_long_name,
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id?.toString(),
      user_id: user?.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    })

    subscribeToTopic(`city_${cityConfig.city_id}_line_${line.route_id}`, fcmToken)
      .catch(e => console.error(e))

    subscribeToTopic(`web_city_${cityConfig.city_id}_line_${line.route_id}`, fcmToken)
      .catch(e => console.error(e))
  }

  const handleRemoveLineFav = (line) => {
    scroll.scrollToTop({
      containerId: 'line-list'
    })

    let localFavLines = getLocalFavLines()

    localFavLines = localFavLines?.filter(current => current?.line_id !== line?.route_id)

    dispatch(setLinesFav(localFavLines))
    setLocalFavLines(localFavLines)

    const currentLines = lines?.map(item => {
      if (line?.line_id === item?.route_id || line.route_id === item?.route_id) {
        item.fav = false
      }
      return item
    })

    dispatch(setCurrentLines(currentLines))
    dispatch(setLinesByAgency(transportationType, currentLines))

    getUserLines()
      .then((userLinesFav) => {
        if (!user.isAnonymous) {
          // current line within the list of the user's favorite lines stored in firestore
          const currentLine = userLinesFav?.find(current => current?.line_id === line?.route_id)

          deleteUserLine(currentLine?.id)
            .catch(e => console.error(e))

          logEvent('line_details', {
            os: 'web',
            line_id: line?.route_id,
            line_color: line?.route_color,
            line_name: line?.route_short_name || line?.route_long_name,
            lat: userPosition?.lat ? userPosition.lat : null,
            lng: userPosition?.lng ? userPosition.lng : null,
            user_id: user?.uid,
            city_id: cityConfig?.city_id.toString()
          })
        }
      })
      .catch(e => console.error(e))

    setUserProperties({
      has_fav_lines: localFavLines?.length >= 1,
      variant_id: REACT_APP_VARIANT_ID.toString()
    })

    unsubscribeToTopic(`city_${cityConfig.city_id}_line_${line.route_id}`, fcmToken)
      .catch(e => console.error(e))
    unsubscribeToTopic(`web_city_${cityConfig.city_id}_line_${line.route_id}`, fcmToken)
      .catch(e => console.error(e))

    logEvent('fav_line_removed', {
      os: 'web',
      line_id: line?.route_id,
      line_name: line?.route_short_name || line?.route_long_name,
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id?.toString(),
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      user_id: user?.uid
    })
  }

  const handleClickLine = async (line) => {
    dispatch(setLoading(true))

    try {
      logEvent('line_details', {
        os: 'web',
        line_id: line?.route_id,
        line_color: line?.route_color,
        line_name: line?.route_short_name || line?.route_long_name,
        lat: userPosition?.lat || null,
        lng: userPosition?.lng || null,
        user_id: user?.uid,
        city_id: cityConfig?.city_id.toString()
      })

      const params = {
        city_id: cityConfig.city_id,
        route_id: line.route_id
      }

      const response = await getRoutes(params)

      dispatch(setLineSelected({
        ...response,
        fav: linesFav ? linesFav?.some(line => line?.line_id === response?.route_id) : false
      }))

      dispatch(setLineRoutes(response?.trips.map(trip => ({ ...trip, points: polyUtil.decode(trip.shape) }))))
      dispatch(setLineSearchResult(''))
      navigate(`/routes/${cityConfig?.city_id}/${line?.route_id}/${response?.trips[0]?.shape_id}`)
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
    }
  }

  const handleClickArrival = async (arrival) => {
    try {
      const reqBody = {
        city_id: cityConfig.city_id,
        route_id: arrival.trip.route_id
      }

      const response = await getRoutes(reqBody)

      dispatch(setLineRoutes(response?.trips.map(trip => {
        return { ...trip, points: polyUtil.decode(trip.shape) }
      })))

      dispatch(setLineSelected({ ...response, fav: linesFav?.some(line => line?.line_id === response?.route_id) }))

      const eventParams = {
        os: 'web', // String
        line_id: arrival?.trip?.route_id, // String
        line_color: arrival?.trip?.route.route_color, // String
        line_name: arrival?.trip?.route.route_short_name, // String
        stop_lat: stopSelected?.stop_lat, // Double
        stop_lng: stopSelected?.stop_lon, // Double
        stop_color: arrival?.trip.route?.transport_type?.color, // String
        stop_id: stopSelected?.stop_id, // String
        arrival_freq: null, // String
        arrival_time: arrival?.arrival_time, // String
        trip_id: parseInt(arrival?.trip?.trip_id), // Int
        trip_name: arrival?.trip?.trip_short_name, // String
        lat: userPosition?.lat || null, // Double
        lng: userPosition?.lng || null, // Double
        city_id: cityConfig?.city_id.toString(), // String
        user_id: user?.uid, // String
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null // String
      }

      // Send events to analytics and mixpanel
      logEvent('stop_details_arrival_line_click', eventParams)

      navigate(`/routes/${cityConfig?.city_id}/${arrival.trip?.route_id}/${arrival?.trip?.shape_id}`)
    } catch (e) {
      console.error('Error to get line routes selected' + e)
    }
  }

  const handleSearch = ({ target: { value = '' } }) => {
    dispatch(setLineSearchResult(value))

    const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

    const currentLines = transportationType === 0 ? lines : lines?.filter(line => line.transport_type_id === transportationType)
    const results = currentLines?.filter(line => {
      const isShortNameMatch = line?.route_short_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(normalizedValue)
      const isLongNameMatch = line?.route_long_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(normalizedValue)
      const isDescMatch = line?.route_desc?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(normalizedValue)
      const isAgencyNameMatch = line?.agency?.agency_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(normalizedValue)

      return isShortNameMatch || isLongNameMatch || isDescMatch || isAgencyNameMatch
    })

    dispatch(setCurrentLines(results))
    dispatch(setLinesByAgency(transportationType, results))
  }

  return {
    handleAddLineFav,
    handleRemoveLineFav,
    handleClickLine,
    handleClickArrival,
    handleSearch
  }
}
