import React from 'react'
import { Button, DialogActions, DialogTitle, DialogContent, DialogContentText, Modal } from '@mui/material'

export const ConfirmDialog = ({ icon, title, content, buttons, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          top: '50%',
          left: '50%',
          maxWidth: '400px',
          transform: 'translate(-50%, -50%)',
          gap: '10px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderRadius: '15px',
          backgroundColor: '#ffffff',
          padding: '25px 20px 10px 20px',
          border: 'none',
          boxSizing: 'border-box',
          width: '90%'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            position: 'absolute',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            top: 0,
            right: '50%',
            transform: 'translate(50%,-50%)'
          }}
        >
          {icon}
        </div>
        <DialogTitle
          sx={{
            padding: 0,
            margin: 0,
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600'
          }}
        >
          {title}
        </DialogTitle>
        {content && (
          <DialogContent
            sx={{
              padding: 0,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <DialogContentText
              sx={{
                padding: 0,
                margin: 0,
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: '400'
              }}
            >
              {content}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            padding: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: `${buttons?.length > 1 ? 'space-between' : 'center'}`
          }}
        >
          {buttons?.map(({ label, onClick, sx }, index) => {
            return (
              <Button key={index} onClick={onClick} sx={sx}>
                {label}
              </Button>
            )
          })}
        </DialogActions>
      </div>
    </Modal>
  )
}
