import React, { createContext, useContext } from 'react'
import { REACT_APP_FONT_FAMILY } from '../constants/config'

const FontContext = createContext('Roboto') // Default font

export const useFont = () => useContext(FontContext)

export const FontProvider = ({ children }) => {
  const fontFamily = REACT_APP_FONT_FAMILY || 'Roboto' // Default to Roboto if not set

  return (
    <FontContext.Provider value={fontFamily}>
      {children}
    </FontContext.Provider>
  )
}
