import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ArrowRightRounded, DirectionsWalkRounded, ErrorRounded } from '@mui/icons-material'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import markerAIcon from '../../img/markerAIcon.png'
import markerBIcon from '../../img/markerBIcon.png'
import occupancyFew from '../../img/occupancyFew.svg'
import occupancyVeryFew from '../../img/occupancyVeryFew.svg'
import occupancyMany from '../../img/occupancyMany.svg'
import occupancyFull from '../../img/occupancyFull.svg'
import RealTimeIcon from '../../img/real-time-icon.svg'
import {
  FromToIcon,
  HeadSign,
  InfoData,
  InfoGroup,
  InfoItem,
  LineContainer,
  LineIcon,
  LineName,
  Number,
  OcupancyContainer,
  OcupancyIcon,
  RealTimeContainer,
  RealTimeIconImg,
  Schedule,
  ScheduleDate,
  Span,
  TripDetails,
  TripSteps,
  WalkStep,
  WalkValue,
  WarningLabel,
  WarningText
} from './styles'
import { REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES, REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION } from '../../constants/config'
import { theme } from '../../theme/theme'

const ResultItem = ({ trip, onClickFunction, border, showOccupancyLevel, realTime, schedule }) => {
  const { t } = useTranslation()
  const mode = useSelector(state => state?.ui.mode)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [occupancy, setOccupancy] = useState({})

  const legNoWalk = trip?.legs?.find(item => item.mode !== 'WALK')

  useEffect(() => {
    const result = crowdedAverage()
    setOccupancy(result)
  }, [])

  const crowdedAverage = () => {
    const value = trip?.legs?.filter(item => item?.mode !== 'WALK')[0]?.crowdedAverage

    switch (value) {
      case 0: {
        return { label: t('trip_share.occupancy_item_title_1'), icon: occupancyVeryFew }
      }
      case 1: {
        return { label: t('trip_share.occupancy_item_title_2'), icon: occupancyFew }
      }
      case 2: {
        return { label: t('trip_share.occupancy_item_title_3'), icon: occupancyMany }
      }
      case 3: {
        return { label: t('trip_share.occupancy_item_title_4'), icon: occupancyFull }
      }
      default: {
        return {}
      }
    }
  }

  return (
    <Button
      border={border}
      onClick={
        onClickFunction
          ? () => onClickFunction({
              ...trip,
              arrivalRealtime: realTime?.minutes
            })
          : () => {
            }
      }
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: border ? '1px solid #E8EDEE' : 'none',
        padding: '16px 6px 16px 23px',
        color: '#000',
        '&:hover': {
          backgroundColor: '#F6F8F9'
        },
        borderRadius: '8px',
        '@media (max-width:480px)': {
          padding: '16px'
        },
        '&:focus': {
          border: `${!mode ? `1px solid ${theme.palette.primary.main}` : 'none'}`
        }
      }}
    >
      <TripDetails>
        <InfoGroup>
          {REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES && (trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits).toFixed(2) > 0 &&
            <InfoItem>
              {trip
                ? (
                  <>
                    <Typography sx={{
                      margin: '0 0 4px 0',
                      color: '#000',
                      fontSize: '14px',
                      fontWeight: '400',
                      '@media (max-width:480px)': {
                        fontSize: '10px',
                        fontWeight: 'bold'
                      }
                    }}
                    >
                      {t('trip_share.search_result_fare_title')}
                    </Typography>
                    <InfoData>{cityConfig?.country?.coinSymbol} {(trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits).toFixed(2)}</InfoData>
                  </>
                  )
                : (
                  <>
                    <Skeleton width='40px' height='25px' variant='text' />
                    <Skeleton width='40px' height='36px' variant='text' />
                  </>
                  )}

            </InfoItem>}
          <InfoItem>
            {trip
              ? (
                <>
                  <Typography
                    sx={{
                      margin: '0 0 4px 0',
                      color: '#000',
                      fontSize: '14px',
                      fontWeight: '400',
                      '@media (max-width:480px)': {
                        fontSize: '10px',
                        fontWeight: 'bold'
                      }
                    }}
                  >{t('trip_share.search_result_arrival_title')}</Typography>
                  <InfoData>{moment(trip?.endTime).format('HH:mm')}</InfoData>
                </>
                )
              : (
                <>
                  <Skeleton width='40px' height='25px' variant='text' />
                  <Skeleton width='40px' height='36px' variant='text' />
                </>
                )}

          </InfoItem>
          <InfoItem
            style={{ position: 'relative' }}
          >
            {showOccupancyLevel && occupancy?.icon && occupancy?.label &&
              <>
                <Typography
                  sx={{
                    margin: '0 0 4px 0',
                    color: '#000',
                    fontSize: '14px',
                    fontWeight: '400',
                    '@media (max-width:480px)': {
                      fontSize: '10px',
                      fontWeight: 'bold'
                    }
                  }}
                >
                  {t('trip_share.passengers_on_board_title')}
                </Typography>
                <OcupancyContainer>
                  <OcupancyIcon src={occupancy.icon} />
                  <InfoData $mode={mode}>{occupancy.label}</InfoData>
                </OcupancyContainer>
              </>}
          </InfoItem>
          {REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION &&
            <InfoItem>
              <ScheduleOrRealTimeMini schedule={schedule} realTime={realTime} />
            </InfoItem>}

        </InfoGroup>
        {!REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION && <ScheduleOrRealTime schedule={schedule} realTime={realTime} />}
        {REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION && trip?.duration && <TripDuration duration={trip?.duration} />}

      </TripDetails>

      <TripSteps $mode={mode}>

        {trip?.legs?.length > 0
          ? (
            <>
              <FromToIcon src={markerAIcon} />
              <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
              {trip?.legs?.map((item, index) => {
                if (item.mode === 'WALK') {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      key={index}
                    >
                      <WalkStep>
                        <DirectionsWalkRounded sx={{ width: '20px', color: '#BCBEC0' }} />
                        <WalkValue>{parseInt(item.distance / 100)}</WalkValue>
                      </WalkStep>
                      <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
                    </div>
                  )
                } else {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      key={index}
                    >
                      <LineContainer color={item?.routeColor}>
                        <LineIcon
                          src={cityConfig?.transport_types?.filter(transport => transport.transport_type_id === item?.transportTypeId)[0]?.icon}
                        />
                        <LineName>{item?.routeShortName || item?.routeLongName}</LineName>
                      </LineContainer>
                      <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
                    </Box>
                  )
                }
              })}
              <FromToIcon src={markerBIcon} />
            </>
            )
          : (
            <Skeleton width='250px' height='30px' variant='rectangular' />
            )}
      </TripSteps>

      {legNoWalk
        ? <HeadSign $mode={mode}>{legNoWalk?.headsign}</HeadSign>
        : <Skeleton width='80%' height='28px' variant='text' />}

      {legNoWalk?.from?.affected &&
        <WarningLabel>
          <ErrorRounded sx={{ width: '18px', color: '#FF4B55', marginRight: '8px' }} />
          <WarningText>Hay reportes en esta línea</WarningText>
        </WarningLabel>}
    </Button>
  )
}

export default ResultItem

const ScheduleOrRealTime = ({ schedule, realTime }) => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { t } = useTranslation()
  const mode = useSelector(state => state?.ui.mode)

  return (
    realTime
      ? (
        <RealTimeContainer>
          <RealTimeIconImg top={cityConfig?.city_id ? '20px' : '5px'} src={RealTimeIcon} />
          <Number
            fontSize={cityConfig?.city_id === 22 ? '14px' : '24px'}
          >
            {realTime.minutes}
          </Number>
          {cityConfig?.city_id !== 22 && <Span>min</Span>}
        </RealTimeContainer>
        )
      : (
        <Schedule>
          {schedule
            ? (
              <>
                <Typography sx={{
                  margin: '0 0 4px 0',
                  color: '#000',
                  fontSize: '14px',
                  fontWeight: '400',
                  '@media (max-width:480px)': {
                    fontSize: '10px',
                    fontWeight: 'bold'
                  }
                }}
                >
                  {t('stop.scheduled')}
                </Typography>
                <ScheduleDate $mode={mode}>{schedule}</ScheduleDate>
              </>
              )
            : (
              <>
                <Skeleton width='20px' height='25px' variant='text' />
                <Skeleton width='50px' height='36px' variant='text' />
              </>
              )}

        </Schedule>
        )
  )
}

const TripDuration = ({ duration }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography sx={{
        margin: '0 0 4px 0',
        color: '#000',
        fontSize: '14px',
        fontWeight: '400',
        '@media (max-width:480px)': {
          fontSize: '10px',
          fontWeight: 'bold'
        }
      }}
      >
        {t('trip_share.trip_duration_title')}
      </Typography>
      <Typography>{(duration / 60).toFixed(0)} min.</Typography>
    </Box>
  )
}

const ScheduleOrRealTimeMini = ({ schedule, realTime }) => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { t } = useTranslation()

  return (
    realTime
      ? (
        <RealTimeContainer>
          <RealTimeIconImg top={cityConfig?.city_id ? '20px' : '5px'} src={RealTimeIcon} />
          <Number
            fontSize='14px'
          >
            {realTime.minutes}
          </Number>
          {cityConfig?.city_id !== 22 && <Span>min</Span>}
        </RealTimeContainer>
        )
      : (
        <Schedule>
          {schedule
            ? (
              <>
                <Typography sx={{
                  margin: '0 0 4px 0',
                  color: '#000',
                  fontSize: '14px',
                  fontWeight: '400',
                  '@media (max-width:480px)': {
                    fontSize: '10px',
                    fontWeight: 'bold'
                  }
                }}
                >
                  {t('stop.scheduled')}
                </Typography>
                <InfoData>{schedule}</InfoData>
              </>
              )
            : (
              <>
                <Skeleton width='20px' height='25px' variant='text' />
                <Skeleton width='50px' height='36px' variant='text' />
              </>
              )}

        </Schedule>
        )
  )
}
