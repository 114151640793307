import React from 'react'
import { CardInfo } from './styles'
import { Skeleton } from '@mui/material'

export const CityInfoSkeleton = () => {
  return (
    <div style={{
      width: '100%'
    }}
    >
      <CardInfo>
        <Skeleton width={47} height={16} />
        <Skeleton width='100%' height={12} />
      </CardInfo>

      <CardInfo>
        <Skeleton width={61} height={16} />
        <Skeleton width='100%' height={12} />
        <Skeleton width='80%' height={12} />
      </CardInfo>

      <CardInfo>
        <Skeleton width={170} height={16} />
        <Skeleton width='100%' height={12} />
      </CardInfo>

      <CardInfo>
        <Skeleton width={146} height={16} />
        <Skeleton width='100%' height={12} />
        <Skeleton width='50%' height={12} />
      </CardInfo>

      <CardInfo>
        <Skeleton width={30} height={16} />
        <Skeleton width='100%' height={12} />
        <Skeleton width='85%' height={12} />
      </CardInfo>
    </div>
  )
}
