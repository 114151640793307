import styled from 'styled-components/macro'

export const TopBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  height: 53px;
  background-color: ${props => props.bgColor ? props.bgColor : '#ffffff'};
  z-index: 1;
`

export const BackAndTitleContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 10px;
`

export const Title = styled.h1`
font-size: 16px;
margin: 0;
color: ${props => props.color ? props.color : '#000000'};
`
