import maplibregl from 'maplibre-gl'
import { REACT_APP_PLACES_HOST, REACT_APP_VARIANT_ID } from '../constants/config'

export const getPlacesByCity = (cityConfig, term, bounds) => {
  return fetch(`${cityConfig?.country?.country_id === 4 ? 'https://photon.komoot.io' : REACT_APP_PLACES_HOST}/api/?lat=${cityConfig?.map.map_center_lat}&lon=${cityConfig?.map.map_center_lng}&lang=${cityConfig?.country?.country_id === 4 ? 'en' : 'es'}&limit=5&q=${term + ', ' + cityConfig?.name}&bbox=${cityConfig?.map.map_southwest_lng},${cityConfig?.map.map_southwest_lat},${cityConfig?.map.map_northeast_lng},${cityConfig?.map.map_northeast_lat}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'variant-id': REACT_APP_VARIANT_ID
    }
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    }).then(response => {
      // verifies that the lat and long of the place are not out of bounds
      return (
        response?.features?.filter(item => {
          if (bounds.contains(new maplibregl.LngLat(item.geometry.coordinates[0], item.geometry.coordinates[1]))) {
            return item
          }
        })
      )
    })
    .catch((err) => console.error(err))
}
