import styled from 'styled-components/macro'

export const LinesContainer = styled.div` 
  box-sizing: border-box;
  height: calc(100% - 175px);
  width: 100%;
`

export const Content = styled.div` 
    height: 100%;
`

export const LinesList = styled.div`  
    height: 100%;
    padding: 0 16px;
`

export const LinesContent = styled.div`
    height: ${window.innerHeight}px;
    width: 100%;
`

export const SearchContainer = styled.div`
  display: flex;
  position: sticky;
  flex-direction: column;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 0 16px 0px 16px; 
  box-sizing: border-box;
  z-index: 1;
`

export const LineNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`
