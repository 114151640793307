import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Box, Collapse, Divider, Fab, List, ListItem, ListItemButton, ListItemText, MobileStepper, Slide, Typography, Zoom, useMediaQuery } from '@mui/material'
import {
  AlternativeInfoContainer,
  IconContainer,
  IconImg,
  InfoData,
  InfoGroup,
  InfoItem,
  InfoTitle,
  MapArea,
  TransportationName,
  TripDetails
} from './styles'
import ResultItem from '../../uicomponents/ResultItem'
import { NavigationButton } from '../../uicomponents/tripResults/NavigationButton'
import NavBar from '../NavBar'
import FeedbackButton from '../../uicomponents/FeedbackButton'
import { ArrowBackRounded, ExpandLess, ExpandMore } from '@mui/icons-material'
import { setTripDetails } from '../../actions/ui'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import { getTripDetails } from '../../db/getTripDetails'
import { theme } from '../../theme/theme'
import { Map } from '../SearchResults'
import maplibregl from 'maplibre-gl'
import { useMap } from 'react-map-gl'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SwipeableViews from 'react-swipeable-views'
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import { Security } from '../../uicomponents/desktop/stop/Security'
import { loadCurrentStopVows } from '../../actions/map'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import RealTimeIcon from '../../img/real-time-icon.svg'
import stop1 from '../../img/stop1.svg'
import stop2 from '../../img/stop2.svg'
import stop3 from '../../img/stop3.svg'
import stop4 from '../../img/stop4.svg'
import stop5 from '../../img/stop5.svg'
import stop6 from '../../img/stop6.svg'
import stop7 from '../../img/stop7.svg'
import stop8 from '../../img/stop8.svg'
import { ConfirmDialog } from '../../uicomponents/ConfirmDialog'
import BackHandRoundedIcon from '@mui/icons-material/BackHandRounded'
import { REACT_APP_IS_IFRAME, REACT_APP_SHOW_STOP_SECURITY_SURVEY, REACT_APP_SHOW_WALK_CARD_ROUTE_TYPE_ICON } from '../../constants/config'
import 'react-spring-bottom-sheet/dist/style.css'
import markerAIcon from '../../img/markerAIcon.png'
import markerBIcon from '../../img/markerBIcon.png'
import Sheet from 'react-modal-sheet'
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft'
import UTurnRightIcon from '@mui/icons-material/UTurnRight'
import ElevatorIcon from '@mui/icons-material/Elevator'
import TurnSlightLeftRoundedIcon from '@mui/icons-material/TurnSlightLeftRounded'
import TurnSlightRightRoundedIcon from '@mui/icons-material/TurnSlightRightRounded'
import TurnLeftRoundedIcon from '@mui/icons-material/TurnLeftRounded'
import TurnRightRoundedIcon from '@mui/icons-material/TurnRightRounded'
import StraightRoundedIcon from '@mui/icons-material/StraightRounded'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'

export const ResultsRoutesAndTrajectory = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { map } = useMap()

  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const { zoom, center } = useSelector(state => state?.map)
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const [pathLegs, setPathLegs] = useState([])
  const [feedback, setFeedback] = useState()
  const [transportType, setTransportType] = useState(null)
  const [isAlternativeItinerary, setIsAlternativeItinerary] = useState(false)
  const [firstLegNoWalk, setFirstLegNoWalk] = useState(null)
  const [travelMode, setTravelMode] = useState(false)
  const [travelModeCurrentStep, setTravelModeCurrentStep] = useState(0)
  const [travelModeCards, setTravelModeCards] = useState([])
  const [travelModeCloseDialog, setTravelModeCloseDialog] = useState(false)

  const mobile = useMediaQuery('(max-width:480px)')

  useEffect(() => {
    if (tripSelected) {
      const firstLegNoWalk = tripSelected?.legs?.find(leg => leg.mode !== 'WALK')
      const currentMode = firstLegNoWalk.mode

      if (firstLegNoWalk) {
        setFirstLegNoWalk(firstLegNoWalk)
        setIsAlternativeItinerary(!!((currentMode === 'BICYCLE' || currentMode === 'CAR' || currentMode === 'MOTORCYCLE')))
      }

      const itinerary = {
        duration: tripSelected.duration,
        start_time: tripSelected.startTime,
        end_time: tripSelected.endTime,
        walk_time: tripSelected.walkTime,
        transit_time: tripSelected.transitTime,
        waiting_time: tripSelected.waitingTime,
        walk_distance: tripSelected.walkDistance,
        transfers: tripSelected.transfers,
        fare_cents: tripSelected?.fare?.cents || null,
        fare_currency: tripSelected?.fare?.currency || null,
        fare_fraction_digits: tripSelected?.fare?.default_fraction_digits || null,
        legs: tripSelected.legs.map(leg => ({
          route_id: leg.routeId || null,
          route_type: leg.routeType || null,
          agency_id: leg.agencyId || null,
          shape_id: leg.shapeId || null,
          trip_id: leg.tripId || null,
          to_stop_id: leg.to.stopId || null,
          start_time: leg.startTime,
          end_time: leg.endTime,
          departure_delay: leg.departureDelay,
          arrival_delay: leg.arrivalDelay,
          realtime: leg.realTime,
          realtime_arrival: leg?.realTimeArrival || null,
          distance: leg.distance,
          mode: leg.mode,
          from_name: leg.from.name,
          from_stop_id: leg.from.stopId || null,
          from_lon: leg.from.lon,
          from_lat: leg.from.lat,
          from_arrival: leg.from.arrival || null,
          from_departure: leg.from.departure,
          from_vertex_type: leg.from.vertexType,
          to_name: leg.to.name,
          to_lon: leg.to.lon,
          to_lat: leg.to.lat,
          to_arrival: leg.to.arrival,
          to_vertex_type: leg.to.vertexType,
          duration: leg.duration || null,
          points: leg.legGeometry?.points
        }))
      }

      const legsNoWalk = tripSelected?.legs?.filter(leg => leg?.mode !== 'WALK')
      const legsNoWalkShapes = legsNoWalk?.map(leg => leg?.shapeId)

      if (!tripSelected.legs.some(leg => leg.mode === 'BICYCLE' || leg.mode === 'CAR' || leg.mode === 'MOTORCYCLE')) {
        setFeedback({
          city_id: cityConfig?.city_id,
          question_id: 1,
          itinerary
        })
        getTripDetails(cityConfig?.city_id, legsNoWalkShapes)
          .then((response) => {
            dispatch(setTripDetails(response?.result))
          })
          .catch(e => console.error(e))
      }
    } else {
      navigate(-1)
    }
  }, [])

  // When start application
  useEffect(() => {
    if (!tripSelected || !map) return

    const pointsCount = []

    pointsCount.push(new maplibregl.LngLat(from?.latLng?.lng, from?.latLng?.lat))

    tripSelected.legs.filter(leg => leg.mode !== 'WALK').forEach(leg => leg.points.forEach(point => {
      pointsCount.push(new maplibregl.LngLat(point[1], point[0]))
    }))

    pointsCount.push(new maplibregl.LngLat(to?.latLng?.lng, to?.latLng?.lat))

    const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

    map?.fitBounds(bounds, {
      animate: false,
      padding: {
        top: 32,
        left: 32,
        right: 32,
        bottom: 150
      }
    })

    const cards = []
    tripSelected.legs.forEach((leg) => {
      if (leg.mode !== 'WALK') {
        cards.push({
          name: 'waiting leg',
          mode: 'WAITING',
          transportTypeIcon: cityConfig?.transport_types?.find(transport => parseInt(transport?.route_type) === parseInt(leg?.routeType))?.icon,
          routeShortName: leg?.routeShortName || leg?.routeLongName,
          headsign: leg?.headsign,
          startTime: leg?.startTime,
          realTime: leg?.realTime,
          realTimeArrival: leg?.realtimeArrival,
          stopId: leg?.from?.stopId,
          from: {
            lat: leg?.from?.lat,
            lon: leg?.from?.lon
          },
          to: {
            lat: leg?.from?.lat,
            lon: leg?.from?.lon
          }
        })
        cards.push(leg)
      } else {
        const transportTypeIcon = leg.to.stopId ? cityConfig?.transport_types?.find(transport => parseInt(transport?.route_type) === parseInt(tripSelected.legs.find(item => item.from.stopId === leg.to.stopId).routeType))?.icon : null

        const obj = {
          transportTypeIcon,
          ...leg
        }

        cards.push(obj)
      }
    })
    setTravelModeCards(cards)
  }, [tripSelected, map])

  const handleBackClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!tripSelected) return
    setTransportType(
      cityConfig?.transport_types?.find(transport => parseInt(transport?.transport_type_id) === parseInt(tripSelected?.legs.find(leg => leg.mode !== 'WALK')?.transportTypeId))
    )
  }, [tripSelected])

  useEffect(() => {
    if (tripDetails) {
      // legs NOT walking are filtered and the stops are obtained
      const pathLegs = tripSelected?.legs
        ?.filter(leg => leg.mode !== 'WALK')
        ?.map(leg => {
          // the detail of the trip that coincides with the shape id of the current section is obtained
          const detail = tripDetails?.find(detail => detail.shape_id === leg.shapeId) || { stops: [] }
          //  Get the stops from the from and to
          const routeStops = [...detail?.stops?.slice(leg.from.stopIndex, leg.to.stopIndex)]
          const stops = leg.mode !== 'WALK' && leg.mode !== 'CAR' ? detail?.stops || [] : detail?.stops?.length >= 1 ? detail?.stops.slice(leg.from.stopIndex, leg.to.stopIndex) : []
          leg.stops = stops.map(stop => {
            return {
              ...stop,
              isOnRoute: routeStops.some(routeStop => routeStop.stop_lat === stop.stop_lat && routeStop.stop_lon === stop.stop_lon)
            }
          })
          return leg
        })

      setPathLegs(pathLegs)
    }
  }, [tripDetails])

  if (!center || !zoom) {
    return <SkeletonApp />
  }

  const handleFitBoundsCard = (index) => {
    const currentCard = travelModeCards[index]

    const pointsCount = []

    pointsCount.push(new maplibregl.LngLat(currentCard?.from?.lon, currentCard?.from?.lat))

    pointsCount.push(new maplibregl.LngLat(currentCard?.to?.lon, currentCard?.to?.lat))

    const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

    map?.fitBounds(bounds, {
      animate: false,
      padding: {
        top: 32,
        left: 32,
        right: 32,
        bottom: 150
      }
    })
  }

  const handleChangeCard = (index) => {
    setTravelModeCurrentStep(index)
    handleFitBoundsCard(index)
    const currentCard = travelModeCards[index]
    if (currentCard?.stopId) {
      dispatch(loadCurrentStopVows({ cityConfig, stopIdParam: currentCard?.stopId }))
    }
  }

  const handleStartTravelMode = () => {
    setTravelMode(true)
    setTravelModeCurrentStep(0)
    // use fitBounds to center the map on the first travel card and use the animation
    const pointsCount = []

    pointsCount.push(new maplibregl.LngLat(travelModeCards[0]?.from?.lon, travelModeCards[0]?.from?.lat))

    pointsCount.push(new maplibregl.LngLat(travelModeCards[0]?.to?.lon, travelModeCards[0]?.to?.lat))

    const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

    map?.fitBounds(bounds, {
      animate: false,
      padding: {
        top: 32,
        left: 32,
        right: 32,
        bottom: 150
      }
    })
  }

  const handleExitTravelMode = () => {
    setTravelMode(false)
    setTravelModeCurrentStep(0)
    setTravelModeCloseDialog(false)

    const pointsCount = []

    pointsCount.push(new maplibregl.LngLat(from?.latLng?.lng, from?.latLng?.lat))

    tripSelected.legs.filter(leg => leg.mode !== 'WALK').forEach(leg => leg.points.forEach(point => {
      pointsCount.push(new maplibregl.LngLat(point[1], point[0]))
    }))

    pointsCount.push(new maplibregl.LngLat(to?.latLng?.lng, to?.latLng?.lat))

    const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

    map?.fitBounds(bounds, {
      animate: false,
      padding: {
        top: 32,
        left: 32,
        right: 32,
        bottom: 150
      }
    })
  }

  const bottomSheetRef = useRef()
  const snapPoints = isAlternativeItinerary ? [0.7, 0.15] : [0.7, 0.3]
  const initialSnap = 1 // Initial snap point when sheet is opened
  // eslint-disable-next-line no-unused-vars
  const [snapPoint, setSnapPoint] = useState(initialSnap)

  const snapTo = (i) => bottomSheetRef.current?.snapTo(i)

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          height: isAlternativeItinerary ? 'calc(100% - 10%)' : 'calc(100% - 194px)',
          width: '100%'
        }}
      >
        <Zoom
          in={!travelMode}
          unmountOnExit
          style={{
            transitionDelay: !travelMode ? '200ms' : '0ms',
            position: 'absolute',
            top: '16px',
            left: '16px',
            zIndex: 500,
            gap: '10px'
          }}
        >
          <Fab
            size='medium'
            onClick={handleBackClick}
            sx={{
              boxShadow: '0 3px 6px #2228311E',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#fff'
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ArrowBackRounded variant='secondary.main' />
          </Fab>
        </Zoom>
        <Zoom
          in={travelMode}
          unmountOnExit
          style={{
            transitionDelay: travelMode ? '200ms' : '0ms',
            position: 'absolute',
            top: '16px',
            right: '16px',
            zIndex: 500,
            gap: '10px'
          }}
        >
          <Fab
            size='medium'
            onClick={() => setTravelModeCloseDialog(true)}
            sx={{
              boxShadow: '0 3px 6px #2228311E',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#fff'
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}

          >
            <CloseRoundedIcon variant='secondary.main' />
          </Fab>
        </Zoom>
        <MapArea>

          <Map
            pathLegs={pathLegs}
            showDraggableDestinationMarkers={false}
            geoControlProps={{
              styles: {
                bottom: isAlternativeItinerary ? '217px' : '318px'
              }
            }}
            attributionPosition={travelMode ? 'top-left' : 'top-right'}
            showFromArrivalTime
          />

        </MapArea>

      </Box>
      <Sheet
        ref={bottomSheetRef}
        isOpen={!travelMode}
        onClose={() => snapTo(1)}
        onSnap={setSnapPoint}
        snapPoints={snapPoints}
        initialSnap={initialSnap}
        style={{
          zIndex: 2
        }}
      >
        <Sheet.Container>
          <span
            style={{
              width: '30px',
              height: '4px',
              borderRadius: '2px',
              backgroundColor: '#E0E0E0',
              position: 'absolute',
              top: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1
            }}
          />
          <Sheet.Content
            style={{ paddingBottom: bottomSheetRef.current?.y }}
          >

            {transportType &&
              <div
                style={{
                  position: 'absolute',
                  top: '-68px',
                  right: '0'
                }}
              >
                <NavigationButton
                  tripSelected={tripSelected}
                  transportType={transportType}
                  to={tripSelected?.link_to}
                  travelModeFunction={handleStartTravelMode}
                  travelMode={travelMode}
                />
              </div>}

            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#fff',
                width: '100%',
                borderRadius: '16px 16px 0px 0px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px 0',
                overflow: 'hidden',
                boxSizing: 'border-box'
              }}
            >
              <Sheet.Header>
                {isAlternativeItinerary
                  ? (
                    <AlternativeInfoContainer>
                      <IconContainer
                        bgcolor={transportType?.color}
                      >
                        <IconImg
                          filter='true'
                          src={transportType?.flat_icon}
                        />
                      </IconContainer>

                      <TripDetails>
                        <TransportationName>{transportType?.name}</TransportationName>
                        <InfoGroup>
                          {firstLegNoWalk?.mode === 'CAR' && tripSelected?.fare && Number((tripSelected?.fare?.cents / 10 ** tripSelected?.fare?.default_fraction_digits).toFixed(2)) > 0 &&
                            <InfoItem>
                              <InfoTitle>{t('trip_share.search_result_fare_title')}</InfoTitle>
                              <InfoData>${Number((tripSelected?.fare?.cents / 10 ** tripSelected?.fare?.default_fraction_digits).toFixed(2))}</InfoData>
                            </InfoItem>}

                          {firstLegNoWalk?.mode === 'BICYCLE' && tripSelected?.fromBikeStation &&
                            <InfoItem>
                              <InfoTitle>Available</InfoTitle>
                              <InfoData>{Number(tripSelected?.fromBikeStation?.free_bikes)}</InfoData>
                            </InfoItem>}
                          <InfoItem>
                            <InfoTitle>{t('trip_share.car_result_distance_title')}</InfoTitle>
                            <InfoData>{Number(firstLegNoWalk?.distance / 1000).toFixed(2)} km</InfoData>
                          </InfoItem>
                          <InfoItem>
                            <InfoTitle>{t('trip_share.search_result_arrival_title')}</InfoTitle>
                            <InfoData>{moment(tripSelected?.endTime).format('HH:mm')}</InfoData>
                          </InfoItem>
                        </InfoGroup>
                      </TripDetails>
                    </AlternativeInfoContainer>
                    )
                  : (
                    <ResultItem
                      style={{ padding: '25px' }}
                      trip={tripSelected}
                      border={false}
                      icons
                      realTime={tripSelected.realTime}
                      schedule={tripSelected.schedule}
                    />
                    )}
              </Sheet.Header>

              <Divider
                variant='middle'
                flexItem
              />
              <Sheet.Scroller
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '10px',
                  padding: '10px 0'
                }}
                draggableAt='top'
              >

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    padding: '0 16px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      boxSizing: 'border-box'
                    }}
                  >
                    <img
                      src={markerAIcon}
                      style={{
                        width: '36px'
                      }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      {from?.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    {moment(tripSelected?.startTime).format('HH:mm')}
                  </Typography>
                </Box>
                <StepsDivider />
                {tripSelected?.legs?.map((leg, index) => {
                  if (leg.mode === 'WALK') {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px'
                        }}
                        key={index}
                      >
                        <WalkStep leg={leg} />
                        {index !== tripSelected?.legs?.length - 1 && <StepsDivider />}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px'
                        }}
                        key={index}
                      >
                        <TransportStep key={index} leg={leg} isAlternativeItinerary={isAlternativeItinerary} />
                        {index !== tripSelected?.legs?.length - 1 && <StepsDivider />}
                      </div>
                    )
                  }
                }
                )}
                <StepsDivider />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    padding: '0 16px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      boxSizing: 'border-box'
                    }}
                  >
                    <img
                      src={markerBIcon}
                      style={{
                        width: '36px'
                      }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      {to?.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    {moment(tripSelected?.endTime).format('HH:mm')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  {feedback && mobile &&
                    <FeedbackButton
                      feedbackObj={feedback}
                      iconColor={theme.palette.primary.main}
                      color={theme.palette.primary.main}
                      size='medium'
                      collapsedMode={false}
                    />}
                </Box>
              </Sheet.Scroller>
            </Box>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
      <Slide
        direction='up'
        in={travelMode}
        style={{
          transitionDelay: travelMode ? '200ms' : '0ms'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 10,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '16px',
              padding: '8px 16px',
              boxShadow: '0 3px 6px #2228311E',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              zIndex: 2
            }}
          >
            <MobileStepper
              steps={travelModeCards.length}
              position='static'
              activeStep={travelModeCurrentStep}
            />
            <SwipeableViews
              index={0}
              enableMouseEvents
              style={{
                width: '100%'
              }}
              animateHeight={travelModeCards[travelModeCurrentStep]?.mode === 'WAITING' || travelModeCards[travelModeCurrentStep]?.mode === 'WALK'}
              onChangeIndex={(index) => handleChangeCard(index)}
            >
              {travelModeCards?.map((leg, index) => {
                if (leg.mode === 'WALK') {
                  return (
                    <WalkStepCard key={index} leg={leg} setTravelModeCurrentStep={setTravelModeCurrentStep} />
                  )
                } else if (leg.mode === 'WAITING') {
                  return (
                    <WaitingCard key={index} leg={leg} setTravelModeCurrentStep={setTravelModeCurrentStep} />
                  )
                } else {
                  return (
                    <TransportCard key={index} leg={leg} setTravelModeCurrentStep={setTravelModeCurrentStep} travelModeCurrentStep={travelModeCurrentStep} travelModeCards={travelModeCards} />
                  )
                }
              }
              )}
            </SwipeableViews>
          </Box>
        </Box>
      </Slide>
      {isIosApp && !REACT_APP_IS_IFRAME &&
        <NavBar />}
      <ConfirmDialog
        open={travelModeCloseDialog}
        icon={<BackHandRoundedIcon
          sx={{
            color: 'rgba(255, 0, 0, 0.50)',
            width: '100%'
          }}
              />}
        title={t('trip_share.close_travel_mode_dialog_title')}
        content={t('trip_share.close_travel_mode_dialog_content')}
        buttons={[
          {
            label: t('trip_share.close_travel_mode_dialog_confirm_text'),
            onClick: () => handleExitTravelMode(),
            sx: {
              color: 'rgba(255, 0, 0, 0.70)',
              fontWeight: 600
            }
          },
          {
            label: t('trip_share.close_travel_mode_dialog_cancel_text'),
            onClick: () => setTravelModeCloseDialog(false),
            sx: {
              color: 'rgb(25, 125, 143)',
              fontWeight: 600
            }
          }
        ]}
        onClose={() => setTravelModeCloseDialog(false)}
      />
    </Box>
  )
}

export const WalkStepCard = ({ key, leg, setTravelModeCurrentStep }) => {
  const places = useSelector(state => state?.ui?.places)
  const { t } = useTranslation()

  const { name: toName } = leg.to
  const { duration, distance, transportTypeIcon } = leg
  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px'
          }}
        >
          <DirectionsWalkRoundedIcon />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: 600
              }}
            >
              {`${t('trip_share.walk_to')} `}
              {transportTypeIcon && REACT_APP_SHOW_WALK_CARD_ROUTE_TYPE_ICON &&
                <img
                  src={transportTypeIcon}
                  alt='agency logo'
                  style={{
                    width: '16px',
                    height: '16px',
                    margin: '0 5px 0 0'
                  }}
                />}
              {toName === 'Destination' ? places.to.name : toName}
            </Typography>
            <Typography
              variant='body2'
              sx={{
                fontWeight: 400
              }}
            >
              {t('trip_share.you_are_aprox', { prop1: parseInt(distance) })}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant='body2'
          sx={{
            fontWeight: 700,
            whiteSpace: 'nowrap'
          }}
        >
          {parseInt(duration / 60)} min
        </Typography>
      </Box>
    </Box>
  )
}

export const WaitingCard = ({ key, leg, setTravelModeCurrentStep }) => {
  const { transportTypeIcon, routeShortName, headsign, startTime, realTimeArrival, realTime } = leg
  const { t } = useTranslation()
  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: '5px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '5px',
          width: '100%'
        }}
      >
        <AccessTimeRoundedIcon sx={{ width: '16px' }} />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '12px',
            whiteSpace: 'nowrap'
          }}
        >
          {t('trip_share.wait_for')}
        </Typography>
        <img
          src={transportTypeIcon}
          alt='agency logo'
          style={{
            width: '16px',
            height: '16px'
          }}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '12px',
            whiteSpace: 'nowrap'
          }}
        >
          {routeShortName}
        </Typography>
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              margin: 0,
              animation: 'scroll-left 8s linear infinite',
              whiteSpace: 'nowrap',
              '@keyframes scroll-left': {
                '0%': {
                  transform: ' translateX(100%)'
                },
                '100%': {
                  opacity: 1, transform: ' translateX(-100%)'
                }
              }

            }}
          >
            {headsign}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '5px'
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px'
            }}
          >
            {moment(startTime).format('HH:mm')}
          </Typography>
          {realTime &&
            <Box
              sx={{
                display: 'flex',
                position: 'relative'

              }}
            >
              <img
                src={RealTimeIcon}
                style={{
                  width: '12px',
                  position: 'absolute',
                  left: '-12px',
                  top: '-4px'
                }}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  whiteSpace: 'nowrap'
                }}
              >
                In {Math.floor(realTimeArrival / 60000)} min
              </Typography>
            </Box>}
        </Box>
      </Box>
      {REACT_APP_SHOW_STOP_SECURITY_SURVEY &&
        <>
          <Divider
            variant='fullWidth'
            sx={{ width: '100%' }}
          />
          <Security />
        </>}
    </Box>
  )
}

export const TransportCard = ({ key, leg, setTravelModeCurrentStep, travelModeCurrentStep, travelModeCards }) => {
  const { t } = useTranslation()
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const { stops, duration, routeShortName, routeLongName, headsign } = leg
  const { name: fromName } = leg.from
  const { name: toName } = leg.to
  const stopsOnRoute = stops?.filter(stop => stop.isOnRoute).length
  const durationMinutes = parseInt(duration / 60)
  const transportTypeIcon = cityConfig?.transport_types?.find(transport => parseInt(transport?.route_type) === parseInt(leg?.routeType))?.icon
  const transportSteps = tripSelected?.legs?.filter(leg => leg.mode !== 'WALK')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (travelModeCards.length === 0) return
    if (travelModeCards[travelModeCurrentStep]?.mode === 'WAITING' || travelModeCards[travelModeCurrentStep]?.mode === 'WALK') {
      setOpen(false)
    }
  }, [travelModeCurrentStep])

  const stepIndex = transportSteps.findIndex(step => step.tripId === leg.tripId)

  const stopIcons = {
    0: [stop1, stop2],
    1: [stop3, stop4],
    2: [stop5, stop6],
    3: [stop7, stop8]
  }

  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: '5px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '5px',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <ArrowForwardIosRoundedIcon sx={{ width: '16px' }} />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              whiteSpace: 'nowrap'
            }}
          >
            {t('trip_share.go_forward', { prop1: stopsOnRoute })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px'
            }}
          >
            {durationMinutes}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px'
            }}
          >
            min.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'flex-start',
          gap: '5px',
          width: '100%',
          padding: '0 20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={transportTypeIcon}
            alt='agency logo'
            style={{
              width: '18px',
              height: '18px'
            }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              whiteSpace: 'nowrap'
            }}
          >
            {routeShortName || routeLongName}
          </Typography>
          <Box
            sx={{
              width: '90%',
              overflow: 'hidden'
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                margin: 0,
                animation: 'scroll-left 8s linear infinite',
                whiteSpace: 'nowrap',
                '@keyframes scroll-left': {
                  '0%': {
                    transform: ' translateX(100%)'
                  },
                  '100%': {
                    opacity: 1, transform: ' translateX(-100%)'
                  }
                }

              }}
            >
              {headsign}
            </Typography>
          </Box>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
            gap: '5px',
            padding: '0 0 10px 0'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#7da7d9',
              borderRadius: '8px',
              gap: '5px',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'normal'
            }}
          >
            <span
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                border: '2px solid #7da7d9'
              }}
            />
            <span
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                border: '2px solid #7da7d9'
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: '100%',
              overflowX: 'hidden'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px',
                overflowX: 'hidden'
              }}
            >
              <img
                src={stopIcons[stepIndex][0]}
                alt='agency logo'
                style={{
                  width: '16px',
                  height: '16px'
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {fromName}
              </Typography>
            </Box>

            <ListItemButton
              sx={{
                padding: '0px 20px',
                width: '100%'
              }}
              onClick={() => setOpen(!open)}
            >
              <ListItemText
                primary={t('trip_share.see_all_stops')}
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={open} timeout='auto' unmountOnExit
              sx={{
                width: '100%',
                maxHeight: '200px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <List
                component='div' disablePadding
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                {stops?.filter(stop => stop.isOnRoute).map((stop, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: '0 0 0 30px',
                      margin: '0 0 5px 0',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {stop.stop_name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              <img
                src={stopIcons[stepIndex][1]}
                alt='agency logo'
                style={{
                  width: '16px',
                  height: '16px'
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {toName}
              </Typography>
            </Box>

          </div>
        </div>
      </Box>
    </Box>
  )
}

const StepsDivider = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        padding: '0 16px'
      }}
    >
      <span
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
          marginLeft: '13px'
        }}
      />
      <Divider
        sx={{
          width: '87%'
        }}
      />
    </Box>
  )
}

const WalkStep = ({ leg }) => {
  const { t } = useTranslation()
  const { duration, distance } = leg
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        boxSizing: 'border-box',
        padding: '0 16px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          boxSizing: 'border-box'
        }}
      >
        <DirectionsWalkRoundedIcon
          sx={{
            width: '36px'
          }}
        />
        <Typography
          variant='body2'
          sx={{
            fontWeight: 600
          }}
        >
          {t('trip_share.walk')}  {parseInt(duration / 60)} min ({parseInt(distance)} m)
        </Typography>
      </Box>
    </Box>
  )
}

const TransportStep = ({ key, leg, isAlternativeItinerary }) => {
  const { t } = useTranslation()
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)

  const { stops, duration, routeShortName, routeLongName, startTime, steps } = leg
  const { name: fromName } = leg.from
  const { name: toName } = leg.to
  const durationMinutes = parseInt(duration / 60)
  const transportTypeIcon = cityConfig?.transport_types?.find(transport => parseInt(transport?.route_type) === parseInt(leg?.routeType))?.icon
  const [open, setOpen] = useState(false)
  const stopsOnRoute = stops?.filter(stop => stop.isOnRoute).length
  const transportSteps = tripSelected?.legs?.filter(leg => leg.mode !== 'WALK')

  const iconMap = {
    DEPART: <StraightRoundedIcon />,
    CONTINUE: <StraightRoundedIcon />,
    LEFT: <TurnLeftRoundedIcon />,
    SLIGHTLY_LEFT: <TurnSlightLeftRoundedIcon />,
    HARD_LEFT: <TurnLeftRoundedIcon />,
    RIGHT: <TurnRightRoundedIcon />,
    SLIGHTLY_RIGHT: <TurnSlightRightRoundedIcon />,
    HARD_RIGHT: <TurnRightRoundedIcon />,
    CIRCLE_CLOCKWISE: <CircleOutlinedIcon />,
    CIRCLE_COUNTERCLOCKWISE: <CircleOutlinedIcon />,
    ELEVATOR: <ElevatorIcon />,
    UTURN_LEFT: <UTurnLeftIcon />,
    UTURN_RIGHT: <UTurnRightIcon />
  }

  const getText = (step) => {
    const { relativeDirection, streetName, exit, absoluteDirection } = step
    const templateKey = t(`otp_directions.${relativeDirection}`)

    if (relativeDirection === 'DEPART') {
      const direction = t(`otp_directions.${absoluteDirection}`)
      return t(templateKey, { absoluteDirection: direction, streetName })
    }

    return exit
      ? t(templateKey, { exit: t(`otp_directions.${exit}`), streetName })
      : streetName
        ? t(templateKey, { streetName })
        : t(templateKey)
  }

  const stepIndex = transportSteps.findIndex(step => step.tripId === leg.tripId)

  const stopIcons = {
    0: [stop1, stop2],
    1: [stop3, stop4],
    2: [stop5, stop6],
    3: [stop7, stop8]
  }

  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: '5px',
        overflow: 'hidden',
        paddingLeft: '6px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '5px',
          width: '100%'
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'flex-start',
          gap: '5px',
          width: '100%',
          padding: '0 20px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
            gap: '5px',
            padding: '0'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#7da7d9',
              borderRadius: '8px',
              gap: '5px',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'normal'
            }}
          >
            <span
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                border: '2px solid #7da7d9'
              }}
            />
            <span
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                border: '2px solid #7da7d9'
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: '100%',
              overflowX: 'hidden',
              gap: '10px'
            }}
          >
            {!isAlternativeItinerary &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px',
                  overflowX: 'hidden'
                }}
              >
                <img
                  src={stopIcons[stepIndex][0]}
                  alt='agency logo'
                  style={{
                    width: '16px',
                    height: '16px'
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {fromName}
                </Typography>
              </Box>}
            {!isAlternativeItinerary &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px',
                  paddingLeft: '20px',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '5px'
                  }}
                >
                  <img
                    src={transportTypeIcon}
                    alt='agency logo'
                    style={{
                      width: '24px',
                      height: '24px'
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '18px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {routeShortName || routeLongName}
                  </Typography>
                </Box>
                {startTime &&
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px'
                    }}
                  >
                    {moment(startTime).format('HH:mm')}
                  </Typography>}
              </Box>}
            {!isAlternativeItinerary &&
              <ListItemButton
                sx={{
                  padding: '0px 20px',
                  width: '100%'
                }}
                onClick={() => setOpen(!open)}
              >
                <ListItemText
                  primary={`${t('trip_share.go_forward', { prop1: stopsOnRoute })} (${durationMinutes} min)`}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>}
            {!isAlternativeItinerary &&
              <Collapse
                in={open} timeout='auto' unmountOnExit
                sx={{
                  width: '100%',
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <List
                  component='div' disablePadding
                  sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  {stops?.filter(stop => stop.isOnRoute).map((stop, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        padding: '0 0 0 30px',
                        margin: '0 0 5px 0',
                        width: '100%'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {stop.stop_name}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>}
            {isAlternativeItinerary &&
              <List
                component='div'
                disablePadding
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                {steps?.map((step, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: '0',
                      paddingLeft: '10px',
                      margin: '0 0 5px 0',
                      width: '100%',
                      gap: '10px'
                    }}
                  >
                    {iconMap[step.relativeDirection]}
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {getText(step)}
                    </Typography>
                  </ListItem>
                ))}
              </List>}
            {!isAlternativeItinerary &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                <img
                  src={stopIcons[stepIndex][1]}
                  alt='agency logo'
                  style={{
                    width: '16px',
                    height: '16px'
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {toName}
                </Typography>
              </Box>}

          </div>
        </div>
      </Box>
    </Box>
  )
}
