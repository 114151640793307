import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tab,
  Tabs
} from '@mui/material'
import { AccountCircleRounded, CloseRounded, ExitToAppRounded, PlusOneRounded } from '@mui/icons-material'
import {
  FormContainer,
  InfoCard,
  InputName,
  InputRowContainer,
  SectionTitle,
  SideBar,
  UalabeeLogo,
  UserInfoContainer,
  UserProfileContainer
} from './styles'
import {
  DisplayName,
  MoreOptionsIcon,
  MoreOptionsIconContainer,
  StatData,
  StatItem,
  StatsContainer,
  StatTitle
} from '../../UserProfile/styles'
import ConnectedPeople from '../../../img/people_connected.svg'
import UalabeeLogoImage from '../../../img/ualabeelogo.svg'
import UserActivity from '../../../uicomponents/UserActivity'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ResendVerificationEmail from '../../ResendVerificationEmail'
import { getAuth } from 'firebase/auth'
import { REACT_APP_HARDCODED_LOGO, REACT_APP_SHOW_BECOME_CONTRIBUTOR_MENU_OPTION, REACT_APP_SHOW_WHY_ADD_POINTS_MENU_OPTION } from '../../../constants/config'
import NoResultMessage from '../../../uicomponents/NoResultMessage'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role='tabpanel'
      hidden={value !== index}
      id={`user-profile-tabpanel-${index}`}
      aria-labelledby={`user-profile-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `user-profile-tab-${index}`,
    'aria-controls': `user-profile-tabpanel-${index}`
  }
}

const UserProfileDesktop = ({
  contributionHistory,
  userData,
  handleBecomeAPartner,
  handlePointsHelp,
  setLogoutConfirmDialog,
  setDeleteAccountFirstConfirmDialog
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const auth = getAuth()
  const user = auth.currentUser

  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const [tabSelected, setTabSelected] = useState(0)

  const handleChange = (event, newValue) => {
    setTabSelected(newValue)
  }

  const getGenderName = (gender) => {
    switch (gender) {
      case 'male': {
        return t('user.personal_information.genders.male')
      }
      case 'female': {
        return t('user.personal_information.genders.female')
      }
      case 'prefer_not_to_say': {
        return t('user.personal_information.genders.prefer_not_to_say')
      }
      default: {
        return ''
      }
    }
  }

  const handleBack = () => {
    navigate('/')
  }

  return (
    <UserProfileContainer>
      <SideBar>
        <InfoCard justifyContent='flex-start'>
          <Avatar
            src={userData?.photo_url || user?.photoURL || user?.providerData[0]?.photoURL}
            sx={{
              width: '72px',
              height: '72px',
              position: 'unset'
            }}
          />
          {userData
            ? (
              <DisplayName>
                {userData?.display_name || user?.displayName || user?.providerData[0]?.displayName}
              </DisplayName>
              )
            : (<Skeleton
                variant='text' width={100} height={20} animation='wave' style={{
                  marginTop: '8px',
                  marginBottom: '26px'
                }}
               />
              )}
          <StatsContainer>
            <StatItem>
              {contributionHistory
                ? (
                  <StatData>{contributionHistory?.level_name}</StatData>)
                : (
                  <Skeleton
                    variant='text'
                    width={60}
                    height={20} animation='wave'
                    sx={{
                      marginBottom: '8px'
                    }}
                  />
                  )}
              <StatTitle>{t('user.level')}</StatTitle>
            </StatItem>
            <Divider
              sx={{
                backgroundColor: '#E8EDEE'
              }}
              orientation='vertical'
              flexItem
            />
            <StatItem>
              {userData
                ? (<StatData>{userData?.score || 0}</StatData>)
                : (
                  <Skeleton
                    variant='text'
                    width={60}
                    height={20}
                    animation='wave'
                    style={{ marginBottom: '8px' }}
                  />
                  )}
              <StatTitle>{t('user.scores')}</StatTitle>
            </StatItem>
          </StatsContainer>
          <ResendVerificationEmail />
        </InfoCard>
        <InfoCard overflow='auto' padding='0' margin='0' justifyContent='space-between'>
          <List
            component='nav'
            aria-label='main mailbox folders'
            sx={{
              width: '100%',
              margin: 0
            }}
          >
            <ListItemButton
              sx={{
                padding: '16px'
              }}
            >
              <MoreOptionsIconContainer bgColor='#E5F3F8'>
                <AccountCircleRounded
                  sx={{
                    width: '100%',
                    color: '#66C3E6'
                  }}
                />
              </MoreOptionsIconContainer>
              <ListItemText
                primary={t('user.my_account')}
                sx={{
                  color: '#222831',
                  margin: 0,
                  fontSize: '16px'
                }}
              />
            </ListItemButton>
            <Divider
              sx={{
                backgroundColor: '#E8EDEE'
              }}
            />
            {!isIosApp && REACT_APP_SHOW_BECOME_CONTRIBUTOR_MENU_OPTION &&
              <>
                <ListItemButton
                  onClick={() => handleBecomeAPartner()}
                  sx={{
                    padding: '16px'
                  }}
                >
                  <MoreOptionsIconContainer bgColor='#F1F8E5'>
                    <MoreOptionsIcon color='#AECC66' url={ConnectedPeople} />
                  </MoreOptionsIconContainer>
                  <ListItemText
                    primary={t('user.options.become_a_contributor')}
                    sx={{
                      color: '#222831',
                      margin: 0,
                      fontSize: '16px'
                    }}
                  />
                </ListItemButton>
                <Divider
                  sx={{
                    backgroundColor: '#E8EDEE'
                  }}
                />
              </>}
            {REACT_APP_SHOW_WHY_ADD_POINTS_MENU_OPTION &&
              <>
                <ListItemButton
                  onClick={() => handlePointsHelp()}
                  sx={{
                    padding: '16px'
                  }}
                >
                  <MoreOptionsIconContainer bgColor='#F8E5EE'>
                    <PlusOneRounded
                      style={{
                        color: '#CC6696'
                      }}
                    />
                  </MoreOptionsIconContainer>
                  <ListItemText
                    primary={t('user.options.why_sum_points')}
                    sx={{
                      color: '#222831',
                      margin: 0,
                      fontSize: '16px'
                    }}
                  />
                </ListItemButton>
                <Divider
                  sx={{
                    backgroundColor: '#E8EDEE'
                  }}
                />
              </>}
            <ListItemButton
              onClick={() => setLogoutConfirmDialog(true)}
              sx={{
                padding: '16px'
              }}
            >
              <MoreOptionsIconContainer bgColor='#E7E5F8'> <ExitToAppRounded
                style={{ color: '#61616A' }}
                                                           />
              </MoreOptionsIconContainer>

              <ListItemText
                primary={t('user.options.sign_out')}
                sx={{
                  color: '#222831',
                  margin: 0,
                  fontSize: '16px'
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton
              onClick={() => setDeleteAccountFirstConfirmDialog(true)}
              sx={{
                padding: '16px'
              }}
            >
              <MoreOptionsIconContainer bgColor='#E7E5F8'>
                <DeleteRoundedIcon style={{ color: '#61616A' }} />
              </MoreOptionsIconContainer>
              <ListItemText
                primary={t('user.options.delete_account')}
                sx={{
                  color: '#222831',
                  margin: 0,
                  fontSize: '16px'
                }}
              />
            </ListItemButton>
          </List>
          <UalabeeLogo src={REACT_APP_HARDCODED_LOGO || UalabeeLogoImage} />
        </InfoCard>
      </SideBar>
      <InfoCard
        padding='0' alignItems='flex-start' justifyContent='flex-start' height='100%'
        margin='0'
      >
        <IconButton
          onClick={() => handleBack()}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px'
          }}
        >
          <CloseRounded style={{ color: 'rgba(34,40,49,0.3)' }} />
        </IconButton>
        <Tabs value={tabSelected} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              padding: '22px 47px',
              color: '#909397',
              '&.Mui-selected': {
                color: '#222831'
              }
            }}
            label={t('user.personal_information.title')}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              padding: '22px 47px',
              color: '#909397',
              '&.Mui-selected': {
                color: '#222831'
              }
            }}
            label={t('user.recent_activity')}
            {...a11yProps(1)}
          />
        </Tabs>
        <Divider
          sx={{
            width: '100%',
            height: '1px'

          }}
        />
        <TabPanel
          value={tabSelected}
          index={0}
          sx={{
            width: '100%'
          }}
        >
          <UserInfoContainer>
            <SectionTitle>
              {t('user.personal_information.account_data_title')}
            </SectionTitle>
            <FormContainer>
              <InputRowContainer>
                <InputName>{t('user.personal_information.email')}</InputName>
                {userData
                  ? (
                    <InputBase
                      disabled
                      name='email'
                      sx={{
                        width: '450px',
                        backgroundColor: '#F6F8F9',
                        borderRadius: '10px',
                        padding: '14.5px 24px',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                        '& .MuiInputBase-input': {
                          padding: 0
                        }
                      }}
                      defaultValue={userData.email || '-'}
                    />
                    )
                  : (<Skeleton
                      variant='rect'
                      width={450}
                      height={48}
                      sx={{ borderRadius: '10px' }}
                      animation='wave'
                     />
                    )}
              </InputRowContainer>
              <InputRowContainer>
                <InputName>{t('user.personal_information.user')}</InputName>
                {userData
                  ? (
                    <InputBase
                      disabled
                      name='username'
                      sx={{
                        width: '450px',
                        backgroundColor: '#F6F8F9',
                        borderRadius: '10px',
                        padding: '14.5px 24px',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                        '& .MuiInputBase-input': {
                          padding: 0
                        }
                      }}
                      defaultValue={userData.nickname || '-'}
                    />
                    )
                  : (<Skeleton
                      variant='rect'
                      width={450}
                      height={48}
                      sx={{ borderRadius: '10px' }}
                      animation='wave'
                     />
                    )}
              </InputRowContainer>
            </FormContainer>
            <SectionTitle>
              {t('user.personal_information.personal_data_title')}
            </SectionTitle>
            <FormContainer>
              <InputRowContainer>
                <InputName>{t('user.personal_information.name_and_surname')}</InputName>
                {userData
                  ? (
                    <InputBase
                      disabled
                      name='firstname-lastname'
                      sx={{
                        width: '450px',
                        backgroundColor: '#F6F8F9',
                        borderRadius: '10px',
                        padding: '14.5px 24px',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                        '& .MuiInputBase-input': {
                          padding: 0
                        }
                      }}
                      defaultValue={userData.display_name || '-'}
                    />
                    )
                  : (<Skeleton
                      variant='rect'
                      width={450}
                      height={48}
                      style={{ borderRadius: '10px' }}
                      animation='wave'
                     />
                    )}
              </InputRowContainer>
              <InputRowContainer>
                <InputName>{t('user.personal_information.telephone')}</InputName>
                {userData
                  ? (
                    <InputBase
                      disabled
                      name='phone-number'
                      sx={{
                        width: '450px',
                        backgroundColor: '#F6F8F9',
                        borderRadius: '10px',
                        padding: '14.5px 24px',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                        '& .MuiInputBase-input': {
                          padding: 0
                        }
                      }}
                      defaultValue={userData.phone_number || '-'}
                    />
                    )
                  : (<Skeleton
                      variant='rect'
                      width={450}
                      height={48}
                      sx={{ borderRadius: '10px' }}
                      animation='wave'
                     />
                    )}
              </InputRowContainer>
              <InputRowContainer>
                <InputName>{t('user.personal_information.gender')}</InputName>
                {userData
                  ? (
                    <InputBase
                      disabled
                      name='gender'
                      sx={{
                        width: '450px',
                        backgroundColor: '#F6F8F9',
                        borderRadius: '10px',
                        padding: '14.5px 24px',
                        fontSize: '16px',
                        boxSizing: 'border-box',
                        '& .MuiInputBase-input': {
                          padding: 0
                        }
                      }}
                      defaultValue={getGenderName(userData.gender) || '-'}
                    />
                    )
                  : (
                    <Skeleton
                      variant='rect'
                      width={450}
                      height={48}
                      sx={{ borderRadius: '10px' }}
                      animation='wave'
                    />
                    )}
              </InputRowContainer>
            </FormContainer>
          </UserInfoContainer>
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>

          {contributionHistory?.contributions?.length >= 1
            ? (
              <UserInfoContainer>
                <UserActivity />
              </UserInfoContainer>
              )
            : (
              <UserInfoContainer>
                <NoResultMessage message={t('user.no_recent_activity')} />
              </UserInfoContainer>
              )}
        </TabPanel>
      </InfoCard>
    </UserProfileContainer>
  )
}

export default UserProfileDesktop
