// Description= This file contains all the constants used in the project

const parseBoolean = (value) => {
  return value === 'true'
}

const REACT_APP_SHOW_MENU = parseBoolean(process.env.REACT_APP_SHOW_MENU)
const REACT_APP_FCM_SUBSCRIBE_TOPIC_VARIANT = parseBoolean(process.env.REACT_APP_FCM_SUBSCRIBE_TOPIC_VARIANT)
const SKIP_PREFLIGHT_CHECK = parseBoolean(process.env.SKIP_PREFLIGHT_CHECK)
const GENERATE_SOURCEMAP = parseBoolean(process.env.GENERATE_SOURCEMAP)
const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
const REACT_APP_WHITE_LABEL = process.env.REACT_APP_WHITE_LABEL
const REACT_APP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID
const REACT_APP_HARDCODED_CITY_ID = Number(process.env.REACT_APP_HARDCODED_CITY_ID)
const REACT_APP_IS_IFRAME = parseBoolean(process.env.REACT_APP_IS_IFRAME)
const REACT_APP_LOGO_EXTERNAL_LINK = process.env.REACT_APP_LOGO_EXTERNAL_LINK
const REACT_APP_HOST = process.env.REACT_APP_HOST
const REACT_APP_SHOW_NAVBAR = parseBoolean(process.env.REACT_APP_SHOW_NAVBAR)
const REACT_APP_SHOW_LOGIN = parseBoolean(process.env.REACT_APP_SHOW_LOGIN)
const REACT_APP_IFRAME_LOGO = process.env.REACT_APP_IFRAME_LOGO
const REACT_APP_SHOW_FILTERS = parseBoolean(process.env.REACT_APP_SHOW_FILTERS)
const REACT_APP_SHOW_MAP_MARKERS = parseBoolean(process.env.REACT_APP_SHOW_MAP_MARKERS)
const REACT_APP_VARIANT_ID = Number(process.env.REACT_APP_VARIANT_ID)
const REACT_APP_CAN_CHANGE_CITY = parseBoolean(process.env.REACT_APP_CAN_CHANGE_CITY)
const REACT_APP_HARDCODED_LOGO = process.env.REACT_APP_HARDCODED_LOGO
const REACT_APP_HARDCODED_FAVICON = process.env.REACT_APP_HARDCODED_FAVICON
const REACT_APP_HARDCODED_TITLE = process.env.REACT_APP_HARDCODED_TITLE
const REACT_APP_HARDCODED_ICON = process.env.REACT_APP_HARDCODED_ICON
const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const REACT_APP_FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL
const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const REACT_APP_FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
const REACT_APP_PLACES_HOST = process.env.REACT_APP_PLACES_HOST
const REACT_APP_FCM_V_API_KEY = process.env.REACT_APP_FCM_V_API_KEY
const REACT_APP_AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY
const REACT_APP_API_FEED = process.env.REACT_APP_API_FEED
const REACT_APP_DATABASE_HOST = process.env.REACT_APP_DATABASE_HOST
const REACT_APP_PALLET_PRIMARY_MAIN_COLOR = process.env.REACT_APP_PALLET_PRIMARY_MAIN_COLOR
const REACT_APP_PALLET_PRIMARY_LIGHT_COLOR = process.env.REACT_APP_PALLET_PRIMARY_LIGHT_COLOR
const REACT_APP_PALLET_SECONDARY_MAIN_COLOR = process.env.REACT_APP_PALLET_SECONDARY_MAIN_COLOR
const REACT_APP_PALLET_SECONDARY_LIGHT_COLOR = process.env.REACT_APP_PALLET_SECONDARY_LIGHT_COLOR
const REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR = process.env.REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
const REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR = process.env.REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR
const REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR = process.env.REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR
const REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR = process.env.REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR
const REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR = process.env.REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
const REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR = process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
const REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR = process.env.REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR
const REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR = process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR
const REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR = process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR
const REACT_APP_PALLET_BUTTONS_PRIMARY_MAIN_COLOR = process.env.REACT_APP_PALLET_BUTTONS_PRIMARY_MAIN_COLOR
const REACT_APP_PALLET_BUTTONS_SECONDARY_MAIN = process.env.REACT_APP_PALLET_BUTTONS_SECONDARY_MAIN
const REACT_APP_HARDCODED_DESCRIPTION = process.env.REACT_APP_HARDCODED_DESCRIPTION
const REACT_APP_HARDCODED_DESCRIPTION_EN = process.env.REACT_APP_HARDCODED_DESCRIPTION_EN
const REACT_APP_HARDCODED_DESCRIPTION_ES = process.env.REACT_APP_HARDCODED_DESCRIPTION_ES
const REACT_APP_HARDCODED_DESCRIPTION_ES_CL = process.env.REACT_APP_HARDCODED_DESCRIPTION_ES_CL
const REACT_APP_ANDROID_PACKAGE_NAME = process.env.REACT_APP_ANDROID_PACKAGE_NAME
const REACT_APP_IOS_BUNDLE_ID = process.env.REACT_APP_IOS_BUNDLE_ID
const REACT_APP_DOMAIN_URI_PREFIX = process.env.REACT_APP_DOMAIN_URI_PREFIX
const REACT_APP_SHOW_MENU_ITEMS = parseBoolean(process.env.REACT_APP_SHOW_MENU_ITEMS)
const REACT_APP_CUSTOM_MENU_FACEBOOK_LINK = process.env.REACT_APP_CUSTOM_MENU_FACEBOOK_LINK
const REACT_APP_CUSTOM_MENU_INSTAGRAM_LINK = process.env.REACT_APP_CUSTOM_MENU_INSTAGRAM_LINK
const REACT_APP_CUSTOM_MENU_TWITTER_LINK = process.env.REACT_APP_CUSTOM_MENU_TWITTER_LINK
const REACT_APP_HARDCODED_META_DESCRIPTION = process.env.REACT_APP_HARDCODED_META_DESCRIPTION
const REACT_APP_FCM_SUBSCRIBE_TOPIC_ALERT_VARIANT = parseBoolean(process.env.REACT_APP_FCM_SUBSCRIBE_TOPIC_ALERT_VARIANT)
const REACT_APP_SHOW_SHARE_BUTTONS = parseBoolean(process.env.REACT_APP_SHOW_SHARE_BUTTONS)
const REACT_APP_SHOW_SEND_TO_PHONE_BUTTONS = parseBoolean(process.env.REACT_APP_SHOW_SEND_TO_PHONE_BUTTONS)
const REACT_APP_SHOW_BECOME_CONTRIBUTOR_MENU_OPTION = parseBoolean(process.env.REACT_APP_SHOW_BECOME_CONTRIBUTOR_MENU_OPTION)
const REACT_APP_SHOW_WHY_ADD_POINTS_MENU_OPTION = parseBoolean(process.env.REACT_APP_SHOW_WHY_ADD_POINTS_MENU_OPTION)
const REACT_APP_SHOW_MISSIONS_OPTION = parseBoolean(process.env.REACT_APP_SHOW_MISSIONS_OPTION)
const REACT_APP_SHOW_BLOG_OPTION = parseBoolean(process.env.REACT_APP_SHOW_BLOG_OPTION)
const REACT_APP_SHOW_YOUR_CITY_OPTION = parseBoolean(process.env.REACT_APP_SHOW_YOUR_CITY_OPTION)
const REACT_APP_CUSTOM_MENU_OUR_COMPANY_LINK = process.env.REACT_APP_CUSTOM_MENU_OUR_COMPANY_LINK
const REACT_APP_APP_NAME = process.env.REACT_APP_APP_NAME
const REACT_APP_SHOW_CAPTIVE_PORTAL = parseBoolean(process.env.REACT_APP_SHOW_CAPTIVE_PORTAL)
const REACT_APP_TILES_HOST = process.env.REACT_APP_TILES_HOST
const REACT_APP_TOS_URL = process.env.REACT_APP_TOS_URL
const REACT_APP_PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL
const REACT_APP_SORT_ROUTE_TYPE_ID = process.env.REACT_APP_SORT_ROUTE_TYPE_ID ? Number(process.env.REACT_APP_SORT_ROUTE_TYPE_ID) : null
const REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS = parseBoolean(process.env.REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS)
const REACT_APP_NPS_SURVEY = parseBoolean(process.env.REACT_APP_NPS_SURVEY)
const REACT_APP_CHANGE_ROUTE_COLOR_ON_TRANSFERS = parseBoolean(process.env.REACT_APP_CHANGE_ROUTE_COLOR_ON_TRANSFERS)
const REACT_APP_SHOW_MAP_USER_REPORTS = parseBoolean(process.env.REACT_APP_SHOW_MAP_USER_REPORTS)
const REACT_APP_SHOW_STOP_SECURITY_SURVEY = parseBoolean(process.env.REACT_APP_SHOW_STOP_SECURITY_SURVEY)
const REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES = parseBoolean(process.env.REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES)
const REACT_APP_FONT_FAMILY = process.env.REACT_APP_FONT_FAMILY
const REACT_APP_SHOW_WALK_CARD_ROUTE_TYPE_ICON = parseBoolean(process.env.REACT_APP_SHOW_WALK_CARD_ROUTE_TYPE_ICON)
const REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON = parseBoolean(process.env.REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON)
const REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION = parseBoolean(process.env.REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION)
const REACT_APP_TICKETS_REWARDS_PROGRAM = parseBoolean(process.env.REACT_APP_TICKETS_REWARDS_PROGRAM)

export {
  REACT_APP_SHOW_MENU,
  REACT_APP_FCM_SUBSCRIBE_TOPIC_VARIANT,
  SKIP_PREFLIGHT_CHECK,
  GENERATE_SOURCEMAP,
  SENTRY_AUTH_TOKEN,
  REACT_APP_ENVIRONMENT,
  REACT_APP_WHITE_LABEL,
  REACT_APP_PROJECT_ID,
  REACT_APP_HARDCODED_CITY_ID,
  REACT_APP_IS_IFRAME,
  REACT_APP_LOGO_EXTERNAL_LINK,
  REACT_APP_HOST,
  REACT_APP_SHOW_NAVBAR,
  REACT_APP_SHOW_LOGIN,
  REACT_APP_IFRAME_LOGO,
  REACT_APP_SHOW_FILTERS,
  REACT_APP_SHOW_MAP_MARKERS,
  REACT_APP_VARIANT_ID,
  REACT_APP_CAN_CHANGE_CITY,
  REACT_APP_HARDCODED_LOGO,
  REACT_APP_HARDCODED_FAVICON,
  REACT_APP_HARDCODED_TITLE,
  REACT_APP_HARDCODED_ICON,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_PLACES_HOST,
  REACT_APP_FCM_V_API_KEY,
  REACT_APP_AMPLITUDE_KEY,
  REACT_APP_API_FEED,
  REACT_APP_DATABASE_HOST,
  REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_PRIMARY_LIGHT_COLOR,
  REACT_APP_PALLET_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_SECONDARY_LIGHT_COLOR,
  REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR,
  REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR,
  REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR,
  REACT_APP_PALLET_BUTTONS_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_BUTTONS_SECONDARY_MAIN,
  REACT_APP_HARDCODED_DESCRIPTION,
  REACT_APP_HARDCODED_DESCRIPTION_EN,
  REACT_APP_HARDCODED_DESCRIPTION_ES,
  REACT_APP_HARDCODED_DESCRIPTION_ES_CL,
  REACT_APP_ANDROID_PACKAGE_NAME,
  REACT_APP_IOS_BUNDLE_ID,
  REACT_APP_DOMAIN_URI_PREFIX,
  REACT_APP_SHOW_MENU_ITEMS,
  REACT_APP_CUSTOM_MENU_FACEBOOK_LINK,
  REACT_APP_CUSTOM_MENU_INSTAGRAM_LINK,
  REACT_APP_CUSTOM_MENU_TWITTER_LINK,
  REACT_APP_HARDCODED_META_DESCRIPTION,
  REACT_APP_FCM_SUBSCRIBE_TOPIC_ALERT_VARIANT,
  REACT_APP_SHOW_SHARE_BUTTONS,
  REACT_APP_SHOW_SEND_TO_PHONE_BUTTONS,
  REACT_APP_SHOW_BECOME_CONTRIBUTOR_MENU_OPTION,
  REACT_APP_SHOW_WHY_ADD_POINTS_MENU_OPTION,
  REACT_APP_SHOW_MISSIONS_OPTION,
  REACT_APP_SHOW_BLOG_OPTION,
  REACT_APP_SHOW_YOUR_CITY_OPTION,
  REACT_APP_CUSTOM_MENU_OUR_COMPANY_LINK,
  REACT_APP_APP_NAME,
  REACT_APP_SHOW_CAPTIVE_PORTAL,
  REACT_APP_TILES_HOST,
  REACT_APP_TOS_URL,
  REACT_APP_PRIVACY_POLICY_URL,
  REACT_APP_SORT_ROUTE_TYPE_ID,
  REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS,
  REACT_APP_NPS_SURVEY,
  REACT_APP_CHANGE_ROUTE_COLOR_ON_TRANSFERS,
  REACT_APP_SHOW_MAP_USER_REPORTS,
  REACT_APP_SHOW_STOP_SECURITY_SURVEY,
  REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES,
  REACT_APP_FONT_FAMILY,
  REACT_APP_SHOW_WALK_CARD_ROUTE_TYPE_ICON,
  REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON,
  REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION,
  REACT_APP_TICKETS_REWARDS_PROGRAM
}
