import styled from 'styled-components/macro'
import { Button } from '@mui/material'
import { Navigation } from '@mui/icons-material'

export const ButtonContainer = styled(Button)` 
`

export const NavigationContent = styled.div` 
    display: flex;
    align-items: center; 
    justify-content: center;
`

export const NavigationLabel = styled.p` 
    margin: 0 5px;
`

export const NavigationIcon = styled(Navigation)` 
`
