import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SwipeableDrawer } from '@mui/material'
import { useAlerts } from '../../hooks/useAlerts'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import TitleDrawer from '../../uicomponents/TitleDrawer'
import ImageCard from '../../uicomponents/ImageCard'
import { AlertsListContainer } from './styles'
import { enabledAlertsAndNewsComponent, setOpenCountries } from '../../actions/ui'
import { Loading as LoadingLinear } from '../../uicomponents/mobile/Loading'
import { AlertsSkeleton } from '../../uicomponents/mobile/alerts/Skeleton'
import NoResultMessage from '../../uicomponents/NoResultMessage'

export const AlertsDrawer = ({ map }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    handleAlertClick,
    handleBack
  } = useAlerts()

  const alertsAndNews = useSelector(state => state?.ui?.components?.alertsAndNews)
  const alerts = useSelector(state => state?.ui?.alerts)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (pathname === '/news') {
      dispatch(setOpenCountries(false))
      dispatch(enabledAlertsAndNewsComponent({ enabled: true }))
    }
  }, [])

  const handleOpen = () => {
    dispatch(enabledAlertsAndNewsComponent({ enabled: true }))
  }

  useEffect(() => {
    if (alerts) {
      setLoading(false)
    }
  }, [alerts])

  return (
    <div>
      {loading && <LoadingLinear />}
      <SwipeableDrawer
        disableDiscovery
        disableSwipeToOpen
        anchor='bottom'
        open={alertsAndNews?.enabled}
        onClose={handleBack}
        onOpen={handleOpen}
        sx={{
          zIndex: '402 !important'
        }}
        PaperProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: navigator.userAgent.includes('UalabeeLite=true') || window.location.search.includes('UalabeeLite=true') ? '13px 16px 16px 16px' : '13px 16px 56px 16px',
            borderRadius: '10px 10px 0 0',
            boxSizing: 'border-box',
            maxHeight: '85vh',
            height: '85vh'
          }
        }}
      >
        <SwipeableBar />
        <TitleDrawer title={t('news.title')} />
        {alerts && alerts?.length > 0 &&
          <AlertsListContainer>
            {alerts?.map((item) => {
              return (
                <ImageCard
                  map={map}
                  onClickFunction={handleAlertClick}
                  key={item?.id}
                  title={item.title}
                  backgroundImg={item.pictureUrl}
                  iconImage={item.icon}
                  validate={item.validate}
                  item={item}
                />
              )
            })}
          </AlertsListContainer>}
        {loading &&
          <AlertsListContainer>
            {[1, 2, 3, 4, 5]?.map((item) => {
              return (
                <AlertsSkeleton key={item} />
              )
            })}
          </AlertsListContainer>}
        {!loading && (!alerts || alerts.length === 0) &&
          <NoResultMessage message={t('news.no_results')} />}
      </SwipeableDrawer>
    </div>
  )
}
