import React, { useEffect, useState } from 'react'
import { InfoContainer, InfoImageContainer, ScheduleContainer, ScheduleText, ScheduleTitle } from './styles'
import { Address, AddressContainer, Description, IconImg, Title, TitleContainer } from '../../Alert/styles'
import testMarkerIcon from '../../../img/covid/testing/Testeo_icon 2.png'
import vaccinationMarkerIcon from '../../../img/covid/vaccination/Vacunación_icon.png'
import testBanner from '../../../img/covid/testing/Testeo.png'
import vaccinationBanner from '../../../img/covid/vaccination/Vacunación.png'
import {
  DrawerTitle,
  OptionContainer,
  OptionIcon,
  OptionName,
  OptionNameContainer,
  SwipeableBar
} from '../../Search/styles'
import { setCurrentCityConfig } from '../../../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { setBounds, setCenter, setZoom } from '../../../actions/map'
import { setLocalCurrentZoom } from '../../../db/setLocalCurrentZoom'
import { setLocalCurrentCenter } from '../../../db/setLocalCurrentCenter'
import { logEvent } from '../../../firebase/firebase-config'
import { setLocalCityConfig } from '../../../db/setLocalCityConfig'
import { getCityConfig } from '../../../db/getCityConfig'
import { getVaccinationLocations } from '../../../db/getVaccinationLocations'
import NavBar from '../../NavBar'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../../uicomponents/layouts/Layout'
import { CustomMarker, MapContainer } from '../../../uicomponents/Map'
import { Chip, Dialog, IconButton, SwipeableDrawer, Switch, useMediaQuery } from '@mui/material'
import { CloseRounded, FilterList, LocationOnRounded } from '@mui/icons-material'
import { theme } from '../../../theme/theme'
import { useMap } from 'react-map-gl'
import useQuery from '../../../hooks/useQuery'
import { Loading } from '../../../uicomponents/Loading'
import maplibregl from 'maplibre-gl'
import { CityChange } from '../../Desktop/CityChange'
import CountriesDrawer from '../../CountriesDrawer'
import CitiesDrawer from '../../CitiesDrawer'
import { REACT_APP_IS_IFRAME } from '../../../constants/config'

const CovidCenters = () => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const zoom = useSelector(state => state?.map.zoom)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const user = useSelector(state => state?.user?.auth)

  const [clinicCenter, setClinicCenter] = useState()
  const [response, setResponse] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [currentClinic, setCurrentClinic] = useState()
  const [schedules, setSchedules] = useState()
  const [dayFrom, setDayFrom] = useState()
  const [dayTo, setDayTo] = useState()
  const [otherDays, setOtherDays] = useState([])
  const [consecutiveDays, setConsecutiveDays] = useState([])
  const [consecutive, setConsecutive] = useState([])
  const [openFilters, setOpenFilters] = useState(false)
  const [testingCenters, setTestingCenters] = useState(true)
  const [vaccinationCenters, setVaccinationCenters] = useState(true)

  const mobile = useMediaQuery('(max-width:480px)')
  const query = useQuery()
  const dispatch = useDispatch()
  const { map } = useMap()

  const id = Number(query.get('city_id'))

  useEffect(() => {
    if (!user || !map) return

    if (query.has('city_id')) {
      getCityConfig(id)
        .then((cityConfig) => {
          setLocalCityConfig(cityConfig)
          setLocalCurrentCenter([Number(cityConfig?.map?.map_center_lat), Number(cityConfig?.map?.map_center_lng)])
          setLocalCurrentZoom(cityConfig?.map?.map_zoom_level)

          const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
          const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)
          const bounds = new maplibregl.LngLatBounds(sw, ne)
          dispatch(setBounds(bounds))

          map.fitBounds(bounds, {
            padding: {
              top: mobile ? 16 : 96,
              left: mobile ? 16 : 486,
              right: 16,
              bottom: 16
            }
          })

          dispatch(setCurrentCityConfig(response))
        })
    }
  }, [user, map])

  useEffect(() => {
    if (response) {
      setClinicCenter(response)
    }
  }, [response])

  useEffect(() => {
    if (cityConfig && !response) {
      getVaccinationLocations({ city_id: cityConfig?.city_id })
        .then((response) => {
          setResponse(response.result)
        })

      // set data in Redux
      dispatch(setZoom(cityConfig?.map?.map_zoom_level))
      dispatch(setCenter([Number(cityConfig.map.map_center_lat), Number(cityConfig.map.map_center_lng)]))

      map?.flyTo({
        center: [cityConfig.map.map_center_lng, cityConfig.map.map_center_lat],
        zoom: cityConfig.map.map_zoom_level,
        speed: 0.7,
        padding: 16
      })
    }
  }, [cityConfig])

  const handleClickClinic = (clinic) => {
    const eventParams = {
      unique_id: clinic.unique_id,
      name: clinic.name,
      category_id: clinic.category_id,
      latitude: clinic.latitude,
      longitude: clinic.longitude,
      city_id: clinic.city_id.toString()
    }

    logEvent('vaccination_center_marker_click', eventParams)

    const clinicSchedules = [
      {
        id: 1,
        day_name: 'Lunes',
        schedule: clinic?.monday
      },
      {
        id: 2,
        day_name: 'Martes',
        schedule: clinic?.tuesday
      },
      {
        id: 3,
        day_name: 'Miércoles',
        schedule: clinic?.wednesday
      },
      {
        id: 4,
        day_name: 'Jueves',
        schedule: clinic?.thursday
      },
      {
        id: 5,
        day_name: 'Viernes',
        schedule: clinic?.friday
      },
      {
        id: 6,
        day_name: 'Sábado',
        schedule: clinic?.saturday
      },
      {
        id: 7,
        day_name: 'Domingo',
        schedule: clinic?.sunday
      }
    ]

    setSchedules(clinicSchedules.filter(schedule => schedule?.schedule !== 'Closed' && schedule?.schedule !== 'closed' && schedule?.schedule !== '-'))
    setCurrentClinic(clinic)
    setOpenModal(true)
  }

  useEffect(() => {
    if (schedules?.length > 0) {
      setDayFrom(schedules.filter(day => day?.schedule !== undefined && day?.schedule !== 'Closed' && day?.schedule !== '-')[0])

      if (dayFrom) {
        schedules?.map(day => {
          if (dayFrom.schedule === day.schedule) {
            setConsecutiveDays(consecutiveDays => [...consecutiveDays, day])
          } else if (dayFrom.day_name !== day.day_name) {
            const exist = consecutiveDays.filter(item => {
              if (item.id === day.id) {
                return true
              }
            })

            if (exist.length === 0) {
              setOtherDays(otherDays => [...otherDays, day])
            }
          }
        })
      }
    }
  }, [schedules, dayFrom])

  useEffect(() => {
    if (consecutiveDays) {
      setDayTo(consecutiveDays[consecutiveDays.length - 1])
    }
  }, [consecutiveDays])

  const handleCloseModal = () => {
    setOpenModal(false)
    setDayTo(undefined)
    setDayFrom(undefined)
    setSchedules([])
    setOtherDays([])
    setConsecutiveDays([])
  }

  const handleClickMoreInformation = (currentClinic) => {
    const eventParams = {
      unique_id: currentClinic.unique_id,
      name: currentClinic.name,
      category_id: currentClinic.category_id,
      latitude: currentClinic.latitude,
      longitude: currentClinic.longitude,
      city_id: currentClinic.city_id.toString()
    }
    logEvent('vaccination_center_marker_cta_click', eventParams)
    window.open(currentClinic?.website, mobile ? '_self' : '_blank')
  }

  useEffect(() => {
    let firstDayId = consecutiveDays[0]?.id

    setConsecutive(
      consecutiveDays?.filter(day => {
        if (day?.id === firstDayId) {
          firstDayId = firstDayId + 1
        }

        if (Number(firstDayId) === Number(day?.id + 1)) {
          return day
        }
      })
    )
  }, [consecutiveDays])

  useEffect(() => {
    if (consecutive.length === 1) {
      setDayTo(undefined)
    }
  }, [consecutive])

  const handleOpenFilters = () => {
    setTimeout(() => {
      setOpenFilters(true)
    }, 500)
  }

  return (
    <Layout>

      {user
        ? (
          <>
            <MapContainer
              showMapDevices
              showUserPosition
              attributionPosition='bottom-right'
              geoControlProps={{
                styles: {
                  bottom: mobile ? '130px' : '176px'
                }
              }}
            >

              {clinicCenter?.map((item, index) => {
                let size

                if (zoom > 16) {
                  size = 25
                } else if (zoom <= 15 && zoom >= 13) {
                  size = 20
                } else {
                  size = 15
                }

                return (
                  <CustomMarker
                    key={index}
                    lat={item.latitude}
                    lng={item.longitude}
                    onClick={() => handleClickClinic(item)}
                    icon={
                      <div>

                        {zoom >= 13
                          ? (
                            <img
                              style={{ width: size, height: size }}
                              src={
                                item?.category_id === 2
                                  ? testMarkerIcon
                                  : vaccinationMarkerIcon
                              }
                              alt='refill'
                            />
                            )
                          : (
                            <div
                              style={{
                                width: size,
                                height: size,
                                backgroundColor: item?.category_id === 2 ? '#a91f5b' : '#4d759c',
                                borderRadius: '100%',
                                border: '1px solid #fff'
                              }}
                            />

                            )}
                      </div>
                    }
                  />
                )
              })}
            </MapContainer>

            <IconButton
              sx={{
                display: 'none',
                right: '16px',
                width: '56px',
                bottom: '75px',
                height: '56px',
                zIndex: 500,
                position: 'absolute',
                boxShadow: '0px 4px 8px rgb(65 115 205 / 20%)',
                backgroundColor: '#fff'
              }}
              onClick={handleOpenFilters}
            >
              <FilterList
                sx={{
                  color: theme.palette.primary.main
                }}
              />
            </IconButton>

            {isIosApp && !REACT_APP_IS_IFRAME &&
              <NavBar />}

            <MapFilters
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              setVaccinationCenters={setVaccinationCenters}
              setTestingCenters={setTestingCenters}
              testingCenters={testingCenters}
              vaccinationCenters={vaccinationCenters}
            />

            <CenterSelected
              handleClickMoreInformation={handleClickMoreInformation}
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              setOpenModal={setOpenModal}
              currentClinic={currentClinic}
              dayFrom={dayFrom}
              dayTo={dayTo}
              consecutiveDays={consecutiveDays}
              otherDays={otherDays}
            />
          </>
          )
        : (
          <Loading />
          )}

      {mobile
        ? (
          <>
            <CountriesDrawer map={map} />
            <CitiesDrawer />
          </>
          )
        : (<CityChange />)}
    </Layout>
  )
}

const CenterSelected = ({ openModal, handleCloseModal, setOpenModal, ...props }) => {
  const mobile = useMediaQuery('(max-width:480px)')

  return (
    <>
      {mobile
        ? (
          <SwipeableDrawer
            anchor='bottom'
            open={openModal}
            disableDiscovery
            onClose={() => handleCloseModal()}
            onOpen={() => setOpenModal(true)}
            sx={{
              '& .MuiDrawer-paperAnchorBottom': {
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px'
              }
            }}
          >
            <CenterInfo handleCloseModal={handleCloseModal} {...props} />
          </SwipeableDrawer>
          )
        : (
          <Dialog
            onClose={() => handleCloseModal()}
            open={openModal}
            PaperProps={{
              style: {
                backgroundColor: '#ffffff',
                outline: 'none !important',
                padding: '24',
                boxShadow: '0px 0px 24px #22283126',
                borderRadius: '16px',
                minWidth: '500px'
              }
            }}
          >
            <CenterInfo handleCloseModal={handleCloseModal} {...props} />
          </Dialog>
          )}
    </>

  )
}

const CenterInfo = ({
  currentClinic,
  dayFrom,
  dayTo,
  consecutiveDays,
  otherDays,
  handleClickMoreInformation,
  handleCloseModal
}) => {
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')

  return (
    <>
      <InfoImageContainer
        backgroundImg={currentClinic?.category_id === 1 ? vaccinationBanner : testBanner}
      >
        {!mobile &&
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute', right: 0, top: 0
            }}
          >
            <CloseRounded sx={{ color: '#fff' }} />
          </IconButton>}
        <AddressContainer>
          <LocationOnRounded
            sx={{ color: '#fff', marginRight: '6px' }}
          />
          <Address>
            {currentClinic?.street_name}, {currentClinic?.street_number && currentClinic?.street_number !== 'No number' && currentClinic?.street_number + '.'} {currentClinic?.state_province}
          </Address>
        </AddressContainer>
      </InfoImageContainer>
      <InfoContainer>
        <TitleContainer>
          <IconImg
            src={currentClinic?.category_id === 1 ? vaccinationMarkerIcon : testMarkerIcon}
          />
          <Title>
            {currentClinic?.category}
          </Title>
        </TitleContainer>
        <Description>
          {currentClinic?.name}
        </Description>
        <ScheduleContainer>
          <ScheduleTitle>
            {t('covid.attention_schedule')}
          </ScheduleTitle>
          {dayFrom && dayTo
            ? <ScheduleText>{dayFrom?.day_name} a {dayTo.day_name} de {dayTo.schedule}</ScheduleText>
            : consecutiveDays.length > 0 &&
              <ScheduleText>{`${consecutiveDays.map(day => day.day_name).join(', ')} de ${consecutiveDays.map(day => day.schedule)[0]}`} </ScheduleText>}

          {otherDays?.length > 0 &&
            otherDays?.map((day, index) => {
              return (
                <ScheduleText
                  key={index}
                >{day.day_name} de {day.schedule} </ScheduleText>
              )
            })}

        </ScheduleContainer>
        <Chip
          sx={{
            width: '100%',
            fontSize: '18px',
            borderRadius: '8px',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontWeight: 400,
            marginTop: '15px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            }
          }}
          clickable
          label={t('covid.more_information')}
          onClick={() => handleClickMoreInformation(currentClinic)}
        />
      </InfoContainer>
    </>
  )
}

const MapFilters = ({
  openFilters,
  setOpenFilters,
  setVaccinationCenters,
  setTestingCenters,
  testingCenters,
  vaccinationCenters
}) => {
  return (
    <SwipeableDrawer
      anchor='bottom'
      open={openFilters}
      onClose={() => setOpenFilters(false)}
      onOpen={() => setOpenFilters(true)}
      sx={{
        '& .MuiDrawer-paperAnchorBottom': {
          borderTopRightRadius: '10px !important',
          borderTopLeftRadius: '10px !important',
          display: 'flex !important',
          alignItems: 'center !important',
          justifyContent: 'center !important',
          padding: '10px !important'
        }
      }}
    >
      <SwipeableBar />
      <DrawerTitle>¿Qué quieres ver en el mapa?</DrawerTitle>
      <OptionContainer>
        <OptionNameContainer>
          <OptionIcon src={testMarkerIcon} />
          <OptionName>Centros de testeo</OptionName>
        </OptionNameContainer>
        <Switch
          color='primary'
          onChange={(e) => setTestingCenters(e.target.checked)}
          checked={testingCenters}
        />
      </OptionContainer>
      <OptionContainer>
        <OptionNameContainer>
          <OptionIcon src={vaccinationMarkerIcon} />
          <OptionName>Centros de vacunación</OptionName>
        </OptionNameContainer>
        <Switch
          color='primary'
          onChange={(e) => setVaccinationCenters(e.target.checked)}
          checked={vaccinationCenters}
        />
      </OptionContainer>
    </SwipeableDrawer>
  )
}

export default CovidCenters
