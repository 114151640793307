import styled from 'styled-components/macro'

export const AlertsListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
`
