import React, { useEffect, useState } from 'react'
import {
  ActionButtonsContainer,
  CloseButton,
  CloseIcon,
  Content,
  ContentButton,
  ContentContainer,
  CreationDate,
  Image,
  Title,
  TitleContainer,
  UserName
} from './styles'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setOpenAlertInformation } from '../../../../actions/ui'
import ShareSmallButton from '../../../ShareSmallButton'
import { Loading } from '../../../Loading'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../../theme/theme'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DirectionsBus, LocationOnRounded } from '@mui/icons-material'
import { REACT_APP_HOST } from '../../../../constants/config'

export const AlertInformation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const alertsAndNews = useSelector(state => state?.ui?.components?.alertsAndNews)
  const alertInformation = useSelector(state => state?.ui?.components?.alertInformation)
  const [currentItem, setCurrentItem] = useState()
  const [eventParams, setEventParams] = useState()
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const handleClose = () => {
    dispatch(setOpenAlertInformation(false))

    if (alertsAndNews?.enabled) {
      navigate('/news')
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    setCurrentItem(alertsAndNews?.current)
  }, [alertsAndNews])

  useEffect(() => {
    if (currentItem?.isAlert) {
      setEventParams({
        user_id: auth?.uid,
        os: 'web',
        user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
        user_gender: userData ? userData?.gender : null,
        user_lat: userPosition ? userPosition.lat : null,
        user_lng: userPosition ? userPosition.lng : null,
        city_id: cityConfig?.city_id,
        report_id: currentItem?.id,
        report_status_id: currentItem?.reportStatusId,
        report_status_name: currentItem?.reportStatusName,
        report_status_reason: currentItem?.reportStatusReason,
        report_address: currentItem?.address,
        report_duration: currentItem?.reportDuration,
        report_type: currentItem?.reportType,
        report_user_nickname: currentItem?.userNickname,
        report_creation_time: currentItem?.reportCreationTime,
        report_lat: currentItem.lat,
        report_lng: currentItem.lng
      })
    } else {
      setEventParams({
        user_id: auth?.uid,
        os: 'web',
        user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
        user_gender: userData ? userData?.gender : null,
        user_lat: userPosition ? userPosition.lat : null,
        user_lng: userPosition ? userPosition.lng : null,
        city_id: cityConfig?.city_id,
        alert_id: currentItem?.id,
        alert_type_id: currentItem?.alertTypeId
      })
    }
  }, [currentItem])

  return (
    <Dialog
      open={alertInformation}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          borderRadius: '16px',
          '& .MuiDialogTitle-root': {
            padding: '16px 12px 16px 24px',
            borderBottom: '1px solid #E8EDEE'
          }
        }
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle>
        <TitleContainer>
          {currentItem?.isAlert
            ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt='' style={{ width: 36, height: 36 }} src={currentItem?.icon} />
                <Title style={{ marginLeft: 20 }}>{currentItem?.typeName || currentItem?.reportType}</Title>
              </div>
              )
            : (<Title>{currentItem?.title}</Title>
              )}

          <ActionButtonsContainer>

            <ShareSmallButton
              eventName={currentItem?.isAlert ? 'share_user_report' : 'alert_share_clicked'}
              eventParams={eventParams}
              params={
                {
                  link: `${REACT_APP_HOST}/${currentItem?.isAlert ? 'user_reports' : 'news'}/${currentItem?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=${currentItem?.isAlert ? 'share_user_report' : 'alert_share_clicked'}`,
                  title: `${currentItem?.isAlert ? `Reporte de ${currentItem?.typeName} - ${currentItem?.address}}` : `Alerta de ${currentItem?.typeName}`}`,
                  description: `${currentItem?.isAlert ? `Reportado por ${currentItem?.userNickname}` : currentItem?.content}`,
                  picture: `${currentItem?.isAlert ? currentItem?.icon : currentItem?.pictureUrl}`
                }
}
              shareText={t('share.title')}
              shareType='share'
            />
            <ShareSmallButton
              eventName='send_to_phone'
              eventParams={{ ...eventParams, from: currentItem?.isAlert ? 'alert' : 'news' }}
              params={
                {
                  link: `${REACT_APP_HOST}/${currentItem?.isAlert ? 'user_reports' : 'news'}/${currentItem?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=${currentItem?.isAlert ? 'share_user_report' : 'alert_share_clicked'}`,
                  title: `${currentItem?.isAlert ? `Reporte de ${currentItem?.typeName} - ${currentItem?.address}}` : `Alerta de ${currentItem?.typeName}`}`,
                  description: `${currentItem?.isAlert ? `Reportado por ${currentItem?.userNickname}` : currentItem?.content}`,
                  picture: `${currentItem?.isAlert ? currentItem?.icon : currentItem?.pictureUrl}`
                }
}
              notificationBody={{
                title: `${currentItem?.isAlert ? `Reporte de ${currentItem?.typeName} - ${currentItem?.address}}` : `Alerta de ${currentItem?.typeName}`}`,
                content: `${currentItem?.isAlert ? `Reportado por ${currentItem?.userNickname}` : currentItem?.content}`,
                icon: `${currentItem?.isAlert ? currentItem?.icon : currentItem?.pictureUrl}`
              }}
              shareText={t('send_to_mobile.title')}
              shareType='send-to-mobile'
            />
            <CloseButton
              onClick={handleClose}
            >
              <CloseIcon />
            </CloseButton>
          </ActionButtonsContainer>
        </TitleContainer>

      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {alertsAndNews?.current
            ? (
              <ContentContainer>
                <Image src={currentItem?.pictureUrl} />
                <div style={{ marginLeft: 22, width: '88%' }}>
                  <Content>
                    {currentItem?.isAlert ? currentItem?.title : currentItem?.content}
                  </Content>
                  {currentItem?.date && !currentItem?.isAlert &&
                    <CreationDate>
                      {t('news.creation_date', { prop1: moment(currentItem?.date).locale(i18n.language).fromNow() })}
                      <UserName>{currentItem?.userNickname}</UserName>
                    </CreationDate>}

                  {currentItem?.date && currentItem?.isAlert &&
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      marginTop: 5
                    }}
                    >

                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                      >  <LocationOnRounded
                        style={{
                          color: '#A6A9AC',
                          marginRight: '6px',
                          height: 18,
                          width: 18
                        }}
                         />
                        <p style={{
                          fontSize: 14,
                          margin: 0
                        }}
                        >{currentItem?.address}</p>
                      </div>
                      <CreationDate>
                        {t('news.creation_date', { prop1: moment(currentItem?.date).locale(i18n.language).fromNow() })}
                        <UserName>{currentItem?.userNickname}</UserName>
                      </CreationDate>
                    </div>}
                  {currentItem?.link?.length > 0 && !currentItem.isAlert &&
                    <ContentButton
                      variant='outlined'
                      sx={{
                        borderRadius: '10px',
                        fontWeight: 400,
                        marginTop: '14px'
                      }}
                      onClick={() => window.open(currentItem?.link, '_blank')}
                    >
                      {t('news.news.see_new')}
                    </ContentButton>}
                  {currentItem?.isAlert && currentItem?.affectedLines?.length > 0 &&
                    <div>
                      <h2 style={{
                        fontSize: 14,
                        color: '#222831',
                        fontWeight: 400
                      }}
                      >{t('news.alerts.possible_affected_routes')}</h2>
                      <div style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                      >

                        {currentItem?.affectedLines.map((line) => {
                          return (
                            <div
                              key={line?.name} style={{
                                widht: 65,
                                border: '1px solid',
                                marginRight: 8,
                                marginBottom: 8,
                                borderLeft: `4px solid ${theme.palette.primary.main}`,
                                borderRadius: 4,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >  <DirectionsBus style={{ color: line.color }} />
                              <p style={{
                                color: '#222831',
                                margin: 0,
                                padding: 2,
                                fontSize: 14
                              }}
                              >{line?.name}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>}
                </div>
              </ContentContainer>
              )
            : (<div style={{ width: 250, height: 130 }}>
              <Loading />
            </div>

              )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
