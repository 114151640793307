import React, { useState } from 'react'
import { Button, DialogTitle, DialogContent, Modal, TextField } from '@mui/material'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import { useTranslation } from 'react-i18next'
import { REACT_APP_NPS_SURVEY } from '../../constants/config'
import { useDispatch, useSelector } from 'react-redux'
import { setNPSSurveyShown } from '../../actions/ui'
import { logEvent } from '../../firebase/firebase-config'
import SuccessFeedbackModal from '../SuccessFeedbackModal'

export default function NPSDialog() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [scoreSelected, setScoreSelected] = useState(null)
  const [comment, setComment] = useState('')
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const MAX_COMMENT_LENGTH = 100

  const searchResultClickCount = useSelector(state => state?.ui?.searchResultClickCount)
  const NPSSurveyShown = useSelector(state => state?.ui?.NPSSurveyShown)
  const { auth, userPosition, userData } = useSelector(state => state?.user)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const handleClose = () => {
    dispatch(setNPSSurveyShown(true))
  }

  const handleSendNPS = () => {
    const eventParams = {
      user_id: auth?.uid,
      lat: userPosition?.lat,
      lng: userPosition?.lng,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_gender: userData?.gender?.toString() || null,
      city_id: cityConfig?.city_id?.toString(),
      nps_score: scoreSelected,
      nps_feedback: comment || null
    }

    logEvent('nps_survey_answer', eventParams)

    handleClose()

    setSuccessModalOpen(true)
  }

  return (
    REACT_APP_NPS_SURVEY &&
      <>
        <Modal
          open={searchResultClickCount > 2 && !NPSSurveyShown}
          onClose={handleClose}
        >
          <div
            style={{
              display: 'flex',
              position: 'relative',
              top: '50%',
              left: '50%',
              maxWidth: '400px',
              transform: 'translate(-50%, -50%)',
              gap: '10px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '15px',
              backgroundColor: '#ffffff',
              padding: '25px 20px 10px 20px',
              border: 'none',
              boxSizing: 'border-box',
              width: '90%'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                position: 'absolute',
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                top: 0,
                right: '50%',
                transform: 'translate(50%,-50%)'
              }}
            >
              <FavoriteRoundedIcon
                sx={{
                  fontSize: '30px',
                  color: '#da6c71'
                }}
              />
            </div>
            <DialogTitle
              sx={{
                padding: 0,
                margin: 0,
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: '600'
              }}
            >
              {t('nps_survey.title')}
            </DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                margin: 0,
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: '600',
                width: '100%',
                gap: '16px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '10px'
                }}
              >

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    margin: '0',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between'
                  }}
                >

                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <Button
                      key={value}
                      variant='contained'
                      color='primary'
                      onClick={() => setScoreSelected(value)}
                      sx={{
                        padding: 0,
                        // aspectRatio: '1/1',
                        width: '24px',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderRadius: '5px',
                        backgroundColor: scoreSelected !== value ? value > 8 ? '#0aac53' : value > 6 ? '#bc920a' : '#bc0608' : '#29b6f6',
                        color: '#ffffff',
                        minWidth: 'unset',
                        '&:hover': {
                          backgroundColor: scoreSelected !== value ? value > 8 ? '#0aac53' : value > 6 ? '#bc920a' : '#bc0608' : '#29b6f6'
                        }
                      }}
                    >
                      {value}
                    </Button>
                  ))}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    margin: '0',
                    boxSizing: 'border-box',
                    width: '100%'
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#000000',
                      margin: '0',
                      padding: '0'
                    }}
                  >
                    0: {t('nps_survey.low_label')}
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#000000',
                      margin: '0',
                      padding: '0'
                    }}
                  >
                    10: {t('nps_survey.high_label')}
                  </span>
                </div>
              </div>

              <TextField
                sx={{
                  width: '100%',
                  '& label.Mui-focused': {
                    color: '#E8E8E8'
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#E8E8E8'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#E8E8E8',
                      borderRadius: '15px'
                    },
                    '&:hover fieldset': {
                      borderColor: 'primary.main'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E8E8E8'
                    }
                  }
                }}
                id='filled-multiline-static'
                label=''
                placeholder={t('nps_survey.text_area_placeholder')}
                multiline
                fullWidth
                minRows={1}
                autoFocus
                variant='outlined'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{
                  maxLength: MAX_COMMENT_LENGTH
                }}
              />

              <Button
                sx={{
                  backgroundColor: 'primary.main',
                  color: '#fff',
                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: 'primary.main'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'secondary.light',
                    color: 'secondary.dark'
                  },
                  height: '45px'
                }}
                disabled={scoreSelected === null}
                onClick={handleSendNPS}
              >
                {t('nps_survey.button_text')}
              </Button>

            </DialogContent>
          </div>
        </Modal>
        <SuccessFeedbackModal
          setSuccessModalOpen={setSuccessModalOpen}
          successModalOpen={successModalOpen}
        />
      </>
  )
}
