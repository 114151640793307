import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useFilters } from '../../../hooks/useFilters'
import { FilterContainer, FilterOption, FilterOptionGroup, FilterTitle, OptionText } from '../../../GlobalStyles'

export const RouteTypes = () => {
  const { t } = useTranslation()

  const {
    handleChangeRadio
  } = useFilters()

  const mode = useSelector(state => state?.ui?.mode)
  const filters = useSelector(state => state?.user?.searchFilters)

  const routeOptions = [
    {
      value: 'best-route',
      name: t('search_preview.filters.items.transfers.best_route'),
      id: 'g1c08OjDL9'
    },
    {
      name: t('search_preview.filters.items.transfers.only_directs'),
      value: 'only-directs',
      id: 'sxI0AJcl0U'
    },
    {
      name: t('search_preview.filters.items.transfers.only_transfers'),
      value: 'only-transfers',
      id: 'g__Qt97dK5'
    }
  ]

  return (
    <FilterContainer
      mode={mode}
    >
      <FilterTitle
        mode={mode}
      >
        {t('search_preview.filters.items.type_route')}
      </FilterTitle>
      <FilterOptionGroup
        mode={mode}
      >
        <RadioGroup value={filters?.type_of_route} onChange={handleChangeRadio}>
          {routeOptions?.map(option => {
            return (
              <FilterOption key={option?.id}>
                <OptionText
                  mode={mode}
                >
                  {option?.name}
                </OptionText>
                <FormControlLabel
                  label=''
                  sx={{
                    margin: 0
                  }}
                  value={option?.value}
                  control={<Radio />}
                />
              </FilterOption>
            )
          })}
        </RadioGroup>
      </FilterOptionGroup>
    </FilterContainer>
  )
}
