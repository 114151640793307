import styled from 'styled-components/macro'

export const SwipeableContainerH = styled.div`
  overflow: auto;
  white-space: nowrap;
  width: 100vw;
  padding: ${props => props?.padding || '8px 16px'};
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  z-index: 401;
  top: 68px;
  gap: 8px;
`

export const PlaceIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  background-color: ${props => props.bgColor}40;
`

export const PlaceName = styled.h4`
  font-size: 14px;
  color: #000000;
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`

export const DesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 12px;
  row-gap: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  background-color: #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
`
