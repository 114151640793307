import React, { useEffect, useRef, useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import FilterDrawer from '../../uicomponents/FilterDrawer'
import {
  handleOpenAlert,
  handleOpenMenu,
  setFromPlacesSelected,
  setLoading,
  setSearch,
  setToPlacesSelected
} from '../../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getDistanceFromLatLonInKm, getPlacesIcon } from '../../utils'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import HelpMessage from '../../uicomponents/HelpMessage'
import SavedPlaces from '../SavedPlaces'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import { motion } from 'framer-motion'
import {
  City,
  Distance,
  InfoContainer,
  MapIcon,
  Name,
  PickButton,
  Result,
  ResultsContainer,
  TypeContainer
} from './styles'
import {
  Icon,
  IconContainer,
  PlaceCity,
  PlaceInformation,
  PlaceName,
  Searcher,
  WaterMark
} from '../Desktop/Search/styles'
import { getLocalRecentPlaces } from '../../db/getLocalRecentPlaces'
import { setLocalRecentPlaces } from '../../db/setLocalRecentPlaces'
import { logEvent, remoteConfig } from '../../firebase/firebase-config'
import NavBar from '../NavBar'
import { useTripDirections } from '../../hooks/useTripDirections'
import { useTranslation } from 'react-i18next'
import { setPointsOfInterest } from '../../db/setPointsOfInterest'
import { Layout } from '../../uicomponents/layouts/Layout'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { getPlaces } from '../../db/getPlaces'
import { setBounds } from '../../actions/map'
import { Badge, Box, Button, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { ArrowBackRounded, CloseRounded, HistoryRounded, MenuRounded, PlaceRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'
import { Logo } from '../../uicomponents/desktop/search/SearchNavbar/styles'
import logoUalabee from '../../img/ualabeelogo.svg'
import { CityChange } from '../Desktop/CityChange'
import CountriesDrawer from '../CountriesDrawer'
import CitiesDrawer from '../CitiesDrawer'
import { IdentifiedCityModal } from '../IdentifiedCityModal'
import { Loading as LoadingLinear } from '../../uicomponents/mobile/Loading'
import maplibregl from 'maplibre-gl'
import { CustomMap } from '../../uicomponents/Map'
import { useMap } from 'react-map-gl'
import { getLocalCityConfig } from '../../db/getLocalCityConfig'
import {
  REACT_APP_SHOW_MENU,
  REACT_APP_IS_IFRAME,
  REACT_APP_LOGO_EXTERNAL_LINK,
  REACT_APP_SHOW_NAVBAR,
  REACT_APP_IFRAME_LOGO,
  REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
  REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON,
  REACT_APP_HARDCODED_LOGO
} from '../../constants/config'

const SearchAutocomplete = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { map } = useMap()

  const autoCompleteRef = useRef()
  const inputRef = useRef()

  const mobile = useMediaQuery('(max-width:480px)')

  const { handleLocationClick } = useTripDirections()

  const dispatch = useDispatch()
  const savedPlaces = useSelector(state => state?.user?.savedPlaces)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const bounds = useSelector(state => state?.map?.bounds)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const showMenuNotification = useSelector(state => state?.ui?.components?.showMenuNotification)
  const term = useSelector(state => state?.ui?.search?.term)
  const orientation = useSelector(state => state?.ui.orientation)
  const loading = useSelector(state => state?.ui?.loading)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const autoCompleteStations = useSelector(state => state?.ui?.autoCompleteStations)

  const [recentPlaces, setRecentPlaces] = useState([])
  const [stationsResults, setStationsResults] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [sessionToken, setSessionToken] = useState(null)
  const [provider, setProvider] = useState({
    id: 'photon_ualabee',
    limit: 4
  })

  useEffect(() => {
    setSessionToken(null)

    fetchAndActivate(remoteConfig)
      .then(() => {
        const id = getValue(remoteConfig, 'places_autocomplete_provider')

        const providers = getValue(remoteConfig, 'places_autocomplete_provider_limit')

        const currentProvider = JSON.parse(providers?._value)?.find(prov => prov?.id === id?._value)

        if (currentProvider) {
          setProvider({ ...currentProvider })

          const localCityConfig = getLocalCityConfig()

          if (localCityConfig) {
            dispatch(setBounds(bounds))
          }
        }
      })
      .catch((e) => {
        console.error(e)

        try {
          const id = getValue(remoteConfig, 'places_autocomplete_provider')
          const providers = getValue(remoteConfig, 'places_autocomplete_provider_limit')

          const currentProvider = JSON.parse(providers._value)?.find(prov => prov.id === id._value)

          if (currentProvider) {
            setProvider({ ...currentProvider })
          }
        } catch (error) {
          console.error(error)
        }
      })
  }, [])

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  useEffect(() => {
    if (!from?.latLng || !to?.latLng || !map) return

    const p1 = new maplibregl.LngLat(from.latLng.lng, from.latLng.lat)
    const p2 = new maplibregl.LngLat(to.latLng.lng, to.latLng.lat)

    const bounds = new maplibregl.LngLatBounds(p1, p2)

    map?.fitBounds(bounds, {
      padding: {
        top: 80,
        left: mobile ? 16 : 482,
        right: 80,
        bottom: 80
      }
    })
  }, [from, to])

  const handlePlaceClick = async (result) => {
    const localRecentPlaces = JSON.parse(localStorage.getItem('recentPlaces') || '[]')

    if (localRecentPlaces?.some(localResult => localResult?.id === result?.id && localResult?.latLng?.lat && localResult?.latLng?.lng)) {
      const localResult = localRecentPlaces.find(item => item.id === result.id)

      await setPlace(localResult)
    } else if (provider?.id === 'google_places_autocomplete' && result?.providerName === 'google_places_autocomplete') {
      const request = {
        placeId: result.id,
        fields: ['geometry', 'place_id'],
        language: 'es-419',
        sessionToken
      }

      const placeService = new window.google.maps.places.PlacesService(document.createElement('div'))

      dispatch(setLoading(true))

      await placeService?.getDetails(request, callback)
    } else {
      const formattedPlace = {
        ...result,
        name: result?.name || result?.properties?.name || result?.title || `${result.properties?.street} ${result.properties?.housenumber}`,
        latLng: {
          lat: result?.lat || result?.geometry?.coordinates?.length >= 1 ? result.geometry.coordinates[0] : result?.position?.length >= 1 ? result.position[0] : 0,
          lng: result?.lng || result?.geometry?.coordinates?.length >= 1 ? result.geometry.coordinates[1] : result?.position?.length >= 1 ? result.position[1] : 0
        },
        id: result?.properties?.osm_id || result?.id,
        city_id: cityConfig?.city_id,
        categoryId: result?.categoryId || result?.properties?.osm_value || result?.category
      }

      await setPlace(formattedPlace)
    }
  }

  const setPlace = async (place) => {
    const localPlace = {
      name: place?.name || place?.properties?.name || place?.title || `${place.properties?.street} ${place.properties?.housenumber}`,
      latLng: {
        lat: place?.lat || place?.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[0] : place?.position?.length >= 1 ? place.position[0] : 0,
        lng: place?.lng || place?.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[1] : place?.position?.length >= 1 ? place.position[1] : 0
      },
      id: place?.properties?.osm_id || place?.id,
      city_id: cityConfig?.city_id,
      categoryId: place?.categoryId || place?.properties?.osm_value || place?.category,
      geometry: place?.geometry || {
        coordinates: [
          place?.lat || place?.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[0] : place?.position?.length >= 1 ? place.position[0] : 0,
          place?.lng || place?.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[1] : place?.position?.length >= 1 ? place.position[1] : 0
        ]
      },
      properties: {
        city: place?.properties?.city || cityConfig?.name,
        country: place?.properties?.country || cityConfig?.country?.name,
        countrycode: place?.properties?.countrycode || '',
        housenumber: place?.properties?.housenumber || '',
        name: place?.properties?.name || null,
        osm_id: place?.properties?.osm_id || place?.id || null,
        osm_key: place?.properties?.osm_key || null,
        osm_type: place?.properties?.osm_type || null,
        osm_value: place?.properties?.osm_value || null,
        postcode: place?.properties?.postcode || null,
        state: place?.properties?.state || cityConfig?.name || null,
        street: place?.properties?.street || place?.name || place?.properties?.name || place?.title || `${place.properties?.street} ${place.properties?.housenumber}` || null
      },
      providerName: place?.providerName || provider?.id,
      distanceToUser: place?.distanceToUser || null
    }

    const pointOfInterest = {
      city_id: cityConfig?.city_id,
      point_of_interest: {
        name: place?.name,
        provider_id: place?.id?.toString(),
        provider_name: place?.providerName || provider?.id,
        category: place?.categoryId || null,
        latitude: place?.latLng?.lat,
        longitude: place?.latLng?.lng
      }
    }

    const localRecentPlaces = JSON.parse(localStorage.getItem('recentPlaces') || '[]')
    // save recent place in local storage if it is not already saved
    if (!localRecentPlaces?.some(recentPlace => recentPlace.id === localPlace.id)) {
      setLocalRecentPlaces([...localRecentPlaces, localPlace])
      setRecentPlaces([...localRecentPlaces, localPlace])
    }

    if (orientation === 'to') {
      dispatch(setToPlacesSelected({
        name: place?.name || place?.title || place?.properties?.name || `${place?.properties?.street || ''} ${place?.properties?.housenumber || ''}`,
        latLng: {
          lat: place.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[0] : place?.position?.length >= 1 ? place.position[0] : 0,
          lng: place.geometry?.coordinates?.length >= 1 ? place.geometry.coordinates[1] : place?.position?.length >= 1 ? place.position[1] : 0
        }
      }))
    } else {
      dispatch(setFromPlacesSelected({
        name: place?.name || place?.title || place?.properties?.name || `${place?.propertie?.street || ''} ${place?.properties?.housenumber || ''}`,
        latLng: {
          lat: place.geometry ? place.geometry.coordinates[0] : place?.position?.length >= 1 ? place.position[0] : 0,
          lng: place.geometry ? place.geometry.coordinates[1] : place?.position?.length >= 1 ? place.position[1] : 0
        }
      }))
    }

    setPointsOfInterest(pointOfInterest)
      .catch((e) => {
        console.error('Error setting point of interest', e)
      })

    navigate('/destination-preview')
  }

  function callback(place, status) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      const currentPlace = searchResults?.find(result => result?.id === place?.place_id || result?.id === place?.id)

      if (!place.geometry?.location?.lat() || !place.geometry?.location?.lng() || !bounds?.contains(new maplibregl.LngLat(place.geometry.location.lng(), place.geometry.location.lat()))) {
        dispatch(setLoading(false))

        dispatch(handleOpenAlert({
          title: '❌ ¡Ups! Este lugar está demasiado lejos',
          severity: 'error'
        }))

        setSessionToken(null)

        return
      } else if (!currentPlace) {
        dispatch(setLoading(false))

        dispatch(handleOpenAlert({
          title: 'Ha ocurrido un error',
          severity: 'error'
        }))

        return
      }

      setPlace({
        ...currentPlace,
        geometry: {
          coordinates: [place.geometry.location.lat(), place.geometry.location.lng()],
          type: 'Point'
        },
        latLng: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        },
        id: place.place_id,
        city_id: cityConfig?.city_id
      })
        .then(() => {
          dispatch(setLoading(false))
        })
    } else {
      dispatch(setLoading(false))

      switch (status) {
        case 'INVALID_REQUEST':
          dispatch(handleOpenAlert({
            title: 'Solicitud no válida.',
            severity: 'error'
          }))
          return
        case 'NOT_FOUND':
          dispatch(handleOpenAlert({
            title: 'No se encontró el lugar al que se hace referencia.',
            severity: 'error'
          }))
          return
        case 'UNKNOWN_ERROR':
          dispatch(handleOpenAlert({
            title: 'Solicitud no válida.',
            message: 'Inténtalo nuevamente.',
            severity: 'error'
          }))
          return
        case 'ZERO_RESULTS':
          dispatch(handleOpenAlert({
            title: 'No se encontró ningún resultado',
            severity: 'error'
          }))
          return
        default:
          dispatch(handleOpenAlert({
            title: 'No se encontró ningún resultado',
            severity: 'error'
          }))
      }
    }

    setSessionToken(null)
  }

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value, currentSessionToken) => {
      const recentPlaces = JSON.parse(localStorage.getItem('recentPlaces') || '[]')

      if (autoCompleteStations?.length > 0) {
        const autoCompleteStationsResults = autoCompleteStations
          .filter(result => result?.stop_name.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ))
          .map(result => ({
            ...result,
            distanceToUser: userPosition?.lat && userPosition?.lng ? getDistanceFromLatLonInKm(userPosition.lat, userPosition.lng, result.stop_lat, result.stop_lon) : undefined,
            categoryId: null,
            name: result?.stop_name,
            id: result?.stop_id,
            providerName: 'ualabee_api',
            geometry: {
              coordinates: [result.stop_lat, result.stop_lon]
            }
          }))

        if (autoCompleteStationsResults.length >= 1) {
          if (userPosition) {
            const orderArr = _.orderBy(autoCompleteStationsResults, ['distanceToUser'], ['asc'])
            setStationsResults([])
            setStationsResults(orderArr.slice(0, 1))
          } else {
            setStationsResults([])
            setStationsResults(autoCompleteStationsResults.slice(0, 1))
          }
        }
      }

      if (value.length >= 3) {
        // Search
        getPlaces(
          provider,
          cityConfig,
          value,
          userPosition,
          currentSessionToken,
          autoCompleteRef,
          displaySuggestions
        )
          .then((response) => {
            const results = response?.results
              ?.filter(result => bounds?.contains(new maplibregl.LngLat(
                result?.geometry?.coordinates[1] || result?.position[1] || result?.geometry.point[0],
                result?.geometry?.coordinates[0] || result?.position[0] || result?.geometry.point[1])))
              ?.map(result => {
                return {
                  ...result,
                  distanceToUser: userPosition?.lat && userPosition?.lng
                    ? getDistanceFromLatLonInKm(userPosition.lat, userPosition.lng, result.geometry.coordinates[0], result.geometry.coordinates[1])
                    : undefined,
                  categoryId: recentPlaces?.some(place => place.id === result?.properties?.osm_id || place.id === result?.id)
                    ? 'recent_place'
                    : result?.properties?.osm_value ? result.properties.osm_value : null,
                  name: result.properties?.name || result?.name || result?.title || result?.label || `${result?.properties?.street} ${result?.properties?.housenumber}`,
                  id: result?.properties?.osm_id || result?.id || result?.label,
                  providerName: provider?.id
                }
              })

            if (results.length >= 1) {
              if (userPosition) {
                const orderArr = _.orderBy(results, ['distanceToUser'], ['asc'])
                setSearchResults([])
                setSearchResults(orderArr)
              } else {
                setSearchResults([])
                setSearchResults(results)
              }
            } else if (recentPlaces.some(result => result.name.includes(value))) {
              const resultsFromRecentPlaces = recentPlaces.filter(result => result.name.includes(value))
                .map(result => ({
                  ...result,
                  categoryId: 'recent_place'
                }))
              setSearchResults([])
              setSearchResults(resultsFromRecentPlaces)
            }
          })
          .catch((e) => {
            console.error(e)
            showRecentPlaces(cityConfig, bounds, provider)
          })
      }

      if (value.length === 0) {
        setStationsResults([])
        showRecentPlaces(cityConfig, bounds, provider)
      }
    }, 300), // Adjust the delay as needed
    [autoCompleteStations, userPosition, provider, cityConfig, bounds, getPlaces]
  )

  const handleChangeSearch = (e) => {
    const { value } = e.target
    dispatch(setSearch(value, new Date()))

    let currentSessionToken = sessionToken
    if (!sessionToken) {
      const sessionToken = new window.google.maps.places.AutocompleteSessionToken()
      setSessionToken(sessionToken)
      currentSessionToken = sessionToken
    }

    debouncedSearch(value, currentSessionToken)
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const handleHereSearch = async () => {
    let hereProvider = {
      id: 'here_maps_autocomplete',
      limit: 1
    }

    try {
      const providers = getValue(remoteConfig, 'places_autocomplete_provider_limit')
      hereProvider = JSON.parse(providers?._value)?.find(prov => prov?.id === 'here_maps_autocomplete')
    } catch (e) {
      console.error(e)
    }

    getPlaces(
      hereProvider,
      cityConfig,
      term,
      userPosition,
      '',
      autoCompleteRef,
      displaySuggestions
    )
      .then((response) => {
        const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
        const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)
        const bounds = new maplibregl.LngLatBounds(sw, ne)

        const filterResults = response?.results?.filter(result => {
          if (bounds.contains(new maplibregl.LngLat(result.position[1], result.position[0]))) {
            return result
          }
        })

        const results = filterResults?.map(result => {
          return userPosition
            ? {
                ...result,
                distanceToUser: getDistanceFromLatLonInKm(userPosition.lat, userPosition.lng, result.position[0], result.position[1]),
                categoryId: result?.resultType,
                providerName: 'here_maps_autocomplete',
                geometry: {
                  coordinates: result.position
                }
              }
            : {
                ...result,
                categoryId: result.resultType,
                providerName: 'here_maps_autocomplete',
                geometry: {
                  coordinates: result.position
                }
              }
        })

        if (userPosition) {
          const orderArr = _.orderBy(results, ['distanceToUser'], ['asc'])
          setSearchResults([])
          setSearchResults(orderArr)
        } else {
          setSearchResults([])
          setSearchResults(results)
        }
      })
      .catch((e) => {
        console.error(e)
        showRecentPlaces(cityConfig, bounds, { id: 'here_maps_autocomplete', limit: 1 })
      })

    // Send events to analytics
    logEvent('here_maps_request_single')
  }

  const removeHistory = () => {
    setSearchResults([])
    setRecentPlaces([])

    getLocalRecentPlaces()
      .then((response) => {
        const places = response?.filter((filter) => filter?.city_id !== cityConfig?.city_id)
        setLocalRecentPlaces(places)
      })
  }

  const displaySuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
      switch (status) {
        case 'INVALID_REQUEST':
          dispatch(handleOpenAlert({
            title: 'Solicitud no válida.',
            severity: 'error'
          }))
          return
        case 'NOT_FOUND':
          dispatch(handleOpenAlert({
            title: 'No se encontró el lugar al que se hace referencia.',
            severity: 'error'
          }))
          return
        case 'UNKNOWN_ERROR':
          dispatch(handleOpenAlert({
            title: 'Solicitud no válida.',
            message: 'Inténtalo nuevamente.',
            severity: 'error'
          }))
          return
        case 'ZERO_RESULTS':
          dispatch(handleOpenAlert({
            title: 'No se encontró ningún resultado',
            severity: 'error'
          }))
          return
        default:
          dispatch(handleOpenAlert({
            title: 'No se encontró ningún resultado',
            severity: 'error'
          }))
      }
    } else {
      const places = predictions?.map(place => ({
        geometry: null,
        type: null,
        properties: {
          osm_id: place?.place_id,
          osm_type: null,
          extent: null,
          country: cityConfig?.country?.name,
          osm_key: 'highway',
          city: place?.structured_formatting.secondary_text,
          countrycode: null,
          osm_value: place?.types[0],
          postcode: null,
          name: place?.structured_formatting.main_text,
          state: place?.structured_formatting.secondary_text,
          street: '',
          housenumber: ''
        },
        distanceToUser: place?.distance_meters ? place.distance_meters / 1000 : undefined,
        categoryId: place?.types[0],
        name: place?.structured_formatting.main_text,
        title: place?.structured_formatting.main_text || '',
        vicinity: place?.structured_formatting.secondary_text,
        id: place?.place_id,
        providerName: 'google_places_autocomplete',
        latLng: { lat: null, lng: null },
        city_id: cityConfig?.city_id,
        city: place?.structured_formatting.secondary_text,
        remoteId: place?.place_id
      }))

      setSearchResults(places)
    }
  }

  const showRecentPlaces = (cityConfig, bounds) => {
    const localRecentPlaces = JSON.parse(localStorage.getItem('recentPlaces') || '[]')

    const placesByProviderId = localRecentPlaces.map(recentPlace => ({
      ...recentPlace,
      providerName: recentPlace?.providerName === 'HERE' || recentPlace?.providerName === 'UALABEE'
        ? recentPlace?.providerName === 'HERE'
          ? 'here_maps_autocomplete'
          : 'photon_ualabee'
        : recentPlace?.providerName || null
    }))

    const recentPlaces = placesByProviderId?.filter(recent => recent?.city_id === cityConfig?.city_id)

    const results = recentPlaces
      ?.filter(result => bounds?.contains(new maplibregl.LngLat(result.latLng.lng, result.latLng.lat)))
      ?.map(result => ({
        ...result,
        distanceToUser: userPosition?.lat && userPosition?.lng ? getDistanceFromLatLonInKm(userPosition?.lat, userPosition?.lng, result.latLng.lat, result.latLng.lng) : undefined,
        categoryId: 'recent_place'
      }))

    if (userPosition) {
      const orderArr = _.orderBy(results, ['distanceToUser'], ['asc'])
      setSearchResults([])
      setSearchResults(orderArr)
      setRecentPlaces(orderArr)
    } else {
      setSearchResults([])
      setSearchResults(results)
      setRecentPlaces(results)
    }
  }

  const handleChoose = () => {
    navigate('choose-from-map')
  }

  const handleLocation = () => {
    handleLocationClick()
  }

  const deleteSearchTerm = () => {
    dispatch(setSearch('', new Date()))
    showRecentPlaces(cityConfig, bounds, provider)
  }

  const handleBackClick = () => {
    navigate('/')
  }

  return (
    <Layout>
      {loading && <LoadingLinear />}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100vh',
          width: '100%'
        }}
      >
        {mobile
          ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                boxSizing: 'border-box',
                height: isIosApp ? '100%' : 'calc(100% - 56px)',
                boxShadow: 'rgb(34 40 49 / 15%) 0px 0px 24px',
                width: '100vw',
                '@media (max-width:480px)': {
                  position: 'relative',
                  top: 'initial',
                  right: 'initial',
                  borderRadius: 0,
                  boxShadow: 'none'
                }
              }}
            >
              <Box
                sx={{
                  padding: '20px 16px 0 16px',
                  width: '100%',
                  boxSizing: 'border-box'
                }}
              >
                <FilterDrawer
                  backButton={!REACT_APP_SHOW_NAVBAR || undefined}
                  style={{ color: 'red', padding: '20px 16px 0 16px' }}
                  value={term}
                  onChangeFunction={handleChangeSearch}
                  inputRef={inputRef}
                  placeholder={orientation === 'to' ? t('hint_location_B') : t('hint_location_A')}
                  autoFocus
                />
              </Box>

              {userPosition && bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat)) &&
                <PickButton
                  sx={{
                    width: '100%',
                    height: '45px',
                    borderRadius: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: '6px 16px',
                    textTransform: 'none',
                    color: theme.palette.primary.main
                  }}
                  onClick={handleLocation}
                >
                  <MyLocationIcon
                    sx={{
                      marginRight: '8px',
                      color: theme.palette.primary.main
                    }}
                  />
                  {t('search.my_location')}
                </PickButton>}

              <PickButton
                sx={{
                  width: '100%',
                  height: '45px',
                  borderRadius: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '6px 16px',
                  textTransform: 'none',
                  color: theme.palette.primary.main
                }}
                onClick={handleChoose}
              >
                <MapIcon
                  sx={{
                    marginRight: '8px',
                    color: theme.palette.primary.main
                  }}
                />
                {t('search.pick_on_map')}
              </PickButton>

              {savedPlaces?.length >= 1 &&
                <SavedPlaces border='true' padding='8px 16px 16px 16px' />}

              <ResultsContainer>

                {recentPlaces?.length >= 1 && term.length === 0 &&
                  <RecentPlacesBar
                    title={t('search.recent')}
                    removeHistoryLabel={t('search.delete_history')}
                    onRemoveHistory={removeHistory}
                  />}

                {stationsResults.length > 0 &&
                  <Result
                    onClick={() => handlePlaceClick(stationsResults[0])}
                  >
                    <TypeContainer>
                      <IconContainer>
                        <Icon style={{ backgroundColor: `${REACT_APP_PALLET_PRIMARY_MAIN_COLOR}3a` }}>
                          {REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON
                            ? <img
                                src={cityConfig?.transport_types.find(item => item.route_type === stationsResults[0].route_type)?.icon}
                                alt={cityConfig?.transport_types.find(item => item.route_type === stationsResults[0].route_type)?.name}
                                style={{ width: '24px', height: '24px' }}
                              />
                            : <PlaceRounded style={{ color: REACT_APP_PALLET_PRIMARY_MAIN_COLOR }} />}
                        </Icon>
                        {!isNaN(stationsResults[0].distanceToUser) &&
                          <Distance>{Math.round(stationsResults[0].distanceToUser)}km</Distance>}
                      </IconContainer>
                    </TypeContainer>
                    <InfoContainer>
                      <Name>
                        {stationsResults[0].stop_name}
                      </Name>
                      <City>{cityConfig.name}</City>
                    </InfoContainer>
                  </Result>}

                {searchResults.length > 0 && searchResults?.length !== 0
                  ? searchResults?.map((result, index) => {
                    const { icon, color } = getPlacesIcon(result.categoryId)
                    const name = result?.properties?.name || result?.title || result.stop_name || `${result?.properties?.street} ${result?.properties?.housenumber}`
                    return (
                      <Result key={index} onClick={() => handlePlaceClick(result)}>
                        <TypeContainer>
                          <IconContainer>
                            <Icon style={{ backgroundColor: color }}>
                              {icon}
                            </Icon>
                            {!isNaN(result.distanceToUser) &&
                              <Distance>{Math.round(result.distanceToUser)}km</Distance>}
                          </IconContainer>

                        </TypeContainer>

                        <InfoContainer>
                          <Name>
                            {name}
                          </Name>
                          <City>{result?.properties ? result?.properties?.city ? result?.properties?.city : result?.properties?.state : result?.vicinity?.replace(/\d/g, '').replace('<br/>', ' ')}</City>
                        </InfoContainer>
                      </Result>
                    )
                  })
                  : (
                    <>
                      {term?.length === 0 &&
                        <StartSearch text={t('search.help')} />}
                    </>
                    )}

                {term?.length >= 3 &&
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      width: '100%'
                    }}
                  >
                    <WaterMark>© OpenStreetMap contributors</WaterMark>
                    <HelpMessage
                      onClick={() => handleHereSearch()}
                      message={t('search.super_search')}
                    />
                  </Box>}

              </ResultsContainer>

            </Box>
            )
          : (<Box
              sx={{
                position: 'absolute',
                top: '16px',
                left: '16px',
                zIndex: 600,
                width: '450px',
                borderRadius: '16px'
              }}
             >
            <Box
              sx={{
                width: '450px',
                background: '#fff',
                borderRadius: '16px',
                padding: '16px 24px 24px',
                boxSizing: 'border-box',
                boxShadow: 'rgb(34 40 49 / 15%) 0px 0px 24px',
                position: 'absolute',
                top: 0,
                zIndex: 1
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                {REACT_APP_SHOW_MENU && <IconButton
                  edge='start'
                  onClick={() => dispatch(handleOpenMenu())}
                                        >
                  <Badge
                    overlap='rectangular' color='error' variant='dot'
                    invisible={!showMenuNotification}
                  >
                    <MenuRounded variant='secondary.main' />
                  </Badge>
                </IconButton>}
                {REACT_APP_IS_IFRAME
                  ? (
                    <a href='https://app.ualabee.com' target='blank'>
                      <Logo
                        src={logoUalabee}
                      />
                    </a>
                    )
                  : (
                    <Logo
                      src={REACT_APP_HARDCODED_LOGO || logoUalabee}
                    />)}

                {REACT_APP_IFRAME_LOGO
                  ? (
                    <a href={REACT_APP_LOGO_EXTERNAL_LINK} target='blank'>
                      <Logo
                        src={REACT_APP_IFRAME_LOGO}
                      />
                    </a>
                    )
                  : (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <PlaceRounded
                        sx={{ color: theme.palette.icons.secondary.main }}
                        fontSize='small'
                      />
                      {cityConfig?.name
                        ? (
                          <Typography
                            variant='h4'
                            sx={{
                              fontSize: '16px',
                              marginLeft: '8px',
                              color: theme.text.secondary.main
                            }}
                          >
                            {cityConfig?.name}
                          </Typography>
                          )
                        : (<Skeleton sx={{ marginLeft: '5px' }} width='120px' height='16px' />
                          )}
                    </Box>)}
              </Box>
              <Box
                sx={{
                  border: '1px solid rgb(224, 232, 247)',
                  marginTop: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: '10px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <IconButton
                    sx={{ margin: '4px' }}
                    onClick={handleBackClick}
                  >
                    <ArrowBackRounded variant='secondary.main' />
                  </IconButton>
                  <Searcher
                    autoFocus
                    onChange={handleChangeSearch}
                    placeholder={orientation === 'to'
                      ? t('hint_location_B')
                      : t('hint_location_A')}
                    value={term}
                  />
                </Box>
                <IconButton
                  sx={{
                    margin: '4px',
                    '&:disabled': {
                      display: 'none'
                    }
                  }}
                  onClick={deleteSearchTerm}
                  disabled={term?.length === 0}
                >
                  <CloseRounded variant='secondary.main' />
                </IconButton>
              </Box>
            </Box>
            <motion.div
              animate={{
                x: -1,
                y: 0,
                scale: 1,
                rotate: 0
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  padding: '16px 23px 23px 23px',
                  position: 'absolute',
                  top: '152px',
                  left: '1px',
                  width: '450px',
                  boxSizing: 'border-box',
                  borderRadius: '0 0 16px 16px',
                  boxShadow: 'rgb(34 40 49 / 15%) 0px 0px 24px',
                  height: 'auto',
                  maxHeight: '250px',
                  overflow: 'auto'
                }}
              >

                {savedPlaces?.length >= 1 &&
                  <SavedPlaces border='true' padding='8px 16px 16px 16px' />}

                {recentPlaces?.length >= 1 && term?.length < 3 &&
                  <RecentPlacesBar
                    title={t('search.recent')}
                    removeHistoryLabel={t('search.delete_history')}
                    onRemoveHistory={removeHistory}
                  />}

                {stationsResults.length > 0 &&
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'stretch',
                      padding: '8px 15px 8px 15px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      margin: '0 0 8px 0',
                      '&:hover': {
                        backgroundColor: '#F6F8F9'
                      }
                    }}
                    onClick={() => handlePlaceClick(stationsResults[0])}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '16px',
                        flexDirection: 'column'
                      }}
                    >
                      <Box
                        sx={{
                          width: '28px',
                          height: '28px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '8px',
                          backgroundColor: `${REACT_APP_PALLET_PRIMARY_MAIN_COLOR}3a`
                        }}
                      >
                        {REACT_APP_SHOW_STATION_AUTOCOMPLETE_ROUTE_TYPE_ICON
                          ? <img
                              src={cityConfig?.transport_types.find(item => item.route_type === stationsResults[0].route_type)?.icon}
                              alt={cityConfig?.transport_types.find(item => item.route_type === stationsResults[0].route_type)?.name}
                              style={{ width: '24px', height: '24px' }}
                            />
                          : <PlaceRounded style={{ color: REACT_APP_PALLET_PRIMARY_MAIN_COLOR }} />}
                      </Box>
                      {stationsResults[0].distanceToUser &&
                        <Distance>{Math.round(stationsResults[0].distanceToUser)}km</Distance>}
                    </Box>
                    <PlaceInformation>
                      <PlaceName>
                        {stationsResults[0].stop_name}
                      </PlaceName>
                      <PlaceCity>{cityConfig.name}</PlaceCity>
                    </PlaceInformation>
                  </Box>}

                {searchResults?.length > 0 && searchResults?.length !== 0
                  ? (
                    <>
                      {searchResults?.map((result) => {
                        const { icon, color } = getPlacesIcon(result?.categoryId)

                        return (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'stretch',
                              padding: '8px 15px 8px 15px',
                              borderRadius: '8px',
                              cursor: 'pointer',
                              margin: '0 0 8px 0',
                              '&:hover': {
                                backgroundColor: '#F6F8F9'
                              }
                            }}
                            key={result?.properties?.osm_id}
                            onClick={() => handlePlaceClick(result)}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '16px',
                                flexDirection: 'column'
                              }}
                            >
                              <Box
                                sx={{
                                  width: '28px',
                                  height: '28px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '8px',
                                  backgroundColor: color
                                }}
                              >
                                {icon}
                              </Box>
                              {result?.distanceToUser &&
                                <Distance>{Math.round(result.distanceToUser)}km</Distance>}
                            </Box>
                            <PlaceInformation>
                              <PlaceName>
                                {result?.name || result?.properties?.name || result?.title || `${result?.properties?.street || ''} ${result?.properties?.housenumber || ''}`}
                              </PlaceName>
                              <PlaceCity>{result?.properties ? result?.properties?.city ? result?.properties?.city : result?.properties?.state : result?.vicinity?.replace(/\d/g, '').replace('<br/>', ' ')}</PlaceCity>
                            </PlaceInformation>
                          </Box>
                        )
                      })}
                      {term.length >= 3 &&
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%'
                          }}
                        >
                          <WaterMark>© OpenStreetMap contributors</WaterMark>
                          <HelpMessage
                            onClick={() => handleHereSearch()}
                            message={t('search.super_search')}
                          />
                        </Box>}
                    </>
                    )
                  : (
                    <>
                      {term.length >= 3
                        ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              width: '100%'
                            }}
                          >
                            <WaterMark>© OpenStreetMap contributors</WaterMark>
                            <HelpMessage
                              onClick={() => handleHereSearch()}
                              message={t('search.super_search')}
                            />
                          </Box>)
                        : (
                          <StartSearch text={t('search.help')} />
                          )}
                    </>
                    )}

              </Box>
            </motion.div>
          </Box>
            )}

        {mobile
          ? (
            <>
              {!REACT_APP_IS_IFRAME &&
                <NavBar />}
              <CountriesDrawer />
              <CitiesDrawer />
            </>
            )
          : (
            <Box
              sx={{
                width: '100%',
                height: '100%'
              }}
            >
              <CustomMap />
              <CityChange />
            </Box>
            )}
      </Box>
      <IdentifiedCityModal />
    </Layout>
  )
}

export default SearchAutocomplete

const StartSearch = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 0,
        padding: '16px 0 0 0',
        '@media (max-width:480px)': {
          margin: '26px 0 0 0',
          padding: '16px'
        }
      }}
    >
      <HistoryRounded
        variant='secondary.main'
        sx={{
          marginBottom: '16px',
          width: '48px',
          height: '48px'
        }}
      />
      <Typography
        sx={{ textAlign: 'center', fontSize: '12px', color: theme.text.secondary.main }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const RecentPlacesBar = ({ title, removeHistoryLabel, onRemoveHistory }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0 0 16px 0',
        '@media (max-width:480px)': {
          padding: '6px 16px',
          boxSizing: 'border-box'
        }
      }}
    >
      <Typography
        variant='h3'
        sx={{
          margin: 0,
          fontSize: '16px',
          fontWeight: 600
        }}
      >
        {title}
      </Typography>
      <Button
        color='error'
        sx={{
          fontSize: '14px'
        }}
        onClick={onRemoveHistory}
      >
        {removeHistoryLabel}
      </Button>
    </Box>
  )
}
