import styled from 'styled-components/macro'

export const Container = styled.div`
  padding: 0 6px 12px 6px;
  background: #ffffff;
  border-radius: 16px;
`

export const LineIcon = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 10px;
  margin-right: 16px;
  background-color: ${props => props?.color && props?.color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  background-color: white;
  -webkit-mask: url(${props => props?.src}) center/contain;
  mask: url(${props => props?.src}) center/contain;
`

export const LinesContainer = styled.div`
  border-radius: 16px;
  background-color: #FAFAFA;
  box-sizing: border-box;
  height: calc(100vh - 256px);
  overflow: auto;
  overflow-x: hidden;
`

export const AllLines = styled.div`
  background-color: white;
  box-sizing: border-box;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.palette.text.primary.main};
  margin: 0;
  margin-bottom: 18px;
`
