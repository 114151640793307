import styled from 'styled-components/macro'
import { Chip } from '@mui/material'

export const Container = styled.div`  
    padding: 20px;
`

export const Title = styled.div`  
    font-size: 18px; 
    margin: 0 0 20px 0;
    text-align: center;
`

export const Reason = styled.div`  
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    width: 100%;
`

export const Info = styled.div`  
    display: flex; 
    align-items: center;
    width: 100%;
    margin-right: 15px;
`

export const Icon = styled.img`  
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
`

export const Label = styled.p`  
    margin: 0;
`

export const Vows = styled(Chip)`   
`
