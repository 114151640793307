import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { uiReducer } from '../reducers/uiReducer'
import { userReducer } from '../reducers/userReducer'
import { mapReducer } from '../reducers/mapReducer'

const reducers = combineReducers({
  ui: uiReducer,
  user: userReducer,
  map: mapReducer
})

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
)
