import styled from 'styled-components/macro'
import { InputBase } from '@mui/material'

export const Input = styled(InputBase)`
    width: 100%;
    position: relative !important;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: ${props => {
  return props.backButton ? '16px 16px 16px 44px;' : '16px 16px 16px 20px;'
}};
    box-sizing: border-box;
    color: #000000;
    font-size: 14px;  
    margin-bottom: 8px; 
    height: 50px;   
    color: ${props => props.theme.palette.text.primary.main};
  }
`
