import styled from 'styled-components/macro'
import { VerifiedUserRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'

export const SecurityContainer = styled.div`
  position: relative;
  bottom: 0;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
`
export const Icon = styled(VerifiedUserRounded)`
  margin-right: 8px;
  color: ${props => props?.theme?.palette?.icons?.primary?.main};
`

export const Label = styled.p`
  margin: 0;
  color: ${props => props?.theme?.palette?.text?.primary?.main};
  font-size: ${props => props?.mode ? '14px' : 'inherit'};
  white-space: nowrap;
`

export const Options = styled.div`
  display: flex;
`

export const Container = styled.div`
  position: relative;
  display: flex;

  :first-child {
    margin-right: 16px;
  }
`

export const Option = styled(Chip)`
`

export const VowsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: white;
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E0E8F7;
`

export const Vows = styled.p`
  margin: 0;
  font-size: 10px;
  color: ${props => props?.color};
`
