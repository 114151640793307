import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share'
import { BackAndTitleContainer, Title, TopBarContainer } from './styles'

const TitleTopBar = ({ title, backPath = '/', bgColor, showShareButton }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    if (backPath) {
      navigate(backPath)
    } else {
      navigate(-1)
    }
  }

  return (

    <TopBarContainer bgColor={bgColor}>
      <BackAndTitleContainer>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Title>{title}</Title>
      </BackAndTitleContainer>
      {showShareButton &&
        <IconButton>
          <ShareIcon />
        </IconButton>}
    </TopBarContainer>

  )
}

export default TitleTopBar
