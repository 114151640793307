import React, { useEffect, useState } from 'react'
import { ArrowIcon } from '../../../components/SearchResults/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, List, ListItem, ListItemText, Skeleton, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment/moment'
import { useTripResults } from '../../../hooks/useTripResults'
import { theme } from '../../../theme/theme'

export const AlternativeRoutes = () => {
  const { t } = useTranslation()

  const tripResponse = useSelector(state => state?.ui?.tripResponse)
  const [alternativeItineraries, setAlternativeItineraries] = useState(null)

  useEffect(() => {
    setAlternativeItineraries(null)
    if (!tripResponse) return

    const {
      itinerariesCar = [],
      itinerariesBike = [],
      itinerariesUserBike = [],
      itinerariesMotorcycle = []
    } = tripResponse

    itinerariesBike?.legs?.forEach((leg) => {
      leg.data = [
        {
          label: t('trip_share.available_title'),
          value: Number(tripResponse?.itinerariesBike?.fromBikeStation?.free_bikes)
        },
        {
          label: t('trip_share.car_result_distance_title'),
          value: `${Number(leg?.distance / 1000)?.toFixed(2)}km`
        },
        {
          label: t('trip_share.search_result_arrival_title'),
          value: moment(tripResponse?.itinerariesBike?.endTime)?.format('HH:mm')
        }
      ]
    })

    itinerariesMotorcycle?.forEach(trip => {
      trip?.legs?.forEach((leg) => {
        leg.data = [
          {
            label: t('trip_share.car_result_distance_title'),
            value: `${Number(leg?.distance / 1000)?.toFixed(2)}km`
          },
          {
            label: t('trip_share.search_result_arrival_title'),
            value: moment(trip.endTime).format('HH:mm')
          }
        ]
      })
    })

    itinerariesUserBike?.legs?.forEach((leg) => {
      leg.data = [
        {
          label: t('trip_share.car_result_distance_title'),
          value: `${Number(tripResponse?.itinerariesUserBike?.legs[0]?.distance / 1000)?.toFixed(2)}km`
        },
        {
          label: t('trip_share.search_result_arrival_title'),
          value: moment(tripResponse?.itinerariesUserBike?.endTime)?.format('HH:mm')
        }
      ]
    })

    itinerariesCar?.forEach(trip => {
      trip?.legs?.forEach((leg) => {
        if (trip?.fare && trip?.fare?.cents > 0) {
          leg.data = [
            {
              label: t('trip_share.search_result_fare_title'),
              value: (trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits).toFixed(2)
            },
            {
              label: t('trip_share.search_result_arrival_title'),
              value: moment(trip.endTime).format('HH:mm')
            }
          ]
        } else {
          leg.data = [
            {
              label: t('trip_share.car_result_distance_title'),
              value: `${Number(leg?.distance / 1000)?.toFixed(2)}km`
            },
            {
              label: t('trip_share.search_result_arrival_title'),
              value: moment(trip.endTime).format('HH:mm')
            }
          ]
        }
      })
    })

    const alternativeItineraries = [itinerariesCar, itinerariesMotorcycle, [itinerariesBike], [itinerariesUserBike]].flat().filter(item => Object.keys(item).length > 0)

    setAlternativeItineraries(alternativeItineraries)
  }, [tripResponse])

  return (
    <Box
      sx={{
        marginBottom: 0,
        width: '100%'
      }}
    >
      {
        alternativeItineraries
          ? (
            <>
              {alternativeItineraries?.filter(item => item.mode !== 'MOTOTAXI').map((trip, index) => {
                return (
                  <AlternativeTransportItem
                    key={index}
                    trip={trip}
                    legs={trip?.legs?.filter(leg => leg?.mode !== 'WALK')}
                  />
                )
              })}
            </>
            )
          : (
            <>
              {[1, 2, 3].map((item1, key1) => (
                <Box
                  key={key1}
                  sx={{
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Skeleton
                    sx={{ borderRadius: '8px', marginRight: '16px' }}
                    width='49px'
                    height='49px'
                    variant='rectangular'
                  />
                  <Box>
                    <Skeleton width='100px' height='20px' variant='text' />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {[0, 1].map((item2, key2) => (
                        <Box
                          key={key2}
                          sx={{
                            '&:first-child': {
                              marginRight: '8px'
                            }
                          }}
                        >
                          <Skeleton width='45px' height='18px' variant='text' />
                          <Skeleton width='45px' height='18px' variant='text' />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
            )
      }
    </Box>
  )
}

const AlternativeTransportItem = ({
  legs = [],
  trip
}) => {
  const {
    handleTripClick
  } = useTripResults()

  const mobile = useMediaQuery('(max-width:480px)')
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [isIconLoaded, setIsIconLoaded] = useState(false)

  return (
    <>
      {legs?.map(({ data = [], transportTypeId = 0 }, index) => {
        const transport = cityConfig?.transport_types?.find(transport => parseInt(transport?.transport_type_id) === parseInt(transportTypeId))

        return (
          <Button
            key={index}
            onClick={() => handleTripClick(trip)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '16px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#F6F8F9'
              },
              '&:focus': {
                border: `${!mobile ? `1px solid ${theme.palette.primary.main}` : 'none'}`
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
              }}
            >
              <Box
                sx={{
                  width: '49px',
                  minWidth: '49px',
                  height: '49px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: transport?.color || '#b0b0b0',
                  borderRadius: '8px',
                  margin: '0 16px 0 0',
                  position: 'relative'
                }}
              >
                <img
                  style={{
                    borderRadius: '8px',
                    position: 'absolute',
                    width: '100%'
                  }}
                  src={transport?.flat_icon}
                  alt={`${transport?.name} icono`}
                  onLoad={() => {
                    setIsIconLoaded(true)
                  }}
                  onError={() => {

                  }}
                  loading='lazy'
                />
                {!isIconLoaded &&
                  <Skeleton
                    sx={{
                      backgroundColor: '#505050',
                      borderRadius: '8px'
                    }}
                    width='50px'
                    height='50px'
                    variant='rectangular'
                  />}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: 'normal',
                    textAlign: 'initial',
                    color: '#000'
                  }}
                >
                  {transport?.name}
                </Typography>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0
                  }}
                >
                  {data?.map(({ label, value }, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        padding: '0 10px',
                        '&:first-child': {
                          paddingLeft: 0
                        }
                      }}
                    >
                      <ListItemText
                        primary={label}
                        secondary={value}
                        sx={{
                          '.MuiListItemText-primary': {
                            color: '#909397',
                            fontSize: '12px'
                          },
                          '.MuiListItemText-secondary': {
                            color: '#000',
                            fontSize: '14px',
                            textAlign: 'center'
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
            <ArrowIcon fontSize={mobile ? 'small' : 'medium'} />
          </Button>
        )
      })}
    </>
  )
}
