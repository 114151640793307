import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { FilterContainer, FilterOption, FilterOptionGroup, FilterTitle, OptionText } from '../../../GlobalStyles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Switch, useMediaQuery } from '@mui/material'

export const TransportTypes = () => {
  const { t } = useTranslation()

  const {
    handleChange
  } = useFilters()

  const mobile = useMediaQuery('(max-width:480px)')
  const filters = useSelector(state => state?.user?.searchFilters)

  return (
    <FilterContainer
      mode={mobile}
    >
      <FilterTitle
        mode={mobile}
      >
        {t('search_preview.filters.items.transport_types')}
      </FilterTitle>
      <FilterOptionGroup
        mode={mobile}
      >
        {filters?.transportation_types?.map((type) => {
          return (
            <FilterOption key={type.transport_type_id}>
              <OptionText
                mode={mobile}
              >
                {type.name}
              </OptionText>
              <Switch
                checked={type.checked}
                onChange={(event) => handleChange(event, type.transport_type_id)}
              />
            </FilterOption>
          )
        })}
      </FilterOptionGroup>
    </FilterContainer>
  )
}
