import React, { useEffect, useState } from 'react'
import { BackIcon, SearchResultsTitle, Subtitle, SubtitleContainer, Title } from './styles'
import { TripResults } from '../Desktop/TripResults'
import { useNavigate, useLocation } from 'react-router-dom'
import { RecommendedRoutes } from '../../uicomponents/tripResults/RecommendedRoutes'
import { AlternativeRoutes } from '../../uicomponents/tripResults/AlternativeRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import { enabledAlertsAndNewsComponent, getTripResults, setTripResponse, setTripSelected } from '../../actions/ui'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import NavBar from '../NavBar'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../uicomponents/layouts/Layout'
import { Box, Button, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
// import required modules
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import { setRegisterClick } from '../../db/ads/registerClick'
import PropTypes from 'prop-types'
import { logEvent, remoteConfig } from '../../firebase/firebase-config'
import useQuery from '../../hooks/useQuery'
import FeedbackBar from '../../uicomponents/FeedbackBar'
import {
  CustomMarker,
  DraggableMarkerA,
  DraggableMarkerB,
  MapContainer,
  StopMarker,
  VehiclesRealtime
} from '../../uicomponents/Map'
import stop1 from '../../img/stop1.svg'
import stop2 from '../../img/stop2.svg'
import stop3 from '../../img/stop3.svg'
import stop4 from '../../img/stop4.svg'
import stop5 from '../../img/stop5.svg'
import stop6 from '../../img/stop6.svg'
import stop7 from '../../img/stop7.svg'
import stop8 from '../../img/stop8.svg'
import { Layer, Source, useMap } from 'react-map-gl'
import polyUtil from 'polyline-encoded'
import maplibregl from 'maplibre-gl'
import { setBikeStationSelected } from '../../actions/map'
import { BikeStationsDrawer } from '../BikeStationsDrawer'
import { getClosetsBikeStation } from '../../utils'
import {
  REACT_APP_IFRAME_LOGO,
  REACT_APP_IS_IFRAME,
  REACT_APP_VARIANT_ID
} from '../../constants/config'
import MotoTaxiResult from '../MotoTaxiResult'
import { fetchAndActivate, getValue } from 'firebase/remote-config'

SwiperCore.use([Autoplay, EffectFade])

const SearchResults = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const query = useQuery()
  const { pathname } = useLocation()

  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const mode = useSelector(state => state?.ui?.mode)
  const user = useSelector(state => state?.user?.auth)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  const tripResponse = useSelector(state => state?.ui?.tripResponse)
  const timestamp = useSelector(state => state?.ui?.dataTimeValue)
  const searchFilters = useSelector(state => state?.user?.searchFilters)

  const [showMore, setShowMore] = useState(false)
  const [itineraries, setItineraries] = useState([])
  const [feedbackObj, setFeedbackObj] = useState()
  const [showBar, setShowBar] = useState(true)
  const [showMotoTaxiResult, setShowMotoTaxiResult] = useState(false)

  useEffect(() => {
    dispatch(setTripResponse(undefined))
    dispatch(setTripSelected(undefined))
    if (!user) return

    dispatch(enabledAlertsAndNewsComponent({
      enabled: false
    }))

    dispatch(getTripResults())
  }, [user, pathname])

  useEffect(() => {
    if (tripResponse) {
      setShowMore(false)
      setItineraries(tripResponse?.itineraries?.slice(0, 3))
      setFeedbackObj({
        city_id: parseInt(query.get('ciudad_id')),
        question_id: 2,
        search: {
          ...urlParams
        }
      })
    } else {
      reset()
    }
  }, [tripResponse])

  useEffect(() => {
    if (!user) return

    if (!tripResponse && !mode) {
      reset()
      dispatch(getTripResults())
    }
  }, [user, searchFilters])

  useEffect(() => {
    if (!mode) {
      if (from && to && from?.name !== t('home.searching') && to?.name !== t('home.searching') && searchFilters) {
        setItineraries([])
        setShowMore(false)
        dispatch(getTripResults())
      }
    }
  }, [from, to, timestamp])

  useEffect(() => {
    // get remote config is_mototaxi_activated value
    fetchAndActivate(remoteConfig)
      .then(() => {
        const isMotoTaxiActivated = getValue(remoteConfig, 'is_mototaxi_activated')
        if (isMotoTaxiActivated.asBoolean()) {
          setShowMotoTaxiResult(true)
        }
      }
      )
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const urlParams = {
    lat_from: Number(query.get('latd')),
    lng_from: Number(query.get('lngd')),
    lat_to: Number(query.get('lath')),
    lng_to: Number(query.get('lngh')),
    radius: Number(query.get('radio')),
    self_search: query.get('self_search') === 'true',
    transport_types: query.get('transport_types'),
    locale: query.get('locale'),
    arrive_by: query.get('arrive_by') === 'true',
    timestamp: query.get('timestamp') === 'null' ? null : Number(query.get('timestamp')),
    transfer: query.get('transfer') === 'null' ? null : query.get('transfer') === 'true'
  }

  const reset = () => {
    setShowMore(false)
    setItineraries([])
    setFeedbackObj(null)
    setShowBar(true)
    dispatch(setTripResponse(undefined))
    dispatch(setTripSelected(undefined))
  }

  return (
    <Layout>
      {user
        ? (
          <>
            {mode
              ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#F6F8F9',
                    overflow: 'hidden hidden'
                  }}
                >
                  <SearchResultsTitle>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row'
                      }}
                    >
                      <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                          margin: '0 16px 0 0'
                        }}
                      >
                        <BackIcon />
                      </IconButton>
                      <Title>
                        {t('trip_share.results')}
                      </Title>
                    </Box>
                    {REACT_APP_IS_IFRAME && REACT_APP_IFRAME_LOGO &&
                      <img
                        style={{
                          width: 'auto',
                          height: '32px'
                        }}
                        alt='logo'
                        src={REACT_APP_IFRAME_LOGO}
                      />}
                  </SearchResultsTitle>

                  <Box
                    sx={{
                      maxHeight: 'calc(100% - 122px)',
                      minHeight: 'calc(100% - 122px)',
                      height: 'calc(100% - 122px)',
                      overflow: 'hidden auto',
                      background: '#fff',
                      borderRadius: '8px 8px 0 0'
                    }}
                  >
                    <MotoTaxiResult
                      show={tripResponse && tripResponse?.itinerariesMotorcycle?.some(itinerary => itinerary.mode === 'MOTOTAXI') && tripResponse?.itinerariesMotorcycle?.find(itinerary => itinerary.mode === 'MOTOTAXI').legs.length > 0 && showMotoTaxiResult}
                    />
                    <SubtitleContainer>
                      <Subtitle>
                        {t('trip_share.recommended_routes_title')}
                      </Subtitle>
                    </SubtitleContainer>
                    <RecommendedRoutes
                      showMore={showMore}
                      setShowMore={setShowMore}
                      itineraries={itineraries}
                      setItineraries={setItineraries}
                    />
                    <SubtitleContainer>
                      <Subtitle>
                        {t('trip_share.alternatives')}
                      </Subtitle>
                    </SubtitleContainer>
                    <AlternativeRoutes />
                    {feedbackObj &&
                      <FeedbackBar
                        feedbackObj={feedbackObj}
                        top='50px'
                        showBar={showBar}
                        setShowBar={setShowBar}
                        questionId={2}
                        screen='SEARCH_RESULTS'
                        barTittle={parseInt(REACT_APP_VARIANT_ID) === 10 ? '¿Algo que reportar sobre los resultados?' : t('trip_share.feedback.bar_title')}
                      />}
                    <AdsBanner />
                  </Box>
                </Box>)
              : (
                <TripResults
                  showMore={showMore}
                  setShowMore={setShowMore}
                  itineraries={itineraries}
                  setItineraries={setItineraries}
                />
                )}
          </>
          )
        : (
          <SkeletonApp />
          )}
      <SendToPhoneModal />
      <ShareModal />
      {isIosApp && !REACT_APP_IS_IFRAME &&
        <NavBar />}
    </Layout>
  )
}

export default SearchResults

/** AdsBanner
 * Returns a list of advertisements
 * @returns {JSX.Element}
 * @constructor
 */

export const AdsBanner = () => {
  const ads = useSelector(state => state.ui.ads.searchResult)
  const rotationTimes = useSelector(state => state.ui.ads.rotationTimes)

  const [delay, setDelay] = useState(5000)

  useEffect(() => {
    if (ads?.length > 1) {
      // sets autoplay delay
      if (rotationTimes?.some(time => time.ad_type_id === 20)) {
        setDelay(rotationTimes?.find(time => time.ad_type_id === 20)?.rotation_time)
      }
    }
  }, [])

  return (
    <>
      {ads?.length >= 1 &&
        <Box
          sx={{
            position: 'sticky',
            padding: '16px 0',
            bottom: 0,
            backgroundColor: '#fff',
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: 0,
            '@media (max-width:480px)': {
              padding: '16px'
            }
          }}
        >
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            centeredSlides
            autoplay={
              ads?.length > 1
                ? {
                    delay, // delay,
                    disableOnInteraction: false
                  }
                : {}
            }
            className='mySwiper'
            loop={ads?.length > 1}
            modules={ads?.length > 1 ? [Autoplay, EffectFade] : [EffectFade]}
          >
            {ads?.map((ad) => {
              return (
                <SwiperSlide
                  key={`ad-${ad?.ad_id}`}
                >
                  <ImageRenderer ad={ad} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>}
    </>
  )
}

/** ImageRenderer
 * Returns a advertisements
 *
 * Initial state:
 *    - isBackgroundImageLoaded: false
 *    - isLogoImageLoaded: false
 *
 * Methods:
 *    - onLoadBackgroundImage(): It's executes when img element has been loaded and the user can see the ad background image. Otherwise, the skeleton is displayed.
 *    - handleOnLogoLoad(): It's executes when img element has been loaded and the user can see the ad logo. Otherwise, the skeleton is displayed.
 *    - handleClick(): It's executes when the user click on the ad. It's opens the link action_uri obtained from ad object and calls the ads/registerClick service passing it the ads_id parameter from ad object
 * @param {object} ad
 * @returns {JSX.Element}
 * @constructor
 */

const ImageRenderer = ({ ad }) => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const user = useSelector(state => state?.user?.auth)

  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false) // ad bakground image state
  const [isLogoImageLoaded, setIsLogoImageLoaded] = useState(false) // ad logo image state

  // onLoadBackgroundImage function is executes when img element has been loaded and the user can see the ad background image. Otherwise, the skeleton is displayed.
  const onLoadBackgroundImage = () => {
    setIsBackgroundImageLoaded(true)
  }

  // onLoadLogoImage function is executes when img element has been loaded and the user can see the ad logo. Otherwise, the skeleton is displayed.
  const onLoadLogoImage = () => {
    setIsLogoImageLoaded(true)
  }

  // handleClick function is executes when the user click on the ad. It's opens the link action_uri obtained from ad object and calls the ads/registerClick service passing it the ads_id parameter from ad object
  const handleClick = () => {
    window.open(ad?.action_uri, '_blank')

    logEvent('custom_ad_clicked', {
      user_id: user?.uid,
      os: 'web',
      city_id: cityConfig?.city_id?.toString(),
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      user_gender: userData?.gender ? userData.gender : null,
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null,
      ad_id: ad?.ad_id,
      ad_category: ad?.categories_key,
      ad_subcategory: ad?.subcategories_key,
      ad_type_id: ad.ad_type_id
    })

    setRegisterClick(ad?.ad_id)
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <Button
      sx={{
        position: 'relative',
        padding: 0,
        width: '100%'
      }}
      onClick={handleClick}
    >
      <img
        onCompositionEnd={() => {
          console.info('end')
        }}
        style={{
          width: '100%',
          height: '90px',
          borderRadius: '8px',
          objectFit: 'cover'
        }}
        src={ad.background_picture_uri}
        alt='...'
        onLoad={onLoadBackgroundImage}
        loading='lazy'
      />

      {!isBackgroundImageLoaded
        ? (
          <Skeleton
            sx={{
              width: '100%',
              height: '90px',
              position: 'absolute',
              top: 0,
              borderRadius: '8px'
            }}
            variant='rectangular'
          />
          )
        : (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '90px',
              top: 0,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              padding: '16px',
              boxSizing: 'border-box'
            }}
          >
            <img
              style={{
                borderRadius: '8px',
                width: '60px',
                height: '60px'
              }}
              src={ad.marker_icon_uri}
              onLoad={onLoadLogoImage}
              alt='ad logo'
            />

            {!isLogoImageLoaded &&
              <Skeleton
                sx={{
                  borderRadius: '8px',
                  width: '60px',
                  height: '60px',
                  position: 'absolute',
                  top: '16px',
                  left: '16px'
                }}
                variant='rectangular'
              />}

            <Box
              sx={{
                width: '100%',
                height: '100%',
                marginLeft: '16px'
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  fontSize: '12px',
                  color: '#fff',
                  fontWeight: 600,
                  margin: '0 0 2px 0',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  textAlign: 'start'
                }}
              >
                {ad?.name}
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontSize: '10px',
                  color: '#fff',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  textAlign: 'start'
                }}
              >
                {ad?.description}
              </Typography>
            </Box>

          </Box>
          )}
    </Button>
  )
}

ImageRenderer.prototype = {
  ad: PropTypes.object.isRequired
}

export const Map = ({
  pathLegs,
  showDraggableDestinationMarkers = true,
  attributionPosition,
  geoControlProps,
  showFromArrivalTime = false,
  radio
}) => {
  const { map } = useMap()
  const dispatch = useDispatch()

  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const bikeStations = useSelector(state => state?.map?.markers?.bikeStations)

  // filter all legs not walk and no CAR, BICYCLE
  const recommendedRoutesLegs = pathLegs?.filter(leg => leg.mode !== 'WALK' && leg.mode !== 'CAR' && leg.mode !== 'BICYCLE')

  const [mode, setMode] = useState(null)
  const [fromLatLng, setFromLatLng] = useState(null)
  const [toLatLng, setToLatLng] = useState(null)
  const [fromBikeStationsNearby, setFromBikeStationsNearby] = useState([])
  const [toBikeStationsNearby, setToBikeStationsNearby] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [attributionPositionState, setAttributionPositionState] = useState(attributionPosition)

  const mobile = useMediaQuery('(max-width:480px)')

  useEffect(() => {
    if (tripSelected) {
      const pointsCount = []

      setMode(tripSelected?.legs?.find(leg => leg.mode !== 'WALK')?.mode)
      setFromLatLng([tripSelected?.legs[0]?.from.lat, tripSelected?.legs[0].from.lon])
      setToLatLng([tripSelected?.legs[tripSelected?.legs.length - 1]?.to.lat, tripSelected?.legs[tripSelected?.legs.length - 1].to.lon])

      pointsCount.push(new maplibregl.LngLat(from?.latLng?.lng, from?.latLng?.lat))

      tripSelected.legs.filter(leg => leg.mode !== 'WALK').forEach(leg => leg.points.forEach(point => {
        pointsCount.push(new maplibregl.LngLat(point[1], point[0]))
      }))

      pointsCount.push(new maplibregl.LngLat(to?.latLng?.lng, to?.latLng?.lat))

      const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

      map?.fitBounds(bounds, {
        padding: {
          top: mobile ? 16 : 200,
          left: mobile ? 16 : 486,
          right: mobile ? 16 : 32,
          bottom: mobile ? 16 : 32
        }
      })

      if (tripSelected?.fromBikeStation) {
        if (!fromLatLng || fromLatLng.length === 0) return
        const fromStations =
          getClosetsBikeStation(
            { lat: fromLatLng[0], lng: fromLatLng[1] },
            radio,
            bikeStations.stations,
            'free_bikes',
            false)
        if (fromStations && fromStations.length > 0) {
          setFromBikeStationsNearby(fromStations.filter(station => station.id !== tripSelected.fromBikeStation.id))
        }
        const toStations = getClosetsBikeStation(
          { lat: toLatLng[0], lng: toLatLng[1] },
          radio,
          bikeStations.stations,
          'empty_slots',
          false)
        if (toStations && toStations.length > 0) {
          setToBikeStationsNearby(toStations.filter(station => station.id !== tripSelected.toBikeStation.id))
        }
      }
    }
  }, [tripSelected])

  const getLegStopIcons = (leg) => {
    const stepIndex = recommendedRoutesLegs.findIndex(step => step.tripId === leg.tripId)

    const stopIcons = {
      0: [stop1, stop2],
      1: [stop3, stop4],
      2: [stop5, stop6],
      3: [stop7, stop8]
    }

    return stopIcons[stepIndex]
  }

  return (
    <MapContainer
      showMapDevices
      showUserPosition
      showStopsNearby={false}
      attributionPosition={attributionPositionState}
      geoControlProps={geoControlProps}
    >

      <DraggableMarkerA
        draggable={showDraggableDestinationMarkers}
        showAdress={showDraggableDestinationMarkers}
      />

      <DraggableMarkerB
        draggable={showDraggableDestinationMarkers}
        showAdress={showDraggableDestinationMarkers}
        showFromArrivalTime={showFromArrivalTime}
      />

      {pathLegs?.length >= 1 &&
        <>
          {mode !== 'CAR' && mode !== 'BICYCLE' &&
            <>
              <StopsItinerary pathLegs={pathLegs} />

              {tripSelected?.transfers <= 1 && (
                <FullPolyline pathLegs={pathLegs} />
              )}
              <VehiclesRealtime />

              {tripSelected?.transfers > 0 && pathLegs?.length > 1 &&
                 (

                   recommendedRoutesLegs.map((leg, index) => {
                     const [stopIcon1, stopIcon2] = getLegStopIcons(leg)
                     return (
                       <div
                         key={index}
                       >
                         <CustomMarker
                           icon={<img style={{ width: '30px', height: '30px' }} src={stopIcon1} alt='stop 2 marker icon' />}
                           lat={leg.from?.lat}
                           lng={leg.from?.lon}
                           zIndex={1}
                         />
                         <CustomMarker
                           icon={<img style={{ width: '30px', height: '30px' }} src={stopIcon2} alt='stop 2 marker icon' />}
                           lat={leg.to?.lat}
                           lng={leg.to?.lon}
                           zIndex={1}
                         />
                       </div>
                     )
                   })
                 )}

            </>}
        </>}

      {tripSelected &&
        <>
          <WalksItinerary
            tripSelected={tripSelected}
          />
          <PathItinerary
            tripSelected={tripSelected}
          />

          {mode === 'BICYCLE' && tripSelected?.fromBikeStation &&
            <>
              <CustomMarker
                icon={getBikeIcon(cityConfig.config.bike_icon, tripSelected?.fromBikeStation.free_bikes)}
                lat={tripSelected?.legs?.find(leg => leg.mode !== 'WALK')?.from?.lat}
                lng={tripSelected.legs.find(leg => leg.mode !== 'WALK')?.from?.lon}
                onClick={() => dispatch(setBikeStationSelected(tripSelected.fromBikeStation))}
              />
              <CustomMarker
                icon={getBikeIcon('/icons/bike-station-icon.svg', tripSelected.toBikeStation.empty_slots)}
                lat={tripSelected?.legs?.find(leg => leg.mode !== 'WALK')?.to?.lat}
                lng={tripSelected.legs.find(leg => leg.mode !== 'WALK')?.to?.lon}
                onClick={() => dispatch(setBikeStationSelected(tripSelected.toBikeStation))}
              />
              {fromBikeStationsNearby.length > 0 && fromBikeStationsNearby?.map((station, index) => {
                return (
                  <CustomMarker
                    key={index}
                    icon={getBikeIcon(cityConfig.config.bike_icon, tripSelected?.fromBikeStation.free_bikes)}
                    lat={station.latitude}
                    lng={station.longitude}
                    onClick={() => dispatch(setBikeStationSelected(station))}
                  />
                )
              })}
              {toBikeStationsNearby.length > 0 && toBikeStationsNearby?.map((station, index) => {
                return (
                  <CustomMarker
                    key={index}
                    icon={getBikeIcon('/icons/bike-station-icon.svg', station.empty_slots)}
                    lat={station.latitude}
                    lng={station.longitude}
                    onClick={() => dispatch(setBikeStationSelected(station))}
                  />
                )
              })}
            </>}

        </>}

      <BikeStationsDrawer />
    </MapContainer>
  )
}

const getBikeIcon = (img, freeBikes) => {
  return (
    <>
      <img style={{ width: '25px' }} src={img} alt='bike station' />
      <span style={{
        display: 'flex',
        boxSizing: 'border-box',
        width: '16px',
        height: '16px',
        fontSize: '8px',
        color: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        border: '1px solid #ffffff',
        borderRadius: '100%',
        backgroundColor: freeBikes === 0 ? '#707070' : '#ffbb33'
      }}
      >
        {freeBikes}
      </span>
    </>
  )
}

const PathItinerary = ({ tripSelected }) => {
  const geojson = {
    type: 'FeatureCollection',
    features: tripSelected.legs
      ?.filter(leg => leg.mode !== 'WALK')
      ?.map(leg => ({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: leg?.points.map(point => [point[1], point[0]])
        },
        properties: {
          color: leg?.routeColor ? `#${leg?.routeColor}` : tripSelected.color,
          width: 8
        }
      }))
  }

  const layerStyle = {
    id: 'lines',
    type: 'line',
    source: 'lines',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width']
    }
  }

  return (
    <Source
      id='lines'
      type='geojson'
      data={geojson}
    >
      <Layer type='line' {...layerStyle} />
    </Source>
  )
}

const WalksItinerary = ({ tripSelected }) => {
  const geojson = {
    type: 'FeatureCollection',
    features: tripSelected.legs
      ?.filter(leg => leg.mode === 'WALK')
      ?.map(leg => ({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: leg.points.map(point => [point[1], point[0]])
        },
        properties: {
          color: '#808080',
          width: 8
        }
      }))
  }

  const layerStyle = {
    id: 'walks',
    type: 'line',
    source: 'walks',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#808080',
      'line-width': 8,
      'line-opacity': 0.7,
      'line-dasharray': [0.1, 1.8]
    }
  }

  return (
    <Source
      id='walks'
      type='geojson'
      data={geojson}
    >
      <Layer type='line' {...layerStyle} />
    </Source>
  )
}

const StopsItinerary = ({ pathLegs = [] }) => {
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  return (
    <>
      {pathLegs?.map((path, key) => {
        return (
          <div key={key}>
            <>
              {path?.stops?.map((stop, index) => {
                if (tripSelected?.transfers <= 1) {
                  return (
                    <StopMarker
                      key={index}
                      stop={stop}
                      color={stop.isOnRoute ? `#${path?.routeColor}` : `#${path?.routeColor}5e`}
                    />
                  )
                } else if (stop.isOnRoute) {
                  return (
                    <StopMarker
                      key={index}
                      stop={stop}
                      color={`#${path?.routeColor}`}
                    />
                  )
                }
              })}
            </>
          </div>
        )
      })}
    </>
  )
}

const FullPolyline = ({ pathLegs = [] }) => {
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)

  const geojson = {
    type: 'FeatureCollection',
    features: pathLegs.map(leg => {
      const detail = tripDetails?.find(detail => detail.shape_id === leg.shapeId)

      if (!detail) return {}
      const shape = polyUtil.decode(detail?.shape)

      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: shape ? shape?.map(point => [point[1], point[0]]) : []
        },
        properties: {
          color: leg?.routeColor ? `#${leg?.routeColor}` : tripSelected.color,
          width: 8
        }
      }
    })
  }

  const layerStyle = {
    id: 'full-width',
    type: 'line',
    source: 'full-width',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width'],
      'line-opacity': 0.4
    }
  }

  const arrowsLayer = {
    id: 'arrows',
    type: 'symbol',
    source: 'full-width',
    layout: {
      'symbol-placement': 'line',
      'icon-image': 'arrow',
      'icon-rotate': -90,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    },
    paint: {
      'icon-color': ['get', 'color']
    }
  }

  return (
    <Source
      id='full-width'
      type='geojson'
      data={geojson}
    >
      <Layer type='line' {...layerStyle} />
      <Layer {...arrowsLayer} />
    </Source>
  )
}
