import styled from 'styled-components/macro'

export const HelpMsgContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: ${props => props?.color || '#ffb633'};
  margin-bottom: 10px;
`

export const HelpMsgText = styled.h4`
  color: #ffffff;
  font-size: 12px;
  margin: 0;
  padding: 0;
`
