import styled from 'styled-components/macro'

export const NoResultsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 25px;
  background-color: #ECECEC;
  color: #000000;
  font-size: 14px;
  text-align: center;
  width: ${props => props?.width ? props.width : '95%'};
`

export const NoResultsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`
