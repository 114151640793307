import React from 'react'
import { Container, Icon, Label, Option, Options, SecurityContainer, Vows, VowsIcon } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import { handleClickWebToAppButton, setNoLoginDialog, setStopSecurity } from '../../../../actions/ui'
import { useLocation } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { Box, useMediaQuery } from '@mui/material'
import { REACT_APP_HOST } from '../../../../constants/config'

export const Security = () => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const stopSecurity = useSelector(state => state?.ui?.components?.stopSecurity)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userData = useSelector(state => state?.user?.userData)
  const mobile = useMediaQuery('(max-width:480px)')

  const handleClick = async (value) => {
    if (userData) {
      dispatch(setStopSecurity({ ...stopSecurity, enabled: !value }))
    } else {
      dispatch(setNoLoginDialog(true))
    }

    dispatch(handleClickWebToAppButton(userData ? value : false, {
      from: value ? 'stop_survey_security_yes' : 'stop_survey_security_no',
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString(),
      link_to: `${REACT_APP_HOST}/${urlParams?.city_id}/${urlParams?.stop_id}`
    }, logEvent))
  }

  return (
    <SecurityContainer mobile={mobile}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Icon />
        <Label mode={mobile}>{t('stop.stop_secure')}</Label>
      </Box>
      <Options>
        {stopSecurity?.options?.map((option, i) => {
          return (
            <Container key={i}>
              <Option
                onClick={() => handleClick(option?.value)}
                label={option.label}
                sx={{
                  width: '48px',
                  height: '24px',
                  '& .MuiChip-label': {
                    position: 'relative'
                  },
                  borderColor: option.selected ? 'none' : '#E0E8F7',
                  backgroundColor: option.selected ? '#d8d8d8' : 'none'
                }}
                variant='outlined'
                clickable
              />
              <VowsIcon>
                <Vows color={option?.color}>{option?.vows}</Vows>
              </VowsIcon>
            </Container>
          )
        })}
      </Options>
    </SecurityContainer>
  )
}
