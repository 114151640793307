import React from 'react'
import { ButtonContainer, Container, ContainerFilters, FiltersContainer, FiltersHeader } from './styles'
import { RouteTypes } from '../../../filters/RouteTypes'
import { TransportTypes } from '../../../filters/TransportTypes'
import { Walk } from '../../../filters/Walk'
import { RememberFilters } from '../../../filters/RememberFilters'
import { SearchFiltersButton } from '../../../filters/SearchFiltersButton'
import { SearchNavbar } from '../../search/SearchNavbar'

export const Filters = () => {
  return (
    <ContainerFilters>
      <FiltersHeader>
        <SearchNavbar />
      </FiltersHeader>
      <Container>
        <FiltersContainer style={{ height: '90%' }}>
          <Walk />
          <RouteTypes />
          <TransportTypes />
        </FiltersContainer>
        <div style={{ padding: '0 20px 35px 20px', borderBottom: '1px solid #22283126', height: '10%' }}>
          <RememberFilters />
        </div>
        <ButtonContainer style={{ height: '10%', padding: '16px 0' }}>
          <SearchFiltersButton />
        </ButtonContainer>
      </Container>
    </ContainerFilters>
  )
}
