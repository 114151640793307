import styled from 'styled-components/macro'

export const MapDriversContainer = styled.div`
  position: absolute;
  bottom: 88px;
  right: 40px;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #e8e5d8;
`

export const Information = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 400;
  background-color: ${props => props?.isLine ? '#FAFAFA' : 'none'};
  width: 450px;
  max-height: calc(100vh - 32px);
  border-radius: ${props => props?.isLine ? '16px' : '0'};
`

export const ShareButtonsContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 480px;
  z-index: 402;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
`
