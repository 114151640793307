import React, { useEffect, useState } from 'react'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import TitleDrawer from '../../uicomponents/TitleDrawer'
import FilterDrawer from '../../uicomponents/FilterDrawer'
import { CountriesListContainer, CountryContainer, CountryName, Flag } from './styles'
import { CityChange } from '../Desktop/CityChange'
import { useDispatch, useSelector } from 'react-redux'
import { setCities, setCountrySelected, setOpenCities, setOpenCountries, setOpenCountryAlert } from '../../actions/ui'
import { logEvent } from '../../firebase/firebase-config'
import { getAllCities } from '../../db/getAllCities'
import { useTranslation } from 'react-i18next'
import { SwipeableDrawer } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

const CountriesDrawer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const mobileMode = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  const [countriesSearchResults, setCountriesSearchResults] = useState([])
  const [countriesSearch, setCountriesSearch] = useState('')

  const userPosition = useSelector(state => state?.user?.userPosition)
  const { openCountries, openCountryAlert } = useSelector(state => state?.ui?.components)
  const { countries } = useSelector(state => state?.ui?.latam)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    setCountriesSearchResults(countries)
  }, [countries])

  const handleSearch = (e) => {
    setCountriesSearch(e.target.value)
  }

  useEffect(() => {
    const results = countries?.filter((country) =>
      country.name_es
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          countriesSearch
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        )
    )
    setCountriesSearchResults(results)
  }, [countriesSearch])

  const handleClose = () => {
    if (cityConfig) {
      dispatch(setOpenCountries(false))
    } else {
      dispatch(setOpenCountries(false))
      dispatch(setOpenCountryAlert(true))
      dispatch(setOpenCountries(true))
    }
  }

  const handleSnackbarCountryClose = () => {
    dispatch(setOpenCountryAlert(false))
  }

  const handleCountrySelect = async (country) => {
    const cities = await getAllCities(country?.country_id)
    dispatch(setCities(cities))

    let lastCountryId = -1
    let lastCountryName = null

    if (cityConfig && cityConfig.country && cityConfig.country.country_id) {
      lastCountryId = cityConfig.country.country_id
      lastCountryName = cityConfig.country.name_es
    }

    const eventParams = {
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      last_country_id: lastCountryId,
      last_country_name: lastCountryName,
      country_id: country?.country_id,
      country_name: country?.name_es,
      user_id: sessionStorage.getItem('uid')
    }

    logEvent('change_country', eventParams)

    dispatch(setOpenCountries(false))
    dispatch(setCountrySelected(country?.country_id))
    dispatch(setOpenCities(true))
  }

  const handleOpen = () => {
    dispatch(setOpenCountries(true))
  }

  return (
    <>
      {mobileMode
        ? (<>
          <SwipeableDrawer
            disableDiscovery
            disableSwipeToOpen
            sx={{
              '& .MuiDrawer-paper': {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '13px 16px 16px 16px',
                backgroundColor: '#ffffff',
                borderRadius: '10px 10px 0 0',
                boxSizing: 'border-box',
                maxHeight: '80vh'
              },
              '& .MuiDrawer-modal': {
                zIndex: '402 !important'
              }
            }}
            anchor='bottom'
            open={openCountries}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <SwipeableBar />
            <TitleDrawer title={t('home.change_country.title')} />
            <FilterDrawer
              placeholder={t('home.change_country.autocomplete.placeholder')}
              value={countriesSearch}
              onChangeFunction={handleSearch}
            />

            {countriesSearchResults
              ? (
                <CountriesListContainer>
                  {countriesSearchResults.map((item) => {
                    return (
                      <CountryContainer
                        onClick={() => handleCountrySelect(item)}
                        key={item.country_id}
                      >
                        <Flag src={item.flag_uri} />
                        <CountryName>{item.name_es}</CountryName>
                      </CountryContainer>
                    )
                  })}
                </CountriesListContainer>
                )
              : (
                  ''
                )}
          </SwipeableDrawer>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openCountryAlert}
            onClose={handleSnackbarCountryClose}
            message={t('home.change_country.alert')}
          />
        </>
          )
        : (
          <CityChange />
          )}
    </>
  )
}

export default CountriesDrawer
