import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_API_FEED } from '../constants/config'
/** getTripUpdates()
 *
 * @param {number} cityId
 * @param {string} stopId
 * @param signal
 * @returns {Promise<Response>}
 */

export const getTripUpdates = async (cityId, stopId, signal) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    stop_id: stopId
  }

  let reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  if (signal) {
    reqConfig = {
      ...reqConfig,
      signal
    }
  }

  return fetch(`${REACT_APP_API_FEED}/${cityId}/realtimeFeed/tripUpdates`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting realtime')
      }
    })
    .then((response) => response)
    .catch((err) => {
      console.error(err)
    })
}

getTripUpdates.propTypes = {
  cityId: PropTypes.number.isRequired,
  stopId: PropTypes.string.isRequired
}
