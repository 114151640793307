import { handleReportClick } from '../actions/map'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../db/getLocalUserUid'
import { useNavigate } from 'react-router-dom'
import { enabledAlertsAndNewsComponent, setCurrentNavbarPosition, setOpenAlertInformation } from '../actions/ui'
import { logEvent } from '../firebase/firebase-config'

export const useAlerts = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { userReportDrawerRef } = useSelector(state => state?.ui)
  const uid = getLocalUserUid()
  const user = useSelector(state => state?.user?.auth)
  const language = window.navigator.language.toLowerCase()
  const alertTypes = useSelector(state => state?.ui?.alertTypes)

  const handleBack = () => {
    dispatch(setCurrentNavbarPosition(0))
    dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
    navigate('/')
  }

  const handleAlertClick = (alert, map) => {
    dispatch(setOpenAlertInformation(true))

    if (alert?.isAlert) {
      dispatch(handleReportClick(
        alert,
        language,
        alertTypes,
        cityConfig,
        userPosition,
        user.uid,
        logEvent,
        map,
        userReportDrawerRef,
        navigate
      ))
    } else {
      logEvent('alert_clicked', {
        alert_id: alert.id,
        is_alert: alert.isAlert,
        lat: userPosition ? userPosition?.lat : null,
        lng: userPosition ? userPosition?.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: uid,
        os: 'web'
      })
      dispatch(enabledAlertsAndNewsComponent({ enabled: true, current: alert }))
      navigate(`/news/${alert.id}`)
    }
  }

  return {
    handleAlertClick,
    handleBack
  }
}
