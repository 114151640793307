import { createGlobalStyle } from 'styled-components'
import { REACT_APP_FONT_FAMILY } from './constants/config'

const fontFamily = REACT_APP_FONT_FAMILY || 'Roboto-Regular' // Fallback to 'Roboto-Regular' if not specified

const AppGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: '${fontFamily}';
    src: local('${fontFamily}'), url('/fonts/${fontFamily}.otf') format('trueType');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: '${fontFamily}', sans-serif;
  }
`

export default AppGlobalStyle
