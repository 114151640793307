import styled from 'styled-components/macro'

export const ButtonFilterIcon = styled.div` 
    width: 24px;
    height: 24px; 
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat; 
    display: inline-block; 
    background-color: ${props => props?.isSelected ? props.theme.palette.icons.primary.main : props.theme.palette.icons.secondary.main};
    mask:url(${props => props?.src}) center/contain;
`
