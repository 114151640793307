import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const getArrivalsRealTime = async (params, signal) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    city_id: params?.city_id,
    url: params?.url,
    method: params?.method || 'GET',
    body: params?.body || {},
    api_buenos_aires: params?.api_buenos_aires || false
  }

  let reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  if (signal) {
    reqConfig = {
      ...reqConfig,
      signal
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/proxy`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting realtime')
      }
    })
    .then(response => response.result)
    .catch((err) => console.error('error', err))
}

getArrivalsRealTime.propTypes = {
  params: PropTypes.object.isRequired
}
