import React from 'react'
import { LogoLoader, SkeletonContainer } from '../../GlobalStyles'
import logo from '../../img/ualabeelogo.svg'
import { Skeleton } from '@mui/material'
import { REACT_APP_HARDCODED_LOGO } from '../../constants/config'

export const SkeletonApp = () => {
  return (
    <SkeletonContainer>
      <LogoLoader src={REACT_APP_HARDCODED_LOGO || logo} />
      <Skeleton
        animation='wave'
        variant='rect'
        width='100%'
        height='100vh'
      />
    </SkeletonContainer>
  )
}
