import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const deleteAccount = async (anonToken) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = anonToken || await user.getIdToken()

  const reqConfig = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/users`, reqConfig).then((response) => {
    if (response.ok) {
      return response.json()
    }
  }).then((response) => response?.result)
    .catch((err) => console.error(err))
}
