import PropTypes from 'prop-types'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'

export const loadSurveyTypes = async () => {
  const docRef = doc(db, 'global/maintenance/surveys/stops/survey_types/security')
  const docSnap = await getDoc(docRef)

  return docSnap.exists() ? docSnap.data()?.no_reasons : null
}

loadSurveyTypes.propTypes = {
  db: PropTypes.string.isRequired
}
