import styled from 'styled-components/macro'
import { theme } from '../../theme/theme'
import { AccessTimeRounded } from '@mui/icons-material'

export const Icon = styled(AccessTimeRounded)`
    margin-right: 16px;
    color: ${theme.palette.icons.secondary.main};
`

export const Label = styled.p`
    margin: 0;
    color: ${theme.palette.icons.secondary.main}; 
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
`
