import React, { useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenShareDrawer } from '../../actions/ui'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LinkIcon from '@mui/icons-material/Link'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'

import {
  ShareButton,
  ShareButtonContainer,
  ShareButtonsContainer,
  ShareButtonText,
  ShareDrawerContainer,
  ShareDrawerTitle,
  ShareItemsContainer,
  SnackbarContentContainer
} from './styles'
import { useTranslation } from 'react-i18next'

function SlideTransition(props) {
  return <Slide {...props} direction='up' />
}

const ShareDrawer = () => {
  const dispatch = useDispatch()
  const { openShareDrawer } = useSelector(state => state?.ui)
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  const handleCopy = () => {
    navigator?.clipboard?.writeText(openShareDrawer?.params?.shortLink).then(function() {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }, function(err) {
      /* clipboard write failed */
      console.error(err)
    })
  }

  const handleWhatsAppShare = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    if (mobile) {
      window.open(`whatsapp://send?text=${openShareDrawer?.params?.shortLink}`, '_blank')
    } else {
      window.open(`https://web.whatsapp.com/send?text=${openShareDrawer?.params?.shortLink}`, '_blank')
    }
  }

  // const handleNativeShare = async () => {
  //
  //     const shareData = {
  //         title: openShareDrawer?.linkData?.title,
  //         text: openShareDrawer?.linkData?.description,
  //         url: openShareDrawer?.params?.shortLink
  //     }
  //
  //     try {
  //         await navigator.share(shareData)
  //
  //     } catch (err) {
  //         console.log(err);
  //     }
  //
  // }

  return (
    <>
      <SwipeableDrawer
        disableEnforceFocus
        disableDiscovery
        disableSwipeToOpen
        sx={{
          '& .MuiDrawer-paper': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '0',
            backgroundColor: '#ffffff',
            borderRadius: '14px 14px 0 0',
            boxSizing: 'border-box'
          },
          '& .MuiDrawer-root': {
            zIndex: '500 !important'
          }
        }}
        anchor='bottom'
        open={openShareDrawer?.enabled}
        onClose={() => dispatch(setOpenShareDrawer({ enabled: false, params: undefined, linkData: undefined }))}
      >
        <ShareDrawerContainer>
          <SwipeableBar />
          <ShareItemsContainer>
            <ShareDrawerTitle>{t('share.title')}</ShareDrawerTitle>
            <ShareButtonsContainer>
              <ShareButtonContainer>
                <ShareButton onClick={handleWhatsAppShare} size='medium' bgcolor='#26D467'>
                  <WhatsAppIcon style={{ color: '#ffffff' }} />
                </ShareButton>
                <ShareButtonText>
                  Whatsapp
                </ShareButtonText>
              </ShareButtonContainer>
              <ShareButtonContainer>
                <ShareButton onClick={handleCopy} size='medium'>
                  <LinkIcon style={{ color: '#C8CBD0' }} />
                </ShareButton>
                <ShareButtonText>
                  Copiar enlace
                </ShareButtonText>
              </ShareButtonContainer>
            </ShareButtonsContainer>
          </ShareItemsContainer>
        </ShareDrawerContainer>

      </SwipeableDrawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={copied}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContentContainer>

          Enlace copiado
        </SnackbarContentContainer>
      </Snackbar>
    </>
  )
}

export default ShareDrawer
