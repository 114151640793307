import styled from 'styled-components/macro'

export const TripResultsContainer = styled.div`
    width: 100vw;
    height: 100vh; 
`

export const Information = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 400;  
    width: 450px; 
    height: calc(100vh - 32px); 
    max-height: calc(100vh - 32px); 
    background-color: #FAFAFA; 
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`

export const ResultsContainer = styled.div`
    background-color: white;
    padding: 0;
    border-radius: 16px;
    box-shadow: 0 0 24px #22283126;
    position: relative;
    max-height: calc(100% - 270px);
    height: calc(100% - 270px);
    overflow: auto;
    width: 100%;
`
