import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, IconButton, Popover, useMediaQuery } from '@mui/material'
import { AccountCircleRounded } from '@mui/icons-material'
import { setUserContribution } from '../../../actions/user'
import { ProfilePopoverContainer } from './styles'
import { getContributionHistory } from '../../../db/getContributionHistory'
import UserProfileStats from '../../UserProfileStats'
import { setOpenLoginPopup } from '../../../actions/ui'
import { getAuth } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'

const UserProfilePreview = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const auth = getAuth()
  const user = auth.currentUser

  const userData = useSelector(state => state?.user?.userData)
  const contributionHistory = useSelector(state => state?.user.userContributionHistory)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const openLoginPopup = useSelector(state => state?.ui?.openLoginPopup)

  const avatarRef = useRef(null)
  const mobile = useMediaQuery('(max-width:480px)')

  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (cityConfig && userData && !contributionHistory) {
      getContributionHistory(cityConfig?.city_id)
        .then((response) => {
          dispatch(setUserContribution(response))
        })
    }
  }, [cityConfig, userData])

  useEffect(() => {
    if (mobile || !avatarRef?.current || pathname !== '/login') return
    avatarRef.current.click()
  }, [avatarRef, pathname])

  const handleClick = () => {
    if (userData) {
      dispatch(setOpenLoginPopup(!openLoginPopup))
    } else {
      navigate('/login')
    }
  }

  const handleClose = () => {
    dispatch(setOpenLoginPopup(false))
  }

  return (
    <>
      <IconButton
        ref={avatarRef}
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '40px',
          padding: '10px',
          zIndex: 400
        }}
        aria-label='search'
      >
        {userData
          ? (
            <Avatar
              src={userData?.photo_url || user?.photoURL || user?.providerData[0]?.photoURL}
              sx={{
                width: '36px',
                height: '36px'
              }}
            />
            )
          : (
            <AccountCircleRounded
              sx={{
                color: '#A6A9AC',
                width: '36px',
                height: '36px'
              }}
            />
            )}
      </IconButton>
      <Popover
        id={id}
        open={openLoginPopup}
        anchorEl={avatarRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        elevation={0}
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '6px',
            boxShadow: 'rgb(34 40 49 / 15%) 0px 0px 24px'
          }
        }}
      >
        <ProfilePopoverContainer>
          {!user?.isAnonymous &&
            <UserProfileStats
              contributionHistory={contributionHistory}
              userData={userData}
              configButton
            />}
        </ProfilePopoverContainer>
      </Popover>
    </>
  )
}

export default UserProfilePreview
