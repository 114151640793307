import React from 'react'
import { setUserProperties } from './firebase/firebase-config'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme/theme'
import './index.css'
import Amplify from '@aws-amplify/core'
import awsExports from './aws-exports'
import Auth from '@aws-amplify/auth'
import { App } from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
  REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR,
  REACT_APP_VARIANT_ID
} from './constants/config'

Sentry.init({
  dsn: 'https://d4168672af3b4e73aa06a395fe56d211@o4504459030691840.ingest.sentry.io/4504459115298816',
  integrations: [new BrowserTracing(), new Sentry.Replay(), new Sentry.Integrations.Breadcrumbs({ console: false })],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: REACT_APP_ENVIRONMENT
})

Amplify.configure(awsExports)
Auth.configure({ mandatorySignIn: false })

setUserProperties({ variant_id: REACT_APP_VARIANT_ID.toString() })

ReactDOM.render(

  <ThemeProvider theme={{
    ...theme,
    palette: {
      ...theme.palette,
      text: {
        primary: {
          main: REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
        },
        secondary: {
          main: REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
          dark: REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR
        }
      }
    }
  }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
