import styled from 'styled-components/macro'
import { CircularProgress } from '@mui/material'

export const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
`

export const Circle = styled(CircularProgress)`
    color: ${props => props.theme.palette.primary.main} !important;
`
