import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const sendToPhone = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(params)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/users/sendToPhone`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .then((response) => response)
    .catch((err) => console.error(err))
}

sendToPhone.propTypes = {
  params: PropTypes.object.isRequired
}
