import styled from 'styled-components/macro'
import { Button, Card } from '@mui/material'

export const AlertsContainer = styled(Card)`
`

export const Container = styled.div`
    height: calc(100vh - 392px);
    overflow-x: hidden;
    margin-right: 6px;
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {  
      border-radius: 7px;
      background-color: #E0E8F7;  
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    }
`

export const Title = styled.h2`
    margin: 0 0 0 24px;
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.palette.text.primary.main};
`

export const ItemsContainer = styled.div`  
`

export const Item = styled(Button)` 
`

export const ItemImg = styled.div`
    width: 100%;   
    background-image: url(${props => props?.img});
    background-color: #cccccc;
    height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 8px;
`

export const ItemInfo = styled.div`
    color: #222831;
    width: 75%; 
    border-bottom: 1px solid #E8EDEE;
`

export const ItemTitle = styled.h3`
    margin: 0;
    font-size: 16px; 
    font-weight: 500;
    text-align: start;
    line-height: normal;
    color: ${props => props.theme.palette.text.primary.main};
    &:first-letter {
        text-transform: uppercase;
    }
`

export const ItemText = styled.p`
    margin: 0;
    height: 57px; 
    font-size: 14px;
    width: 100%;
    margin-bottom: 5px; 
    text-align: initial;
    overflow: hidden;
    line-height: normal;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.text.primary.main};
    &:first-letter {
        text-transform: uppercase;
    }
`
