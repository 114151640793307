import styled from 'styled-components/macro'
import { ArrowBackRounded, MyLocationRounded, Room } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const Container = styled.div`
    width: 100vw; 
    height: ${window.innerHeight}px;
`

export const NavigationBar = styled.div` 
    background-color: white; 
    padding: 8px 16px;
    display: flex;
    align-items: center; 
`

export const BackButton = styled(IconButton)`  
    width: 45px;
    height: 45px;
`

export const BackIcon = styled(ArrowBackRounded)`  
`

export const Title = styled.h1`  
    font-size: 16px;
    margin: 0;
`

export const Location = styled(MyLocationRounded)` 
`

export const Information = styled.div`
    width: 100%;
    background-color: white; 
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    z-index: 400;
    padding: 20px 0
`

export const Address = styled.p`
    margin: 0; 
`

export const AddressContent = styled.div` 
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AddressIcon = styled(Room)` 
`
