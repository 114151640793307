import styled from 'styled-components/macro'

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 97px;
  background-color: #efefef;
  border-radius: 10px;  
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 24px 17px; 
`
