import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { CloseRounded } from '@mui/icons-material'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Switch,
  Typography,
  useMediaQuery
} from '@mui/material'
import { setCurrentFilters, setOpenMapFilters, setShowFilters } from '../../actions/ui'
import { setLocalFiltersConfig } from '../../db/setLocalMapFilters'
import { getLocalFiltersConfig } from '../../db/getLocalMapFilters'

import { Image } from '../../GlobalStyles'

import { setBikeStations, setRefillPoints, setStops, setSubwayAndTrainNetworks, setUserReports } from '../../actions/map'
import { getRechargePointsByLocation } from '../../db/getRechargePointsByLocation'
import { logEvent } from '../../firebase/firebase-config'

export const MapFilters = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')

  const filters = useSelector(state => state?.ui?.components?.mapFilters)

  const handleClose = () => {
    dispatch(setOpenMapFilters(false))
  }

  const handleOpen = () => {
    dispatch(setOpenMapFilters(true))
  }

  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (filters?.enabled && ref.current && !ref.current?.contains(e.target)) {
        dispatch(setShowFilters(false))
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filters])

  return (
    <>
      {mobile
        ? (
          <SwipeableDrawer
            disableDiscovery
            disableSwipeToOpen
            disableEnforceFocus
            elevation={0}
            anchor='bottom'
            onClose={handleClose}
            onOpen={handleOpen}
            open={filters?.enabled}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '10px 10px 0 0'
              },
              zIndex: '401 !important'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '13px 16px 76px'
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '3.75px',
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  boxSizing: 'border-box',
                  display: 'table'
                }}
              />
              <Typography
                variant='h6'
                sx={{
                  fontSize: '18px',
                  padding: 0,
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  margin: '12px 0px 16px'
                }}
              >
                {t('home.filters.title')}
              </Typography>
              <Options />
            </Box>
          </SwipeableDrawer>
          )
        : (<Box
            sx={{
              backgroundColor: '#fff',
              position: 'absolute',
              bottom: '24px',
              right: '64px',
              borderRadius: '16px',
              padding: '8px 16px 16px 16px',
              boxShadow: '0px 3px 6px #22283129',
              display: filters?.enabled ? 'block' : 'none'
            }}
           >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant='h6'
              sx={{
                fontSize: '16px',
                padding: 0,
                margin: '0 8px 0 0',
                fontWeight: 400,
                whiteSpace: 'nowrap'
              }}
            >
              {t('home.filters.title')}
            </Typography>
            <IconButton
              onClick={handleClose}
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Options />
        </Box>
          )}
    </>
  )
}

const Options = () => {
  const dispatch = useDispatch()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const stops = useSelector(state => state?.map?.markers.stops)
  const center = useSelector(state => state?.map?.center)
  const currentFilters = useSelector(state => state?.ui?.currentFilters)
  const { userReports, bikeStations } = useSelector(state => state?.map?.markers)
  const user = useSelector(state => state?.user?.auth)
  const { trips } = useSelector(state => state?.map?.subwayAndTrainNetworks)

  const handleChange = async (checked, id) => {
    const localFilters = getLocalFiltersConfig()

    const filterSelected = currentFilters?.find((transport) => transport?.transport_type_id === id)

    const newFilters = []

    currentFilters?.forEach((transport) => {
      if (transport?.transport_type_id === id) {
        transport.checked = checked
      }
      if (!filterSelected.alone && transport.alone) {
        transport.checked = false
      }
      if (filterSelected.alone && !transport.alone) {
        transport.checked = false
      }
      if (!checked && filterSelected.alone && !transport.alone) {
        transport.checked = true
      }
      if (filterSelected.alone && transport.alone && transport?.transport_type_id !== id) {
        transport.checked = false
      }
      newFilters.push(transport)
    })

    const currentMapFilters = newFilters?.map((transport) => {
      return {
        id: transport.transport_type_id,
        show: transport.checked
      }
    })

    const currentLocalFilters = localFilters?.map((filters) => {
      if (cityConfig?.city_id === filters?.city_id) {
        filters.filters = currentMapFilters
      }
      return filters
    })

    setLocalFiltersConfig(currentLocalFilters)
    dispatch(setCurrentFilters(newFilters))

    switch (id) {
      case 0: { // Refill points
        if (checked) {
          const eventParams = {
            os: 'web',
            lat: userPosition ? userPosition?.lat : null,
            lng: userPosition ? userPosition?.lng : null,
            city_id: cityConfig?.city_id.toString(),
            user_id: user.uid
          }

          // Send events to analytics
          logEvent('card_recharge_places', eventParams)

          const currentStops = stops?.map((group) => {
            group.enabled = false
            return group
          })

          dispatch(setStops(currentStops))

          const refillPoints = await getRechargePointsByLocation(cityConfig, center)

          dispatch(setRefillPoints({ enabled: true, points: refillPoints }))
          dispatch(setUserReports({ enabled: false, reports: userReports?.reports }))
          dispatch(setBikeStations({ enabled: false, stations: bikeStations?.stations }))
          dispatch(setSubwayAndTrainNetworks({ enabled: false, trips }))
        } else {
          dispatch(setRefillPoints({ enabled: false, points: undefined }))
          dispatch(setUserReports({ enabled: true, reports: userReports?.reports }))
          dispatch(setBikeStations({ enabled: true, stations: bikeStations?.stations }))

          const currentStops = stops?.map((group) => {
            if (group?.transport_type_id === id) {
              group.enabled = true
            }
            return group
          })

          dispatch(setStops(currentStops))
          dispatch(setSubwayAndTrainNetworks({ enabled: checked, trips }))
        }

        break
      }
      case -1: { // User reports
        if (checked) {
          dispatch(setRefillPoints({ enabled: checked, points: null }))
          dispatch(setSubwayAndTrainNetworks({ enabled: false, trips }))
        }
        dispatch(setUserReports({ enabled: checked, reports: userReports?.reports }))
        checked && dispatch(setRefillPoints({ enabled: checked, points: undefined }))

        break
      }
      case 5: { // Bike stations
        dispatch(setBikeStations({ enabled: checked, stations: bikeStations?.stations }))
        checked && dispatch(setRefillPoints({ enabled: checked, points: undefined }))

        break
      }
      case 'network_map': { // Subway and train networks
        if (checked) {
          const currentStops = stops?.map((group) => {
            group.enabled = false
            return group
          })

          dispatch(setStops(currentStops))

          dispatch(setRefillPoints({ enabled: false, points: null }))
          dispatch(setUserReports({ enabled: false, reports: userReports?.reports }))
          dispatch(setBikeStations({ enabled: false, stations: bikeStations?.stations }))
          dispatch(setSubwayAndTrainNetworks({ enabled: true, trips }))
        } else {
          dispatch(setSubwayAndTrainNetworks({ enabled: false, trips }))
          dispatch(setUserReports({ enabled: true, reports: userReports?.reports }))
          dispatch(setBikeStations({ enabled: true, stations: bikeStations?.stations }))

          const currentStops = stops?.map((group) => {
            if (group?.transport_type_id === id) {
              group.enabled = true
            }
            return group
          })

          dispatch(setStops(currentStops))
        }

        break
      }
      default: { // Transports
        if (checked) {
          dispatch(setRefillPoints({ enabled: checked, points: null }))
          dispatch(setSubwayAndTrainNetworks({ enabled: false, trips }))
        }

        const currentStops = stops?.map((group) => {
          if (group?.transport_type_id === id) {
            group.enabled = checked
          }
          return group
        })

        dispatch(setStops(currentStops))
      }
    }
  }

  return (
    <List
      sx={{
        width: '100%',
        maxHeight: '164px',
        overflow: 'auto',
        '@media (max-width:480px)': {
          maxHeight: 'none',
          overflow: 'auto'
        }
      }}
    >
      {currentFilters?.map((transport) => {
        return (
          <ListItem key={transport.name}>
            {transport?.icon &&
              <Image
                width='25px'
                height='25px'
                margin='0 16px 0 0'
                alt='transport icon'
                src={transport?.icon}
              />}
            {transport.mui_icon && transport.mui_icon}
            <ListItemText sx={{ margin: '0 8px 0 0' }}>{transport.name}</ListItemText>
            <Switch
              checked={transport?.checked}
              onChange={(e) => handleChange(e?.target?.checked, transport?.transport_type_id)}
            />
          </ListItem>
        )
      })}
    </List>
  )
}
