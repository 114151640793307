import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const getUserReportById = async (id, cityId) => {
  const reqBody = {
    id,
    city_id: Number(cityId)
  }

  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/userReports/getById`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting alert user map report')
      }
    })
    .then((response) => response)
    .catch((err) => console.error(err))
}
