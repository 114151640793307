import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { logEvent } from '../../../../firebase/firebase-config'
import { setOpenMapFilters } from '../../../../actions/ui'
import { theme } from '../../../../theme/theme'
import { FilterListRounded } from '@mui/icons-material'

export const Filters = () => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state?.ui?.components?.mapFilters)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const user = useSelector(state => state?.user?.auth)
  const userData = useSelector(state => state?.user?.userData)
  const savedPlaces = useSelector(state => state?.user?.savedPlaces)
  const hasSavedPlaces = savedPlaces?.some(place => place?.city_id?.toString() === cityConfig?.city_id?.toString())

  const handleOpenFilters = () => {
    dispatch(setOpenMapFilters(!filters?.enabled))

    const eventParams = {
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: user.uid,
      city_id: cityConfig?.city_id.toString(),
      os: 'web',
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    }

    // Send events to analytics
    logEvent('open_map_filters_menu', eventParams)
  }

  return (
    <IconButton
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        bottom: hasSavedPlaces || userData ? -110 : -61,
        right: 0,
        width: 45,
        height: 45,
        boxShadow: '0 0 4px 0 rgb(0 0 0 / 20%), 0 6px 12px 0 rgb(0 0 0 / 8%)',
        zIndex: 400,
        backgroundColor: '#ffffff !important',
        '&:hover': {
          backgroundColor: '#ffffff !important'
        }
      }}
      onClick={handleOpenFilters}
    >
      <FilterListRounded
        sx={{
          color: theme.palette.icons.primary.main
        }}
      />
    </IconButton>
  )
}
