import React from 'react'
import { ResultsContainer, Title } from './styles'
import { RecommendedRoutes } from '../../../tripResults/RecommendedRoutes'
import { AlternativeRoutes } from '../../../tripResults/AlternativeRoutes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AdsBanner } from '../../../../components/SearchResults'
import FeedbackBar from '../../../FeedbackBar'
import { REACT_APP_VARIANT_ID } from '../../../../constants/config'

export const Results = (props) => {
  const { t } = useTranslation()

  const {
    searchResultsFeedbackObj,
    showBar,
    setShowBar
  } = props

  const ads = useSelector(state => state.ui.ads.searchResult)

  return (
    <ResultsContainer
      showAds={ads?.length >= 1}
      showBar={props?.showBar}
      sx={{
        backgroundColor: '#fff'
      }}
    >
      <Title>{t('trip_share.recommended_routes_title')}</Title>
      <RecommendedRoutes {...props} />
      <Title>{t('trip_share.alternatives')}</Title>
      <AlternativeRoutes />
      <FeedbackBar
        feedbackObj={searchResultsFeedbackObj}
        top='0px'
        borderRadius='16px 16px 0 0'
        padding='10px'
        formContainer='modal'
        showBar={showBar}
        setShowBar={setShowBar}
        questionId={2}
        screen='SEARCH_RESULTS'
        barTittle={parseInt(REACT_APP_VARIANT_ID) === 10 ? '¿Algo que reportar sobre los resultados?' : t('trip_share.feedback.bar_title')}
      />
      <AdsBanner />
    </ResultsContainer>
  )
}
