import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../../constants/config'

export const getCustomToken = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/auth/getCustomToken`, reqConfig).then(response => {
    return response.json()
  }).then(response => response?.customToken)
}
