import styled from 'styled-components/macro'
import { Button, Card, Chip, InputBase } from '@mui/material'
import { AllInclusive, ArrowBack, Close, NavigationRounded, SearchRounded } from '@mui/icons-material'

export const SearchContainer = styled(Card)`
    width: 100%;    
    margin-bottom: 12px;
    border-radius: 16px;
    box-shadow: 0px 0px 24px #22283126; 
    z-index: 500;
`

export const SearchResults = styled(Card)` 
`

export const Container = styled.div` 
    padding:  ${props => props?.isTransportFilters ? '16px 24px 0px 24px' : '16px 24px 24px 24px'};
`

export const SearcherContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.palette.outline.primary.main};
    width: 100%;
    height: 46px;
    border-radius: 10px; 
    margin-top: 38px;
`

export const Searcher = styled(InputBase)`
    width: 100%;
    color: ${props => props.theme.palette.text.primary.main};
`

export const SearchIconContainer = styled.div` 
    width: 68px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const SearchIcon = styled(SearchRounded)`
    color: ${props => props.theme.palette.icons.secondary.main};
`

export const BackIcon = styled(ArrowBack)`
    color: ${props => props.theme.palette.icons.secondary.main}; 
`

export const NavigationButton = styled(Button)`
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    &:after {
        content: "";
        background: ${props => props.theme.palette.outline.secondary.main};
        position: absolute;
        bottom: 8px;
        left: 0;
        height: 65%;
        width: 1px;
    } 
`
export const NavigationIcon = styled(NavigationRounded)`
    color: ${props => props.theme.palette.icons.primary.main};
`

export const CloseIcon = styled(Close)`
    color: ${props => props.theme.palette.icons.secondary.main};
`

export const FiltersContainer = styled.div`
    width: 100%;
    height: 48px; 
    display: flex; 
    align-items: flex-end;
    justify-content: flex-start;
    height: 60px;
`

export const AllButtonIcon = styled(AllInclusive)`
    margin-right: 4px; 
`

export const ButtonFilterIcon = styled.div` 
    width: 20px;
    min-width: 20px;
    height: 20px; 
    min-height: 20px;
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat; 
    display: inline-block; 
    background-color: ${props => props?.isSelected ? props.theme.palette.icons.primary.main : props.theme.palette.icons.secondary.main};
     -webkit-mask:url(${props => props?.src}) center/contain;
      mask:url(${props => props?.src}) center/contain; 
`

export const LocationContainer = styled.div`
    display: flex;
    align-items: center;
`

export const PlaceItem = styled.div` 
    display: flex;
    align-items: stretch;
    
    padding-top: 8px;
    padding-left: 15px;
    padding-bottom: 8px;
    cursor: pointer;
    &:hover {
        background-color: #F6F8F9;
    }
`

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 16px; 
`

export const Icon = styled.div` 
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`

export const Distance = styled.p`
    font-size: 12px;
    color: ${props => props.theme.palette.text.secondary.main}; 
    margin: 0; 
`

export const PlaceName = styled.p`
    font-size: 18px;
    color: ${props => props.theme.palette.text.primary.main};  
    margin: 0; 
`

export const PlaceInformation = styled.div`
    display: flex;
    flex-direction: column;
`

export const PlaceCity = styled.p`
    margin: 0;
    color: ${props => props.theme.palette.text.secondary.main};  
    font-size: 14px;
`

export const FindPlaceButton = styled(Chip)` 
`

export const FindPlaceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const WaterMark = styled.div`
    font-size: 12px;
    color: #0078a8;
    margin-bottom: 5px;
    cursor: pointer;
`

export const RecentPlacesContainer = styled.div`
    box-sizing: border-box;
    margin-right: 5px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 20px 23px;
    ::-webkit-scrollbar { 
      width: 6px;
      height: 164px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    }
`
