import React from 'react'
import { LinearProgress } from '@mui/material'

export const Loading = () => {
  return (
    <LinearProgress
      sx={{
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 600
      }}
    />
  )
}
