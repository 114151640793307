import styled from 'styled-components/macro'

export const CityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
  width: 100%;
  min-height: 200px;
    -webkit-box-shadow: inset 0px -59px 22px -10px rgba(0,0,0,0.65);
-moz-box-shadow: inset 0px -59px 22px -10px rgba(0,0,0,0.65);
box-shadow: inset 0px -59px 22px -10px rgba(0,0,0,0.65);
  background: ${(props) => {
  return props.bg ? `url(${props.bg})` : ''
}};
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
`

export const CityTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`

export const Title = styled.h1`
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-right: 4px;
`

export const CityName = styled.h1`
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-style: bold;
`

export const ChangeCityText = styled.h2`
  font-size: 12px;
  color: ${props => props.theme.palette.primary.main};
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const LinksContainer = styled.div`
  display: flex;
  margin: 16px 0 0 0;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`

export const LinkItem = styled.a`
  font-size: 14px;
  color: ${props => props.theme.palette.text.primary.main};
  display: flex;
  padding: 13px 16px;
  margin: 0;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
`

export const LogoUalabee = styled.img`
  width: 160px;
`
export const SocialNetworksContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const CopyText = styled.p`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0%;
`

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
  box-sizing: border-box;
`

export const LegalLink = styled.a`
  font-size: 10px;
  color: ${props => props.theme.palette.primary.main};
  margin: 0%;
`

export const HelperIcon = styled.img`
    color: rgba(0,0,0,.6);
    margin-right: 33px;
`

export const DownloadAppContainer = styled.div` 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
`
