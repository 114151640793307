import styled from 'styled-components/macro'

export const DisclaimerContainer = styled.div`
  font-size: 14px;
  margin: 0;
  color: #9a9898;
  margin-top: 20px;
  text-align: center;
`

export const LegalLink = styled.a`
  font-size: 14px;
  color: #0d98b1;
  margin: 0;
`

export const ProfilePopoverContainer = styled.div`
        width: 336px;
        min-width: 300px;
        min-height: 250px;
        padding: 12px;
        max-height: 50vh;
        height: 100%;
        overflow: auto; 
`
