import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, ButtonContainer, ButtonIcon, Container, Label, NavbarContainer } from './styles'
import { AttachMoneyRounded } from '@mui/icons-material'
import {
  enabledAlertsAndNewsComponent,
  enableTransportFiltersComponent,
  handleRecharge,
  setButtonNavbarSelected,
  setCityInfoSelected,
  setShowNavbar,
  setShowUsefulInformation,
  verifyCityInfo
} from '../../../actions/ui'
import { logEvent } from '../../../firebase/firebase-config'
import { theme } from '../../../theme/theme'
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded'
import { REACT_APP_TICKETS_REWARDS_PROGRAM } from '../../../constants/config'

export const Navbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { alertsAndNews } = useSelector(state => state?.ui?.components)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const auth = useSelector(state => state.user.auth)
  const userData = useSelector(state => state?.user?.userData)

  const handleShowLines = () => {
    logEvent('lines', {
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      user_id: auth.uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString()
    })
    dispatch(setButtonNavbarSelected('lines'))
    dispatch(enableTransportFiltersComponent(true))
    dispatch(setShowNavbar(false))
    navigate('/lines')
  }

  const handleShowCityInfo = () => {
    dispatch(setCityInfoSelected(undefined))

    if (alertsAndNews?.enabled) {
      dispatch(enabledAlertsAndNewsComponent({ enabled: !alertsAndNews?.enabled }))
    }

    dispatch(setButtonNavbarSelected('information'))
    dispatch(setShowUsefulInformation(true))

    if (pathname.includes('city_info')) {
      navigate('/')
    } else {
      const eventParams = {
        lat: userPosition?.lat ? userPosition.lat : null,
        lng: userPosition?.lng ? userPosition.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: auth.uid,
        os: 'web',
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null // String
      }

      logEvent('city_info_button', eventParams)

      navigate('/city_info')
      dispatch(verifyCityInfo())
    }
  }

  const handleShowAlerts = () => {
    logEvent('alerts', {
      user_id: auth.uid,
      city_id: cityConfig?.city_id?.toString(), // String
      os: 'web', // String
      lat: userPosition?.lat || null, // Double
      lng: userPosition?.lng || null, // Double
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    })

    dispatch(setButtonNavbarSelected('alerts'))
    dispatch(enabledAlertsAndNewsComponent({ enabled: !alertsAndNews?.enabled }))

    if (pathname === '/city_info') {
      dispatch(setCityInfoSelected(undefined))
      navigate('/')
      navigate('/news')
    } else {
      if (!alertsAndNews?.enabled) {
        navigate('/news')
      } else {
        navigate('/')
      }
    }
  }

  const handleShowRewards = () => {
    // log event
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_gender: userData?.gender || null // String
    }

    logEvent('ticket_rewards', eventParams)

    if (!document.startViewTransition) {
      navigate(`${!userData ? '/login' : '/rewards/tickets'}`)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => navigate(`${!userData ? '/login' : '/rewards/tickets'}`))
  }

  return (
    <NavbarContainer
      sx={{
        width: '100%',
        borderRadius: '16px',
        boxShadow: '0px 3px 6px #22283129'
      }}
    >
      <Container>

        <ButtonContainer onClick={handleShowLines}>
          <Button>
            <ButtonIcon>
              <svg
                xmlns='http://www.w3.org/2000/svg' width='15.909' height='19.095'
                viewBox='0 0 15.909 19.095'
              >
                <path
                  id='Path_242' data-name='Path 242'
                  d='M9.531,10.841,8.169,12.21A7.028,7.028,0,0,1,6.453,9.375L8.313,8.9A5.159,5.159,0,0,0,9.531,10.841Zm.355-5.8L7.2,2.345a.473.473,0,0,0-.681,0l-2.676,2.7a.482.482,0,0,0,.345.82H5.925a14.525,14.525,0,0,0,.182,2.093l1.86-.473a12.814,12.814,0,0,1-.125-1.62H9.55A.482.482,0,0,0,9.886,5.045Zm9.59,0L16.8,2.354a.473.473,0,0,0-.681,0L13.444,5.045a.478.478,0,0,0,.336.82h1.707c-.1,3.549-1.228,4.581-2.436,5.671A8.344,8.344,0,0,0,11.66,13.03a6.865,6.865,0,0,0-1.084-1.2l-1.352,1.36A3.744,3.744,0,0,1,10.7,16.473h0V20.33a.965.965,0,0,0,.959.964h0a.965.965,0,0,0,.959-.964V16.473h0a3.917,3.917,0,0,1,1.717-3.5c1.323-1.2,2.954-2.681,3.069-7.108h1.726A.482.482,0,0,0,19.476,5.045Z'
                  transform='translate(-3.705 -2.2)'
                  fill={`${theme?.palette?.primary?.main}`}
                />
              </svg>
            </ButtonIcon>
            <Label>{t('home.navbar.items.lines')}</Label>
          </Button>
        </ButtonContainer>

        {cityConfig?.config?.marketplace_enabled &&
          <ButtonContainer onClick={() => dispatch(handleRecharge())}>
            <Button>
              <ButtonIcon>
                <AttachMoneyRounded
                  sx={{
                    fill: theme.palette.primary.main
                  }}
                />
              </ButtonIcon>
              <Label>{t('home.navbar.items.card_refill')}</Label>
            </Button>

          </ButtonContainer>}

        <ButtonContainer
          sx={{
            backgroundColor: pathname?.includes('city_info') ? '#FAFAFA' : '#fff',
            '&:hover': {
              backgroundColor: pathname?.includes('city_info') ? '#FAFAFA' : '#fff'
            }
          }}
          onClick={handleShowCityInfo}
        >
          <Button>
            <ButtonIcon>
              <svg
                id='Icono_de_información' data-name='Icono de información'
                xmlns='http://www.w3.org/2000/svg'
                width='20' height='20' viewBox='0 0 20 20'
              >
                <path
                  id='Path_515'
                  data-name='Path 515'
                  d='M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm1.72,15.158a.7.7,0,0,1-.154.144,4.82,4.82,0,0,1-2.752.9,1.143,1.143,0,0,1-1.2-1.51c.252-1.056.514-2.108.77-3.162a.758.758,0,0,0-.014-.58.642.642,0,0,0-.334-.268,1.274,1.274,0,0,0-.236-.042c-.1-.022-.238-.038-.308-.144L7.47,10.46A.326.326,0,0,1,7.564,10l.182-.12.27-.15a4.474,4.474,0,0,1,.5-.222A5.772,5.772,0,0,1,9.584,9.2a5.09,5.09,0,0,1,.67-.074,2.448,2.448,0,0,1,.54.058,1.1,1.1,0,0,1,.8,1.438c-.25,1.064-.516,2.124-.776,3.178-.124.5,0,.724.508.86.076.02.154.034.232.052C11.82,14.78,11.882,14.944,11.72,15.158Zm-.854-7.944a1.708,1.708,0,1,1,1.716-1.688A1.708,1.708,0,0,1,10.866,7.214Z'
                  fill={theme.palette.primary.main}
                />
              </svg>
            </ButtonIcon>
            <Label>{t('home.navbar.items.alt_info_uppercase')}</Label>
          </Button>
        </ButtonContainer>

        <ButtonContainer
          sx={{
            backgroundColor: pathname?.includes('news') ? '#FAFAFA' : '#fff',
            '&:hover': {
              backgroundColor: pathname?.includes('news') ? '#FAFAFA' : '#fff'
            }
          }}
          onClick={() => handleShowAlerts()}
        >
          <Button>
            <ButtonIcon>
              <svg
                xmlns='http://www.w3.org/2000/svg' width='15.17' height='19.5'
                viewBox='0 0 15.17 19.5'
              >
                <path
                  id='Path_539'
                  data-name='Path 539'
                  d='M12,22a2.006,2.006,0,0,0,2-2H10A2,2,0,0,0,12,22Zm6-6V11c0-3.07-1.64-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.63,5.36,6,7.92,6,11v5L4.71,17.29A1,1,0,0,0,5.41,19H18.58a1,1,0,0,0,.71-1.71Z'
                  transform='translate(-4.414 -2.5)'
                  fill={theme.palette.primary.main}
                />
              </svg>
            </ButtonIcon>
            <Label>{t('home.navbar.items.alt_alerts')}</Label>
          </Button>
        </ButtonContainer>

        {REACT_APP_TICKETS_REWARDS_PROGRAM &&
          <ButtonContainer
            sx={{
              backgroundColor: pathname?.includes('rewards') ? '#FAFAFA' : '#fff',
              '&:hover': {
                backgroundColor: pathname?.includes('rewards') ? '#FAFAFA' : '#fff'
              }
            }}
            onClick={() => handleShowRewards()}
          >
            <Button>
              <ButtonIcon>
                <ConfirmationNumberRoundedIcon
                  sx={{
                    fill: theme.palette.primary.main
                  }}
                />

              </ButtonIcon>
              <Label>{t('tickets_rewards_program.nav_bar_button')}</Label>
            </Button>
          </ButtonContainer>}

      </Container>
    </NavbarContainer>
  )
}
