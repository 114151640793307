import styled from 'styled-components/macro'

export const ModalContainer = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    border: none;
    box-sizing: border-box;
    :focus {
        outline: none;
    }
`

export const Title = styled.h2`
font-size: 20px;
width: 100%;
color: #00CCE3;
margin: 0;
text-align: center;
margin-bottom: 10px;
`

export const Description = styled.p`
font-size: 12px;
color: #000000;
margin: 0;
text-align: center;
`
