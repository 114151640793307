import { OptionText } from '../../../components/FilterDrawer/styles'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { FilterContainer, FilterOption, FilterOptionGroup, FilterTitle } from '../../../GlobalStyles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@mui/material'

export const RememberFilters = () => {
  const { t } = useTranslation()

  const {
    handleCheckboxChange
  } = useFilters()

  const mode = useSelector(state => state?.ui?.mode)
  const filters = useSelector(state => state?.user?.searchFilters)

  return (
    <FilterContainer
      mode={mode}
    >
      <FilterTitle
        mode={mode}
      >
        {t('search_preview.filters.items.filters')}
      </FilterTitle>
      <FilterOptionGroup
        mode={mode}
      >
        <FilterOption>
          <OptionText
            mode={mode}
          >
            {t('search_preview.filters.items.remember_changes')}
          </OptionText>
          <Checkbox
            checked={filters?.remember_settings}
            onChange={handleCheckboxChange}
          />
        </FilterOption>
      </FilterOptionGroup>
    </FilterContainer>
  )
}
