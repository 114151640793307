import { Player } from '@lottiefiles/react-lottie-player'
import EmptyBox from '../../animations/empty_box.json'
import { NoResultsContainer, NoResultsText } from './styles'

export default function NoResultMessage({ message }) {
  return (
    <NoResultsContainer style={{ margin: 0 }}>
      <Player
        autoplay
        loop
        src={EmptyBox}
        style={{ height: '250px', width: '250px' }}
      />
      <NoResultsText width='auto'>
        {message}
      </NoResultsText>
    </NoResultsContainer>
  )
}
