import styled from 'styled-components/macro'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Close,
  ReportRounded,
  ThumbUpAltRounded
} from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const HeaderText = styled.p`
    margin: 0;     
    color: white;
    position: absolute;
    top: 16px;
    left: 24px;
    z-index: 1; 
    text-transform: capitalize; 
`

export const UserInformation = styled.div`
    position: absolute;
    bottom: 12px;
    left: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
`

export const Name = styled.p`
    margin: 0 15px 0 0;     
    color: white; 
    text-transform: capitalize;  
`

export const ReportButton = styled(IconButton)`  
    position: absolute !important;
    bottom: -12px; 
    right: 12px;
    z-index: 1; 
    cursor: pointer; 
`

export const CloseButton = styled(IconButton)`    
    position: absolute !important;
    top: 4px;
    right: 12px;
    z-index: 1;
`

export const ReportIcon = styled(ReportRounded)`  
    color: #F05454;
`

export const CloseIcon = styled(Close)`
    color: white;   
`

export const SwiperButtons = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 12px;
    z-index: 1;
    width: inherit; 
`

export const Prev = styled(IconButton)` 
    margin-right: 10px;
`

export const PrevIcon = styled(ArrowBackIosRounded)`  
    color: white; 
    height: 20px;
`

export const Next = styled(IconButton)`   
    margin-left: 10px;
`

export const NextIcon = styled(ArrowForwardIosRounded)`   
    color: white; 
    height: 20px;
`

export const Image = styled.div`   
    background-image: url(${props => props?.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    width: 100%;
    height: 70vh;
`

export const ThumbIcon = styled(ThumbUpAltRounded)`    
    color: white;
    margin-right: 5px;
`

export const Thumbs = styled.p`    
    color: white;
    margin: 0;
`
