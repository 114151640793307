import React from 'react'
import { ArrivalTimeContainer, Container, Icon, Label, ShowMore } from './style'
import ArrivalTimeIcon from '../../../../img/arrival_time.png'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenArrivalInformation } from '../../../../actions/ui'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import moment from 'moment'
import { logEvent } from '../../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'

export const ArrivalTime = () => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const { t } = useTranslation()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const handleClick = () => {
    dispatch(setOpenArrivalInformation(true))

    // weekend_banner_details event is sent by selecting the View button of how we obtain the arrivals.
    logEvent('weekend_banner_details', {
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: uid,
      day_of_week: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').locale('es').format('dddd'),
      city_id: cityConfig?.city_id.toString(),
      os: 'web'
    })
  }

  return (
    <ArrivalTimeContainer>
      <Container>
        <Icon src={ArrivalTimeIcon} />
        <Label>{t('stop.arrival_times_text')}</Label>
      </Container>
      <ShowMore
        onClick={handleClick}
        clickable
        variant='outlined'
        label={t('stop.watch')}
      />
    </ArrivalTimeContainer>
  )
}
