import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../../constants/config'

/** setRegisterClick()
 * @description Registers the click of the advertisement when the user clicks in a ad in search results page
 * @param {number} adId - ad id clicked
 * @returns {Promise<Response>}
 */

export const setRegisterClick = async (adId) => {
  if (typeof adId !== 'number') throw new Error("ad id param isn't valid!")

  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    ads_id: adId
  }

  const reqConfig = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/ads/registerClick`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('An error occurred while trying to register current ad click')
      }
    })
    .then((response) => response?.result)
    .catch((error) => {
      console.error(error)
    })
}
