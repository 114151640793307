import { subscribeToTopic } from './subscribeToTopic'
import { unsubscribeToTopic } from './unsubscribeToTopic'
import { REACT_APP_VARIANT_ID } from '../constants/config'

export const unsubscribeToCityTopics = async (oldCity, newCity, fcmToken) => {
  if (fcmToken) {
    await Promise.all([
      unsubscribeToTopic(`city_${oldCity}`, fcmToken),
      unsubscribeToTopic(`web_city_${oldCity}`, fcmToken),
      unsubscribeToTopic(`city_${oldCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      unsubscribeToTopic(`web_city_${oldCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      unsubscribeToTopic(`alerts_${oldCity}`, fcmToken),
      unsubscribeToTopic(`web_alerts_${oldCity}`, fcmToken),
      unsubscribeToTopic(`ack_city_${oldCity}`, fcmToken),
      unsubscribeToTopic(`web_ack_city_${oldCity}`, fcmToken),
      unsubscribeToTopic(`alerts_${oldCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      unsubscribeToTopic(`web_alerts_${oldCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      subscribeToTopic(`city_${newCity}`, fcmToken),
      subscribeToTopic(`web_city_${newCity}`, fcmToken),
      subscribeToTopic(`city_${newCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      subscribeToTopic(`web_city_${newCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      subscribeToTopic(`alerts_${newCity}`, fcmToken),
      subscribeToTopic(`web_alerts_${newCity}`, fcmToken),
      subscribeToTopic(`ack_city_${newCity}`, fcmToken),
      subscribeToTopic(`web_ack_city_${newCity}`, fcmToken),
      subscribeToTopic(`alerts_${newCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken),
      subscribeToTopic(`web_alerts_${newCity}_variant_${REACT_APP_VARIANT_ID}`, fcmToken)
    ])
  }

  if (localStorage.getItem('linesFavs') && fcmToken) {
    const oldLines = JSON.parse(localStorage.getItem('linesFavs')).filter(line => line.cityId === oldCity)
    const newLines = JSON.parse(localStorage.getItem('linesFavs')).filter(line => line.cityId === newCity)
    if (oldLines.length !== 0) {
      await Promise.all(oldLines.map((line) => {
        return Promise.all([
          unsubscribeToTopic(`city_${oldCity}_line_${line.lineId}`, fcmToken),
          unsubscribeToTopic(`web_city_${oldCity}_line_${line.lineId}`, fcmToken)
        ])
      }))
    }

    if (newLines.length !== 0) {
      await Promise.all(newLines.map((line) => {
        return Promise.all([
          subscribeToTopic(`city_${newCity}_line_${line.lineId}`, fcmToken),
          subscribeToTopic(`web_city_${newCity}_line_${line.lineId}`, fcmToken)
        ])
      }))
    }
  }
} // unsubscribe current city to topics
