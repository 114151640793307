import React from 'react'
import Box from '@mui/material/Box'
import { Player } from '@lottiefiles/react-lottie-player'
import GreenCheckIcon from '../../animations/green_check_icon.json'
import { Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'

export default function SuccessFeedbackModal({ successModalOpen, setSuccessModalOpen }) {
  const { t } = useTranslation()

  return (
    <Modal
      open={successModalOpen}
      onClose={() => setSuccessModalOpen(false)}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          top: '50%',
          left: '50%',
          width: '75%',
          maxWidth: '350px',
          transform: 'translate(-50%, -50%)',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderRadius: '15px',
          backgroundColor: '#ffffff',
          padding: '25px 20px',
          border: 'none',
          boxSizing: 'border-box',
          '& :focus': {
            outline: 'none'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '3px',
            borderRadius: '100%',
            backgroundColor: '#ffffff',
            position: 'absolute',
            top: '0px',
            right: '50%',
            transform: 'translate(50%,-50%)'
          }}
        >
          <Player
            autoplay
            keepLastFrame
            src={GreenCheckIcon}
            style={{ height: '50px', width: '50px' }}
          />
        </Box>
        <Typography
          variant='h6'
          sx={{
            color: '#000000',
            fontSize: '18px',
            textAlign: 'center',
            margin: '0px 0px 10px 0px',
            fontWeight: 'bold'
          }}
        >
          {t('feedback_success_modal.title')}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            color: '#000000',
            fontSize: '14px',
            textAlign: 'center',
            margin: '0px'
          }}
        >
          {t('feedback_success_modal.description')}
        </Typography>
      </Box>
    </Modal>
  )
}
