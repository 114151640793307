import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import PropTypes from 'prop-types'

export const getUser = async (uid) => {
  const docRef = doc(db, 'users', uid)

  if (uid) {
    try {
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        console.error('No such document!')
        return null
      }
    } catch (e) {
      console.error('Error getting user document:', e)
      return null
    }
  } else {
    console.error('No user id provided')
    return null
  }
}

getUser.propTypes = {
  uid: PropTypes.string.isRequired
}
