import React from 'react'
import ualabeeLogo from '../../img/ualabeelogo.svg'
import { DownloadButton, LandingContainer, LogoContainer, LogoImg, Subtitle, Title } from './styles'
import NavBar from '../NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../uicomponents/layouts/Layout'
import { handleDownload } from '../Menu'
import { useMediaQuery } from '@mui/material'
import { REACT_APP_IS_IFRAME } from '../../constants/config'

export const LandingPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mobile = useMediaQuery('(max-width:480px)')
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  return (
    <Layout>
      <LandingContainer>

        <LogoContainer>
          <LogoImg alt='Ualabee logo' src={ualabeeLogo} />
        </LogoContainer>

        <Title>{t('deep_links.title')}</Title>
        <Subtitle>{t('deep_links.content')}</Subtitle>
        <DownloadButton
          onClick={() => handleDownload(mobile, dispatch)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#1b7585',
            color: 'white',
            fontSize: 22,
            fontWeight: 400,
            width: 300,
            '&:hover': {
              backgroundColor: '#1b7585'
            }
          }}
        >
          {t('deep_links.download')}
        </DownloadButton>

        {isIosApp && !REACT_APP_IS_IFRAME &&
          <NavBar />}
      </LandingContainer>
    </Layout>
  )
}
