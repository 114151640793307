import React from 'react'
import { ShowMore } from '../../../../GlobalStyles'

export const ShowMoreButton = ({ handleClick, label }) => {
  return (
    <ShowMore
      sx={{
        padding: '8px 0',
        width: '100%',
        textTransform: 'none',
        fontWeight: 400,
        borderRadius: 0
      }}
      onClick={handleClick}
    >
      {label}
    </ShowMore>
  )
}
