import styled from 'styled-components/macro'
import { CloseRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const Container = styled.div`  
`

export const Header = styled.div`   
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    border-bottom: 1px solid #E8EDEE;
`

export const Title = styled.p`  
    margin: 0;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
`

export const ContainerContent = styled.div`  
    padding: 20px;  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const Content = styled.p`  
    margin: 16px 0 0 0;
`
export const Realtime = styled.div`   
    position: relative;
`

export const RealtimeContainer = styled.div`   
    position: relative;
    display: flex;
    align-items: baseline;
`

export const RealtimeIcon = styled.img` 
    position: absolute;
    top: 0;
    left: -13px;
`

export const Schedule = styled.p` 
    font-size: 28px;
    color: #222831;
    margin: 0;
    font-weight: 600;
    text-align: center;
`

export const Ancillary = styled.p` 
    margin: 0 0 0 2px;
    font-size: 16px;
    color: #909397; 
    margin-left: 5px; 
`
export const CloseButton = styled(IconButton)`     
`

export const CloseIcon = styled(CloseRounded)`
    color: #A6A9AC;   
`
