import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { FavoriteRounded, HomeRounded, WorkRounded } from '@mui/icons-material'
import { DesktopContainer, PlaceIconContainer, PlaceName, SwipeableContainerH } from './styles'
import {
  enabledAlertsAndNewsComponent,
  setFromPlacesSelected,
  setOpenTripPreview,
  setShowLines,
  setShowNavbar,
  setShowSearcher,
  setToPlacesSelected
} from '../../actions/ui'
import { setZoom } from '../../actions/map'
import { logEvent } from '../../firebase/firebase-config'
import { theme } from '../../theme/theme'
import { REACT_APP_PALLET_PRIMARY_MAIN_COLOR } from '../../constants/config'

const SavedPlaces = ({ border, padding }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const auth = useSelector(state => state?.user?.auth)
  const userData = useSelector(state => state?.user?.userData)
  const orientation = useSelector(state => state?.ui?.orientation)
  const mode = useSelector(state => state?.ui?.mode)
  const savedPlaces = useSelector(state => state?.user?.savedPlaces)

  const [userSavedPlaces, setUserSavedPlaces] = useState([])

  useEffect(() => {
    if (savedPlaces) {
      setUserSavedPlaces(savedPlaces.filter(place => Number(place.city_id) === Number(cityConfig?.city_id)))
    }
  }, [savedPlaces, cityConfig])

  const handlePlaceClick = (place) => {
    if (orientation === 'from') {
      dispatch(setFromPlacesSelected({
        name: place.name || place.address,
        latLng: {
          lat: place.location.latitude,
          lng: place.location.longitude
        }
      }))
    } else {
      dispatch(setToPlacesSelected(
        {
          name: place.name || place.address,
          latLng: {
            lat: place.location.latitude,
            lng: place.location.longitude
          }
        }
      ))
    }

    logEvent('select_fav_place', {
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      city_id: cityConfig?.city_id?.toString(),
      user_id: auth.uid,
      os: 'web',
      user_gender: userData?.gender || null,
      place_id: place.id,
      type: place.type,
      place_lat: place.location.latitude,
      place_lng: place.location.longitude,
      name: place?.name || null,
      address: place?.address !== 'null' ? place.address : null
    })

    navigate('/destination-preview')

    if (!mode) {
      dispatch(setZoom(14))
      dispatch(setOpenTripPreview(true))
      dispatch(setShowLines(false))
      dispatch(setShowSearcher(false))
      dispatch(setShowNavbar(false))
      dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
      dispatch(setShowSearcher(false))
    }
  }

  const handleAddPlace = (type) => {
    navigate(`/choose-from-map/?add_favorite_place=${type}`)
  }

  const PlaceButton = ({ place, type }) => {
    let placeName
    if (place) {
      placeName = type === 'home'
        ? t('home.places.home')
        : (type === 'work' ? t('home.places.work') : place?.name) ||
        place?.address
    } else {
      placeName = type === 'home'
        ? t('home.places.add_home')
        : type === 'work'
          ? t('home.places.add_work')
          : t('home.places.add_favorite_place')
    }

    return (
      <Button
        onClick={() => place ? handlePlaceClick(place) : handleAddPlace(type)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#ffffff !important',
          boxSizing: 'border-box !important',
          borderRadius: '10px !important',
          '&:hover': {
            backgroundColor: '#f6f8f9 !important'
          },
          padding: '10px',
          boxShadow: pathname === '/' && mode ? '0 0 4px 0 rgb(0 0 0 / 20%)' : '0 6px 12px 0 rgb(0 0 0 / 5%) !important',
          gap: '10px',
          minWidth: '45%'
        }}
      >
        <PlaceIconContainer
          bgColor={type === 'place' ? '#F06292' : REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
        >
          {type === 'home'
            ? <HomeRounded
                style={{
                  color: theme.palette.primary.main,
                  fontSize: '22px'
                }}
              />
            : type === 'work'
              ? <WorkRounded
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: '22px'
                  }}
                />
              : <FavoriteRounded
                  style={{ color: '#F06292', fontSize: '22px' }}
                />}
        </PlaceIconContainer>
        <PlaceName>
          {placeName}
        </PlaceName>
      </Button>
    )
  }

  return (
    <>
      {mode
        ? (
            userData &&
              <SwipeableContainerH
                style={{ position: 'unset' }}
                padding={padding}
              >
                {!userSavedPlaces.some(place => place.type === 'home') &&
                  <PlaceButton type='home' />}
                {userSavedPlaces?.filter(place => place.type === 'home')
                  .map((place, index) => <PlaceButton place={place} key={index} type={place.type} />)}
                {!userSavedPlaces.some(place => place.type === 'work') &&
                  <PlaceButton type='work' />}
                {userSavedPlaces?.filter(place => place.type === 'work')
                  .map((place, index) => <PlaceButton place={place} key={index} type={place.type} />)}
                {userSavedPlaces?.filter(place => place.type === 'place')
                  .map((place, index) => <PlaceButton place={place} key={index} type={place.type} />)}
                <PlaceButton type='place' />
              </SwipeableContainerH>
          )
        : (
          <DesktopContainer>
            {savedPlaces?.map((place, index) => {
              if (Number(place.city_id) === Number(cityConfig?.city_id)) {
                return (
                  <Button
                    onClick={() => handlePlaceClick(place)}
                    border={border}
                    key={index}
                    totalplaces={savedPlaces.length}
                    sx={{
                      backgroundColor: '#ffffff !important',
                      boxSizing: 'border-box !important',
                      borderRadius: '10px !important',
                      marginRight: !mode ? 0 : '8px !important',
                      minWidth: mode ? savedPlaces?.length > 2 ? '40vw !important' : 'calc(50vw - 20px) !important' : '100% !important',
                      '&:hover': {
                        backgroundColor: '#f6f8f9 !important'
                      },
                      '&:last-child': {
                        margin: '0 !important'
                      },
                      padding: '10px',
                      boxShadow: pathname === '/' && mode && '0 0 4px 0 rgb(0 0 0 / 20%), 0 6px 12px 0 rgb(0 0 0 / 8%) !important',
                      overflow: 'hidden'
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      width: '100%'
                    }}
                    >
                      <PlaceIconContainer
                        bgColor={place.type === 'place' ? '#F06292' : REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
                      >
                        {place.type === 'home'
                          ? (
                            <HomeRounded
                              sx={{
                                color: REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
                                fontSize: '22px'
                              }}
                            />
                            )
                          : (place.type === 'work'
                              ? (
                                <WorkRounded
                                  sx={{
                                    color: REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
                                    fontSize: '22px'
                                  }}
                                />
                                )
                              : (
                                <FavoriteRounded
                                  sx={{ color: '#F06292', fontSize: '22px' }}
                                />
                                )
                            )}
                      </PlaceIconContainer>
                      <PlaceName>
                        {place.type === 'home' ? t('home.places.home') : (place.type === 'work' ? t('home.places.work') : place.name) || place.address}
                      </PlaceName>
                    </div>

                  </Button>
                )
              }
            })}
          </DesktopContainer>
          )}
    </>
  )
}

export default SavedPlaces
