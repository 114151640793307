import {
  setDataTimeValue,
  setOpenTripPreview, setSelectDateTimeLabel,
  setTimestamp
} from '../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalResultFilters } from '../db/getLocalResultFilters'
import { getLocalUserUid } from '../db/getLocalUserUid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useTripSearch = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const uid = getLocalUserUid()

  const timestamp = useSelector(state => state?.ui?.dataTimeValue)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const userData = useSelector(state => state?.user?.userData)

  const [arriveBy, setArriveBy] = useState(false)
  const [clearedDate, handleClearedDateChange] = useState()
  const [transfer, setTransfer] = useState()

  useEffect(() => {
    if (clearedDate) {
      dispatch(setDataTimeValue(clearedDate._d.getTime()))
      dispatch(setSelectDateTimeLabel(clearedDate?.format('ddd, HH:mm')))
      setArriveBy(true)
    }
  }, [clearedDate])

  const handleSearch = () => {
    const localFilters = getLocalResultFilters()

    const citySearchFilters = localFilters?.find((filters) => filters?.city_id === cityConfig?.city_id)

    if (citySearchFilters) { // current city search filters
      const transports = citySearchFilters?.transportation_types
        ?.filter(type => type.checked)
        ?.map(type => type.transport_type_id).toString()

      setTransfer(getTransferValue(citySearchFilters))

      const params = {
        city_id: cityConfig?.city_id.toString(),
        lat_from: from?.latLng?.lat,
        lng_from: from?.latLng?.lng,
        lat_to: to?.latLng?.lat,
        lng_to: to?.latLng?.lng,
        radius: citySearchFilters?.walks * 100,
        invited_by: uid,
        referrer_name: userData?.display_name ? userData.display_name : null,
        log_event: 'share',
        transport_types: transports?.toString(),
        locale: (window?.navigator?.language || navigator?.browserLanguage).substring(0, 2),
        self_search: false,
        transfer: transfer || null,
        arrive_by: arriveBy,
        timestamp: timestamp || null
      }

      dispatch(setTimestamp(new Date().getTime()))
      dispatch(setOpenTripPreview(false))

      navigate(`/trip_share?ciudad_id=${params.city_id}&latd=${params.lat_from}&lngd=${params.lng_from}&lath=${params.lat_to}&lngh=${params.lng_to}&radio=${params.radius}&invitedby=${params.invited_by}&referrerName=${params.referrer_name}&logEvent=${'share'}&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
    }
  }

  // returns a boolean or null value based on the value of the transport filter
  const getTransferValue = (currentTripFilters) => {
    if (currentTripFilters?.type_of_route === 'only-directs') {
      return false
    } else if (currentTripFilters?.type_of_route === 'only-transfers') {
      return true
    } else {
      return null
    }
  }

  return {
    handleSearch,
    handleClearedDateChange,
    clearedDate,
    arriveBy,
    getTransferValue,
    transfer
  }
}
