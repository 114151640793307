import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { ArrowBack, CloseRounded, FilterListRounded, SwapHoriz } from '@mui/icons-material'
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../theme/theme'
import { useTripSearch } from '../../hooks/useTripSearch'
import { useTripDirections } from '../../hooks/useTripDirections'
import { setAutocompleteOrientation, setTripResponse } from '../../actions/ui'
import logoUalabee from '../../img/ualabeelogo.svg'
import { Layout } from '../../uicomponents/layouts/Layout'
import { TripOptions } from '../../uicomponents/TripOption'

import NavBar from '../NavBar'
import { Walk } from '../../uicomponents/filters/Walk'
import { RouteTypes } from '../../uicomponents/filters/RouteTypes'
import { TransportTypes } from '../../uicomponents/filters/TransportTypes'
import { RememberFilters } from '../../uicomponents/filters/RememberFilters'
import { SearchFiltersButton } from '../../uicomponents/filters/SearchFiltersButton'
import { Loading as LoadingLinear } from '../../uicomponents/mobile/Loading'
import { FilterDrawer } from '../FilterDrawer'
import maplibregl from 'maplibre-gl'
import { CustomMap } from '../../uicomponents/Map'
import { useMap } from 'react-map-gl'
import { REACT_APP_IS_IFRAME, REACT_APP_HARDCODED_LOGO, REACT_APP_PALLET_PRIMARY_MAIN_COLOR, REACT_APP_LOGO_EXTERNAL_LINK, REACT_APP_IFRAME_LOGO } from '../../constants/config'

const DestinationPreview = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { map } = useMap()

  const mobile = useMediaQuery('(max-width:480px)')

  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)
  const tripResponse = useSelector(state => state?.ui?.tripResponse)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const loading = useSelector(state => state?.ui?.loading)

  const [openFilters, setOpenFilters] = useState(false)

  useEffect(() => {
    if (tripResponse) {
      dispatch(setTripResponse(undefined))
    }
  }, [])

  useEffect(() => {
    if (!from?.latLng || !to?.latLng || !map) return

    const p1 = new maplibregl.LngLat(from.latLng.lng, from.latLng.lat)
    const p2 = new maplibregl.LngLat(to.latLng.lng, to.latLng.lat)

    const bounds = new maplibregl.LngLatBounds(p1, p2)

    map?.fitBounds(bounds, {
      padding: {
        top: 80,
        left: mobile ? 80 : 482,
        right: 80,
        bottom: 80
      }
    })
  }, [from, to, map])

  const {
    handleLocationClick,
    handleExchangeTravelDirections,
    handleChangeTravelDirection
  } = useTripDirections()

  const handleBack = () => {
    dispatch(setAutocompleteOrientation('to'))
    navigate(-1)
  }

  const handleClose = () => {
    navigate('/')
  }

  return (
    <Layout>
      {loading && <LoadingLinear />}
      <Box
        sx={{
          position: 'relative',
          width: '100vw',
          height: `${window.innerHeight}px`,
          maxHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}
      >
        {openFilters &&
          <Filters
            openFilters={openFilters}
            setOpenFilters={setOpenFilters}
            map={map}
          />}

        <TripPreview
          handleBack={handleBack}
          handleClose={handleClose}
          handleLocationClick={handleLocationClick}
          handleChangeTravelDirection={handleChangeTravelDirection}
          handleExchangeTravelDirections={handleExchangeTravelDirections}
          setOpenFilters={setOpenFilters}
        />

        {mobile &&
          <Search
            map={map}
            setOpenFilters={setOpenFilters}
          />}
        <CustomMap
          showStopsNearby={false}
          showDraggableDestinationMarkers
          attributionPosition='top-right'
          geoControlProps={{
            styles: {
              bottom: 185,
              right: 14
            }
          }}
        />
        {isIosApp && !REACT_APP_IS_IFRAME &&
          <NavBar />}
      </Box>
    </Layout>
  )
}

export default DestinationPreview

export const MarkerA = ({
  width,
  height
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={width}
      height={height}
      viewBox='0 0 150.000000 150.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,150.000000) scale(0.100000,-0.100000)'
        fill={REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
        stroke='none'
      >
        <path
          d='M685 1464 c-126 -19 -232 -74 -319 -164 -184 -190 -204 -470 -51 -686 56 -78 154 -154 242 -186 l62 -24 55 -112 c35 -70 61 -112 71 -112 9 0 37 43 72 115 39 77 63 115 74 115 9 0 52 18 96 40 135 67 235 188 279 335 24 84 22 220 -5 306 -51 160 -190 300 -347 349 -64 19 -178 31 -229 24z m217 -509 c48 -148 88 -271 88 -272 0 -2 -31 -3 -69 -3 -68 0 -69 1 -80 30 l-10 30 -85 0 -84 0 -7 -30 c-7 -30 -7 -30 -76 -30 -38 0 -69 3 -69 8 0 4 41 128 91 275 l92 268 61 -3 60 -3 88 -270z'
        />
        <path d='M737 950 c-33 -78 -33 -80 13 -80 46 0 45 -4 15 80 l-13 35 -15 -35z' />
        <path d='M724 115 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44 8z' />
      </g>
    </svg>
  )
}

export const MarkerB = ({
  width,
  height
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={width}
      height={height}
      viewBox='0 0 150.000000 150.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,150.000000) scale(0.100000,-0.100000)'
        fill={REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
        stroke='none'
      >
        <path
          d='M685 1444 c-126 -19 -232 -74 -319 -164 -184 -190 -204 -470 -51 -686 56 -78 154 -154 242 -186 l62 -24 55 -112 c35 -70 61 -112 71 -112 9 0 37 43 72 115 39 77 63 115 74 115 9 0 52 18 96 40 135 67 235 188 279 335 24 84 22 220 -5 306 -51 160 -190 300 -347 349 -64 19 -178 31 -229 24z m206 -64 c209 -64 349 -253 349 -469 0 -132 -43 -240 -134 -339 -57 -60 -142 -114 -219 -135 -39 -12 -45 -18 -87 -99 -25 -48 -49 -87 -54 -88 -5 -1 -28 36 -50 81 -23 46 -48 86 -56 90 -148 66 -190 92 -243 148 -256 268 -144 698 212 812 77 25 201 24 282 -1z'
        />
        <path
          d='M590 919 l0 -272 109 5 c95 4 114 8 154 31 94 56 121 164 61 250 -27 38 -30 50 -27 98 8 109 -57 158 -209 159 l-88 0 0 -271z m175 126 c0 -14 -8 -21 -27 -23 -24 -3 -28 0 -28 23 0 23 4 26 28 23 19 -2 27 -9 27 -23z m24 -160 c51 -26 51 -74 1 -100 -16 -8 -40 -15 -55 -15 -24 0 -25 2 -25 65 0 63 1 65 25 65 15 0 39 -7 54 -15z'
        />
        <path d='M724 105 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44 8z' />
      </g>
    </svg>
  )
}

export const TripPreview = ({
  handleBack,
  handleClose,
  handleChangeTravelDirection,
  handleExchangeTravelDirections,
  setOpenFilters,
  showSearchButton = true
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const mobile = useMediaQuery('(max-width:480px)')

  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const handleOpenFilters = () => {
    setOpenFilters(true)
  }

  return (
    <Box
      sx={{
        width: '450px',
        maxHeight: pathname.includes('trip_share') ? '40%' : 'auto',
        zIndex: 401,
        position: pathname.includes('trip_share') ? 'relative' : !showSearchButton ? 'sticky' : 'absolute',
        margin: !showSearchButton ? '0 0 16px 0' : 0,
        top: pathname.includes('trip_share') ? 0 : '16px',
        left: pathname.includes('trip_share') ? 0 : '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px rgba(65, 115, 205, 0.2)',
        borderRadius: '16px',
        padding: '16px 24px 24px',
        boxSizing: 'border-box',
        '@media (max-width:480px)': {
          position: 'initial',
          width: '100%',
          height: '224px',
          borderRadius: '0 0 8px 8px',
          top: 0,
          left: 0,
          padding: 0
        }
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 0,
          margin: '0 0 20px 0',
          '@media (max-width:480px)': {
            padding: '16px 16px 16px 8px',
            margin: 0,
            justifyContent: 'flex-start'
          }
        }}
      >
        <IconButton
          edge='start'
          onClick={handleBack}
          sx={{
            '@media (max-width:480px)': {
              margin: '0 8px 0 0'
            }
          }}
        >
          <ArrowBack variant='secondary.main' />
        </IconButton>
        {mobile
          ? (
            <Typography
              variant='h1'
              sx={{
                fontSize: '16px'
              }}
            >
              {t('search_preview.title')}
            </Typography>
            )
          : (
            <>{REACT_APP_IS_IFRAME
              ? (
                <a href={REACT_APP_LOGO_EXTERNAL_LINK} target='blank'>
                  <img
                    style={{
                      width: 'auto',
                      height: '38px'
                    }}
                    alt='logo'
                    src={REACT_APP_IFRAME_LOGO}
                  />
                </a>
                )
              : (
                <img
                  style={{
                    width: 'auto',
                    height: '38px'
                  }}
                  alt='logo'
                  src={REACT_APP_HARDCODED_LOGO || logoUalabee}
                />
                )}

              <IconButton edge='end' onClick={handleClose}>
                <CloseRounded variant='secondary.main' />
              </IconButton>
            </>
            )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '0',
          width: '100%',
          '@media (max-width:480px)': {
            margin: '0 24px 0 28px',
            width: 'calc(100% - 48px)'
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '8px'
              }}
            >
              <MarkerA
                width='30'
                height='30'
              />
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  margin: '0 8px'
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    padding: '5px 15px',
                    border: '1px solid #E0E8F7',
                    height: '40px',
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                    borderRadius: '14px',
                    textOverflow: 'ellipsis',
                    textTransform: 'none',
                    color: from?.name !== t('hint_location_A') ? '#222831' : 'error.main',
                    fontSize: '16px',
                    justifyContent: 'flex-start',
                    '@media (max-width:480px)': {
                      fontSize: '14px'
                    },
                    '&:hover': {
                      backgroundColor: '#fff'
                    }
                  }}
                  variant='outlined'
                  onClick={() => handleChangeTravelDirection('from')}
                >
                  {from?.name || t('hint_location_A')}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '8px'
              }}
            >
              <MarkerB
                width='30'
                height='30'
              />
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  margin: '0 8px'
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    padding: '5px 15px',
                    border: '1px solid #E0E8F7',
                    height: '40px',
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                    borderRadius: '14px',
                    textOverflow: 'ellipsis',
                    textTransform: 'none',
                    color: to?.name !== t('hint_location_B') ? '#222831' : 'error.main',
                    fontSize: '16px',
                    justifyContent: 'flex-start',
                    '@media (max-width:480px)': {
                      fontSize: '14px'
                    },
                    '&:hover': {
                      backgroundColor: '#fff'
                    }
                  }}
                  variant='outlined'
                  onClick={() => handleChangeTravelDirection('to')}
                >
                  {to?.name || t('hint_location_B')}
                </Button>
              </Box>
            </Box>
          </Box>
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: `${theme.palette.primary.main}14`,
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}14`
              },
              '&:disabled': {
                backgroundColor: `${theme.palette.icons.secondary.main}14`
              }
            }}
            onClick={handleExchangeTravelDirections}
            disabled={to?.name === t('hint_location_B') && from?.name === t('hint_location_A')}
          >
            <SwapHoriz
              variant={to?.name === t('hint_location_B') && from?.name === t('hint_location_A') ? 'secondary.main' : 'primary.main'}
              sx={{
                transform: 'rotate(90deg)'
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 0,
          justifyContent: 'space-between',
          '@media (max-width:480px)': {
            padding: '0 20px',
            margin: 0
          },
          margin: '17px 0 0 0'
        }}
      >
        <TripOptions />

        {!mobile &&
          <Button
            sx={{
              color: theme.text.secondary.main,
              fontSize: '16px',
              '&:hover': {
                backgroundColor: `${theme.text.secondary.main}14`
              }
            }}
            onClick={handleOpenFilters}
            startIcon={<FilterListRounded variant='secondary.main' />}
          >
            {t('search_preview.alt_title')}
          </Button>}

      </Box>
      {showSearchButton && !mobile &&
        <Search />}
    </Box>
  )
}

const Search = ({ setOpenFilters }) => {
  const { t } = useTranslation()
  const { handleSearch } = useTripSearch()

  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const mobile = useMediaQuery('(max-width:480px)')

  const disabled = (from?.name === t('hint_location_A') || to?.name === t('hint_location_B')) || !from && !to

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '26px 0 0 0',
        '@media (max-width:480px)': {
          width: '100%',
          position: 'fixed',
          zIndex: 500,
          bottom: '24px',
          display: 'flex'
        }
      }}
    >
      <Button
        sx={{
          width: '220px',
          borderRadius: '10px',
          fontSize: '16px',
          '@media (max-width:480px)': {
            width: '90%'
          },
          '&:disabled': {
            backgroundColor: '#a6a9ac'
          }
        }}
        onClick={handleSearch}
        disableElevation
        disabled={disabled}
        variant='contained'
      >
        {t('search_preview.search')}
      </Button>
      {mobile &&
        <Box
          sx={{
            position: 'absolute',
            right: 16,
            bottom: 100
          }}
        >
          <IconButton
            sx={{
              backgroundColor: '#fff',
              padding: '12px',
              width: '56px',
              height: '56px',
              boxShadow: '0px 4px 8px rgb(65 115 205 / 20%)',
              '&:hover': {
                backgroundColor: '#fff'
              }
            }}
            onClick={() => setOpenFilters(true)}
          >
            <FilterListRounded variant='primary.main' />
          </IconButton>
        </Box>}
    </Box>
  )
}

export const Filters = ({ setOpenFilters, map, openFilters }) => {
  const { t } = useTranslation()

  const mobile = useMediaQuery('(max-width:480px)')

  return (
    <>
      {mobile
        ? (
          <FilterDrawer
            openFilter={openFilters}
            setOpenFilter={setOpenFilters}
            arriveBy=''
          />
          )
        : (<Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              position: 'absolute',
              zIndex: 450,
              backgroundColor: '#fff',
              top: '16px',
              left: '16px',
              width: '450px',
              height: 'calc(100vh - 32px)',
              borderRadius: '16px'
            }}
           >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              borderRadius: '16px 16px 0 0',
              padding: '8px 24px',
              border: '1px solid rgb(232, 237, 238)'
            }}
          >
            <Typography
              variant='h1'
              sx={{
                margin: 0,
                fontSize: '18px',
                fontWeight: 500
              }}
            >
              {t('search_preview.filters.title')}
            </Typography>
            <IconButton
              edge='end'
              onClick={() => setOpenFilters(false)}
            >
              <CloseRounded variant='secondary.main' />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: '16px',
              height: '100%',
              overflow: 'auto'
            }}
          >
            <Walk />
            <RouteTypes />
            <TransportTypes />
          </Box>
          <Box
            sx={{ padding: '16px' }}
          >
            <RememberFilters />
            <Box
              sx={{
                borderTop: '1px solid #22283126',
                padding: '16px 0px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SearchFiltersButton
                setOpenFilters={setOpenFilters}
                map={map}
              />
            </Box>
          </Box>

        </Box>
          )}
    </>
  )
}
