import {
  setAutocompleteOrientation,
  setFromPlacesSelected,
  setOpenTripPreview,
  setSearch,
  setShowSearcher,
  setToPlacesSelected,
  setTripResponse,
  setTripSelected
} from '../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../db/getLocalUserUid'
import { useNavigate, useLocation } from 'react-router-dom'
import useQuery from './useQuery'
import { logEvent } from '../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import maplibregl from 'maplibre-gl'

export const useTripDirections = () => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const navigate = useNavigate()
  const query = useQuery()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { mode, orientation } = useSelector(state => state?.ui)
  const userData = useSelector(state => state?.user?.userData)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const bounds = useSelector(state => state?.map?.bounds)

  const params = {
    city_id: Number(query.get('ciudad_id')),
    lat_from: query.get('latd'),
    lng_from: query.get('lngd'),
    lat_to: query.get('lath'),
    lng_to: query.get('lngh'),
    radius: Number(query.get('radio')),
    self_search: query.get('self_search') === 'true',
    transport_types: query.get('transport_types'),
    locale: query.get('locale'),
    arrive_by: query.get('arrive_by') === 'true',
    invited_by: query.get('invitedby'),
    referrer_name: query.get('referrerName'),
    timestamp: query.get('timestamp'),
    transfer: query.get('transfer') === 'only-directs' ? false : (query.get('transfer') === 'only-transfers' ? true : null)
  }

  const handleLocationClick = (sense) => {
    const finalSense = sense || orientation

    dispatch(setAutocompleteOrientation(finalSense))

    const userLocationObj = {
      name: t('my_location'),
      latLng: userPosition
    }

    if (userPosition && bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
      if (finalSense === 'to') {
        dispatch(setToPlacesSelected(userLocationObj))
      } else if (finalSense === 'from') {
        dispatch(setFromPlacesSelected(userLocationObj))
      }
    }

    if (mode) {
      navigate('/destination-preview')
    } else if (pathname.includes('trip_share')) {
      navigate(`/trip_share?ciudad_id=${params?.city_id}&latd=${userPosition?.lat}&lngd=${userPosition?.lng}&lath=${params.lat_to}&lngh=${params.lng_to}&radio=${params.radius}&invitedby=${params.invited_by}&referrerName=${params.referrer_name}&logEvent=${'share'}&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
    }
  }

  const handleChangeTravelDirection = (direction) => {
    dispatch(setAutocompleteOrientation(direction))

    dispatch(setAutocompleteOrientation(direction))

    dispatch(setSearch('', new Date()))
    dispatch(setShowSearcher(true))
    dispatch(setOpenTripPreview(false))

    navigate('/search')
  }

  const handleExchangeTravelDirections = () => {
    dispatch(setTripResponse(undefined))
    dispatch(setTripSelected(undefined))

    const eventParams = {
      city_id: cityConfig?.city_id?.toString(), // String
      lat: userPosition ? userPosition?.lat : null, // Double
      lng: userPosition ? userPosition?.lng : null, // Double
      os: 'web', // String
      user_birthday_timestamp: userData ? userData?.birthday?.long_value : null, // Long
      user_id: uid, // String
      user_gender: userData ? userData?.gender : null // String
    }

    // Send events to analytics
    logEvent('swap_location', eventParams)

    // Exchange directions ( to => from and from => to ) in redux
    if (from?.name === t('hint_location_A') || to.name === t('hint_location_B')) {
      if (from?.name === t('hint_location_A') && to?.name !== t('hint_location_B')) {
        dispatch(setFromPlacesSelected(to))
        dispatch(setToPlacesSelected({
          name: t('hint_location_B'),
          latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
        }))
      } else if (to?.name === t('hint_location_B') && from.name !== t('hint_location_A')) {
        dispatch(setToPlacesSelected(from))
        dispatch(setFromPlacesSelected({
          name: t('hint_location_A'),
          latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
        }))
      }
    } else {
      if (pathname.includes('trip_share') && !mode) {
        navigate(`/trip_share?ciudad_id=${params?.city_id}&latd=${to?.latLng?.lat}&lngd=${to?.latLng?.lng}&lath=${from?.latLng?.lat}&lngh=${from?.latLng?.lng}&radio=${params.radius}&invitedby=${params.invited_by}&referrerName=${params.referrer_name}&logEvent=${'share'}&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
      }

      dispatch(setFromPlacesSelected(to))
      dispatch(setToPlacesSelected(from))
    }
  }

  return {
    handleLocationClick,
    handleChangeTravelDirection,
    handleExchangeTravelDirections
  }
}
